export const countUnique = <T, K extends keyof T>(array: T[], field: K): number => {
  if (!array) return 0;

  return new Set(array.map((item) => item[field])).size;
};

export const allBallancesColumnConfig = <T, K extends keyof T>(array: T[], field: K) => {
  const columnCount = countUnique(array, field);
  return {
    span: columnCount * 2,
    xs: 24,
    sm: 24,
    md: 24,
    xl: columnCount * 2,
    xxl: columnCount * 2
  };
};

export const chartsColumnConfig = <T, K extends keyof T>(array: T[], field: K) => {
  const columnCount = countUnique(array, field);
  const dynamicWidth = Math.max(columnCount * 3, 5);
  return {
    span: dynamicWidth,
    xs: 24,
    md: 12,
    xl: dynamicWidth,
    xxl: dynamicWidth,
    style: {
      flex: '1 1 100%',
      minWidth: Math.max(columnCount * 5 * 30, 300)
    }
  };
};

export const shiftMainStatsColumnConfig = {
  span: 12,
  xs: 24,
  sm: 24,
  md: 24,
  xl: 12,
  xxl: 8
};
