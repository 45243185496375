import './AddPayment.scss';

import { Col, DatePicker, Form, FormInstance, Input, Row, Select, Spin } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useDictionary } from 'api/hooks/dictionaries/useDictionary';
import { useBankAccountsList } from 'api/hooks/finances/useBankAccountsList';
import { useCreatePayment } from 'api/hooks/payments/useCreatePayment';
import { useEditPayment } from 'api/hooks/payments/useEditPayment';
import { IPayment, IPaymentPayload, PaymentFormData } from 'api/interfaces/Payment';
import { MODAL, useModal } from 'api/store/modalStore';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import { billType } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import { AddDictionaryModal } from 'layout/modals/addDictionary/AddDictionaryModal';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';
import { DATE_TIME_VIEW_FORMAT } from 'constants/common.tsx';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IPayment;
  locale: ILocale;
  setFormIsTouched?: (touched) => void;
}>;

export const AddPaymentForm = ({
  form,
  initialValues,
  children,
  locale,
  setFormIsTouched
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const formEditMode = Boolean(initialValues?.id);
  const { open, closeModal } = useModal();

  const { dictionary: billPaymentTypes } = useDictionary(clinicId, DICTIONARY.billCategory);
  const { bankAccounts } = useBankAccountsList(clinicId);
  const billCategories = getOptions(billPaymentTypes);

  const createPayment = useCreatePayment(clinicId, closeModal);
  const editPayment = useEditPayment(clinicId, initialValues?.id, closeModal);

  const [billCategorySearchValue, setBillCategoryearchValue] = useState('');

  const editingOfNameDisabled =
    ['sale', 'invoice', 'appointment'].includes(initialValues?.bill?.sub_type) ||
    initialValues?.name === 'internal_transfer';

  const onSubmit = (data: IPaymentPayload): void => {
    const payload = { ...data };
    if (data?.amount) {
      payload.amount = Number(data.amount);
    }
    if (data?.date) {
      payload.date = dayjs(data.date).utc().format('DD.MM.YYYYTHH:mm:ss');
    }

    if (initialValues?.id) {
      editPayment.mutate(payload);
    } else {
      createPayment.mutate(payload);
    }
  };

  const accountsList = bankAccounts?.map((item) => (
    <Option key={item.name} title={item.name} value={item.id}>
      {item?.name} {item?.account_number ? `, ${item?.account_number}` : ''}
    </Option>
  ));

  const mapFormData = (): PaymentFormData => {
    const data = { ...initialValues,
      date: initialValues?.date ? dayjs(initialValues?.date) : dayjs()
    } as PaymentFormData;
    if (data?.financial_account?.id) {
      data.account_id = data?.financial_account?.id;
    }
    if (data.name === 'internal_transfer') {
      data.name = locale.labels.transferBetweenAccounts;
    }
    return data;
  };

  const filterBillCategoryOption = (input: string, option): boolean =>
    option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  return (
    <Spin spinning={createPayment.isPending || editPayment.isPending}>
      <Form
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={mapFormData()}
      >
        <Form.Item name='clinic_id' hidden>
          <Input />
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item label={locale.labels.name} name={'name'} rules={[requiredRule()]}>
              <Input disabled={editingOfNameDisabled} />
            </Form.Item>
            <Form.Item label={locale.labels.amount} rules={[requiredRule()]} name='amount'>
              <VLXInputNumber
                disabled={formEditMode}
                onChange={(value) => {
                  form.setFieldsValue({ amount: value });
                }}
              />
            </Form.Item>
            <Form.Item
              name='payment_type'
              rules={[requiredRule()]}
              label={locale.labels.paymentType}
            >
              <Select
                disabled={formEditMode}
                filterOption={false}
                options={useLocalizedList(billType)}
              />
            </Form.Item>
            <Form.Item name='bill_payment_type_id' label={locale.labels.category}>
              <Select
                showSearch
                virtual={false}
                onSearch={(val) => setBillCategoryearchValue(val)}
                filterOption={filterBillCategoryOption}
                notFoundContent={
                  <div onClick={() => open(MODAL.addDictionary)} id='addNewServiceGroupBtn'>
                    + {locale.labels.addBillCategory}
                  </div>
                }
              >
                {billCategories}
              </Select>
            </Form.Item>
            <Form.Item
              name={'date'}
              label={locale.labels.date}
            >
              <DatePicker showTime format={DATE_TIME_VIEW_FORMAT} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              rules={[requiredRule()]}
              className='add-equipment-form__multi-select-field'
              label={locale.labels.account}
              name='account_id'
            >
              <Select
                style={{ overflow: 'hidden' }}
                filterOption={(input, option) =>
                  option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                }
                showSearch
              >
                {accountsList}
              </Select>
            </Form.Item>
            <TextEditor label={locale.labels.notes} name={['notes']} rows={2} />
            <TextEditor label={locale.labels.description} name={['description']} rows={2} />
          </Col>
        </Row>
        {children}
      </Form>
      <AddDictionaryModal
        defaultDictionaryNameValue={billCategorySearchValue}
        title={locale.labels.addBillCategory}
        dictionaryKey={DICTIONARY.billCategory}
        onSuccess={(id) => {
          form.setFieldValue('bill_payment_type_id', id);
          if (formEditMode) {
            setFormIsTouched(true);
          }
        }}
      />
    </Spin>
  );
};
