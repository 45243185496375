import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientDetails } from 'api/interfaces/Patient';

export const usePatientDetails = (clinicId: number, patientId: number) => {
  return useQuery<IPatientDetails>({
    queryKey: ['patientDetails', clinicId, patientId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}`);
      return data;
    },
    enabled: !!patientId
  });
};
