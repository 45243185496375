import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Select, Tag } from 'antd';
import { useOpenedShift } from 'api/hooks/shifts/useOpenedShift';
import useShiftStore from 'api/store/shiftStore';
import { useSidebar } from 'api/store/sidebarStore';
import useUserStore from 'api/store/userStore';
import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import LogoMobile from 'assets/img/logo-mobile.svg';
import LogoSvg from 'assets/img/logo.svg';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { Actions } from 'layout/components/actions/Actions';
import Logo from 'layout/components/logo/Logo';
import * as ls from 'local-storage';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { Header: AntdHeader } = Layout;
const { Option } = Select;

type Props = {
  selectedClinicId: number;
};

export const Header = ({ selectedClinicId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const locale = useLocale('private.header');
  const token: string | undefined = ls.get('auth_token');

  const { sidebarOpened, sidebarToggle } = useSidebar();
  const shiftOpened = useShiftStore(({ shift }) => shift?.open);
  const getOpenedShift = useOpenedShift();
  const { userAccesses: clinics, id: authorized } = useUserStore(({ user }) => user);

  useEffect((): void => {
    if (token && authorized && clinics?.length > 0) {
      getOpenedShift.mutate({ clinicId: clinicId || clinics[0].clinicId, token });
    }
  }, [authorized, clinicId, token]);

  const onClinicSelect = (clinicId: number): void => {
    dispatch({ type: 'RESET_STORE' });
    navigate(`clinic/${clinicId}/administration/info`);
  };

  const clinicsList = useMemo(
    (): JSX.Element[] =>
      (clinics || []).map((item) => (
        <Option
          className='clinic-select-option clinic-select-option__in-header'
          key={`${item.clinicName}${item.clinicId}`}
          title={item.clinicName}
          value={item.clinicId}
        >
          <div className='clinic-select-option__logo-container'>
            <img src={item?.clinicAvatar ? item?.clinicAvatar : clinicDefaultLogo} />
          </div>
          <div className='clinic-select-option__name-container'> {item.clinicName}</div>
        </Option>
      )),
    [clinics]
  );

  return (
    <AntdHeader>
      <Row
        align='middle'
        className={
          authorized ? 'header-container header-container__authorized' : 'header-container'
        }
      >
        <Col span={'auto'} sm={authorized ? 3 : 4} lg={0}>
          <Button
            type='link'
            size='large'
            style={{ color: 'white' }}
            icon={sidebarOpened ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={sidebarToggle}
          />
        </Col>
        <Row align={'middle'} className='header-container__left-side'>
          <Col className={'header-left-col'}>
            <Logo src={LogoSvg} className='logo-desktop' />
            <Logo src={LogoMobile} className='logo-mobile' />
            {/* {authorized ? <></> : <HorizontalLayout />} */}
          </Col>
          {!!authorized && clinics?.length > 0 && (
            <>
              <div className='header-pipe'>|</div>
              <Select className='clinic-select' onSelect={onClinicSelect} value={selectedClinicId}>
                {clinicsList}
              </Select>
            </>
          )}

          {/*Shift status tag*/}
          {!!authorized && clinics?.length > 0 && (
            <Col className={'hidden-on-small'}>
              <Tag color={shiftOpened ? 'green' : 'red'}>
                {shiftOpened ? locale.labels.shiftOpened : locale.labels.shiftClosed}
              </Tag>
            </Col>
          )}
        </Row>
        <Actions />
      </Row>
    </AntdHeader>
  );
};
