import { Button, Row, Table, TableColumnProps } from 'antd';
import { useEditSale } from 'api/hooks/sales/useEditSale';
import { ISaleDetails, ISaleStock } from 'api/interfaces/Sale';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import { useMemo, useState } from 'react';

import { FORMATS } from '../utils/constants';
import { formPayloadItems } from '../utils/formPayloadItems';

type Props = {
  editItem: (val: ISaleStock, index: number) => void;
  locale: ILocale;
  saleDetails: ISaleDetails;
  saleEditable: boolean;
};

export const SaleItemsPreviewTable = ({
  saleDetails,
  editItem,
  locale,
  saleEditable
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { unitFormat, packageFormat } = usePlural();
  const [deleteItemIndex, setDeleteItemIndex] = useState<string>();

  const editSale = useEditSale(clinicId, saleDetails);

  const deleteItem = (): void => {
    const payload = {
      client_id: saleDetails.client?.id || null,
      vat_included: false,
      discount_percentage: saleDetails.stock_discount.percentage || 0,
      items: saleDetails.stock
        .filter((_, index) => index !== +deleteItemIndex)
        .map(formPayloadItems)
    };

    editSale.mutate(payload);
    setDeleteItemIndex(null);
  };

  const getQuantity = (item: ISaleStock): number => {
    if (item.format === FORMATS.package)
      return item.quantity_in_units / item.quantity_per_package + item.quantity_in_packages;

    return item.quantity_per_package * item.quantity_in_packages + item.quantity_in_units;
  };

  const columns = useMemo(
    (): TableColumnProps<ISaleStock>[] => [
      {
        key: 'name',
        title: locale.labels.stock,
        render: (e) => `${e.name} (${e.manufacturer})`
      },
      {
        key: 'saleItem',
        title: locale.labels.saleItem,
        render: (e) =>
          e.format === FORMATS.package
            ? packageFormat(1, e.packaging_format)
            : unitFormat(1, e.unit_of_measurement)
      },
      {
        key: 'price',
        title: locale.labels.price,
        render: (e) =>
          `${
            e.format === FORMATS.package
              ? money(e.stock_price?.price_per_package)
              : money(e.stock_price?.price_per_unit)
          } ${locale.labels.uah}`
      },
      {
        key: 'count',
        title: locale.labels.count,
        render: getQuantity
      },
      {
        key: 'sum',
        title: locale.labels.sum,
        render: (e) => `${money(e.price)} ${locale.labels.uah}`
      },
      {
        key: 'operation',
        title: locale.labels.actions,
        render: (e, _, index) => (
          <Row>
            <Button
              onClick={() => editItem(e, index)}
              type='primary'
              shape={'circle'}
              style={{ marginRight: 20 }}
            >
              <span className='icofont icofont-edit-alt' />
            </Button>
            <Button
              onClick={() => setDeleteItemIndex(String(index))}
              danger
              type='primary'
              shape={'circle'}
            >
              <span className='icofont icofont-trash' />
            </Button>
          </Row>
        ),
        hidden: !saleEditable
      }
    ],
    [saleDetails, saleEditable]
  );

  return (
    Boolean(saleDetails.stock) && (
      <div id='sale-preview-table'>
        <Table
          pagination={false}
          className='accent-header stock-list'
          rowKey={(item) => `${item.id}_${crypto.randomUUID()}`}
          dataSource={saleDetails.stock}
          columns={columns}
        />
        {deleteItemIndex && (
          <ConfirmDeleteModal
            customTitle={locale.messages.deleteItemMsg.replace(
              /%placeholder%/,
              saleDetails.stock[deleteItemIndex].name
            )}
            open={deleteItemIndex}
            onOk={deleteItem}
            onCancel={() => setDeleteItemIndex(null)}
          />
        )}
      </div>
    )
  );
};
