import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPhoneChangePayload } from 'api/interfaces/User';

const mutationFn = async (payload: IPhoneChangePayload): Promise<{ phone_number: string }> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.USER}/phone/change`, payload);
  return response.data?.phone_number;
};

export const useChangePhone = (onSuccess: (phone) => void, onError: () => void) =>
  useMutation({ mutationFn, onSuccess, onError });
