import { Form, FormInstance, Modal } from 'antd';
import { useChangeAppointmentStatus } from 'api/hooks/appointments/useChangeAppointmentStatus';
import { useEditBill } from 'api/hooks/bills/useEditBill';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IBillDetails } from 'api/interfaces/Bills';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

import { AppointmentBillForm } from './AppointmentBillForm';

type Props = {
  bill: IBillDetails;
  appointment?: IAppointmentDetails;
  form: FormInstance;
  billDetailsPage?: boolean;
};

export const AppointmentBill = ({
  bill,
  appointment,
  form,
  billDetailsPage
}: Props): JSX.Element => {
  const userAccess = useAccess();
  const clinicId = useClinicId();
  const { closeModal } = useModal();
  const locale = useLocale('private.appointments.appointment-bill');
  const values = Form.useWatch([], form);

  const editBill = useEditBill(
    clinicId,
    bill?.id,
    () => {
      appointmentStatus.mutate({ appointmentId: appointment.id, clinicId, status: 'finished' });
      closeModal();
    },
    appointment?.id
  );
  const appointmentStatus = useChangeAppointmentStatus();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [finalTotalPrice, setFinalTotalPrice] = useState<number | string>(null);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect((): void => {
    const totalPrice =
      typeof bill?.final_amount === 'number' ? bill?.final_amount : bill?.amount || 0;

    setFinalTotalPrice(totalPrice);
  }, [bill]);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormValid(true);
      },
      () => {
        setFormValid(false);
      }
    );
  }, [values]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();

    setShowCancelModal(false);
  };

  const onSubmit = (): void => {
    const data = form.getFieldsValue();

    const getDiscount = ({ amount, percentage }: { amount: number; percentage: number }) => ({
      amount: amount ? Number(amount).toFixed(2) : null,
      percentage: percentage ? Math.round(percentage) : null
    });

    const payload = {
      name: bill?.name,
      description: bill?.description,
      date: bill?.date,
      amount: Number(finalTotalPrice).toFixed(2),
      vat_included: bill?.vat_included,
      bill_type: bill?.bill_type,
      services_discount: getDiscount(data.services_discount),
      stock_discount: data.stock_discount
        ? getDiscount(data.stock_discount)
        : { amount: '0.00', percentage: 0 },
      clinic_id: clinicId,
      client_id: bill?.client?.id
    };

    editBill.mutate(payload);
  };

  const onSaveModalClick = (): void => {
    if (!formValid) return;

    if (billDetailsPage) {
      onSubmit();
    } else {
      Modal.confirm(modalParams);
    }
  };

  const modalParams = {
    title: locale.labels.confirmModalTitle,
    content: locale.messages.confirmModalMessage,
    okText: locale.labels.confirmButton,
    cancelText: locale.labels.cancelButton,
    centered: true,
    onOk: onSubmit
  };

  return (
    <>
      {bill && (
        <AppointmentBillForm
          localization={locale}
          form={form}
          billDetails={bill}
          finalTotalPrice={finalTotalPrice}
          setFinalTotalPrice={setFinalTotalPrice}
          billDetailsPage={billDetailsPage}
        >
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.services}
            onSaveModalClick={onSaveModalClick}
            fixed
            editMode
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
          />
        </AppointmentBillForm>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
