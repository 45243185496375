import './VaccinationItemModal.scss';

import { AutoComplete, Avatar, Col, DatePicker, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import { useClientPatientsList } from 'api/hooks/patients/useClientPatientsList';
import { useStockList } from 'api/hooks/stock/useStockList';
import { useCreateVaccination } from 'api/hooks/vaccinations/useCreateVaccination';
import { useEditVaccination } from 'api/hooks/vaccinations/useEditVaccination';
import { IStock } from 'api/interfaces/Stock';
import { IVaccination, IVaccinationPayload } from 'api/interfaces/Vaccination';
import { TextEditor } from 'components/TextEditor';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { ClientsSelect } from 'layout/components/clientsSelect/ClientsSelect';
import { PatientsSelect } from 'layout/components/patientsSelect/PatientsSelect';
import { getDefaultAvatar } from 'pages/patients/utils/PatientHelper';
import React, { useEffect, useMemo, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { getDate } from 'utils/get-time';

const { Text } = Typography;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IVaccination & { owner: number };
  onFieldsChange: (e) => void;
  setFormEditMode: (e) => void;
  editMode: boolean;
  closeModal: () => void;
}>;

export const VaccinationItemForm = ({
  form,
  initialValues,
  children,
  setFormEditMode,
  onFieldsChange,
  editMode,
  closeModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.patients.patient-page');

  const [inputVaccinationNameValue, setInputVaccinationNameValue] = useState('');
  const [inputManufacturerNameValue, setInputManufacturerNameValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(initialValues?.owner);
  const [selectedPatientsId, setSelectedPatientsId] = useState<number>(initialValues?.patient_id);
  const [manufacturerDropdownOpen, setManufacturerDropdownOpen] = useState(false);

  const createVaccination = useCreateVaccination(clinicId, selectedPatientsId, closeModal);
  const editVaccination = useEditVaccination(
    clinicId,
    selectedPatientsId,
    initialValues?.id,
    closeModal
  );

  const { data: clientPatients } = useClientPatientsList(clinicId, selectedUserId);
  const { stocks } = useStockList(clinicId, { page: 0, items: 20 });

  useEffect(() => {
    if (selectedUserId) {
      form.setFields([{ name: 'owner', value: selectedUserId, errors: [] }]);
    }
  }, [selectedUserId]);

  const onSubmit = (data: IVaccinationPayload): void => {
    const payload = {
      ...data,
      expiration_date: getDate(data.expiration_date),
      date_of_vaccination: getDate(data.date_of_vaccination),
      date_of_next_vaccination: getDate(data.date_of_next_vaccination),
      manufacturer: data.manufacturer || null
    };

    if (editMode) {
      editVaccination.mutate(payload);
    } else {
      createVaccination.mutate(payload);
    }
  };

  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);

  const autoCompleteOptions = stocks.map((option: IStock) => ({
    key: `${option.id}-${crypto.randomUUID()}`,
    value: option.name?.toString(),
    manufacturer: option.manufacturer?.toString()
  }));

  const manufacturers = stocks.map((option: IStock): string => option.manufacturer?.toString());
  const uniqueManufacturers = manufacturers.filter((val, i, arr) => arr.indexOf(val) === i);

  const autoCompleteManufacturerOptions = uniqueManufacturers.map((manufacturer) => ({
    key: crypto.randomUUID(),
    value: manufacturer
  }));

  const onImmuneResponseChange = () => {
    const expirationDate = form.getFieldsValue().expiration_date;
    const nextVaccination = form.getFieldsValue().date_of_next_vaccination;
    if (!nextVaccination && expirationDate) {
      form.setFieldsValue({
        date_of_next_vaccination: dayjs(expirationDate).add(1, 'day')
      });
    }
  };

  const onNextVaccinationChange = () => {
    const expirationDate = form.getFieldsValue().expiration_date;
    const nextVaccination = form.getFieldsValue().date_of_next_vaccination;
    if (!expirationDate && nextVaccination) {
      form.setFieldsValue({
        expiration_date: dayjs(nextVaccination).subtract(1, 'day')
      });
    }
  };

  const patientsList = useMemo(
    () =>
      clientPatients?.data.map((item) => (
        <Option key={`patient_${item.id}`} value={item.id} title={item.name}>
          <Row align='middle' style={{ width: '100%' }}>
            <Col style={{ marginRight: '5px' }}>
              <div className={'pet-avatar-select'}>
                <Avatar src={item.avatar ? item.avatar : getDefaultAvatar(item.species)} />
              </div>
            </Col>
            <Col>
              <Text strong>{item.name}</Text>
            </Col>
          </Row>
        </Option>
      )),
    [clientPatients]
  );

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={{
        ...initialValues,
        date_of_vaccination: initialValues?.date_of_vaccination || dayjs()
      }}
    >
      <Form.Item name='clinic_id' initialValue={clinicId} hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]} className='add-vaccination-form'>
        <Col span={24} md={12}>
          <>
            <Form.Item
              label={locale.labels.owner}
              name='owner'
              tooltip={!!initialValues?.owner && locale.labels.clientFieldDisabled}
              className='single-field-select'
              rules={[requiredRule(locale.errors.ownerNotValid)]}
            >
              <ClientsSelect
                disabled={!!initialValues?.owner}
                onChange={(value) => {
                  setSelectedUserId(value);
                  setSelectedPatientsId(null);
                  form.setFieldValue('patient_id', null);
                }}
                selectedClientId={selectedUserId}
                patientIds={[selectedPatientsId]}
              />
            </Form.Item>
            <Form.Item
              label={locale.labels.patient}
              name='patient_id'
              tooltip={!!initialValues?.patient_id && locale.labels.clientFieldDisabled}
              className='single-field-select'
              rules={[requiredRule(locale.errors.patientNotValid)]}
            >
              {!selectedUserId ? (
                <PatientsSelect
                  clinicId={clinicId}
                  disabled={!!initialValues?.patient_id}
                  setSelectedOwnerId={setSelectedUserId}
                  setSelectedPatientsIds={(id) => {
                    setSelectedPatientsId(id);
                    form.setFieldValue('patient_id', id);
                    setFormEditMode(true);
                  }}
                />
              ) : (
                <Select
                  filterOption={false}
                  optionLabelProp='title'
                  className='patient-select'
                  showSearch
                  value={selectedPatientsId}
                  onSelect={setSelectedPatientsId}
                  onDeselect={() => setSelectedPatientsId(null)}
                  disabled={!selectedUserId || !!initialValues?.patient_id}
                  placeholder={locale.placeholders.searchPatient}
                >
                  {patientsList}
                </Select>
              )}
            </Form.Item>
          </>
          <Form.Item
            label={locale.labels.vacinationName}
            name={['name']}
            rules={[
              requiredRule(locale.messages.inputVaccinationName),
              patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)
            ]}
          >
            <AutoComplete
              options={autoCompleteOptions}
              filterOption={(inputValue, option) =>
                option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={() => setDropdownOpen(false)}
              allowClear
              open={dropdownOpen && inputVaccinationNameValue.length >= 2}
              onSearch={(value) => {
                setInputVaccinationNameValue(value);
                setDropdownOpen(true);
              }}
              onBlur={() => setDropdownOpen(false)}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.manufacturer}
            name={['manufacturer']}
            rules={[patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)]}
          >
            <AutoComplete
              options={autoCompleteManufacturerOptions}
              filterOption={(inputValue, option) =>
                option.value?.toString().toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={() => setManufacturerDropdownOpen(false)}
              allowClear
              open={manufacturerDropdownOpen && inputManufacturerNameValue.length >= 2}
              onSearch={(value) => {
                setInputManufacturerNameValue(value);
                setManufacturerDropdownOpen(true);
              }}
              onBlur={() => setManufacturerDropdownOpen(false)}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.coverage}
            name={['coverage']}
            rules={[requiredRule(locale.messages.inputCoverage)]}
          >
            <Select
              mode='multiple'
              options={localizedVaccineAntigens.map((el) => ({ ...el, key: el.value }))}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.serialNumber}
            name={['serial_number']}
            rules={[patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.dateVaccination}
            name={['date_of_vaccination']}
            rules={[requiredRule(locale.messages.inputDateVaccination)]}
          >
            <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label={locale.labels.expirationDate} name={['expiration_date']}>
            <DatePicker onChange={onImmuneResponseChange} />
          </Form.Item>
          <Form.Item label={locale.labels.dateNextVaccination} name={['date_of_next_vaccination']}>
            <DatePicker onChange={onNextVaccinationChange} />
          </Form.Item>
          <TextEditor label={locale.labels.notes} name={['notes']} />
        </Col>
      </Row>

      {children}
    </Form>
  );
};
