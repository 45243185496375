import './PatientsSelect.scss';

import { Col, Row, Select, Spin, Typography } from 'antd';
import { usePatientSearch } from 'api/hooks/patients/usePatientSearch';
import { IPatient } from 'api/interfaces/Patient';
import { AvatarComponent } from 'components/Avatar';
import * as config from 'config/config-manager';
import { useLocale } from 'hooks/useLocale';
import debounce from 'lodash/debounce';
import { getDefaultAvatar } from 'pages/patients/utils/PatientHelper';
import React, { useMemo, useState } from 'react';

const { Option } = Select;
const { Text } = Typography;

type Props = {
  clinicId: number;
  setSelectedOwnerId?: (id: number) => void;
  setSelectedPatientsIds?: (ids: number) => void;
  disabled?: boolean;
};

export const PatientsSelect = ({
  clinicId,
  setSelectedOwnerId,
  setSelectedPatientsIds,
  disabled
}: Props): JSX.Element => {
  const locale = useLocale('private.select-with-required-search');

  const [searchValue, setSearchValue] = useState(null);
  const { data: patients, isLoading } = usePatientSearch(clinicId, searchValue);

  const onPatientSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;
    setSearchValue(value);
  };

  const onPatientSelect = (patient: IPatient): void => {
    setSelectedOwnerId(patient?.owner?.id);
    setSelectedPatientsIds(patient.id);
  };

  const patientsList: JSX.Element[] = useMemo(
    () =>
      patients?.data.map((item) => (
        <Option
          key={`${item.id}_${crypto.randomUUID()}`}
          data={item}
          value={item.id}
          title={`${item.name} - ${item.owner?.name} (+${item.owner?.phone_number})`}
        >
          <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
            <Col style={{ marginRight: '5px', minWidth: 50 }}>
              <div className={'pet-avatar-select'}>
                <AvatarComponent src={item.avatar ? item.avatar : getDefaultAvatar(item.species)} />
              </div>
            </Col>
            <Col className='pet-info-box'>
              <Col>
                <Text className='pet-info-box__text'>{item.name}</Text>
              </Col>
              <Row align='middle'>
                <Col xs={24} sm={24} md={12} lg={12} style={{ marginRight: '5px', minWidth: '100%' }}>
                  <Text type='secondary'>{item.owner.name}</Text>
                </Col>
                {item.owner.phone_number && (
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Text type='secondary'>{`+${item.owner.phone_number}`}</Text>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Option>
      )),
    [patients]
  );

  return (
    <Select
      placeholder={locale.placeholders.inputMinForSearch}
      id={'patients-select-with-search'}
      virtual={false}
      onChange={onPatientSelect}
      notFoundContent={isLoading ? <Spin size='small' /> : null}
      filterOption={false}
      showSearch
      allowClear
      disabled={disabled}
      onSearch={debounce(onPatientSearch, config.selectSearchDelay)}
      onSelect={(_id, { data }) => {
        onPatientSelect(data);
      }}
    >
      {patientsList}
    </Select>
  );
};
