import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPasswordChangePayload } from 'api/interfaces/User';

const mutationFn = async (payload: IPasswordChangePayload): Promise<void> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.USER}/password/change`, payload);
  return response.data;
};

export const useChangePassword = (onSuccess: () => void, onError: () => void) =>
  useMutation({ mutationFn, onSuccess, onError });
