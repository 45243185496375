import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { DICTIONARY } from 'api/constants/dictionary';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateDictionary = (
  clinicId: number,
  key: DICTIONARY,
  callbackFn?: (id) => void
) => {
  return useCustomMutation(
    (payload: any) => axiosClient.post(`${API_ENDPOINTS.DICTIONARIES(clinicId)}/${key}`, payload),
    [key, clinicId],
    (data) => callbackFn(data?.data.id)
  );
};
