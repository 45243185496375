import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeletePatient = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = (patientId: number) =>
    axiosClient.delete(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}`);

  return useCustomMutation(mutationFn, ['patientsList'], callbackFn);
};
