import './EventsCalendar.scss';

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Calendar, Checkbox, Collapse, Divider, Input, Layout } from 'antd';
import { useAppointmentDetails } from 'api/hooks/appointments/useAppointmentDetails';
import { useEventDetails } from 'api/hooks/calendarEvents/useEventDetails';
import { useFetchEvents } from 'api/hooks/calendarEvents/useFetchEvents';
import { useEmployeesColoredList } from 'api/hooks/employees/useEmployeesColoredList';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { ISelectedEvent } from 'api/interfaces/CalendarEvent';
import { IEmployee } from 'api/interfaces/Employee';
import { IRoom } from 'api/interfaces/Room';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import useUserStore from 'api/store/userStore';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import AddEvent from 'layout/modals/addEvent/AddEvent';
import * as ls from 'local-storage';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar as BigCalendar } from 'react-big-calendar';

import { AllDayEventsModal } from './AllDayEventsModal';
import CustomDayView from './CustomDayView';
import { CustomEventContainer } from './CustomEventContainer';
import { CustomViewHeader } from './CustomViewHeader';
import { EventModal } from './EventModal';
import { CalendarModeType, CalendarViewType, LocalStorageFilters } from './types';
import { bigCalendarFormats } from './utils/big-calendar-formats';
import { dateLocalizer } from './utils/dateLocalizer';
import { defineTimeFrameFilter } from './utils/define-time-frame-filter';
import { getEmployeeName } from './utils/name-utils';
import { resourcesFilter } from './utils/resources-filter';

const USER_COLOR = '#8bd1ca';

const localizer = dateLocalizer();

const EventsCalendar = (): JSX.Element => {
  const mobile = window.innerWidth < 992;
  const clinicId = useClinicId();
  const locale = useLocale('private.calendar.calendar');
  const calendarRef = React.useRef(null);
  const { open, isOpened } = useModal();

  const [initialDataForAppointment, setInitialDataForAppointment] = useState(null);

  const [selectedEventId, setSelectedEventId] = useState(null);
  const { data: selectedEventData } = useEventDetails(selectedEventId);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { data: selectedAppointmentData } = useAppointmentDetails(selectedAppointment);

  const { rooms } = useRoomsList(clinicId, { page: 0 });

  const { localeCode } = useLocaleCode();
  const userId = useUserStore(({ user }) => user.id);

  const { employees } = useEmployeesColoredList(clinicId);

  const [eventsDateRange, setEventsDateRange] = useState(null);
  const { data: events } = useFetchEvents(clinicId, eventsDateRange);

  const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 350);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [collapsed, setCollapsed] = useState(mobile);
  const [selectedEvent, setSelectedEvent] = useState<ISelectedEvent>(null);
  const [selectedEmployees, setSelectedEmployees] = useState([userId]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState(null);
  const [roomsFilter, setRoomsFilter] = useState(null);
  const [calendarView, setCalendarView] = useState(null);
  const [hasDefinedFilters, setHasDefinedFilters] = useState(false);
  const [smallCalendarMode, setSmallCalendarMode] = useState<CalendarModeType>('month');

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.onresize = () => {
      setCalendarHeight(window.innerHeight - 369);
    };

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    if (rooms?.length && !hasDefinedFilters && !selectedRooms?.length) {
      const roomIds = filteredRooms.map((room): number => room.id);
      setSelectedRooms(roomIds);
    }
    if (employees?.length && !hasDefinedFilters && !selectedEmployees?.length) {
      const employeeIds = employees.map((item): number => item.id);
      setSelectedEmployees(employeeIds);
    }
  }, [rooms, hasDefinedFilters, employees, selectedEmployees, selectedRooms]);

  useEffect((): void => {
    const calendarFilters: LocalStorageFilters = ls.get('eventCalendarFilters');
    const { selectedEmployees, view, selectedRooms, selectedDate, smallCalendarMode } =
      calendarFilters?.[clinicId] ?? {};

    const employeesIds = filteredEmployees.map((item) => item.id);
    setSelectedEmployees(employeesIds);

    if (calendarFilters?.[clinicId]) setHasDefinedFilters(true);
    if (selectedEmployees) setSelectedEmployees(selectedEmployees);
    if (selectedRooms) setSelectedRooms(selectedRooms);
    if (selectedDate) setSelectedDate(selectedDate);
    if (smallCalendarMode) setSmallCalendarMode(smallCalendarMode);

    const calendarView = view || (window.innerWidth < 992 ? 'day' : 'week');
    setCalendarView(calendarView);

    fetchData(selectedDate, calendarView);
  }, []);

  const getEmployeeColor = (id: number): string => {
    if (id === userId) return USER_COLOR;

    return employees.find((employee: IEmployee) => employee.id === id)?.color;
  };

  const onSelectSlot = (info): void => {
    const duration = moment.duration(moment(info.end).diff(moment(info.start)));
    const hours = duration.asHours();
    let minutes = duration.asMinutes();
    if (minutes) {
      minutes = minutes % 60;
    }

    setInitialDataForAppointment({
      doctor_id: info.resourceId,
      start_time: info.start,
      end_time: info.end,
      duration: dayjs().hour(hours).minute(minutes),
      allDay: calendarView === 'day' && duration.asDays() === 1
    });
  };

  const setLocalStorageFilters = (newVals: LocalStorageFilters): void => {
    setHasDefinedFilters(true);
    let calendarFilters: LocalStorageFilters = ls.get('eventCalendarFilters');

    if (calendarFilters) {
      calendarFilters[clinicId] = { ...calendarFilters[clinicId], ...newVals };
    } else {
      calendarFilters = { [clinicId]: newVals };
    }
    ls.set('eventCalendarFilters', calendarFilters);
  };

  const clearSelectedFilters = (): void => {
    ls.remove('eventCalendarFilters');
    setHasDefinedFilters(false);
    setSelectedDate(new Date());
    setSmallCalendarMode('month');

    const view = window.innerWidth < 992 ? 'day' : 'week';
    setCalendarView(view);

    fetchData(null, view);

    const employeeIds = filteredEmployees.map((item): number => item.id);
    setSelectedEmployees(employeeIds);
    const roomIds = filteredRooms.map((room): number => room.id);
    setSelectedRooms(roomIds);
  };

  const setCurrentDate = (date, { source }): void => {
    const view = source === 'month' ? 'month' : calendarView;

    if (source === 'month') {
      setCalendarView(view);
    }

    fetchData(date, view);
    setSelectedDate(date);
    setLocalStorageFilters({ current: date });
  };

  const onNavigate = (date: Date, view: CalendarViewType): void => {
    fetchData(date, view);
    setSelectedDate(date);
    setLocalStorageFilters({ selectedDate: date });
  };

  const fetchData = (date: Date, view: CalendarViewType): void => {
    const dateRange = defineTimeFrameFilter(date, view);
    setEventsDateRange(dateRange);
  };

  const onSelectEvent = (event): void => {
    const { id, resource } = event;
    setSelectedEvent(event);

    if (!id || !resource) return;

    if (resource.event_type === 'appointment') {
      setSelectedAppointment(resource);
    }

    // TODO API why do we need condition resource.location?.details !== 'unavailable' ?
    if (resource.event_type === 'event' && resource.location?.details !== 'unavailable') {
      setSelectedEventId(id);
    }
  };

  const handleShowMore = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    open(MODAL.allDayEventsModal);
  };

  function filterEmployees(e: React.ChangeEvent<HTMLInputElement>): void {
    const data = e.target.value;

    setEmployeesFilter(data);
  }

  function filterRooms(e: React.ChangeEvent<HTMLInputElement>): void {
    const data = e.target.value;
    setRoomsFilter(data);
  }

  const onWeekClick = useCallback((): void => {
    fetchData(selectedDate, 'week');
    setCalendarView('week');
    setLocalStorageFilters({ view: 'week' });
  }, [selectedDate]);

  const onDayClick = useCallback((): void => {
    const type = 'day';
    fetchData(selectedDate, type);
    setCalendarView(type);
    setLocalStorageFilters({ view: type });
  }, [selectedDate]);

  const onMonthClick = useCallback((): void => {
    fetchData(selectedDate, 'month');
    setCalendarView('month');
    setLocalStorageFilters({ view: 'month' });
  }, [selectedDate]);

  const onSelectEmployee = (selected: boolean, employeeId: number): void => {
    const newSelectedEmployeesList = selected
      ? [...selectedEmployees, employeeId]
      : selectedEmployees.filter((item) => item !== employeeId);

    setSelectedEmployees(newSelectedEmployeesList);
    setLocalStorageFilters({ selectedEmployees: newSelectedEmployeesList });
  };

  const onSelectRoom = (selected: boolean, roomId: number): void => {
    const res = selected
      ? [...selectedRooms, roomId]
      : selectedRooms.filter((item) => item !== roomId);

    setSelectedRooms(res);
    setLocalStorageFilters({ selectedRooms: res });
  };

  const onSelectAll = (selected: boolean): void => {
    const res = selected ? filteredEmployees.map((item) => item.id) : [];
    setSelectedEmployees(res);
    setLocalStorageFilters({ selectedEmployees: res });
  };

  const onSelectAllRooms = (selected: boolean): void => {
    const roomIds = selected ? filteredRooms.map((item) => item.id) : [];
    setSelectedRooms(roomIds);
    setLocalStorageFilters({ selectedRooms: roomIds });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const target = calendarRef.current?.children?.[0].children?.[1]?.children?.[1];

    if (!target) return;

    const scrollAmount = 500;

    if (event.key === 'ArrowRight') {
      target.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    } else if (event.key === 'ArrowLeft') {
      target.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    } else if (event.key === 'ArrowDown') {
      target.scrollBy({
        top: scrollAmount,
        behavior: 'smooth'
      });
    } else if (event.key === 'ArrowUp') {
      target.scrollBy({
        top: -scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const buttonIcon = useMemo((): JSX.Element => {
    if (collapsed) return <MenuUnfoldOutlined style={{ margin: '0px' }} />;

    return <MenuFoldOutlined style={{ margin: '0px' }} />;
  }, [collapsed]);

  const filteredEmployees = useMemo((): IEmployee[] => {
    if (!employeesFilter) return employees;

    return employees.filter((item) =>
      item.full_name.toLowerCase().includes(employeesFilter.toLowerCase())
    );
  }, [employeesFilter, employees]);

  const filteredRooms = useMemo((): IRoom[] => {
    if (!roomsFilter) return rooms;
    return rooms.filter((item) => item.name.toLowerCase().includes(roomsFilter.toLowerCase()));
  }, [roomsFilter, rooms]);

  const roomsList = filteredRooms.map(
    (item): JSX.Element => (
      <div key={`room_${item.id}`}>
        <Checkbox
          checked={selectedRooms.includes(item.id)}
          onChange={(e) => onSelectRoom(e.target.checked, item.id)}
        >
          {item.name}
        </Checkbox>
      </div>
    )
  );

  const allEvents = useMemo(
    () =>
      events
        ?.filter(
          (item) =>
            (selectedEmployees.includes(item.user_id) ||
              selectedRooms.includes(item.location?.id)) &&
            item.occupied
        )
        .map((item) => ({
          title: item.title === 'Busy' ? locale.labels.busy : item.title,
          id: item.id,
          resourceId: item.user_id,
          allDay: !!item.all_day,
          start: new Date(item.start_time),
          end: new Date(item.end_time),
          location: item.location,
          resource: {
            ...item,
            color: getEmployeeColor(item.user_id)
          }
        })),
    [userId, events, employees, selectedEmployees, selectedRooms]
  );

  const resources = useMemo(
    () => resourcesFilter(filteredEmployees, selectedEmployees, selectedRooms, userId),
    [filteredEmployees, selectedEmployees, selectedRooms, userId]
  );

  const collapseItems = useMemo(
    () => [
      {
        key: 1,
        label: locale.labels.selectDate,
        children: (
          <Calendar
            style={{ paddingInlineStart: 0 }}
            fullscreen={false}
            mode={smallCalendarMode}
            onPanelChange={(data, mode) => {
              setSmallCalendarMode(mode);
              setLocalStorageFilters({ smallCalendarMode: mode });
            }}
            value={dayjs(selectedDate)}
            onSelect={setCurrentDate}
          />
        )
      },
      {
        key: 2,
        label: locale.labels.employees,
        children: (
          <>
            <Input
              placeholder={locale.placeholders.search}
              allowClear
              onChange={debounce(filterEmployees, 500)}
            />
            <Checkbox
              onChange={(e) => onSelectAll(e.target.checked)}
              style={{ marginTop: '15px' }}
              checked={selectedEmployees?.length === filteredEmployees.length}
            >
              {locale.labels.selectUnselectAll}
            </Checkbox>
            <Divider style={{ margin: '15px 0' }} />
            {filteredEmployees
              .sort((x: IEmployee, y: IEmployee) =>
                x.id === userId ? -1 : y.id === userId ? 1 : 0
              )
              .map(
                (item): JSX.Element => (
                  <div key={item.id} className='colored-label-wrapper'>
                    <span
                      className='colored-label'
                      style={{ background: item.id === userId ? USER_COLOR : item.color }}
                    />
                    <Checkbox
                      onChange={(e) => onSelectEmployee(e.target.checked, item.id)}
                      checked={selectedEmployees.includes(item.id)}
                    >
                      {getEmployeeName(locale, item, userId)}
                    </Checkbox>
                  </div>
                )
              )}
          </>
        )
      },
      {
        key: 3,
        label: locale.labels.rooms,
        children: (
          <>
            <Input
              placeholder={locale.placeholders.search}
              onChange={debounce(filterRooms, 500)}
              allowClear
            />
            <Checkbox
              onChange={(e) => onSelectAllRooms(e.target.checked)}
              style={{ marginTop: '15px' }}
              checked={selectedRooms?.length === filteredRooms?.length}
            >
              {locale.labels.selectUnselectAll}
            </Checkbox>
            <Divider style={{ margin: '15px 0' }} />
            {roomsList}
          </>
        )
      }
    ],
    [roomsList, selectedEmployees, filteredEmployees, userId, selectedRooms, filteredRooms]
  );

  return (
    !!calendarView && (
      <>
        <Button
          type='primary'
          size='small'
          icon={buttonIcon}
          onClick={() => setCollapsed(!collapsed)}
        >
          {locale.buttons.filter}
        </Button>
        {hasDefinedFilters && (
          <Button
            style={{ marginLeft: 10 }}
            type='primary'
            size='small'
            icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
            onClick={clearSelectedFilters}
          >
            {locale.buttons.resetFilters}
          </Button>
        )}
        <Layout
          className='ant-layout-has-sider calendar-layout calendar'
          style={{
            background: 'none',
            paddingTop: 10
          }}
        >
          <aside className={collapsed ? 'aside-closed' : 'aside'}>
            <Collapse
              accordion
              defaultActiveKey='1'
              className='calendar-filter-panel'
              items={collapseItems}
            ></Collapse>
          </aside>
          <Layout
            ref={calendarRef}
            style={{ background: 'none' }}
            className={calendarView === 'day' ? 'custom-view' : undefined}
          >
            <BigCalendar
              date={selectedDate}
              eventPropGetter={(event) => ({
                key: `${event.id}_${event.user_id}`
              })}
              localizer={localizer}
              culture={localeCode}
              events={allEvents}
              resources={calendarView === 'day' ? resources : undefined}
              style={{
                height: mobile ? 500 : calendarHeight,
                minHeight: '100%'
              }}
              calendarView={calendarView}
              view={calendarView}
              formats={bigCalendarFormats}
              startAccessor='start'
              endAccessor='end'
              scrollToTime={new Date(selectedDate)}
              onNavigate={onNavigate}
              onView={setCalendarView}
              onSelectSlot={onSelectSlot}
              onSelectEvent={onSelectEvent}
              showMultiDayTimes
              selectable
              allDayMaxRows={3}
              step={15}
              views={{
                month: true,
                week: true,
                day: CustomDayView
              }}
              components={{
                day: {
                  event: CustomEventContainer,
                  resourceHeader: CustomViewHeader
                },
                eventWrapper: ({ event, children }) => {
                  const updatedChild = React.cloneElement(children, {
                    style: {
                      ...children.props.style,
                      borderLeftColor: event.resource?.color || 'black'
                    }
                  });
                  return (
                    <div
                      className='custom-event-wrapper'
                      onContextMenu={(e) => {
                        alert(`${event.title} is clicked.`);
                        e.preventDefault();
                      }}
                    >
                      {updatedChild}
                    </div>
                  );
                }
              }}
              messages={{
                showMore: (count) => (
                  <div onClick={handleShowMore}>{`+${count} ${locale.buttons.more}`}</div>
                ),
                week: (
                  <span onClick={onWeekClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.week}
                  </span>
                ),
                day: (
                  <>
                    <span onClick={onDayClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                      {locale.buttons.day}
                    </span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setInitialDataForAppointment({});
                      }}
                      style={{
                        marginLeft: '8px',
                        background: 'white',
                        border: '1px solid #ff6f2c',
                        color: '#ff6f2c',
                        padding: '0 8px'
                      }}
                      className='ant-btn ant-btn-primary ant-btn-sm'
                    >
                      <span
                        className='icofont icofont-plus mr-2 sticky-btn-icon'
                        style={{ marginRight: '8px' }}
                      />
                      {locale.buttons.addEvent}
                    </span>
                  </>
                ),
                month: (
                  <span onClick={onMonthClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.month}
                  </span>
                ),
                previous: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretLeftOutlined />
                  </span>
                ),
                next: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretRightOutlined />
                  </span>
                ),
                yesterday: 'Yesterday',
                tomorrow: 'Tomorrow',
                today: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm'>{locale.buttons.today}</span>
                )
              }}
            />
          </Layout>
        </Layout>
        {isOpened(MODAL.allDayEventsModal) && <AllDayEventsModal events={events} />}
        {!!selectedEvent && (
          <EventModal
            showEventButtons
            selectedEvent={selectedEvent}
            selectedEventData={selectedEventData}
            setSelectedEvent={setSelectedEvent}
            selectedAppointmentData={selectedAppointmentData}
            locale={locale}
          />
        )}
        {!!initialDataForAppointment && (
          <AddEvent
            initialDataForAppointment={initialDataForAppointment}
            onClickCallback={() => setInitialDataForAppointment(null)}
          />
        )}
      </>
    )
  );
};

export default EventsCalendar;
