import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ICreateAppointmentPayload } from 'api/interfaces/Appointment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateAppointment = (clinicId: number, onSuccess?: () => void) => {
  const mutationFn = (payload: ICreateAppointmentPayload): Promise<void> =>
    axiosClient.post(`${API_ENDPOINTS.APPOINTMENTS(clinicId)}`, payload);

  return useCustomMutation(mutationFn, ['calendar-events', 'appointmentsList'], onSuccess);
};
