import { Tag } from 'antd';
import { money } from 'helpers/list-helpers';
import React from 'react';

import { useLocale } from '../hooks/useLocale';

type Props = {
  debt: number;
};

export const DebtStatus = ({ debt }: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.debt-status');
  if (debt !== undefined && debt !== null) {
    if (debt < 0) {
      const diff = (Number(debt) * -1).toFixed(2);
      return (
        <Tag className='appointment-status' color={'red'}>
          {locale.labels.debt}: {money(diff)} {currencies.uah}
        </Tag>
      );
    } else if (debt === 0) {
      return (
        <Tag className='appointment-status' color={'green'}>
          {locale.labels.allPayed}
        </Tag>
      );
    } else {
      return (
        <Tag className='appointment-status' color={'yellow'}>
          {locale.labels.overpaid}: {debt} {currencies.uah}
        </Tag>
      );
    }
  }
  return null;
};
