import { IEmployee } from 'api/interfaces/Employee';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

import { Localizer } from './utils/dateLocalizer';

const startOfDay = (date: Date): Date => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0); // Reset to the start of the day
  return normalizedDate;
};

const filterEvents = (events, filteredResources) =>
  events.filter((event) => filteredResources.some((resource) => resource.id === event.resourceId));

type Props = {
  date: Date;
  scrollToTime: Date;
  events: {
    id: number;
    allDay: boolean;
    start: Date;
    end: Date;
    location: string;
    resource: IEmployee;
  }[];
  resources: IEmployee[];
  localizer: Localizer;
};

const CustomDayView = ({ date, events, resources, localizer, ...props }: Props): JSX.Element => {
  const filteredEvents = filterEvents(events, resources);

  return (
    <TimeGrid
      localizer={localizer}
      range={[startOfDay(date)]}
      backgroundEvents={[]}
      events={filteredEvents}
      resources={resources}
      min={new Date(2000, 0, 0, 0, 0)} // year, month, day values are ignored
      max={new Date(2000, 0, 0, 23, 59)} // year, month, day values are ignored
      resourceIdAcessor='id'
      {...props}
    />
  );
};

CustomDayView.navigate = (date: Date, action: string): Date => {
  const nextDate = new Date(date);
  switch (action) {
    case 'PREV':
      nextDate.setDate(new Date(date).getDate() - 1);
      break;
    case 'NEXT':
      nextDate.setDate(new Date(date).getDate() + 1);
      break;
    case 'TODAY':
      nextDate.setHours(0, 0, 0, 0);
      break;
    default:
      return date;
  }
  return nextDate;
};

CustomDayView.title = (date, { localizer }) => localizer.format(date, 'MMMM dd, yyyy');

export default CustomDayView;
