import './scss/ViewDetailsButtonsGroup.scss';

import { Affix, Button, Form } from 'antd';
import React from 'react';

const ViewDetailsButtonsGroup = ({
  accessGroup,
  editMode,
  onEditClick,
  onCancelClick,
  onDeleteClick,
  onSaveModalClick,
  hideDeleteBtn,
  fixed,
  hideCancelBtn,
  showCreateBtn,
  submitButtonIcon,
  saveButtonDisabled
}: any) => {
  const content = (
    <div className={'affix-position'}>
      <Form.Item className={'submit-form-item'}>
        {(accessGroup.create || showCreateBtn) && (
          <Button
            onClick={onSaveModalClick}
            block
            type='primary'
            shape={'circle'}
            disabled={saveButtonDisabled}
            htmlType='submit'
            style={{ display: editMode ? null : 'none' }}
          >
            <span className={submitButtonIcon ?? 'icofont-save'} />
          </Button>
        )}
      </Form.Item>
      {!hideCancelBtn && (
        <Button
          onClick={onCancelClick}
          danger
          type='primary'
          className={'inventory-update-buttons'}
          shape={'circle'}
          style={{ display: editMode ? null : 'none' }}
        >
          <span className='icofont icofont-ban' />
        </Button>
      )}
      <Button
        onClick={onEditClick}
        type='primary'
        className={'inventory-update-buttons'}
        shape={'circle'}
        style={{ display: !editMode ? null : 'none' }}
      >
        <span className='icofont icofont-edit-alt' />
      </Button>
      {accessGroup.delete && !hideDeleteBtn && (
        <Button
          onClick={onDeleteClick}
          danger
          type='primary'
          shape={'circle'}
          className={'inventory-update-buttons'}
          style={{
            marginLeft: '8px',
            display: !editMode ? null : 'none'
          }}
        >
          <span className='icofont icofont-trash' />
        </Button>
      )}
    </div>
  );

  return (
    <div className={'inventory-update-buttons'}>
      {fixed ? content : <Affix offsetBottom={40}>{content}</Affix>}
    </div>
  );
};

export default ViewDetailsButtonsGroup;
