import { Form } from 'antd';
import { useCreateTool } from 'api/hooks/tools/useCreateTool';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { CreateToolForm } from './components/CreateToolForm';

export const CreateToolModal = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const locale = useLocale('private.inventory.tools');
  const { closeModal } = useModal();
  const [createToolForm] = Form.useForm();
  const userAccess = useAccess();
  const createTool = useCreateTool(clinicId);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      form={createToolForm}
      title={locale.labels.createNewTool}
      open={true}
      onModalClose={closeModal}
      width={800}
    >
      <CreateToolForm editMode={true} onFinish={createTool.mutate} form={createToolForm}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.tools}
          editMode={true}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (createToolForm.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </CreateToolForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
