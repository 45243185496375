import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEquipmentDetails } from 'api/interfaces/Equipment';

export const useEquipmentDetails = (clinicId: number, equipmentId: number) => {
  return useQuery<IEquipmentDetails>({
    queryKey: ['equipmentDetails', clinicId, equipmentId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.EQUIPMENT(clinicId)}/${equipmentId}`);
      return data;
    },
    enabled: !!equipmentId
  });
};