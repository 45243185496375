import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ISaleDetails, ISalePayload } from 'api/interfaces/Sale';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditSale = (clinicId: number, sale: ISaleDetails, callbackFn?: () => void) => {
  const mutationFn = (payload: ISalePayload) =>
    axiosClient.put(`${API_ENDPOINTS.SALES(clinicId)}/${sale?.id}`, payload);

  return useCustomMutation(
    mutationFn,
    [
      ['salesList', clinicId],
      ['billsList', clinicId],
      ['saleDetails', clinicId, sale?.id],
      ['billDetails', clinicId, sale?.bill_id]
    ],
    callbackFn
  );
};
