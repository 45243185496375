import './BaseLayout.scss';

import { Breadcrumb, Layout } from 'antd';
import useUserStore from 'api/store/userStore';
import { useAccess } from 'hooks/useAccess';
import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import { useGetNetworkStatus } from 'hooks/useGetNetworkStatus';
import { Forbidden } from 'pages/sessions/403';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import VerticalLayout from '../vertical/Vertical';
import FooterItems from './footer/Footer';
import { Header } from './header/Header';

const { Content, Footer } = Layout;

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const BaseLayout = (): JSX.Element => {
  const location = useLocation();
  useGetNetworkStatus();

  const { hasAccess, selectedClinicId, selectedClinicName } = useAccess();
  const { id: authorized } = useUserStore(({ user }) => user);
  const breadcrumbs = useBreadcrumbs(selectedClinicName);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    if (shouldRefresh) return;

    const id = setTimeout(() => setShouldRefresh(true), DAY_IN_MS);

    return () => {
      clearTimeout(id);
    };
  }, [shouldRefresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shouldRefresh) {
      window.location.reload();
      setShouldRefresh(false);
    }
  }, [location]);

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Header selectedClinicId={selectedClinicId} />
      <Layout style={{ alignItems: 'stretch' }}>
        <VerticalLayout />
        <Content className={!authorized ? 'vlx-content-area-unauthorized' : 'vlx-content-area'}>
          {breadcrumbs?.length > 0 && (
            <Breadcrumb
              separator='>'
              style={{ marginBottom: '10px', marginTop: '5px' }}
              items={breadcrumbs}
            ></Breadcrumb>
          )}
          {hasAccess ? <Outlet /> : <Forbidden />}
        </Content>
      </Layout>
      <Footer>
        <FooterItems />
      </Footer>
    </Layout>
  );
};

export default BaseLayout;
