import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ISalePayload } from 'api/interfaces/Sale';

import { useCustomMutation } from '../useCustomMutation';

export const useCreateSale = (clinicId: number, callbackFn: (data) => void) => {
  const mutationFn = (payload: ISalePayload) =>
    axiosClient.post(`${API_ENDPOINTS.SALES(clinicId)}`, payload);

  return useCustomMutation(mutationFn, ['salesList', clinicId], callbackFn);
};
