import './scss/BillPrint.scss';

import { Col, Row, Table, TableColumnProps } from 'antd';
import { IService } from 'api/interfaces/Service';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import React, { useMemo } from 'react';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { generateUniqueBarcode } from 'utils/generate-unique-barcode';

interface Props {
  bill: any;
  showFreeItems: boolean;
  hideStockDetails: boolean;
}

export const InvoicePrintInfo = ({ bill, showFreeItems, hideStockDetails }: Props): JSX.Element => {
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;
  const { unitFormat } = usePlural();

  const servicesColumns = useMemo(
    (): TableColumnProps<IService>[] => [
      {
        key: 'name',
        title: locale.labels.services,
        render: (e) => e.name,
        width: '50%'
      },
      {
        key: 'price_per_service',
        title: locale.labels.price,
        render: (e) =>
          `${e.price_per_service ? `${money(e.price_per_service)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'service_count',
        title: locale.labels.qty,
        render: (e) => e.service_count ?? '-'
      },
      {
        key: 'total_price',
        title: locale.labels.sum,
        render: (e) => `${e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-'}`
      }
    ],
    [bill]
  );

  const stockColumns = useMemo(
    (): TableColumnProps<IStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.stock,
        render: (e) => `${e.name} (${e.manufacturer})`,
        width: '50%'
      },
      {
        key: 'unit_of_measurement',
        title: locale.labels.qtyItem,
        render: (e) => unitFormat(1, e.unit_of_measurement)
      },
      {
        key: 'price_per_unit',
        title: locale.labels.price,
        render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'quantity',
        title: locale.labels.qty,
        render: (e) => e.quantity ?? e.included_in_service_price_quantity ?? '-'
      },
      {
        key: 'price',
        title: locale.labels.sum,
        render: (e) => `${money(e.price)} ${currencies.uah}`
      }
    ],
    [bill]
  );

  const getServiceGeneralPrice = (): string => {
    let res = 0;
    if (bill.total_services_price && bill.services_discount.amount !== null) {
      res = bill.total_services_price - bill.services_discount.amount;
    }
    return money(res);
  };

  const getStockGeneralPrice = (): string => {
    let res = 0;
    if (bill.total_stock_price && bill.stock_discount.amount !== null) {
      res = bill.total_stock_price - bill.stock_discount.amount;
    }
    return money(res);
  };

  const getStockList = () => {
    if (showFreeItems) {
      let res = [];
      bill?.appointment?.appointment_consolidated?.stock.forEach((el) => {
        if (el.quantity) {
          res.push(el);
        }
        if (el.included_in_service_price_quantity) {
          res.push({ ...el, quantity: el.included_in_service_price_quantity, price: 0 });
        }
      });
      return res;
    }
    return bill?.appointment?.appointment_consolidated?.stock.filter((el) => el.price !== 0);
  };

  const stockTableFooter = () => {
    return <Col className='invoice-print-info__sum-container'>
      <Col>
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.sum}: </span>
          <span id='stock-sum'>
            {money(bill?.total_stock_price || 0)} {currencies.uah}
          </span>
        </>
      </Col>
      <Col>
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
          <span id='stock-discount'>
            {money(bill?.stock_discount?.amount || 0)}{' '}
            {currencies.uah}{' '}
            {bill?.stock_discount?.percentage
              ? `(${bill?.stock_discount?.percentage}%)`
              : ''}
          </span>
        </>
      </Col>
      <Col>
        <span style={{ fontWeight: 600 }}>{locale.labels.sumWithDiscount}: </span>
        <span id='stock-sum-with-discount'>
          {getStockGeneralPrice()} {currencies.uah}
        </span>
      </Col>
    </Col>;
  };

  return (
    <div className='invoice-print-info'>
      <h5 className='invoice-print-info__header'>
        {locale.labels.invoice} №{bill?.id} {locale.labels.from}{' '}
        {dayjs(bill?.created_at).format('DD.MM.YYYY')}
      </h5>

      {bill?.appointment?.appointment_consolidated?.services.length > 0 && (
        <div style={{ marginBottom: 20 }}>
          <Table
            pagination={false}
            rowKey='id'
            className='simple-view-table'
            dataSource={bill?.appointment?.appointment_consolidated?.services}
            columns={servicesColumns}
            footer={() => (
              <Col className='invoice-print-info__sum-container'>
                <Col>
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.sum}: </span>
                    <span id='services_subtotal'>
                      {money(bill?.total_services_price || 0)} {currencies.uah}
                    </span>
                  </>
                </Col>
                <Col>
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
                    <span id='services_discount'>
                      {money(bill?.services_discount?.amount || 0)} {currencies.uah}{' '}
                      {bill?.services_discount?.percentage
                        ? `(${bill?.services_discount?.percentage}%)`
                        : ''}
                    </span>
                  </>
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.sumWithDiscount}: </span>
                  <span id='services_total'>
                    {getServiceGeneralPrice()} {currencies.uah}
                  </span>
                </Col>
              </Col>
            )}
          />
        </div>
      )}

      {getStockList()?.length > 0 && (
        <div style={{ marginBottom: 20 }}>
          {hideStockDetails ?
          <Row className='hide-stock-info-box-view' justify={'space-between'}>
              <span style={{ fontWeight: 700}}>{locale.labels.stockAndConsumables}</span>
              {stockTableFooter()}
            </Row>
          : <Table
            pagination={false}
            rowKey={generateUniqueBarcode}
            className='simple-view-table'
            dataSource={getStockList()}
            columns={stockColumns}
            footer={stockTableFooter}
          />}
        </div>
      )}
    </div>
  );
};
