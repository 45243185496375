import { IStockPayload } from 'api/interfaces/Stock';

export const getStockItemPayload = (data: IStockPayload): IStockPayload => {
  const payload = {
    ...data
  };

  if (!payload.packaging_format) {
    payload.markup_package = payload.markup_unit;
    payload.price_per_package = payload.price_per_unit;
    payload.quantity_in_packages = 0;
    payload.quantity_per_package = 1;
    payload.unit_of_measurement = 'pcs';
    payload.packaging_format = 'none';
  }

  return payload;
};
