import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRoom, IRoomList } from 'api/interfaces/Room';

import { useTableData } from '../useTableData';

export const useRoomsList = (
  clinicId: number,
  params: Record<string, number | string> = { page: 0, 'q[deleted]': 'false' },
  searchDisabled: boolean = true
) => {
  const endpoint = API_ENDPOINTS.ROOMS(clinicId);

  const result = useTableData<IRoomList>(
    endpoint,
    params,
    ['roomsList'],
    searchDisabled || undefined
  );

  return {
    ...result,
    rooms: (result.data.data as IRoom[]) || []
  };
};
