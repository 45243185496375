import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockPayload } from 'api/interfaces/Stock';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditStock = (clinicId: number, stockId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IStockPayload) =>
    axiosClient.put(`${API_ENDPOINTS.STOCK(clinicId)}/${stockId}`, payload);

  return useCustomMutation(
    mutationFn,
    [
      ['stockList', clinicId],
      ['stockDetails', clinicId, stockId]
    ],
    callbackFn
  );
};
