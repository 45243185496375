import { ISearchCriteria } from '../../interfaces/search-criteria.ts';
import { ISortObject } from '../interfaces/ISortObject';

export function generateSearchQuery(searchArray: ISearchCriteria[] = []): string {
  if (searchArray.length === 0) {
    return '';
  }
  let query = '';
  searchArray.forEach((item) => {
    if (item?.isArrayValue) {
      query += `&q[${item.name}][]=${encodeURIComponent(item.value)}`;
    } else {
      query += `&q[${item.name}]=${encodeURIComponent(item.value)}`;
    }
  });
  return query;
}

export function sorting(sortObject: ISortObject | null = null): string {
  if (!sortObject) {
    return '';
  }
  return `&sort_key=${sortObject.sort_key}&sort_order=${sortObject.sort_order}`;
}
