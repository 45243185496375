import 'components/scss/HealthRecordsHistory.scss';
import 'moment/locale/uk';
import '../scss/StockDetails.scss';

import { Col, Row, Table, TableColumnProps } from 'antd';
import { useStockPurchases } from 'api/hooks/stock/useStockPurchases';
import { IStock, IStockPurchase } from 'api/interfaces/Stock';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { TextEditorView } from 'components/TextEditorView';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import getExpirationStatus from '../helpers/ExpirationTagSelector';
import { usePlural } from '../utils/usePlural';

type Props = {
  locale: ILocale;
  stock: IStock;
};

export const StockHistoryTab = ({ locale, stock }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const currencies = useLocale('private.currencies').labels;
  const { unitFormat, packageFormat } = usePlural();
  const { stockItems, isLoading } = useStockPurchases(clinicId, stock?.id);

  const getExpirationDate = (e: IStockPurchase): JSX.Element | '-' =>
    e.expiration_date ? getExpirationStatus(e.expiration_date) : '-';

  const itemFormat = (item: IStockPurchase): string => {
    const packagingFormat = packageFormat(1, item?.purchase_format) || '';
    const unitOfMeasurement = unitFormat(1, item?.purchase_format) || '';

    return packagingFormat || unitOfMeasurement;
  };

  const getPrice = (price: number, unitSellingPrice?: number): JSX.Element => (
    <Col>
      {stock?.packaging_format !== 'none' && (
        <Row className='time-cell-row' style={{ width: 'max-content' }}>
          {`${packageFormat(1, stock?.packaging_format)}: ${money(price)} ${currencies.uah}`}
        </Row>
      )}
      <Row className='time-cell-row' style={{ width: 'max-content' }}>
        {stock?.unit_of_measurement !== 'pcs' || stock?.packaging_format !== 'none'
          ? unitFormat(1, stock?.unit_of_measurement) + ': '
          : ''}
        {unitSellingPrice ? money(unitSellingPrice) : money(price / stock?.quantity_per_package)} {currencies.uah}
      </Row>
    </Col>
  );

  const columns: TableColumnProps<IStockPurchase>[] = [
    {
      key: 'purchase_date',
      title: locale.labels.purchaseDate,
      render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
    },
    {
      key: 'invoice_number',
      title: locale.labels.invoiceNumber,
      render: (e) =>
        e.invoice_id
          ? ListFieldWithTooltip(
              <Link
                className={'custom-links'}
                to={`/clinic/${clinicId}/inventory/invoices/${e.invoice_id}`}
              >
                {e.invoice_number}
              </Link>
            )
          : '-'
    },
    {
      key: 'provider',
      title: locale.labels.provider,
      render: (e) => e.provider?.name || '-'
    },
    {
      key: 'purchase_price',
      title: locale.labels.pricePerUnit,
      render: (e) => getPrice(e?.price_per_unit)
    },
    {
      key: 'purchase_format',
      title: locale.labels.purchaseFormat,
      render: (e) => itemFormat(e) || '-'
    },
    {
      key: 'initial_quantity',
      title: locale.labels.purchaseQuantity,
      render: (e) => e.initial_quantity || '-'
    },
    {
      key: 'selling_price',
      title: locale.labels.price,
      render: (e) => getPrice(e?.selling_price, e?.unit_selling_price)
    },
    {
      key: 'expiration_date',
      title: locale.labels.expirationDate,
      render: getExpirationDate
    },
    {
      key: 'series',
      title: locale.labels.series,
      render: (e) => e.series || '-'
    },
    Table.EXPAND_COLUMN
  ];

  return (
    <div className='stock-row-container__table'>
      <Table
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        pagination={false}
        className='accent-header stock-list'
        rowKey='id'
        loading={isLoading}
        dataSource={stockItems.reverse()}
        columns={columns}
        expandable={{
          rowExpandable: (record) => !!record.notes,
          expandedRowRender: (record) => (
            <Table
              rowKey='id'
              dataSource={[record]}
              columns={[
                {
                  key: 'notes',
                  title: 'Notes',
                  render: () => <TextEditorView value={record.notes} borderless />
                }
              ]}
              pagination={false}
              size='small'
              className='invoice-items-table'
              bordered
            />
          )
        }}
      />
    </div>
  );
};
