import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEquipmentList } from 'api/interfaces/Equipment';

import { useTableData } from '../useTableData';

export const useEquipmentList = (
  clinicId: number,
  defaultParams: Record<string, number | string> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = API_ENDPOINTS.EQUIPMENT(clinicId);

  const result = useTableData<IEquipmentList>(
    endpoint,
    defaultParams,
    ['equipmentList', clinicId],
    searchDisabled
  );

  return {
    ...result,
    equipment: result.data.data || []
  };
};
