import '../assets/scss/search-filters-horizontal.scss';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

import { useLocale } from '../hooks/useLocale';

interface Props {
  onFinish: () => void;
  formInstance: FormInstance;
  object: { name: string; placeholder: string }[];
}

const SearchFiltersHorizontal = ({ onFinish, formInstance, object }: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const locale = useLocale('private.search-filters-component');
  const showForm = () => {
    if (!collapsed) {
      return (
        <Form
          layout='horizontal'
          form={formInstance}
          onValuesChange={debounce(onFinish, 1000)}
          size='middle'
        >
          <Row gutter={[10, 0]}>
            {object.map((item: any, index: number) => (
              <Col span={16} md={8} key={index}>
                <Form.Item name={item.name}>
                  <Input placeholder={item.placeholder} />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      );
    }
  };

  return (
    <div className={'search-filters-horizontal'}>
      <Button
        type='primary'
        size='small'
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{ margin: '20px 0' }}
      >
        {locale.buttons.searchFilters}
      </Button>
      {showForm()}
    </div>
  );
};

export default SearchFiltersHorizontal;
