import '../scss/Provider.scss';

import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import { useCreateProvider } from 'api/hooks/providers/useCreateProvider';
import { useEditProvider } from 'api/hooks/providers/useEditProvider';
import { IEmail, IProvider } from 'api/interfaces/Provider';
import { useModal } from 'api/store/modalStore';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { ReactNode, useMemo } from 'react';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { TextArea } = Input;

type Props = {
  form: FormInstance;
  children: ReactNode;
  formEditMode?: boolean;
  onFieldsChange?: (changedFields, allFields) => void;
  initialValues?: IProvider;
  callBackFunction?: (data: IProvider) => void;
  defaultProviderName?: string;
};

export const AddProviderForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  onFieldsChange,
  callBackFunction,
  defaultProviderName
}: Props): JSX.Element => {
  const locale = useLocale('private.inventory.providers');
  const clinicId = useClinicId();
  const { closeModal } = useModal();

  const onSuccess = ({ data }: { data: IProvider }): void => {
    closeModal();
    callBackFunction(data);
  };
  const createProvider = useCreateProvider(clinicId, onSuccess);
  const editProvider = useEditProvider(clinicId, initialValues?.id, onSuccess);

  const onSubmit = (data: IProvider): void => {
    const payload = { ...data };

    payload.emails = payload.emails
      ? JSON.stringify(
          (payload.emails as IEmail[])
            .filter((item) => item.email)
            .map(({ email, description }) => ({ email, description }))
        )
      : [];

    payload.phone_numbers = JSON.stringify(payload.phone_numbers);

    if (formEditMode) {
      editProvider.mutate(payload);
    } else {
      createProvider.mutate(payload);
    }
  };

  const parseValues = useMemo(() => {
    if (initialValues) {
      const data = { ...initialValues };

      const emails = JSON.parse(initialValues.emails as string);
      data.emails = emails.length ? emails : [{}];
      const phoneNumbers = JSON.parse(initialValues.phone_numbers as string);
      data.phone_numbers = phoneNumbers.length ? phoneNumbers : [{}];

      return data;
    }
    return {
      emails: [{}],
      phone_numbers: [{}]
    };
  }, [initialValues]);

  const EmailFormItem = ({ name }): JSX.Element => (
    <Form.Item
      style={{ marginBottom: 0 }}
      name={[name, 'email']}
      rules={[patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)]}
    >
      <Input placeholder={locale.labels.emails} />
    </Form.Item>
  );

  const PhoneFormItem = ({ name }): JSX.Element => (
    <PhoneFieldFormItem
      formItemPhonePrefix={[name, 'country_code']}
      formItemPhoneNumber={[name, 'phone_number']}
      hideLabel
    />
  );

  const ContactsFormItem = ({ title, listName }): JSX.Element => (
    <div style={{ marginBottom: 15 }}>
      <Typography className='form-label'>
        {title}
      </Typography>
      <Form.List name={listName}>
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name }) => (
              <Row key={key} gutter={[20, 10]}>
                <Col span={24} md={24} lg={12}>
                  {listName === 'emails' ? (
                    <EmailFormItem name={name} />
                  ) : (
                    <PhoneFormItem name={name} />
                  )}
                </Col>
                <Col span={24} md={20} lg={10} xs={21}>
                  <Form.Item name={[name, 'description']}>
                    <Input placeholder={locale.labels.description} />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Button
                    onClick={(e) => {
                      remove(name);
                      formEditMode && onFieldsChange([{ e }], [{ e }]);
                    }}
                    danger
                    type='primary'
                    shape={'circle'}
                    className={'inventory-update-buttons'}
                  >
                    <span className='icofont icofont-trash' />
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{
                  borderRadius: 30,
                  fontWeight: 'bold'
                }}
                onClick={() => add()}
              >
                {locale.buttons.add}
              </Button>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={parseValues}
    >
      <Col span={24}>
        <Form.Item
          label={locale.labels.name}
          name={'name'}
          rules={[requiredRule()]}
          initialValue={defaultProviderName}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <ContactsFormItem listName='phone_numbers' title={locale.labels.contactPhone} />
      </Col>
      <Col span={24}>
        <ContactsFormItem listName='emails' title={locale.labels.emails} />
      </Col>
      <Col span={24}>
        <Form.Item
          label={locale.labels.address}
          name='address'
          rules={[maxRule(255, locale.errors.length_rule_255)]}
        >
          <TextArea rows={2} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <TextEditor label={locale.labels.comments} name={['comments']} rows={3} />
      </Col>
      {children}
    </Form>
  );
};
