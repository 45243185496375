import { Form } from 'antd';
import { IEventDetails } from 'api/interfaces/CalendarEvent';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { EditEventForm } from './EditEventForm';

interface Props {
  initialValues: IEventDetails;
  closeEventModal: () => void;
}

export const EditEventModal = ({ initialValues, closeEventModal }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const locale = useLocale('private.appointments.event');
  const { closeModal } = useModal();
  const userAccess = useAccess();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.editEvent}
        form={form}
        open
        onModalClose={closeModal}
        width={800}
      >
        {initialValues && (
          <EditEventForm
            form={form}
            initialValues={initialValues}
            closeEventModal={closeEventModal}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.events}
              fixed
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  closeModal();
                }
              }}
              onDeleteClick={() => setShowCancelModal(false)}
            />
          </EditEventForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
