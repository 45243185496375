import { IRoom } from 'api/interfaces/Room';
import { ILocation, IReassignedLocation } from 'api/interfaces/Stock';

export const getLocationReassignPayload = (
  list: IReassignedLocation[],
  roomsList: IRoom[],
  locationsList: ILocation[]
): IReassignedLocation[] =>
  roomsList
    .map((room) => {
      const { quantity = 0, residual = 0 } =
        locationsList?.find((location) => location?.room_id === room.id) || {};

      const reassignedRoom = list.reduce((acc, item) => {
        if (item.room_id !== room.id) return acc;
        if (!acc) return item;

        acc.quantity_packages += item.quantity_packages || 0;
        acc.quantity_units += item.quantity_units || 0;
        return acc;
      }, null);

      return {
        room_id: room.id,
        quantity_packages: (reassignedRoom?.quantity_packages || 0) + (quantity || 0),
        quantity_units:
          (reassignedRoom?.quantity_units || 0) + (quantity === 0 ? residual : residual % quantity)
      };
    })
    .filter((item) => item.quantity_packages || item.quantity_units);
