import './AppointmentDetails.scss';

import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useAddAppointmentHealthRecord } from 'api/hooks/appointments/useAddAppointmentHealthRecord';
import { useUpdateAppointmentHealthRecord } from 'api/hooks/appointments/useUpdateAppointmentHealthRecord';
import { useCreateDictionary } from 'api/hooks/dictionaries/useCreateDictionary';
import { useDictionary } from 'api/hooks/dictionaries/useDictionary';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IDictionary } from 'api/interfaces/Dictionary';
import { IHealthRecord } from 'api/interfaces/Patient';
import { TextEditor } from 'components/TextEditor';
import { patientState } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useState } from 'react';
import { getDate } from 'utils/get-time';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IHealthRecord;
  locale: ILocale;
  patientId: number;
  onFieldsChange: () => void;
  appointment: IAppointmentDetails;
  disabled: boolean;
  setEditMode: (value: boolean) => void;
}>;

export const AppointmentDetailsPatientTabForm = ({
  form,
  initialValues,
  children,
  locale,
  patientId,
  onFieldsChange,
  appointment,
  disabled,
  setEditMode
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const localizedPatientState = useLocalizedList(patientState);

  const { dictionary: diagnoses } = useDictionary(clinicId, DICTIONARY.diagnoses);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<string[]>([]);
  const [selectedProvisionalDiagnosis, setSelectedProvisionalDiagnosis] = useState<string[]>([]);
  const [noDiagnosisRequired, setNoDiagnosisRequired] = useState<boolean>(false);

  const createDiagnosis = useCreateDictionary(clinicId, DICTIONARY.diagnoses);
  const addHealthRecord = useAddAppointmentHealthRecord(clinicId, appointment.id, patientId, () =>
    setEditMode(false)
  );
  const updateHealthRecord = useUpdateAppointmentHealthRecord(
    clinicId,
    patientId,
    initialValues?.id,
    () => setEditMode(false)
  );

  useEffect(() => {
    form.resetFields();
    setNoDiagnosisRequired(initialValues?.no_diagnosis_required || false);
    setSelectedDiagnosis(parseDiagnosis(initialValues?.diagnosis || []));
    setSelectedProvisionalDiagnosis(parseDiagnosis(initialValues?.provisional_diagnosis || []));
  }, [initialValues, patientId]);

  const onSubmit = (data: IHealthRecord): void => {
    const apiData = {
      ...data,
      clinic_id: clinicId,
      services_ids: initialValues?.services.map((el) => el.id) || [],
      date_of_next_visit: getDate(data.date_of_next_visit)
    };

    if (data.diagnosis) {
      try {
        apiData.diagnosis = JSON.stringify(data.diagnosis);
      } catch (e) {
        apiData.diagnosis = data.diagnosis;
      }
    }
    if (data.provisional_diagnosis) {
      try {
        apiData.provisional_diagnosis = JSON.stringify(data.provisional_diagnosis);
      } catch (e) {
        apiData.provisional_diagnosis = data.provisional_diagnosis;
      }
    }
    if (!data.diagnosis && !data.provisional_diagnosis) {
      apiData.no_diagnosis_required = true;
    }

    if (initialValues) {
      updateHealthRecord.mutate(apiData);
    } else {
      addHealthRecord.mutate(apiData);
    }
  };

  const formInitalValues = () => {
    if (initialValues) {
      const data = {
        diagnosis: parseDiagnosis(initialValues.diagnosis),
        provisional_diagnosis: parseDiagnosis(initialValues.provisional_diagnosis),
        date_of_next_visit: null
      };

      if (initialValues.date_of_next_visit) {
        data.date_of_next_visit = dayjs(initialValues.date_of_next_visit);
      }
      return { ...initialValues, ...data };
    } else if (!initialValues && appointment?.services) {
      return { services_ids: appointment?.services.map((el) => el.id) };
    } else {
      return null;
    }
  };

  const parseDiagnosis = (diagnosis: string | string[]) => {
    if (diagnosis) {
      try {
        return JSON.parse(diagnosis as string);
      } catch (_e) {
        return diagnosis as string[];
      }
    }
    return [];
  };

  const onChangeNoDiagnosisRequired = (e: { target: { checked: boolean } }): void => {
    setNoDiagnosisRequired(e.target.checked);
    if (e.target.checked) {
      form.setFieldsValue({
        diagnosis: [],
        provisional_diagnosis: []
      });
      form.setFields([
        { name: 'diagnosis', errors: [] },
        { name: 'provisional_diagnosis', errors: [] }
      ]);
      setSelectedDiagnosis([]);
      setSelectedProvisionalDiagnosis([]);
    }
  };

  const formatValue = (value: string): string => {
    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
  };

  const onDiagnosisSelect = (value: string): void => {
    const formattedValue = formatValue(value);

    createFormattedDiagnosis(formattedValue, 'provisional_diagnosis');
    setSelectedDiagnosis([...selectedDiagnosis, formattedValue]);
  };

  const onDiagnosisDeselect = (value: string): void => {
    setSelectedDiagnosis(selectedDiagnosis.filter((item: string) => item !== value));
  };

  const onProvisionalDiagnosisSelect = (value: string): void => {
    const formattedValue = formatValue(value);

    createFormattedDiagnosis(formattedValue, 'diagnosis');
    setSelectedProvisionalDiagnosis([...selectedProvisionalDiagnosis, formattedValue]);
  };

  const onProvisionalDiagnosisDeselect = (value: string): void => {
    setSelectedProvisionalDiagnosis(
      selectedProvisionalDiagnosis.filter((item: string) => item !== value)
    );
  };

  const createFormattedDiagnosis = (value: string, key: string): void => {
    if (!diagnoses.find((item: any) => item.name.toLowerCase() === value.toLowerCase())) {
      createDiagnosis.mutate({ name: value, description: null });
    }

    form.setFields([{ name: key, errors: [] }]);
  };

  return (
    <Form
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={formInitalValues()}
      disabled={disabled}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.complaints} name={'complaints'}>
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.noDiagnosisRequired}
            name='no_diagnosis_required'
            className='flat-checkbox-form-item'
            valuePropName='checked'
          >
            <Checkbox onChange={onChangeNoDiagnosisRequired} />
          </Form.Item>
          <Form.Item
            label={locale.labels.diagnosis}
            className='add-appointment-form__multi-select-field'
            name={'diagnosis'}
          >
            <Select
              value={selectedDiagnosis}
              disabled={noDiagnosisRequired || disabled}
              options={diagnoses.map((item: IDictionary) => ({
                key: item.id,
                value: item.name
              }))}
              mode='tags'
              onSelect={onDiagnosisSelect}
              onDeselect={onDiagnosisDeselect}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.preliminaryDiagnosis}
            name={'provisional_diagnosis'}
            className='add-appointment-form__multi-select-field'
          >
            <Select
              value={selectedProvisionalDiagnosis}
              disabled={noDiagnosisRequired || disabled}
              options={diagnoses.map((item: IDictionary) => ({
                key: item.id,
                value: item.name
              }))}
              mode='tags'
              onSelect={onProvisionalDiagnosisSelect}
              onDeselect={onProvisionalDiagnosisDeselect}
            />
          </Form.Item>
          <TextEditor label={locale.labels.anamnesis} name={['anamnesis']} />
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.condition} name={'patient_state'}>
            <Select options={localizedPatientState} />
          </Form.Item>
          <TextEditor label={locale.labels.recommendations} name={['recommendations']} />
          {/* <Button type={'primary'} style={{ borderRadius: 30, marginBottom: 20 }}>{locale.buttons.createTreatmentPlan}</Button> */}
          <Form.Item label={locale.labels.returnVisitRecomended} name='date_of_next_visit'>
            <DatePicker disabledDate={(d) => !d || d.isBefore(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <TextEditor label={locale.labels.notes} name={['notes']} />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
