import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBankAccountsList } from 'api/interfaces/Finances';

import { useTableData } from '../useTableData';

export const useBankAccountsList = (
  clinicId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = API_ENDPOINTS.ACCOUNTS(clinicId);

  const result = useTableData<IBankAccountsList>(
    endpoint,
    defaultParams,
    ['bankAccountsList', clinicId],
    searchDisabled || undefined
  );

  return {
    ...result,
    bankAccounts: result.data.data || []
  };
};
