import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddEquipmentForm } from './AddEquipmentForm';

export const AddEquipmentModal = (): JSX.Element => {
  const userAccess = useAccess();
  const [addEquipmentForm] = Form.useForm();
  const locale = useLocale('private.inventory.technical-equipment');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const { closeModal } = useModal();

  return (
    <VLXModal
      form={addEquipmentForm}
      title={locale.labels.createNewEquipment}
      open={true}
      onModalClose={closeModal}
      width={800}
    >
      <AddEquipmentForm form={addEquipmentForm}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.equipment}
          editMode={true}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (addEquipmentForm.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </AddEquipmentForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
