import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import useShiftStore from 'api/store/shiftStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useOpenShift = (clinicId: number) => {
  const { setOpenedShift } = useShiftStore();
  const mutationFn = () => axiosClient.post(`${API_ENDPOINTS.SHIFTS(clinicId)}`);

  return useCustomMutation(mutationFn, ['shiftsList', clinicId], ({ data }) =>
    setOpenedShift(data)
  );
};
