import '../Finances.scss';
import './ShiftsDetails.scss';
import './ShiftsList.scss';

import {
  Card,
  Col,
  Collapse,
  Row,
  Spin,
  Statistic,
  Table,
  TableColumnProps,
  Typography
} from 'antd';
import { useShiftDetails } from 'api/hooks/shifts/useShiftDetails';
import { IAccount, IPayment } from 'api/interfaces/Shift';
import { IUser } from 'api/interfaces/User';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';

import ShiftStatusCard from '../dashboard/components/ShiftStatusCard';
import FinanceIcon from '../helpers/FinanceIcon';
import { currentEarnings, getShiftDuration, userData } from './ShiftsBaseHelper';

const ShiftDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const { shiftId }: Readonly<Params<string>> = useParams();
  const paymentsLocale = useLocale('private.finances.payments');
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  const { data: shift, isPending } = useShiftDetails(clinicId, shiftId);

  const redirectToPaymentDetails = (record: IPayment) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
    }
  });

  const getClosedBy = (closedBy: IUser): string => {
    const closedByName = closedBy?.name || '-';
    const closedByTitle = closedBy?.title ? `(${closedBy.title})` : '';
    return `${closedByName} ${closedByTitle}`;
  };

  const columns: TableColumnProps<IPayment>[] = [
    {
      key: 'payment_type',
      dataIndex: 'payment_type',
      title: '',
      sorter: (a, b) => (a.payment_type > b.payment_type ? 1 : -1),
      render: (payment_type) => <FinanceIcon type={payment_type} />
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: paymentsLocale.labels.name,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => name || '-'
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: paymentsLocale.labels.date,
      sorter: (a, b) => (a.date > b.date ? 1 : -1),
      render: (date) => `${date ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-'}`
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: paymentsLocale.labels.amount,
      sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
      render: (amount) => (amount !== undefined ? `${amount} ${currencies.uah}` : '-')
    },
    {
      key: 'created_by',
      dataIndex: 'created_by',
      title: paymentsLocale.labels.createdBy,
      sorter: (a, b) => (a.closed_by.name > b.closed_by.name ? 1 : -1),
      render: (closed_by) => getClosedBy(closed_by)
    }
  ];

  const HeaderItem = ({ item }: { item: JSX.Element }): JSX.Element => (
    <Col
      span={6}
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: '95px',
        marginBottom: '5px'
      }}
    >
      <Typography>{item}</Typography>
    </Col>
  );

  const collapseHeader = (account: IAccount): JSX.Element => (
    <Row gutter={[24, 12]} justify={'space-between'} className={'collapse-header-row'}>
      <Col span={6} style={{ minWidth: '112px' }}>
        <Typography.Title level={5}>{account?.name}</Typography.Title>
      </Col>
      <Col span={12}>
        <Row justify={'space-between'} style={{ display: 'flex', width: '100%' }}>
          <HeaderItem
            item={
              <Typography>
                {`${locale.labels.incomeOperations}: ${account?.income_payments_number}`}
              </Typography>
            }
          />
          <HeaderItem
            item={
              <Typography>
                {`${locale.labels.outcomeOperations}: ${account?.outcome_payments_number}`}
              </Typography>
            }
          />
          <HeaderItem
            item={
              <Typography.Title level={5}>
                {currentEarnings(account.diff, currencies)}
              </Typography.Title>
            }
          />
        </Row>
      </Col>
    </Row>
  );

  return (
    <Spin spinning={isPending} className='bills-list-page-spin-container'>
      {shift && (
        <>
          <Row>
            <Col span={12} xs={24} md={18} xl={15} xxl={10} style={{ marginRight: '10px' }}>
              <Card
                className='ant-card-bordered personal-info-card with-shadow'
                style={{ minHeight: '261px' }}
              >
                <ShiftStatusCard shift={shift} showInfo={false} />
              </Card>
            </Col>
            <Col span={12} xs={24} md={18} xl={15} xxl={10} className={'shift-details-status-card'}>
              <Card className='ant-card-bordered personal-info-card with-shadow'>
                <Row justify={'space-evenly'} style={{ marginBottom: '10px' }}>
                  <Statistic
                    title={locale.labels.duration}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                    value={getShiftDuration(shift, locale)}
                  />
                </Row>
                <Row justify={'space-evenly'} style={{ marginBottom: '10px' }}>
                  <Col>
                    <Statistic
                      title={locale.labels.openedBy}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={userData(shift?.opened_by)}
                    />
                    <Statistic
                      title={locale.labels.closedBy}
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={!shift?.open ? userData(shift?.closed_by) : '-'}
                    />
                  </Col>
                  <Col>
                    <Statistic
                      title={locale.labels.openedAt}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={dayjs(shift.opened_at).local().format('DD.MM.YYYY HH:mm')}
                    />
                    <Statistic
                      title={locale.labels.closedAt}
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={
                        !shift?.open
                          ? dayjs(shift.closed_at).local().format('DD.MM.YYYY HH:mm')
                          : '-'
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Collapse
              style={{ width: '100%' }}
              items={shift?.accounts.map((account: IAccount) => {
                return {
                  key: account.id,
                  label: collapseHeader(account),
                  children: (
                    <Table
                      pagination={false}
                      className='accent-header payments-list-table'
                      rowKey='id'
                      onRow={redirectToPaymentDetails}
                      dataSource={account.payments}
                      columns={columns}
                      rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
                    />
                  ),
                  className: 'shits-collapse-header'
                };
              })}
            ></Collapse>
          </Row>
        </>
      )}
    </Spin>
  );
};

export default ShiftDetails;
