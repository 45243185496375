import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRoleDetails } from 'api/interfaces/Role';

export const useRoleDetails = (clinicId: number, roleId: number) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(`${API_ENDPOINTS.ROLES(clinicId)}/${roleId}`);
    return data;
  };

  return useQuery<IRoleDetails>({
    queryFn,
    queryKey: ['roleDetails', clinicId, roleId],
    enabled: !!roleId
  });
};
