import { Button, Form, Input, Modal, Row, Spin } from 'antd';
import { useChangeEmail } from 'api/hooks/user/useChangeEmail';
import { useConfirmChangeEmail } from 'api/hooks/user/useConfirmChangeEmail';
import useUserStore from 'api/store/userStore';
import React, { useEffect, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { useLocale } from '../../hooks/useLocale';
import MessageModal from '../components/messageModal/MessageModal';

const ChangeUserEmail = (): JSX.Element => {
  const [form] = Form.useForm();
  const locale = useLocale('private.user.user-profile');
  const { setUser, user } = useUserStore((store) => store);

  const [visible, setVisible] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [cooldown, setCooldown] = useState<number>(0);

  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setInterval((): void => {
        setCooldown((prev: number) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const onSuccess = (email: string): void => {
    MessageModal('success', locale.messages.emailChanged);
    setUser({ ...user, email });
    setVisible(false);
    form.resetFields();
  };

  const onRequestError = (error): void => {
    MessageModal('error', error.response.data.message);
  };

  const onConfirmError = (error): void => {
    form.setFields([{ name: 'code', errors: [error.response.data.message] }]);
  };

  const onChangeRequested = (): void => {
    MessageModal('success', locale.messages.codeWasSent);
    setSubmitEnabled(true);
  };

  const onRequestEmailChange = (): void => {
    form
      .validateFields(['email', 'password'])
      .then(() => {
        changeEmail.mutate({
          email: form.getFieldValue('email'),
          password: form.getFieldValue('password')
        });
        setCooldown(30);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onConfirmEmailChange = (): void => {
    form
      .validateFields(['code'])
      .then(() => {
        confirmChangeEmail.mutate({
          code: form.getFieldValue('code')
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeEmail = useChangeEmail(onChangeRequested, onRequestError);
  const confirmChangeEmail = useConfirmChangeEmail(onSuccess, onConfirmError);

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changeEmail}
      </Button>
      <Modal
        destroyOnClose
        title={locale.labels.changeEmailTitle}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        footer={null}
        width={350}
      >
        <Spin spinning={changeEmail.isPending}>
          <Form onFinish={onConfirmEmailChange} autoComplete='off' layout='vertical' form={form}>
            <Form.Item
              label={locale.labels.password}
              name='password'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.email}
              name='email'
              rules={[
                requiredRule(locale.messages.emptyField),
                patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.confirmationCode}
              name='code'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Input style={{ maxWidth: '120px' }} />
                <Button
                  type='link'
                  disabled={cooldown > 0}
                  style={{ padding: 0 }}
                  onClick={onRequestEmailChange}
                >
                  {cooldown > 0
                    ? `${locale.labels.wait} ${cooldown}${locale.labels.seconds}`
                    : locale.labels.sendCode}
                </Button>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button disabled={!submitEnabled} block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeUserEmail;
