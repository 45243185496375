import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvoicePayload } from 'api/interfaces/Invoice';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditInvoice = (clinicId: number, invoiceId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IInvoicePayload) =>
    axiosClient.put(`${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}`, payload);

  return useCustomMutation(
    mutationFn,
    [
      ['invoiceList', clinicId],
      ['invoiceDetails', clinicId, invoiceId]
    ],
    callbackFn
  );
};
