import '../scss/ViewEditInvoice.scss';

import { Button, Col, Row, Tooltip } from 'antd';
import { useDeleteInvoiceDocument } from 'api/hooks/documents/useDeleteDocument';
import { useInvoiceDocuments } from 'api/hooks/documents/useDocuments';
import { useUploadInvoiceDocument } from 'api/hooks/documents/useUploadDocument';
import { IBillDetails } from 'api/interfaces/Bills';
import { IInvoice } from 'api/interfaces/Invoice';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import DocumentsTab from 'components/DocumentsTab';
import { TextEditorView } from 'components/TextEditorView';
import { Title } from 'components/Title';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  invoice: IInvoice;
  currency: string;
  clinicId: number;
  locale: ILocale;
  editingDisabled?: boolean;
  bill: IBillDetails;
};

export const InvoiceDetailsHeader = ({
  invoice,
  clinicId,
  locale,
  currency,
  editingDisabled,
  bill
}: Props): JSX.Element => {
  const { open } = useModal();
  const userAccess = useAccess();

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const { data: invoiceDocuments } = useInvoiceDocuments(clinicId, invoice?.id);
  const deleteDocument = useDeleteInvoiceDocument(clinicId, invoice?.id);
  const uploadDocument = useUploadInvoiceDocument(clinicId, invoice?.id);

  return (
    <div style={{ marginBottom: 10 }}>
      <div className='appointment-details-top-container' style={{ marginTop: 0 }}>
        <Row gutter={[20, 0]}>
          <Col span={24} md={12} className='invoice-details-top-container__left-column'>
            <h5>{locale.labels.info}</h5>
            <Col>
              {invoice?.purchase_date && (
                <>
                  <Title title={`${locale.labels.date}: `} />
                  <span id='purchase-date'>
                    {invoice?.purchase_date
                      ? moment(invoice.purchase_date as string).format('DD.MM.YYYY')
                      : '-'}
                  </span>
                </>
              )}
            </Col>
            <Col>
              {invoice?.provider?.id && (
                <>
                  <Title title={`${locale.labels.provider}: `} />
                  <Link
                    id='provider'
                    className={'custom-links'}
                    to={`/clinic/${clinicId}/inventory/providers/${invoice?.provider?.id}`}
                  >
                    {invoice.provider.name}
                  </Link>
                </>
              )}
            </Col>
            <Col>
              {invoice?.bill_id && (
                <>
                  <Title title={`${locale.labels.bill}: `} />
                  <Link
                    className='custom-links'
                    id='provider'
                    to={`/clinic/${clinicId}/finances/bills/${invoice?.bill_id}`}
                  >
                    {invoice?.bill_id}
                  </Link>
                </>
              )}
            </Col>
            <Col>
              <>
                <Title title={`${locale.labels.sum}: `} />
                <span id='total-price'>
                  {invoice?.total_price
                    ? `${Number(invoice.total_price).toFixed(2)} ${currency}`
                    : 0}
                </span>
              </>
            </Col>
            {bill?.remaining_amount > 0 && (
              <Col>
                <>
                  <Title title={`${locale.labels.remainingAmount}: `} />
                  <span id='remaining-amount'>
                    {bill?.remaining_amount
                      ? `${Number(bill?.remaining_amount).toFixed(2)} ${currency}`
                      : 0}
                  </span>
                </>
              </Col>
            )}
            <Col>
              <>
                <Title title={`${locale.labels.totalItems}: `} />
                <span id='total-items'>
                  {invoice?.number_of_items ? invoice.number_of_items : 0}
                </span>
              </>
            </Col>
            <Col>
              {invoice?.notes && (
                <>
                  <Title title={`${locale.labels.notes}: `} />
                  <TextEditorView value={invoice?.notes} id='notes' />
                </>
              )}
            </Col>
            <Row className='invoice-details-top-container__buttons-container'>
              {bill?.remaining_amount > 0 && (
                <Tooltip
                  trigger={'hover'}
                  placement='topLeft'
                  title={!shiftOpened && locale.labels.paymentBlockedClosedShift}
                >
                  <Button
                    id='add-payment'
                    disabled={!shiftOpened}
                    onClick={() => open(MODAL.addBillPayment)}
                    className='change-status-basic-button'
                    style={{ marginBottom: 0, height: 45 }}
                  >
                    <span className='icofont-money' />
                    <span>{locale.labels.addPayment}</span>
                  </Button>
                </Tooltip>
              )}
              {!editingDisabled && (
                <ViewDetailsButtonsGroup
                  hideCancelBtn
                  hideDeleteBtn
                  fixed
                  accessGroup={userAccess.accesses.invoices}
                  editMode={false}
                  onEditClick={() => open(MODAL.editInvoiceModal)}
                />
              )}
            </Row>
          </Col>
          <Col span={24} md={12} className='invoice-details-top-container__right-column'>
            <h5>{locale.labels.documents}</h5>
            <DocumentsTab
              uploadDocument={uploadDocument}
              deleteDocument={deleteDocument}
              data={invoiceDocuments}
            />
          </Col>
        </Row>
        <AddBillPayment bill={bill} hideAddButton />
      </div>
    </div>
  );
};
