import { Form, FormListFieldData } from 'antd';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { EditAppointmentForm } from './EditAppointmentForm';

type Props = {
  initialValues: IAppointmentDetails;
  disableTimeEditing: boolean;
};

export const EditAppointmentModal = ({ initialValues, disableTimeEditing }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const locale = useLocale('private.appointments.event');

  const [editMode, setEditMode] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onFieldsChange = (e: FormListFieldData[]): void => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <VLXModal
      title={locale.labels.editAppointment}
      centered
      form={form}
      open
      onModalClose={() => {
        closeModal();
        setEditMode(false);
      }}
      width={800}
    >
      <EditAppointmentForm
        disableTimeEditing={disableTimeEditing}
        onFieldsChange={onFieldsChange}
        initialValues={initialValues}
        form={form}
        locale={locale}
        closeModal={closeModal}
      >
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.appointments}
          editMode={editMode}
          onEditClick={() => setEditMode(true)}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              setEditMode(false);
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </EditAppointmentForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
