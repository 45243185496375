import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClientPayload } from 'api/interfaces/Client';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditClient = (clinicId: number, clientId: number, callbackFn?: (data) => void) => {
  const mutationFn = (payload: IClientPayload) =>
    axiosClient.put(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}`, payload);

  const queryKeys = ['clientsList', ['clientDetails', clinicId, clientId]];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
