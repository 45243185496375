import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IReassignLocationsPayload } from 'api/interfaces/Stock';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useReassignLocations = (
  clinicId: number,
  stockId: number,
  callbackFn?: () => void
) => {
  const mutationFn = (payload: IReassignLocationsPayload) =>
    axiosClient.post(`${API_ENDPOINTS.STOCK(clinicId)}/${stockId}/locations`, payload);

  return useCustomMutation(mutationFn, ['stockDetails', clinicId, stockId], callbackFn);
};
