import { Form } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { MODAL, useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import React, { useState } from 'react';

import { AddDictionaryForm } from './AddDictionaryForm';

type Props = {
  onSuccess: (id: number) => void;
  title: string;
  defaultDictionaryNameValue: string;
  dictionaryKey?: DICTIONARY;
};

export const AddDictionaryModal = ({
  onSuccess,
  title,
  defaultDictionaryNameValue,
  dictionaryKey
}: Props): JSX.Element => {
  const { isOpened, closeModal } = useModal();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    closeModal();
    setShowCancelModal(false);
  };

  return (
    <>
      {isOpened(MODAL.addDictionary) && (
        <VLXModal
          defZindex={9999}
          title={title}
          form={form}
          centered
          open
          onModalClose={closeModal}
          width={800}
        >
          <AddDictionaryForm
            defaultDictionaryNameValue={defaultDictionaryNameValue}
            form={form}
            onSuccess={(id: number) => {
              onSuccess(id);
              closeModal();
            }}
            dictionaryKey={dictionaryKey}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.services}
              fixed
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  closeModal();
                }
              }}
              onDeleteClick={() => setShowCancelModal(false)}
            />
          </AddDictionaryForm>
        </VLXModal>
      )}
      <ConfirmCancelModal
        defZindex={99999}
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
