import { IUserProfile } from 'api/interfaces/User';
import { create } from 'zustand';

import { defaultUserAccesses, IUserAccesses, setAccesses } from './userAccesses';

type UserState = { userAccesses: IUserAccesses[] | null } & IUserProfile;

export interface IUserState {
  user: UserState;
  setUser: (data: IUserProfile) => void;
  clearUser: () => void;
}

const defaultUserState: UserState = {
  id: null,
  avatar: null,
  email: null,
  first_name: null,
  last_name: null,
  middle_name: null,
  birth_date: null,
  phone_number: null,
  country_code: null,
  locale: null,
  country: null,
  region: null,
  city: null,
  address: null,
  skills: [],
  created_at: null,
  updated_at: null,
  user_scopes: [],
  userAccesses: [defaultUserAccesses]
};

const useUserStore = create<IUserState>((set) => ({
  user: defaultUserState,
  setUser: (data: IUserProfile) =>
    set(() => ({ user: { ...data, userAccesses: setAccesses(data.user_scopes) } })),
  clearUser: () => set(() => ({ user: defaultUserState }))
}));

export default useUserStore;
