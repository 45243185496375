import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IServicePayload } from 'api/interfaces/Service';
import { useModal } from 'api/store/modalStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateService = (clinicId: number, callbackFn?: () => void) => {
  const { closeModal } = useModal();
  const mutationFn = (payload: IServicePayload) =>
    axiosClient.post(`${API_ENDPOINTS.SERVICES(clinicId)}`, payload);

  return useCustomMutation(mutationFn, ['servicesList', clinicId], closeModal);
};
