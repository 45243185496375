import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IHealthRecord } from 'api/interfaces/Patient';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useAddAppointmentHealthRecord = (
  clinicId: number,
  appointmentId: number,
  patientId: number,
  onSuccess?: () => void
) => {
  const mutationFn = (payload: IHealthRecord): Promise<void> =>
    axiosClient.post(
      `${API_ENDPOINTS.APPOINTMENT_PATIENTS(clinicId, appointmentId)}/${patientId}/health_records`,
      payload
    );

  return useCustomMutation(
    mutationFn,
    [
      ['appointmentPatients', clinicId, appointmentId],
      ['appointmentBill', clinicId, appointmentId]
    ],
    onSuccess
  );
};
