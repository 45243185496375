import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBankAccount } from 'api/interfaces/Finances';

export const useBankAccountDetails = (clinicId: number, accountId: number) => {
  return useQuery<IBankAccount>({
    queryKey: ['bankAccountDetails', clinicId, accountId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.ACCOUNTS(clinicId)}/${accountId}`);
      return data;
    },
    enabled: !!accountId
  });
};
