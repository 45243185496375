import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ISalesList } from 'api/interfaces/Sale';

import { useTableData } from '../useTableData';

export const useSalesList = (clinicId: number, defaultParams: Record<string, string | number>) => {
  const endpoint = API_ENDPOINTS.SALES(clinicId);
  const result = useTableData<ISalesList>(endpoint, defaultParams, ['salesList', clinicId]);

  return {
    ...result,
    sales: result.data.data,
    stats: result.data.stats
  };
};
