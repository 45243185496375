import { FormInstance } from 'antd';
import { useCreateEquipment } from 'api/hooks/equipment/useCreateEquipment';
import useClinicId from 'hooks/useClinicId';
import React, { useEffect } from 'react';

import { EquipmentItemForm } from './components/EquipmentItemForm';

interface Props {
  form: FormInstance;
  children: JSX.Element;
}

export const AddEquipmentForm = ({ form, children }: Props) => {
  const clinicId = useClinicId();
  const createEquipment = useCreateEquipment(clinicId);

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <>
      <EquipmentItemForm
        onFinish={createEquipment.mutate}
        form={form}
      >
        {children}
      </EquipmentItemForm>
    </>
  );
};
