import { Form, FormInstance, Input, Select, Spin } from 'antd';
import { useBankAccountsList } from 'api/hooks/finances/useBankAccountsList';
import { useTransfer } from 'api/hooks/finances/useTransfer';
import { useModal } from 'api/store/modalStore';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { maxRule, requiredRule, validatorRule } from 'utils/form-rules';

const { TextArea } = Input;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  financialLocale: ILocale;
  currentAccountId: number;
}>;

export const TransferAccountsForm = ({
  form,
  children,
  currentAccountId,
  locale,
  financialLocale
}: Props): JSX.Element => {
  const { closeModal } = useModal();

  const clinicId = useClinicId();
  const currencies = useLocale('private.currencies').labels;

  const [accountIdFrom, setAccountIdFrom] = useState(currentAccountId);
  const [accountIdTo, setAccountIdTo] = useState(null);

  const transferAccount = useTransfer(clinicId, accountIdFrom, closeModal);
  const { bankAccounts, isPending } = useBankAccountsList(clinicId);

  useEffect(() => {
    form.setFieldValue('account_from_id', currentAccountId);
  }, [currentAccountId]);

  const filteredAccounts = (accountId: number): JSX.Element[] =>
    bankAccounts
      .filter((el) => +el.id !== accountId)
      .map((item) => (
        <Option key={item.name} title={item.name} value={item.id}>
          {item?.name} ({item?.current_balance} {currencies.uah})
        </Option>
      ));

  const accountFromList = useMemo(() => filteredAccounts(accountIdTo), [bankAccounts, accountIdTo]);

  const accountToList = useMemo(
    () => filteredAccounts(accountIdFrom),
    [bankAccounts, accountIdFrom]
  );

  const getMaxAmount = (): number =>
    bankAccounts.find((el) => +el.id === accountIdFrom)?.current_balance;

  const changeAccountFromId = (id: number): void => {
    setAccountIdFrom(id);
    const maxAmm = bankAccounts.find((el) => +el.id === id)?.current_balance;
    const currSumm = form.getFieldValue('amount');
    form.setFields([
      {
        name: 'amount',
        errors:
          !currSumm || (currSumm && maxAmm > currSumm) ? [] : [financialLocale.errors.summError]
      }
    ]);
  };

  return (
    <Spin spinning={transferAccount.isPending || isPending}>
      <Form onFinish={transferAccount.mutate} autoComplete='off' layout='vertical' form={form}>
        <Form.Item
          rules={[requiredRule()]}
          name='account_from_id'
          className='add-equipment-form__multi-select-field'
          label={locale.labels.fromAccount}
        >
          <Select
            style={{ overflow: 'hidden' }}
            filterOption={(input, option) =>
              option?.title.toString().toLowerCase().includes(input?.toLowerCase())
            }
            showSearch
            onChange={changeAccountFromId}
            value={accountIdFrom}
          >
            {accountFromList}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            requiredRule(),
            validatorRule(
              (value) => value !== accountIdFrom,
              financialLocale.errors.accountsAreTheSame
            )
          ]}
          className='add-equipment-form__multi-select-field'
          label={locale.labels.toAccount}
          name='account_to_id'
        >
          <Select
            style={{ overflow: 'hidden' }}
            onChange={setAccountIdTo}
            filterOption={(input, option) =>
              option?.title.toString().toLowerCase().includes(input?.toLowerCase())
            }
            showSearch
          >
            {accountToList}
          </Select>
        </Form.Item>
        <Form.Item
          label={locale.labels.sum}
          rules={[
            requiredRule(),
            validatorRule((value) => value <= getMaxAmount(), financialLocale.errors.summError)
          ]}
          name='amount'
        >
          <VLXInputNumber />
        </Form.Item>
        <Form.Item
          label={locale.labels.description}
          name='description'
          rules={[maxRule(255, locale.errors.length_rule_255)]}
        >
          <TextArea rows={2} />
        </Form.Item>
        {children}
      </Form>
    </Spin>
  );
};
