import 'assets/scss/row.scss';
import './components/EmployeeProfile.scss';

import { Card, Col, Row, Spin } from 'antd';
import { useUploadEmployeeAvatar } from 'api/hooks/avatar.ts/useUploadAvatar';
import { useEmployeeDetails } from 'api/hooks/employees/useEmployeeDetails';
import { useRoles } from 'api/hooks/roles/useRoles';
import userAvatar from 'assets/img/user-avatar.svg';
import { AvatarComponent } from 'components/Avatar';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import EmployeeProfileForm from './components/EmployeeProfileForm';

const EmployeeProfilePage = (): JSX.Element => {
  const rolesAccess = useAccess().accesses.roles.showList;
  const locale = useLocale('private.administration.employee-page');
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);
  const { clinicId, employeeId }: any = useParams();

  const { data: roles, isPending: isRolesLoading } = useRoles(clinicId, rolesAccess);
  const { data: employee, isPending: isEmployeeLoading } = useEmployeeDetails(clinicId, employeeId);

  const uploadAvatar = useUploadEmployeeAvatar(clinicId, employeeId);

  const handleSetAvatar = (data, callbackFn): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    uploadAvatar.mutate(avatarData);
    setChangeAvatarModalVisible(false);
    callbackFn();
  };

  return (
    <Spin spinning={isEmployeeLoading || isRolesLoading} className='profile-page-spin-container'>
      {employee && (
        <Row>
          <Col span={24} md={12}>
            <div className='header'>
              <Card>
                <AvatarComponent
                  loading={uploadAvatar.isPending}
                  editAccess
                  src={employee?.avatar ? employee?.avatar : userAvatar}
                  onImageChange={handleSetAvatar}
                  setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                  changeAvatarModalVisible={changeAvatarModalVisible}
                />
              </Card>
            </div>
            <div className='info'>
              {employee && roles && (
                <EmployeeProfileForm
                  locale={locale}
                  employee={employee}
                  clinicId={clinicId}
                  employeeId={employeeId}
                  roles={roles}
                />
              )}
            </div>
          </Col>
          {/* TODO Should we need this? */}
          {/* <Col span={24} md={12}> */}
          {/*  <Card> */}
          {/*    <EmployeeActionsTimeline /> */}
          {/*  </Card> */}
          {/* </Col> */}
        </Row>
      )}
    </Spin>
  );
};

export default EmployeeProfilePage;
