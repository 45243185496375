const isInvalidValue = (value): boolean =>
  value === undefined || value === null || value === '' || value === 'Invalid Date';

export const cleanParams = (params?: Record<string, string | number | string[]>) => {
  const val = Object.fromEntries(
    Object.entries(params || {})
      .filter(([_key, value]) => !isInvalidValue(value))
      .map(([key, value]) => [
        key,
        typeof value === 'string' && value.includes(',') ? value.split(',') : value
      ])
  );

  return val;
};
