import './scss/FinancialAccounts.scss';

import { Button, Pagination, Row, Spin, Table, TableColumnProps, Tooltip } from 'antd';
import { useBankAccountTransactions } from 'api/hooks/finances/useBankAccountTransactions';
import { IBankingTransaction } from 'api/interfaces/Finances';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddPaymentModal } from 'layout/modals/finances/addPayment/AddPaymentModal';
import { TransferAccountsModal } from 'layout/modals/finances/transferAccounts/TransferAccountsModal';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import FinanceIcon from '../helpers/FinanceIcon';
import { getPaymentName } from '../payments/utils/utils';

type Props = {
  currentAccountId: number;
  financialAccountId: number;
  currency: string;
};

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const BankAccountTransactions = ({
  currentAccountId,
  financialAccountId,
  currency
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { isOpened, open } = useModal();
  const locale = useLocale('private.finances.payments');
  const financialLocale = useLocale('private.finances.financial-accounts');

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const { accountPayments, data, isPending, params, updateParams, updateSorting } =
    useBankAccountTransactions(clinicId, currentAccountId, defaultParams, false);

  useEffect(() => updateParams(defaultParams), []);

  const redirectToPaymentDetails = (record: IBankingTransaction) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IBankingTransaction>[] => [
      {
        key: 'payment_type',
        dataIndex: 'payment_type',
        title: '',
        sorter: (a, b) => (a.payment_type > b.payment_type ? 1 : -1),
        render: (bill_type) => <FinanceIcon type={bill_type} />,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (e) => getPaymentName(e?.name, locale),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: locale.labels.date,
        sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
        render: (created_at) =>
          created_at !== undefined ? dayjs(created_at).format('DD.MM.YYYY HH:mm') : '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: locale.labels.sum,
        sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
        render: (amount) => (amount ? `${money(amount)} ${currency}` : '-'),
        onCell: redirectToPaymentDetails
      }
    ],
    []
  );

  const renderShowModalBtn = (icon: string, buttonTxt: string, tooltipTxt: string, onClick: () => void): ReactNode => (
    <Tooltip title={!shiftOpened ? tooltipTxt : null}>
      <Row>
        <Button
          className={`icofont ${icon} open-modal-button`}
          type={'primary'}
          style={{ borderRadius: 30 }}
          disabled={!shiftOpened}
          onClick={onClick}
        >
          <span className='open-modal-button__txt'>{buttonTxt}</span>
        </Button>
      </Row>
    </Tooltip>
  );

  return (
    <>
      <h4>{locale.labels.payments}</h4>
      <Spin spinning={isPending}>
        <Table
          className='accent-header bills-list-table'
          rowKey='id'
          dataSource={accountPayments}
          columns={columns}
          pagination={false}
          onChange={updateSorting}
          rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        />
      </Spin>
      <Row justify={'space-between'} style={{ marginTop: 20 }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <Row style={{ gap: 20 }}>
          {!isOpened(MODAL.addPaymentModal) &&
            renderShowModalBtn('icofont-plus', locale.buttons.addPayment, locale.labels.paymentBlockedClosedShift, () =>
              open(MODAL.addPaymentModal)
            )}
          {!isOpened(MODAL.transferAccountsModal) &&
            renderShowModalBtn(
              'icofont-loop',
              financialLocale.buttons.transferBetweenAccounts,
              financialLocale.labels.transferBlockedClosedShift,
              () => open(MODAL.transferAccountsModal)
            )}
        </Row>
      </Row>
      {isOpened(MODAL.addPaymentModal) && (
        <AddPaymentModal initialValues={{ account_id: Number(financialAccountId) }} />
      )}
      {isOpened(MODAL.transferAccountsModal) && (
        <TransferAccountsModal currentAccountId={currentAccountId} />
      )}
    </>
  );
};
