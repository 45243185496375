import { Button } from 'antd';
import { IBillDetails } from 'api/interfaces/Bills';
import React, { useState } from 'react';

import { BillContentToPrintWrapper } from './BillContentToPrintWrapper';

interface Props {
  bill: IBillDetails;
  size?: number;
  iconSize?: number;
}

export const BillPrintButton = ({ bill, iconSize, size }: Props): JSX.Element => {
  const [printingStarted, setPrintingStarted] = useState(false);

  return (
    <>
      <Button
        onClick={() => setPrintingStarted(true)}
        block
        disabled={!bill}
        type='primary'
        shape={'circle'}
        style={{ width: size ?? 60, height: size ?? 60, fontSize: iconSize ?? 24 }}
      >
        <span className='icofont icofont-print' />
      </Button>
      {printingStarted && (
        <BillContentToPrintWrapper bill={bill} setPrintingStarted={setPrintingStarted} />
      )}
    </>
  );
};
