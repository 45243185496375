import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockList } from 'api/interfaces/Stock';

import { useTableData } from '../useTableData';

export const useStockList = (
  clinicId: number,
  defaultParams: Record<string, string | number | any>
) => {
  const endpoint = API_ENDPOINTS.STOCK(clinicId);
  const result = useTableData<IStockList>(endpoint, defaultParams, ['stockList', clinicId]);

  return {
    ...result,
    stocks: result.data.data || []
  };
};
