import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEventDetails } from 'api/interfaces/CalendarEvent';

export const useEventDetails = (eventId: number) => {
  const queryFn = async () => {
    const response = await axiosClient.get<IEventDetails>(`${API_ENDPOINTS.EVENTS}/${eventId}`);
    return response.data;
  };

  return useQuery<IEventDetails>({
    queryKey: ['calendar-event', eventId],
    queryFn,
    enabled: !!eventId
  });
};
