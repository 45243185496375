import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteSale = (clinicId: number, saleId: number, callbackFn?: () => void) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.SALES(clinicId)}/${saleId}`);

  return useCustomMutation(
    mutationFn,
    [
      ['saleDetails', clinicId, saleId],
      ['salesList', clinicId]
    ],
    callbackFn
  );
};
