import { Col, Row } from 'antd';
import { BillType, IBillDetails } from 'api/interfaces/Bills';
import { StatusTag } from 'components/StatusTag';
import { billType } from 'constants/dictionary/default/selectOptions';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';

import getType from '../../helpers/TagSelector';
import { BillHeaderContent } from './BillHeaderContent';
import { SaleBillHeaderContent } from './SaleBillHeaderContent';

type Props = {
  bill: IBillDetails;
  currency: string;
  locale: ILocale;
};

export const BillDetailsHeader = ({ bill, currency, locale }: Props): JSX.Element => {
  const localizedTypes = useLocalizedList(billType);

  const getBillTypeTag = (type: BillType): { value: BillType; label: string } => {
    const getLocalizedLabel = (val: any) => {
      return localizedTypes.find((el: any) => el.value === val);
    };
    return { value: type, label: getLocalizedLabel(type).label };
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='bill-name'>
              {bill?.name}
            </h4>
            <span style={{ marginRight: 12 }}>{getType(getBillTypeTag(bill?.bill_type))}</span>
            <StatusTag status={bill?.payment_status} />
          </div>
        </Col>
      </Row>

      <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
        <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
          {bill?.sale ? (
            <SaleBillHeaderContent bill={bill} locale={locale} currency={currency} />
          ) : (
            <BillHeaderContent bill={bill} locale={locale} currency={currency} />
          )}
        </div>
      </div>
    </>
  );
};
