import './PatientList.scss';

import { Button, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useVaccinationsList } from 'api/hooks/vaccinations/useVaccinationsList';
import { IVaccination } from 'api/interfaces/Vaccination';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import animalsList from 'constants/dictionary/animals';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { GenerateVaccinationReport } from 'layout/modals/generateVaccinationReport/GenerateVaccinationReport';
import { VaccinationItemModal } from 'layout/modals/vaccination/VaccinationItemModal';
import React, { useCallback, useMemo, useState } from 'react';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const VaccinationsTab = (): JSX.Element => {
  const clinicId = useClinicId();
  const { localeCode } = useLocaleCode();
  const { open, isOpened } = useModal();
  const localePage = useLocale('private.patients.patient-page');
  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);

  const { data, vaccinations, isLoading, params, updateParams, updateSorting } =
    useVaccinationsList(clinicId, defaultParams);

  const [selectedVaccination, setSelectedVaccination] = useState(null);

  const getCoverageCellData = (data: string[]): string | JSX.Element[] => {
    if (!data?.length) return '-';

    return data.map((el) => (
      <div key={el}>{localizedVaccineAntigens.find((item) => item.value === el).label}</div>
    ));
  };

  const getPatientSpecies = useCallback(
    (specie: string): string => {
      const species = animalsList.find((el) => el.value === specie);
      return species?.[localeCode];
    },
    [localeCode]
  );

  const editVaccinationToggle = (record: IVaccination): { onClick: () => void } => ({
    onClick: () => openVaccinationModal(record)
  });

  const openVaccinationModal = (record: IVaccination | {}): void => {
    setSelectedVaccination(record);
    open(MODAL.vaccinationItemModal);
  };

  const columns = useMemo(
    (): TableColumnProps<IVaccination>[] => [
      {
        key: 'patient_name',
        title: localePage.labels.patient,
        sorter: () => null,
        render: (e) => e.patient_name,
        onCell: editVaccinationToggle
      },
      {
        key: 'patient_species',
        title: localePage.labels.species,
        sorter: () => null,
        render: (e) => getPatientSpecies(e.patient_species),
        onCell: editVaccinationToggle
      },
      {
        key: 'client_name',
        title: localePage.labels.owner,
        sorter: () => null,
        render: (e) => e.client_name,
        onCell: editVaccinationToggle
      },
      {
        key: 'name',
        title: localePage.labels.vaccine,
        sorter: () => null,
        render: (e) => e.name,
        onCell: editVaccinationToggle
      },
      {
        key: 'manufacturer',
        title: localePage.labels.manufacturer,
        sorter: () => null,
        render: (e) => e.manufacturer,
        onCell: editVaccinationToggle
      },
      {
        key: 'coverage',
        title: localePage.labels.coverage,
        sorter: () => null,
        render: (e) => getCoverageCellData(e.coverage),
        onCell: editVaccinationToggle
      },
      {
        key: 'serial_number',
        title: localePage.labels.serialNumber,
        sorter: () => null,
        render: (e) => e.serial_number,
        onCell: editVaccinationToggle
      },
      {
        key: 'date_of_vaccination',
        title: localePage.labels.dateVaccination,
        sorter: () => null,
        onCell: editVaccinationToggle,
        render: (e) =>
          e.date_of_vaccination ? dayjs(e.date_of_vaccination).format('DD.MM.YYYY') : '-'
      },
      {
        key: 'date_of_next_vaccination',
        title: localePage.labels.dateNextVaccination,
        sorter: () => null,
        onCell: editVaccinationToggle,
        render: (e) =>
          e.date_of_next_vaccination ? dayjs(e.date_of_next_vaccination).format('DD.MM.YYYY') : '-'
      }
    ],
    [vaccinations]
  );

  return (
    <>
      <Row justify={'space-between'} style={{ gap: '0 20px' }}>
        <VLXSearchNEW onFilterChange={updateParams} />
        <Button
          id={'create-vaccination-report'}
          className='composit-search-select-option-search-button'
          type='primary'
          style={{ marginBottom: 20 }}
          onClick={() => open(MODAL.generateVaccinationReport)}
        >
          {localePage.buttons.createReport}
        </Button>
      </Row>
      <Table
        pagination={false}
        className='accent-header patients-list-table'
        rowKey={(row) => `${row.id}_${crypto.randomUUID()}`}
        dataSource={vaccinations}
        loading={isLoading}
        onChange={updateSorting}
        columns={columns}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => openVaccinationModal({})}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={localePage.buttons.addVaccination}
        />
      </Row>
      {isOpened(MODAL.generateVaccinationReport) && <GenerateVaccinationReport />}
      {isOpened(MODAL.vaccinationItemModal) && (
        <VaccinationItemModal initialValues={selectedVaccination} />
      )}
    </>
  );
};
