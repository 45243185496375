import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteEquipment = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = (equipmentId: number) =>
    axiosClient.delete(`${API_ENDPOINTS.EQUIPMENT(clinicId)}/${equipmentId}`);

  return useCustomMutation(mutationFn, ['equipmentList', clinicId], callbackFn);
};