import './SalesList.scss';

import {
  Card,
  Col,
  Pagination,
  Popover,
  Row,
  Segmented,
  Statistic,
  Table,
  TableColumnProps
} from 'antd';
import { useSalesList } from 'api/hooks/sales/useSalesList';
import { ISale, ISalesStats } from 'api/interfaces/Sale';
import useShiftStore from 'api/store/shiftStore';
import { StatusTag } from 'components/StatusTag';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { sales as salesLocale } from 'i18n/pages/sales';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SALES_LIST_RANGES } from './utils/constants';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc',
  'q[list_range]': 'current_shift'
};

const SalesList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useI18n<typeof salesLocale['ukUA']>(salesLocale);

  const [listRangeFilter, setListRangeFilter] = useState<string>('current_shift');
  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const { data, sales, isLoading, stats, params, updateParams, updateSorting } = useSalesList(
    clinicId,
    defaultParams
  );

  const currencies = useLocale('private.currencies').labels;

  const redirectToSaleDetails = (sale: ISale) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/shop-farmacy/${sale.id}`);
    }
  });

  const redirectToClient = (sale: ISale): void => {
    navigate(`/clinic/${clinicId}/clients/${sale?.client?.id}`);
  };

  const redirectToEmployee = (sale: ISale): void => {
    navigate(`/clinic/${clinicId}/administration/employees/${sale?.user?.id}`);
  };

  const Content = (sale: ISale): JSX.Element => (
    <div>
      <p style={{ marginBottom: '2px' }}>
        {`${locale.labels.deletedBy}: ${sale?.deleted_by?.name} `}
        {sale?.deleted_by?.title && `(${sale?.deleted_by?.title})`}
      </p>
    </div>
  );

  const getPaymentRow = (sale: ISale): JSX.Element => (
    <Row align='middle' className='payment-cell'>
      <StatusTag status={sale.payment_status} />
      {sale?.deleted && (
        <Popover placement='topLeft' title={locale.labels.saleDeleted} content={Content(sale)}>
          <span className='icofont-info-circle' style={{ fontSize: '15px', marginLeft: 4 }} />
        </Popover>
      )}
    </Row>
  );

  const columns = useMemo(
    (): TableColumnProps<ISale>[] => [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: locale.labels.date,
        sorter: () => null,
        render: (created_at) =>
          `${created_at ? dayjs(created_at).local().format('DD.MM.YYYY HH:mm') : '-'}`,
        onCell: redirectToSaleDetails
      },
      {
        key: 'client_name',
        title: locale.labels.client,
        sorter: () => null,
        render: (e) =>
          e?.client?.name ? (
            <a onClick={() => redirectToClient(e)} className={'custom-links'}>
              {e?.client?.name}
            </a>
          ) : (
            '-'
          )
      },
      {
        key: 'payment_status',
        sorter: () => null,
        title: locale.labels.paymentStatus,
        render: getPaymentRow,
        onCell: redirectToSaleDetails
      },
      {
        key: 'final_amount',
        title: locale.labels.totalBilledAmount,
        sorter: () => null,
        render: (e) =>
          e.total_billed_amount !== undefined
            ? `${money(e.total_billed_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'total_income_amount',
        title: locale.labels.totalPayedAmount,
        sorter: () => null,
        render: (e) =>
          e.total_payed_amount !== undefined
            ? `${money(e.total_payed_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'remaining_amount',
        title: locale.labels.remainingAmount,
        sorter: () => null,
        render: (e) =>
          e.remaining_amount !== undefined
            ? `${money(e.remaining_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'created_by_name',
        title: locale.labels.createdBy,
        sorter: () => null,
        render: (e) => (
          <a onClick={() => redirectToEmployee(e)} className={'custom-links'}>
            {e?.user?.name || '-'}
          </a>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Segmented<string>
        options={[
          { label: locale.labels.currentShift, value: SALES_LIST_RANGES.currentShift },
          { label: locale.labels.allSales, value: SALES_LIST_RANGES.allSales },
          { label: locale.labels.deletedSales, value: SALES_LIST_RANGES.deletedSales }
        ]}
        value={listRangeFilter}
        onChange={(listRange) => {
          setListRangeFilter(listRange);
          updateParams({ listRange });
        }}
        style={{ marginBottom: 10 }}
      />
      {listRangeFilter !== SALES_LIST_RANGES.deletedSales && (
        <Row gutter={16} className={'sales-stats-row'}>
          <Col span={8} xs={24} sm={12} md={8} xl={8} xxl={8}>
            <Card bordered={false}>
              <Statistic
                title={locale.labels.salesCount}
                value={(stats as ISalesStats)?.sales_count}
                precision={0}
                valueStyle={{ color: '#000000' }}
                style={{ textAlign: 'center', paddingTop: '5px' }}
              />
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} xl={8} xxl={8}>
            <Card bordered={false}>
              <Statistic
                title={locale.labels.totalBilled}
                value={(stats as ISalesStats)?.total_billed}
                precision={2}
                valueStyle={{ color: '#cf7113' }}
                suffix={currencies.uah}
                style={{ textAlign: 'center', paddingTop: '5px' }}
              />
            </Card>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} xl={8} xxl={8}>
            <Card bordered={false}>
              <Statistic
                title={locale.labels.totalIncome}
                value={(stats as ISalesStats)?.total_income}
                precision={2}
                valueStyle={{ color: '#2ca61b' }}
                suffix={currencies.uah}
                style={{ textAlign: 'center', paddingTop: '5px' }}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Table
        pagination={false}
        className='accent-header sales-list-table'
        rowKey='id'
        dataSource={sales}
        loading={isLoading}
        onChange={updateSorting}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => navigate(`/clinic/${clinicId}/shop-farmacy/new`)}
          tooltip={!shiftOpened ? locale.labels.paymentBlockedClosedShift : null}
          disabled={!shiftOpened}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addSale}
        />
      </Row>
    </>
  );
};

export default SalesList;
