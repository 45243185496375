import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IReplenishmentBalance } from 'api/interfaces/Client';
import { useModal } from 'api/store/modalStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useClientBalanceReplenishment = (
  clinicId: number,
  clientId
) => {
  const { closeModal } = useModal();
  return useCustomMutation(
    (payload: IReplenishmentBalance) =>
      axiosClient.post(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}/replenishments`, payload),
    ['clientDetails', clinicId, clientId],
    closeModal
  );
};
