import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillPayload } from 'api/interfaces/Bills';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateBill = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IBillPayload) => axiosClient.post(`${API_ENDPOINTS.BILLS(clinicId)}`, payload),
    ['billsList', clinicId],
    callbackFn
  );
};
