import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IHealthRecord } from 'api/interfaces/Patient';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useUpdateAppointmentHealthRecord = (
  clinicId: number,
  patientId: number,
  healthRecordId: number,
  onSuccess?: () => void
) => {
  const mutationFn = (payload: IHealthRecord): Promise<void> =>
    axiosClient.put(
      `${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}/health_records/${healthRecordId}`,
      payload
    );

  return useCustomMutation(
    mutationFn,
    [
      ['appointmentPatients', clinicId],
      ['appointmentBill', clinicId, patientId]
    ],
    onSuccess
  );
};
