import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeletePayment = (clinicId: number, paymentId: number, callbackFn?: () => void) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.PAYMENTS(clinicId)}/${paymentId}`);

  return useCustomMutation(mutationFn, ['paymentDetails', clinicId, paymentId], callbackFn);
};
