import { Button, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import { useEditUser } from 'api/hooks/user/useEditUser';
import { IUserProfile } from 'api/interfaces/User';
import useUserStore from 'api/store/userStore';
import MultiSkillsSelect from 'components/MultiSkillsSelect';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import * as ls from 'local-storage';
import React, { useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const EditUserData = (): JSX.Element => {
  const [editProfileForm] = Form.useForm();
  const locale = useLocale('private.user.user-profile');
  const editUser = useEditUser(() => setShowModal(false));

  const { user } = useUserStore();

  const [selectedItems, setSelectedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const onSubmit = (values: Partial<IUserProfile>) => {
    values.skills = selectedItems;
    editUser.mutate(values);
  };

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setShowModal(true)}>
        {locale.buttons.editProfile}
      </Button>
      <VLXModal
        form={editProfileForm}
        title={locale.labels.editProfileTitle}
        open={showModal}
        onModalClose={() => setShowModal(false)}
        width={800}
      >
        <Spin spinning={editUser.isPending}>
          <Form
            form={editProfileForm}
            onFinish={onSubmit}
            autoComplete='off'
            layout='vertical'
            initialValues={{
              ...user,
              birth_date: dayjs(user.birth_date)
            }}
          >
            <Row gutter={[20, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.firstName}
                  name='first_name'
                  rules={[
                    requiredRule(locale.labels.emptyFirstName),
                    patternRule(PATTERNS.NAME, locale.messages.nameNotValid)
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.middleName}
                  name='middle_name'
                  rules={[patternRule(PATTERNS.NAME, locale.messages.nameNotValid)]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.lastName}
                  name='last_name'
                  rules={[
                    requiredRule(locale.labels.emptyLastName),
                    patternRule(PATTERNS.NAME, locale.messages.nameNotValid)
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.dateOfBirth}
                  name='birth_date'
                  rules={[requiredRule(locale.labels.emptyDateOfBirth)]}
                >
                  <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
                </Form.Item>
                <Form.Item label={locale.labels.skills} name='skills'>
                  <MultiSkillsSelect
                    user={user}
                    editMode={true}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.address}
                  style={{ marginBottom: 0 }}
                  required={true}
                >
                  <Form.Item
                    name='country'
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                      marginRight: '16px'
                    }}
                    rules={[requiredRule(locale.messages.countryEmpty)]}
                  >
                    <Input placeholder={locale.placeholders.country} />
                  </Form.Item>
                  <Form.Item
                    name='region'
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    rules={[requiredRule(locale.messages.regionEmpty)]}
                  >
                    <Input placeholder={locale.placeholders.region} />
                  </Form.Item>
                </Form.Item>
                <Form.Item name='city' rules={[requiredRule(locale.messages.cityEmpty)]}>
                  <Input placeholder={locale.placeholders.city} />
                </Form.Item>
                <Form.Item name='address' rules={[requiredRule(locale.messages.addressEmpty)]}>
                  <Input placeholder={locale.placeholders.address} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item hidden={true} name='locale' initialValue={ls.get('locale')}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type='primary' block htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </VLXModal>
    </>
  );
};

export default EditUserData;
