import './AddAppointmentForm.scss';

import { Avatar, Button, Col, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import { useCreateAppointment } from 'api/hooks/appointments/useCreateAppointment';
import { useEmployeesList } from 'api/hooks/employees/useEmployeesList';
import { useClientPatientsList } from 'api/hooks/patients/useClientPatientsList';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { useServicesList } from 'api/hooks/services/useServicesList';
import { ICreateAppointmentPayload } from 'api/interfaces/Appointment';
import DateRangePicker from 'components/DateRangePicker';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import animalsList from 'constants/dictionary/animals';
import { locationTypes, roomStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import { ClientsSelect } from 'layout/components/clientsSelect/ClientsSelect';
import { EmployeesSelect } from 'layout/components/employeesSelect/EmployeesSelect';
import { PatientsSelect } from 'layout/components/patientsSelect/PatientsSelect';
import { RoomsSelect } from 'layout/components/roomsSelect/RoomsSelect';
import { getDefaultAvatar } from 'pages/patients/utils/PatientHelper';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { patternRule, PATTERNS, requiredRule, validatorRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';

const { Option } = Select;
const { Text } = Typography;

type Props = React.PropsWithChildren<{
  closeModal: () => void;
  form: FormInstance;
  initialValues?: any;
  locale: ILocale;
  eventTypeSelect?: ReactNode;
}>;

export const AddAppointmentForm = ({
  closeModal,
  form,
  initialValues,
  children,
  locale,
  eventTypeSelect
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const localizedRoomStatuses = useLocalizedList(roomStatuses);
  const locations = useLocalizedList(locationTypes);
  const localizedAnimalsList = useLocalizedList(animalsList);

  const [createNewClient, setCreateNewClient] = useState(false);
  const [createNewPatient, setCreateNewPatient] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(initialValues?.client_id);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [selectedPatientsIds, setSelectedPatientsIds] = useState([]);
  const [locationType, setLocationType] = useState<string>('room');

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);

  const createAppointment = useCreateAppointment(clinicId, closeModal);
  const { employees } = useEmployeesList(clinicId, { page: 0 });
  const { rooms } = useRoomsList(clinicId, { page: 0, 'q[deleted]': 'false' });
  const { services } = useServicesList(clinicId, { page: 0, 'q[deleted]': 'false' });
  const { data: clientPatients } = useClientPatientsList(clinicId, selectedUserId);

  useEffect(() => {
    if (initialValues?.start_time) {
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(initialValues?.duration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(initialValues?.duration));
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
    if (initialValues?.patients_ids) {
      setSelectedPatientsIds(initialValues.patients_ids);
    }
  }, [initialValues]);

  const onSubmit = (payload: Partial<ICreateAppointmentPayload>): void => {
    payload.event.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
    payload.event.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);

    payload.patients = payload.patients.filter((el) => el.name);
    payload.clinic_id = clinicId;
    payload.patients_ids = selectedPatientsIds;

    createAppointment.mutate(payload as ICreateAppointmentPayload);
  };

  const patientsList = useMemo(
    () =>
      clientPatients?.data.map((item) => (
        <Option key={`patient_${item.id}`} value={item.id} title={item.name}>
          <Row align='middle' style={{ width: '100%' }}>
            <Col style={{ marginRight: '5px' }}>
              <div className={'pet-avatar-select'}>
                <Avatar src={item.avatar ? item.avatar : getDefaultAvatar(item.species)} />
              </div>
            </Col>
            <Col>
              <Text strong>{item.name}</Text>
            </Col>
          </Row>
        </Option>
      )),
    [clientPatients]
  );

  const employeesList = useMemo(
    () =>
      employees
        .filter((employee) => employee.id !== selectedUserId)
        .map(
          (item) =>
            !selectedEmployees?.includes(item.id) && (
              <Option key={item.full_name} title={item.full_name} value={item.id}>
                {item.full_name}
              </Option>
            )
        ),
    [employees, selectedEmployees, selectedUserId]
  );

  const getRoomStatus = (status: string): string | null => {
    const res = localizedRoomStatuses.find((el) => el?.value === status);
    return res?.label;
  };

  const roomsList = rooms
    ?.filter((room) => !room.not_for_appointment)
    .map((item) => (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
          <span style={{ color: 'grey', paddingLeft: 10 }}>{getRoomStatus(item?.status)}</span>
        </div>
      </Option>
    ));

  const servicesList = useMemo(() => getOptions(services.filter((s) => !s.deleted)), [services]);

  useEffect(() => {
    if (rooms?.length) {
      const roomsForAppoinment = rooms.filter((el) => !el.not_for_appointment);
      if (roomsForAppoinment?.length === 1) {
        const event = form.getFieldValue('event');
        form.setFieldsValue({
          event: { ...event, location: { ...event.location, id: roomsForAppoinment[0].id } }
        });
      }
    }
  }, [roomsList]);

  const selectClient = (id: number): void => {
    setSelectedUserId(id);
    form.setFieldsValue({ client_id: id });
    form.setFieldsValue({ patients_ids: [] });
    setSelectedPatientsIds([]);
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
      style={{ position: 'relative' }}
    >
      <div className='modal-content add-appointment-form'>
        {eventTypeSelect}
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.visitReason}
              name='title'
              rules={[
                requiredRule(locale.messages.mandatoryField),
                patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='add-appointment-form__doctor-field'
              label={locale.labels.doctor}
              name='doctor_id'
              rules={[requiredRule(locale.errors.doctorNotValid)]}
            >
              <Select
                onChange={setSelectedDoctorId}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {employeesList}
              </Select>
            </Form.Item>
            <Form.Item label={locale.labels.attendees} name='attendees'>
              <EmployeesSelect selectedDoctorId={selectedDoctorId} onChange={setSelectedEmployees} />
            </Form.Item>
            <Form.Item label={''} style={{ marginBottom: 0 }}>
              <Form.Item
                name={['event', 'location', 'type']}
                initialValue={'room'}
                label={locale.labels.location}
                rules={[requiredRule(locale.errors.enterLocationType)]}
              >
                <Select filterOption={false} onSelect={setLocationType} options={locations} />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={['event', 'location', 'details']}
                label={locale.labels.details}
                hidden={locationType !== 'other'}
                rules={locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []}
              >
                <Input />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                id='location-room'
                name={['event', 'location', 'id']}
                hidden={locationType !== 'room'}
                className='add-appointment-form__doctor-field appointment-room-select-field'
                label={locale.labels.room}
                rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
              >
                <RoomsSelect appointment />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              duration={duration}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDuration={setDuration}
            />
          <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.service}
              name='services'
            >
              <Select
                mode='multiple'
                filterOption={(input, option) =>
                  option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                }
                showSearch
                allowClear
              >
                {servicesList}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={5}>
          <span style={{ color: '#ff4d4f' }}>*</span>
          {locale.labels.client}
        </Typography.Title>

        {!createNewClient && (
          <Row>
            <Col md={24} span={24}>
              <Form.Item
                className='single-field-select'
                name={'client_id'}
                rules={[requiredRule(locale.errors.ownerNotValid)]}
              >
                <ClientsSelect
                  onChange={selectClient}
                  selectedClientId={selectedUserId}
                  patientIds={selectedPatientsIds}
                />
              </Form.Item>
            </Col>
            <Button
              id='addNewClient'
              type={'primary'}
              style={{ borderRadius: 30 }}
              onClick={() => {
                form.setFieldsValue({ patients_ids: [] });
                setCreateNewClient(true);
                setCreateNewPatient(true);
              }}
            >
              {locale.buttons.addNew}
            </Button>
          </Row>
        )}
        {createNewClient && (
          <Row gutter={[20, 10]}>
            <Col span={24} md={12}>
              <Form.Item
                label={locale.labels.firstName}
                name={['client', 'first_name']}
                rules={[requiredRule()]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={locale.labels.lastName} name={['client', 'last_name']}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <PhoneFieldFormItem
                formItemPhonePrefix={['client', 'country_code']}
                formItemPhoneNumber={['client', 'phone_number']}
              />
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={locale.labels.email} name={['client', 'email']}>
                <Input />
              </Form.Item>
            </Col>
            <Button
              type={'primary'}
              style={{ borderRadius: 30 }}
              onClick={() => {
                setCreateNewClient(false);
                setCreateNewPatient(false);
                if (!form.getFieldValue('patients').length) {
                  form.setFieldValue('patients', [
                    {
                      name: '',
                      species: ''
                    }
                  ]);
                }
              }}
            >
              {locale.buttons.chooseExisting}
            </Button>
          </Row>
        )}
        <Typography.Title level={5}>
          <span style={{ color: '#ff4d4f' }}>*</span>
          {locale.labels.patients}
        </Typography.Title>
        <Form.Item name='patients' initialValue={[{ name: '', species: '' }]} hidden>
          <Input />
        </Form.Item>
        {!createNewClient && (
          <Row>
            <Col md={24} span={24}>
              <Form.Item
                rules={[
                  validatorRule(
                    (items) =>
                      !(
                        items.length < 1 &&
                        form.getFieldValue('patients').length &&
                        !form.getFieldValue('patients')[0].name &&
                        !form.getFieldValue('patients')[0].species
                      ),
                    locale.errors.patientNotValid
                  )
                ]}
                name={['patients_ids']}
              >
                {!selectedUserId ? (
                  <PatientsSelect
                    clinicId={clinicId}
                    setSelectedOwnerId={(id: number) => {
                      form.setFields([{ name: 'client_id', value: id, errors: [] }]);
                      setSelectedUserId(id);
                    }}
                    setSelectedPatientsIds={(ids) => {
                      setSelectedPatientsIds([ids]);
                      form.setFieldValue('patients_ids', [ids]);
                    }}
                  />
                ) : (
                  <Select
                    filterOption={(input, option) =>
                      option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                    }
                    optionLabelProp='title'
                    className='patient-select'
                    showSearch
                    value={selectedPatientsIds}
                    mode='multiple'
                    onSelect={(value) => setSelectedPatientsIds([...selectedPatientsIds, value])}
                    onDeselect={(value) =>
                      setSelectedPatientsIds(selectedPatientsIds.filter((id) => id !== value))
                    }
                    disabled={!selectedUserId}
                    placeholder={locale.placeholders.searchPatient}
                  >
                    {patientsList}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {!createNewPatient && (
              <Button
                type={'primary'}
                style={{ borderRadius: 30, marginBottom: 10 }}
                onClick={() => {
                  setCreateNewPatient(true);
                  if (!form.getFieldValue('patients').length) {
                    form.setFieldValue('patients', [{ name: '', species: '' }]);
                  }
                }}
              >
                {locale.buttons.addNew}
              </Button>
            )}
          </Row>
        )}
        {createNewPatient && (
          <Form.List
            name='patients'
            rules={[
              validatorRule(
                (items) =>
                  !((!items || items.length < 1) && !form.getFieldValue('patients_ids').length),
                locale.errors.patientNotValid
              )
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[20, 10]}>
                    <Col span={22} md={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[requiredRule(locale.errors.animalNameNotValid)]}
                      >
                        <Input placeholder={locale.labels.name} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={(createNewClient && key > 0) || !createNewClient ? 22 : 24}
                      md={(createNewClient && key > 0) || !createNewClient ? 11 : 12}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'species']}
                        rules={[requiredRule(locale.errors.animalTypeNotValid)]}
                      >
                        <Select filterOption={false} options={localizedAnimalsList} />
                      </Form.Item>
                    </Col>
                    {((createNewClient && key > 0) || !createNewClient) && (
                      <Col span={1} md={1}>
                        <Button
                          onClick={() => {
                            remove(name);
                          }}
                          danger
                          type='primary'
                          shape={'circle'}
                          className={'inventory-update-buttons'}
                        >
                          <span className='icofont icofont-trash' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button
                      className='icofont icofont-plus'
                      type={'primary'}
                      style={{
                        borderRadius: 30,
                        fontWeight: 'bold'
                      }}
                      onClick={() => add()}
                    >
                      {locale.buttons.addMore}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        )}
        <TextEditor label={locale.labels.notes} name={['event', 'notes']} />
      </div>
      {children}
    </Form>
  );
};
