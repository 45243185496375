import { Dropdown } from 'antd';
import { useLocaleCode } from 'api/store/localeContext';
import { locales } from 'constants/locales';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Localization = (): JSX.Element => {
  const { localeCode, setLocale } = useLocaleCode();

  const onSelect = (e): void => {
    setLocale(e.key);
  };

  return (
    <Dropdown
      menu={{
        items: locales,
        onClick: onSelect
      }}
      trigger={['click']}
      placement='bottom'
    >
      <span className='locale-menu'>
        {locales.find((lang) => lang.key === localeCode)?.label || 'UA'}
      </span>
    </Dropdown>
  );
};

export default Localization;
