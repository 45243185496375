import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillDetails } from 'api/interfaces/Bills';

export const useAppointmentBill = (clinicId: number, appointmentId: number) => {
  const endpoint = `${API_ENDPOINTS.APPOINTMENTS(clinicId)}/${appointmentId}/bill`;

  const queryFn = async () => {
    const response = await axiosClient.get<IBillDetails>(endpoint);
    return response.data;
  };
  return useQuery<IBillDetails>({
    queryFn,
    queryKey: ['appointmentBill', clinicId, appointmentId],
    enabled: !!clinicId && !!appointmentId
  });
};
