import './scss/Provider.scss';

import { Pagination, Table, TableColumnProps } from 'antd';
import { useProvidersHistory } from 'api/hooks/providers/useProvidersHistory';
import { IProvidersHistoryItem } from 'api/interfaces/Provider';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { StatusTag } from 'components/StatusTag';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

type Props = {
  clinicId: number;
  providerId: number;
};

export const ProviderHistoryTab = ({ clinicId, providerId }: Props): JSX.Element => {
  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;

  const { providersHistory, isLoading, data, params, updateParams } = useProvidersHistory(
    clinicId,
    providerId,
    defaultParams,
    false
  );

  useEffect(() => updateParams(defaultParams), []);

  const columns = useMemo(
    (): TableColumnProps<IProvidersHistoryItem>[] => [
      {
        key: 'invoice_number',
        title: locale.labels.invoiceNumber,
        render: (e) =>
          e.id
            ? ListFieldWithTooltip(
                <Link
                  className={'custom-links'}
                  to={`/clinic/${clinicId}/inventory/invoices/${e.id}`}
                >
                  {e.invoice_number}
                </Link>
              )
            : '-'
      },
      {
        key: 'purchase_date',
        title: locale.labels.date,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'bill',
        title: locale.labels.bill,
        render: (e) =>
          e.id
            ? ListFieldWithTooltip(
                <Link
                  className={'custom-links'}
                  to={`/clinic/${clinicId}/finances/bills/${e.bill_id}`}
                >
                  {e.bill_id}
                </Link>
              )
            : '-'
      },
      {
        key: 'number_of_items',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.number_of_items
      },
      {
        key: 'total_price',
        sorter: () => null,
        title: locale.labels.totalPrice,
        render: (e) => (e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-')
      },
      {
        key: 'payment_status',
        sorter: () => null,
        title: locale.labels.paymentStatus,
        render: (e) => <StatusTag status={e.payment_status} />
      },
      {
        key: 'remaining_amount',
        sorter: () => null,
        title: locale.labels.remainingAmount,
        render: (e) => (e.remaining_amount ? `${money(e.remaining_amount)} ${currencies.uah}` : '-')
      }
    ],
    [providersHistory]
  );

  return (
    <>
      <Table
        pagination={false}
        className='accent-header stock-list'
        rowKey='id'
        loading={isLoading}
        dataSource={providersHistory}
        columns={columns}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={+params.page}
        total={data.metadata.total || 0}
        showSizeChanger={false}
        pageSize={20}
        onChange={(page) => {
          updateParams({ page });
        }}
      />
    </>
  );
};
