import birdAvatar from 'assets/img/bird_avatar.svg';
import catAvatar from 'assets/img/cat_avatar.svg';
import dogAvatar from 'assets/img/dog_avatar.svg';
import pawAvatar from 'assets/img/dog_paw.svg';
import exoticAvatar from 'assets/img/exotic_avatar.svg';
import rodentAvatar from 'assets/img/rodent_avatar.svg';
import dayjs from 'dayjs';

export function getAnimalAge(locale, birthDate: string, deathDate?: string): string | null {
  if (!birthDate && !deathDate) return null;

  const formatDate = (date: string): string => dayjs(date).format('DD.MM.YYYY');
  const calculateAge = (start: string, end: string): string => {
    const diffYears = dayjs(end).diff(start, 'years');
    if (diffYears > 0) return `${diffYears}${locale.labels.y}`;

    const diffMonths = dayjs(end).diff(start, 'months');
    if (diffMonths > 0) return `${diffMonths}${locale.labels.m}`;

    const diffDays = dayjs(end).diff(start, 'days');
    return `${diffDays}${locale.labels.d}`;
  };

  if (deathDate) {
    const deathDateFormatted = formatDate(deathDate);
    const passedAwayOn = `${locale.labels.passedAwayOn} ${deathDateFormatted}`;

    if (!birthDate) return passedAwayOn;
    return `${passedAwayOn} ${locale.labels.atAge} ${calculateAge(birthDate, deathDate)}`;
  }

  if (birthDate) {
    return calculateAge(birthDate, dayjs().toISOString());
  }

  return null;
}

export const getDefaultAvatar = (animalSpecies: string): string =>
  ({
    cat: catAvatar,
    dog: dogAvatar,
    rodent: rodentAvatar,
    bird: birdAvatar,
    exotic: exoticAvatar
  }[animalSpecies?.toLowerCase()] || pawAvatar);
