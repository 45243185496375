import { Form } from 'antd';
import { useDeleteRoom } from 'api/hooks/rooms/useDeleteRoom';
import { IRoom } from 'api/interfaces/Room';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

import { AddRoomForm } from './AddRoomForm';

type Props = {
  clinicId: number;
  rooms: IRoom[];
  selectedRoom: IRoom | null;
  closeModal: () => void;
};

export const AddRoom = ({ clinicId, selectedRoom, closeModal }: Props): JSX.Element => {
  const [roomForm] = Form.useForm();
  const userAccess = useAccess();
  const locale = useLocale('private.administration.rooms');

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const deleteRoom = useDeleteRoom(clinicId, closeModal);

  useEffect(() => {
    if (!selectedRoom) {
      setEditMode(true);
    }
  }, [selectedRoom]);

  const onConfirmDeleteClick = () => {
    deleteRoom.mutate({ roomId: selectedRoom.id });
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <VLXModal
        title={`${selectedRoom ? locale.labels.editRoom : locale.labels.createRoom}`}
        form={roomForm}
        open
        onModalClose={closeModal}
        width={800}
      >
        <AddRoomForm
          clinicId={clinicId}
          form={roomForm}
          onFieldsChange={onFieldsChange}
          closeModal={closeModal}
          selectedRoom={selectedRoom}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.rooms}
            editMode={editMode}
            hideDeleteBtn={!selectedRoom}
            onCancelClick={() => {
              if (roomForm.isFieldsTouched()) {
                setShowDeleteModal(false);
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onEditClick={() => setEditMode(true)}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </AddRoomForm>
        <ConfirmDeleteModal
          type={'room'}
          open={showDeleteModal}
          onOk={() => onConfirmDeleteClick()}
          onCancel={() => setShowDeleteModal(false)}
          customTitle={locale.messages.roomDeletionConfirmation}
        />
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
