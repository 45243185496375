import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IProvider, IProvidersList } from 'api/interfaces/Provider';

import { useTableData } from '../useTableData';

export const useProvidersList = (
  clinicId: number,
  params: Record<string, number | string> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = API_ENDPOINTS.PROVIDERS(clinicId);

  const result = useTableData<IProvidersList>(
    endpoint,
    params,
    ['providersList', clinicId],
    searchDisabled || undefined
  );

  return {
    ...result,
    providers: (result.data.data as IProvider[]) || []
  };
};
