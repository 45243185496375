import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientsList } from 'api/interfaces/Patient';

const defaultParams = {
  page: 0,
  sort_key: 'name',
  sort_order: 'asc'
};

export const usePatientSearch = (clinicId: number, searchValue: string) => {
  const queryFn = async () => {
    const searchArray = searchValue.trim().split(/\s+/);
    const { data } = await axiosClient.get(API_ENDPOINTS.PATIENTS(clinicId), {
      params: { ...defaultParams, 'q[composite][]': searchArray }
    });
    return data;
  };

  return useQuery<IPatientsList>({
    queryKey: ['patientSearch', clinicId, searchValue],
    queryFn,
    enabled: !!searchValue
  });
};
