import { Form, FormInstance, Select } from 'antd';
import VLXModal from 'components/VLXModal';
import { VLXModalFooter } from 'components/VLXModalFooter';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useCallback, useEffect, useState } from 'react';

import { AddAppointmentForm } from '../addAppointment/AddAppointmentForm';
import { AddEventForm } from './EventForm';

const { Option } = Select;

enum FormType {
  event = 'event',
  appointment = 'appointment'
}

type Props = {
  initialDataForAppointment;
  onClickCallback: () => void;
};

const AddEvent = ({ initialDataForAppointment, onClickCallback }: Props): JSX.Element => {
  const userAccess = useAccess();
  const locale = useLocale('private.appointments.event');
  const [addEventForm] = Form.useForm();
  const [addAppointmentForm] = Form.useForm();

  const [formType, setFormType] = useState<FormType>();

  useEffect(() => {
    if (initialDataForAppointment?.allDay) {
      setFormType(FormType.event);
    } else {
      setFormType(FormType.appointment);
    }

    return () => {
      setFormType(null);
    };
  }, [initialDataForAppointment?.allDay]);

  const selectForm = useCallback((): FormInstance => {
    if (formType === FormType.appointment) return addAppointmentForm;

    return addEventForm;
  }, [formType]);

  const modalTitle = (): string =>
    formType === FormType.appointment
      ? locale.labels.createNewAppointment
      : locale.labels.createNewEvent;

  const eventTypeSelect = 
    <Form.Item label={locale.labels.eventType} className='event-type-select-wrapper'>
      <Select
        onChange={setFormType}
        className='event-type-select'
        defaultValue={formType}
        id='event-type'
      >
        <Option value={FormType.event}>{locale.labels.meeting}</Option>
        <Option value={FormType.appointment}>{locale.labels.appointment}</Option>
      </Select>
    </Form.Item>;

  return (
    <>
      {formType && (
        <VLXModal
          newModalStyle
          title={modalTitle()}
          form={selectForm()}
          open
          centered
          onModalClose={onClickCallback}
          width={800}
        >

          {formType === FormType.appointment ? (
            <AddAppointmentForm
              form={addAppointmentForm}
              initialValues={initialDataForAppointment}
              closeModal={onClickCallback}
              locale={locale}
              eventTypeSelect={eventTypeSelect}
            >
              <VLXModalFooter
                accessGroup={userAccess.accesses.appointments}
                editMode
                form={addAppointmentForm}
                onClickCallback={onClickCallback}
              />
            </AddAppointmentForm>
          ) : (
            <AddEventForm
              form={addEventForm}
              closeModal={onClickCallback}
              initialValues={initialDataForAppointment}
              locale={locale}
              eventTypeSelect={eventTypeSelect}
            >
              <VLXModalFooter
                accessGroup={userAccess.accesses.events}
                editMode
                form={addEventForm}
                onClickCallback={onClickCallback}
              />
            </AddEventForm>
          )}
        </VLXModal>
      )}
    </>
  );
};

export default AddEvent;
