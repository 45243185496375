import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockPayload } from 'api/interfaces/Stock';

import { useCustomMutation } from '../useCustomMutation';

export const useInvoiceStockPurchase = (
  clinicId: number,
  invoice: any, // todo API fix this.
  callbackFn?: (data) => void
) => {
  const mutationFn = ({ payload, id }: { payload: IStockPayload; id: number }) =>
    axiosClient.post(
      `${API_ENDPOINTS.INVOICES(clinicId)}/${invoice?.id}/stock/${id}/items`,
      payload
    );

  return useCustomMutation(
    mutationFn,
    [
      ['billDetails', clinicId, invoice?.bill_id],
      ['invoiceDetails', clinicId, invoice?.id],
      ['invoiceStockPurchases', clinicId, invoice?.id]
    ],
    callbackFn
  );
};
