import 'assets/sass/components/_table.scss';

import { Button, Form, Row, Spin, Table, TableColumnProps } from 'antd';
import { useRoles } from 'api/hooks/roles/useRoles';
import { IRole } from 'api/interfaces/Role';
import { MODAL, useModal } from 'api/store/modalStore';
import StickyButton from 'components/StickyButton';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddRoleModal } from 'layout/modals/Role/AddRoleModal';
import { EditRoleForm } from 'layout/modals/Role/EditRoleForm';
import { RoleDetails } from 'layout/modals/Role/RoleDetails';
import React, { useEffect, useMemo, useState } from 'react';

const RolesList = (): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.roles');
  const { open, isOpened } = useModal();
  const accesses = useAccess().accesses.roles;

  const { data: roles, isPending } = useRoles(clinicId);

  const [expandedId, setExpandedId] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const onClick = () => setEditMode(true);

  const view = useMemo(
    () => (
      <>
        <RoleDetails clinicId={clinicId} selectedRoleId={expandedId}>
          {accesses.edit && (
            <>
              <Button type='primary' onClick={onClick} shape='circle'>
                <span className='icofont icofont-edit-alt' />
              </Button>
              <Button type='primary' onClick={onClick} shape='circle' danger>
                <span className='icofont icofont-ui-delete' />
              </Button>
            </>
          )}
        </RoleDetails>
      </>
    ),
    [expandedId]
  );
  const edit = useMemo(
    () => (
      <EditRoleForm clinicId={clinicId} selectedRoleId={expandedId}>
        <Form.Item>
          <Button type='primary' htmlType='submit' shape='circle'>
            <span className='icofont icofont-save' />
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type='primary' onClick={() => setEditMode(false)} shape='circle' danger>
            <span className='icofont icofont-ban' />
          </Button>
        </Form.Item>
      </EditRoleForm>
    ),
    [expandedId]
  );

  const [content, setContent] = useState(view);

  useEffect(() => {
    editMode ? setContent(edit) : setContent(view);
  }, [roles, editMode]);

  const columns: TableColumnProps<IRole>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: locale.labels.name,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => name
    }
  ];

  return (
    <Spin spinning={isPending}>
      <Table
        pagination={false}
        className='accent-header ant-table-improved-pagination'
        rowKey='id'
        dataSource={roles}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          onExpand: (expanded, record: IRole) => {
            setEditMode(false);
            const current = expanded ? record.id : null;
            setExpandedId(current);
          },
          expandedRowKeys: [expandedId],
          expandedRowRender: () => <Row>{content}</Row>
        }}
      />
      {accesses.create && (
        <Row style={{ justifyContent: 'flex-end', marginTop: 10, alignItems: 'flex-end' }}>
          <StickyButton
            offsetBottom={10}
            onClick={() => open(MODAL.addRoleModal)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addRole}
          />
        </Row>
      )}

      {isOpened(MODAL.addRoleModal) && <AddRoleModal />}
    </Spin>
  );
};

export default RolesList;
