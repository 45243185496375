import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientPayload } from 'api/interfaces/Patient';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreatePatient = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IPatientPayload) => axiosClient.post(`${API_ENDPOINTS.PATIENTS(clinicId)}`, payload),
    [['patientsList', clinicId], ['clientPatients']],
    callbackFn
  );
};
