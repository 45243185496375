import { ISocialLinks } from 'api/interfaces/Clinic';

import { PATTERNS } from './form-rules';

export const trimSocialLinks = (links: ISocialLinks[]): string =>
  links
    ? JSON.stringify(
        links.map((item) => ({
          type: item.type,
          description: PATTERNS.SOCIAL_LINK.exec(item.description)?.[2]
        }))
      )
    : null;
