import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClientDetails } from 'api/interfaces/Client';

export const useClientDetails = (clinicId: number, clientId: number) => {
  return useQuery<IClientDetails>({
    queryKey: ['clientDetails', clinicId, clientId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}`);
      return data;
    },
    enabled: !!clientId
  });
};
