import { Form } from 'antd';
import { useCreateStock } from 'api/hooks/stock/useCreateStock';
import { IStock } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { StockItemForm } from '../forms/StockItemForm';
import { getStockItemPayload } from '../utils/getStockItemPayload';

type Props = {
  callbackFn?: (payload?: IStock) => void;
  createFromInvoice?: boolean;
};

export const CreateStockItemModal = ({ callbackFn, createFromInvoice }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const locale = useLocale('private.inventory.stock');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSuccess = ({ data }: { data: IStock }): void => {
    closeModal();
    callbackFn?.(data);
  };
  const createStock = useCreateStock(clinicId, onSuccess);

  return (
    <VLXModal form={form} title={locale.labels.createNewStock} onModalClose={closeModal} open>
      <StockItemForm
        createFromInvoice={createFromInvoice}
        onSubmit={(data) => createStock.mutate(getStockItemPayload(data))}
        form={form}
      >
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.stock}
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
        />
      </StockItemForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
