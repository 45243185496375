import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { FinancialAccountForm } from './FinancialAccountForm';

export const AddFinancialAccountModal = (): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();

  const userAccess = useAccess();
  const locale = useLocale('private.finances.financial-accounts');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.createAccount}
        form={form}
        centered
        open
        onModalClose={closeModal}
        width={800}
      >
        <FinancialAccountForm locale={locale} form={form}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.services}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </FinancialAccountForm>

        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
