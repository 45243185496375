import './scss/EquipmentList.scss';

import { Form, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useEquipmentList } from 'api/hooks/equipment/useEquipmentList';
import { IEquipment } from 'api/interfaces/Equipment';
import { MODAL, useModal } from 'api/store/modalStore';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import StickyButton from 'components/StickyButton';
import { deviceStatuses } from 'constants/dictionary/default/inventoryOptions';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddEquipmentModal } from './AddEquipmentModal';
import { deviceStatus } from './helpers/TagSelector';

function getSearchItems(
  placeholders: Record<string, string>
): { name: string; placeholder: string }[] {
  return [
    {
      name: 'name',
      placeholder: placeholders.name
    },
    {
      name: 'serial_number',
      placeholder: placeholders.serialNumber
    },
    {
      name: 'location',
      placeholder: placeholders.location
    },
    {
      name: 'first_name',
      placeholder: placeholders.responsibleFirstName
    },
    {
      name: 'last_name',
      placeholder: placeholders.responsibleLastName
    },
    {
      name: 'device_status',
      placeholder: placeholders.deviceStatus
    },
    {
      name: 'manufacturer',
      placeholder: placeholders.manufacturer
    }
  ];
}

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const EquipmentList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const userAccess = useAccess();
  const locale = useLocale('private.inventory.technical-equipment');

  const [form] = Form.useForm();

  const searchItems = useMemo(() => getSearchItems(locale.placeholders), [locale]);

  const { isOpened, open } = useModal();

  const { equipment, data, isLoading, params, updateParams, updateSorting } = useEquipmentList(
    clinicId,
    defaultParams,
    false
  );

  useEffect(() => updateParams(defaultParams), []);

  const onFinish = () => {
    const searchObject = {};
    const object = form.getFieldsValue();
    Object.entries(object).forEach(([key, value]) => {
      if (value && value !== '') {
        searchObject[key] = value;
      }
    });
    updateParams(searchObject);
  };

  const redirectToEquipmentDetails = (record: IEquipment) => {
    if (userAccess.accesses.equipment.showItem) {
      return {
        onClick: () => {
          navigate(`/clinic/${clinicId}/inventory/equipment/${record.id}`);
        }
      };
    }
  };

  const localizedDeviceStatuses = useLocalizedList(deviceStatuses);

  const getDeviceStatus = (e: IEquipment): JSX.Element | string => {
    const res = localizedDeviceStatuses.find((el) => el.value === e);
    return res ? deviceStatus(res) : '';
  };

  const columns = useMemo(
    (): TableColumnProps<IEquipment>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name, record) => name,
        onCell: redirectToEquipmentDetails
      },
      {
        key: 'device_status',
        dataIndex: 'device_status',
        title: locale.labels.deviceStatus,
        sorter: () => null,
        onCell: redirectToEquipmentDetails,
        render: (device_status) => getDeviceStatus(device_status)
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: redirectToEquipmentDetails,
        render: (location) => location || '-'
      }
    ],
    [equipment]
  );

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Table
        pagination={false}
        loading={isLoading}
        className='accent-header equipment-list'
        rowKey='id'
        dataSource={equipment}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onChange={updateSorting}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          className={'equipment-pagination'}
          current={+params.page}
          pageSize={20}
          total={data.metadata.total || 0}
          onChange={(page) => updateParams({ page })}
        />
        {userAccess.accesses.equipment.create && (
          <StickyButton
            offsetBottom={10}
            onClick={() => open(MODAL.addEquipmentModal)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
        )}
        {userAccess.accesses.equipment.create && isOpened(MODAL.addEquipmentModal) && (
          <AddEquipmentModal />
        )}
      </Row>
    </>
  );
};
