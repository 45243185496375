import { Button, Form, Input, Spin } from 'antd';
import { useForgotPassword } from 'api/hooks/user/useForgotPassword';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import MessageModal from '../messageModal/MessageModal';

type Props = {
  closeModal: () => void;
};

const ForgotPassword = ({ closeModal }: Props): JSX.Element => {
  const locale = useLocale('public.modals.forgot-password');
  const forgotPassword = useForgotPassword(() => {
    closeModal();
    MessageModal('success', locale.messages.success);
  });

  const onSubmit = (values: any) => {
    forgotPassword.mutate(values);
  };

  return (
    <Spin spinning={forgotPassword.isPending}>
      <Form onFinish={onSubmit} autoComplete='off' layout='vertical'>
        <Form.Item
          label={locale.labels.email}
          name='email'
          rules={[
            requiredRule(locale.messages.emailEmpty),
            patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {locale.buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ForgotPassword;
