import * as ls from 'local-storage';
import React, { createContext, useContext, useState } from 'react';

export enum LC {
  ua = 'ukUA',
  en = 'enGB'
}

interface LocaleContextValue {
  localeCode: LC.ua | LC.en;
  setLocale: (value: LC) => void;
}

const LocaleContext = createContext<LocaleContextValue | undefined>(undefined);

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [localeCode, setLocaleCode] = useState<LC>(ls.get('locale') || LC.ua);

  const updateLocale = (newLocaleCode: LC) => {
    ls.set('locale', newLocaleCode);
    setLocaleCode(newLocaleCode);
  };

  return (
    <LocaleContext.Provider value={{ localeCode, setLocale: updateLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleCode = (): LocaleContextValue => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within LocaleProvider');
  }
  return context;
};
