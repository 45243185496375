import { Spin } from 'antd';
import { useDeleteClinicDocument } from 'api/hooks/documents/useDeleteDocument';
import { useClinicDocuments } from 'api/hooks/documents/useDocuments';
import { useUploadClinicDocument } from 'api/hooks/documents/useUploadDocument';
import DocumentsTab from 'components/DocumentsTab';
import useClinicId from 'hooks/useClinicId';
import React from 'react';

const ClinicDocuments = (): JSX.Element => {
  const clinicId = useClinicId();
  const deleteDocument = useDeleteClinicDocument(clinicId);
  const uploadDocument = useUploadClinicDocument(clinicId);
  const { data, isLoading } = useClinicDocuments(clinicId);

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: 20 }}>
        <DocumentsTab data={data} uploadDocument={uploadDocument} deleteDocument={deleteDocument} />
      </div>
    </Spin>
  );
};

export default ClinicDocuments;
