import './UserRegistration.scss';

import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import { useUserRegistration } from 'api/hooks/user/useUserRegistration';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import * as ls from 'local-storage';
import React, { useEffect } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import MessageModal from '../messageModal/MessageModal';

const UserRegistration = ({ closeModal, form }): JSX.Element => {
  const locale = useLocale('public.modals.sign-up');
  const { labels, messages, links, buttons, placeholders } = locale;

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSuccess = () => {
    closeModal();
    MessageModal('success', messages.registrationSuccess);
  };

  const userRegistration = useUserRegistration(onSuccess);

  return (
    <Spin spinning={userRegistration.isPending}>
      <Form form={form} onFinish={userRegistration.mutate} autoComplete='off' layout='vertical'>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.firstName}
              name='first_name'
              rules={[
                requiredRule(messages.firstNameEmpty),
                patternRule(PATTERNS.NAME, messages.firstNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.middleName}
              name='middle_name'
              rules={[patternRule(PATTERNS.NAME, messages.firstNameNotValid)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.lastName}
              name='last_name'
              rules={[
                requiredRule(messages.lastNameEmpty),
                patternRule(PATTERNS.NAME, messages.lastNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.enterPassword}
              name='password'
              rules={[
                requiredRule(messages.inputPassword),
                patternRule(PATTERNS.PASSWORD, messages.inputPassword)
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={labels.repeatPassword}
              name='repeatPassword'
              dependencies={['password']}
              hasFeedback
              rules={[
                requiredRule(messages.inputPasswordRetry),
                ({ getFieldValue }) => ({
                  async validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(messages.passwordsDontMatch));
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <PhoneFieldFormItem />
            <Form.Item
              label={labels.email}
              name='email'
              rules={[
                requiredRule(messages.emailNotValid),
                patternRule(PATTERNS.EMAIL, messages.emailNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.dateOfBirth}
              name='birth_date'
              rules={[requiredRule(messages.dateOfBirthNotValid)]}
            >
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
              label={<h6>{labels.homeAddress}</h6>}
              style={{ marginBottom: 0 }}
              required={true}
            >
              <Form.Item
                name='country'
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  marginRight: '16px'
                }}
                rules={[requiredRule(messages.countryEmpty)]}
              >
                <Input placeholder={placeholders.country} />
              </Form.Item>
              <Form.Item
                name='region'
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)'
                }}
                rules={[requiredRule(messages.regionEmpty)]}
              >
                <Input placeholder={placeholders.region} />
              </Form.Item>
            </Form.Item>
            <Form.Item name='city' rules={[requiredRule(messages.cityEmpty)]}>
              <Input placeholder={placeholders.city} />
            </Form.Item>
            <Form.Item name='address' rules={[requiredRule(messages.addressEmpty)]}>
              <Input placeholder={placeholders.address} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='agree'
          valuePropName='checked'
          validateTrigger={['onSubmit', 'onChange']}
          rules={[
            requiredRule(messages.acceptTerms),
            {
              transform: (value) => value || undefined, // Those two lines
              type: 'boolean',
              message: messages.acceptTerms
            }
          ]}
        >
          <Checkbox>
            {labels.acknowledge}
            <Button
              type='link'
              size={'small'}
              onClick={() => window.open(`${window.location.origin}/terms-and-conditions`)}
            >
              {links.termsAndPrivacy}
            </Button>
          </Checkbox>
        </Form.Item>
        <Form.Item
          hidden={true}
          name='locale'
          initialValue={ls.get('locale') ? ls.get('locale') : 'enGB'}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserRegistration;
