import './AppointmentDetails.scss';

import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popover, Row, Tooltip } from 'antd';
import { useChangeAppointmentStatus } from 'api/hooks/appointments/useChangeAppointmentStatus';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IBillDetails } from 'api/interfaces/Bills';
import { IClientDetails } from 'api/interfaces/Client';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import { appointmentStatuses, paymentStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import { ClientItemModal } from 'layout/modals/addClient/ClientItemModal';
import { AppointmentBillModal } from 'layout/modals/appointmentBill/AppointmentBillModal';
import { EditAppointmentModal } from 'layout/modals/editAppointment/EditAppointmentModal';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import { BillPrintButton } from 'pages/finances/bills/print/BillPrintButton';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { money } from '../../helpers/list-helpers';
import { AppointmentCard } from './AppointmentCard';
import { renderAppointmentStatus, renderPaymentStatus } from './AppointmentsBaseHelper';
import { CancellationForm } from './components/CancellationForm';

const Cancellation = ({
  appointment,
  locale
}: {
  appointment: IAppointmentDetails;
  locale: ILocale;
}): JSX.Element => (
  <div>
    <p style={{ marginBottom: '2px' }}>
      {locale.labels.cancelledBy}:{' '}
      {`${appointment?.cancellation?.cancelled_by?.name} (${appointment?.cancellation?.cancelled_by?.title})`}
    </p>
    <p style={{ marginBottom: '2px' }}>
      {locale.labels.reason}: {`${appointment?.cancellation?.reason}`}
    </p>
  </div>
);

type Props = {
  appointment: IAppointmentDetails;
  appointmentBill: IBillDetails;
  appointmentClient: IClientDetails;
  healthRecordsNotFinalized: boolean;
  inventoryItemsNotFinalized: boolean;
};

const AppointmentDetailsTopContainer = ({
  appointment,
  appointmentBill,
  appointmentClient,
  healthRecordsNotFinalized,
  inventoryItemsNotFinalized
}: Props): JSX.Element => {
  const { open, isOpened } = useModal();
  const clinicId = useClinicId();
  const { availableModules } = useAccess();
  const locale = useLocale('private.appointments.event');
  const currencies = useLocale('private.currencies').labels;
  const tooltipLocale = useLocale('private.subscription-tooltip');
  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const changeAppointmentStatus = useChangeAppointmentStatus();

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const disableAppointmentEdit = useMemo((): boolean => {
    if (!appointment.status) return true;
    return ['cancelled', 'finished'].includes(appointment.status);
  }, [appointment.status]);

  const EditButton = ({ onClick }: { onClick: () => void }): JSX.Element =>
    !disableAppointmentEdit && (
      <EditOutlined
        className='appointment-details-top-container__edit-appointmnet-info'
        id='edit-button'
        onClick={onClick}
      />
    );

  const StatusButton = useMemo((): JSX.Element => {
    switch (appointment?.status.toUpperCase()) {
      case 'FUTURE':
        const allowToStartAppointment = (): boolean => {
          const timeNow = dayjs();
          return dayjs(appointment?.start_time).diff(timeNow, 'hours') <= 3;
        };
        const handleStatusChange = (status: string): void => {
          changeAppointmentStatus.mutate({ clinicId, appointmentId: appointment.id, status });
        };

        return (
          <>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!allowToStartAppointment() ? locale.messages.appointmentCanNotBeStarted : null}
            >
              <Button
                id='start-appointment'
                disabled={!allowToStartAppointment()}
                onClick={() => handleStatusChange('in_progress')}
                className='change-status-basic-button'
              >
                <span className='icofont-play-alt-1' />
                <span style={{ verticalAlign: 'middle' }}>{locale.buttons.start}</span>
              </Button>
            </Tooltip>
            <Button
              id='cancel-appointment'
              onClick={() => open(MODAL.cancellationForm)}
              className='cancel-button'
            >
              <span className='icofont-ui-block' />
              <span>{locale.buttons.cancel}</span>
            </Button>
          </>
        );

      case 'IN_PROGRESS':
        const inProgressTooltip = (): string | undefined => {
          // if (!canFinishAppointment()) {
          //   return locale.messages.appointmentCanNotBeFinished;
          // } //clarified at 2 clinics, they can finalize appointment for each other, let's not block for now
          if (healthRecordsNotFinalized) return locale.messages.patientsHealthRecordsNotFinalized;
          if (inventoryItemsNotFinalized) return locale.messages.inventoryItemsNotFinalized;
        };

        return (
          <>
            <Tooltip trigger={'hover'} placement='topLeft' title={inProgressTooltip()}>
              <Button
                id='finish-appointment'
                className='change-status-basic-button'
                disabled={healthRecordsNotFinalized || inventoryItemsNotFinalized}
                onClick={() => open(MODAL.appointmentBillModal)}
              >
                <span className='icofont-stop' />
                <span>{locale.buttons.finish}</span>
              </Button>
            </Tooltip>
            <Button
              id='cancel-appointment'
              onClick={() => open(MODAL.cancellationForm)}
              className='cancel-button'
            >
              <span className='icofont-ui-block' />
              <span>{locale.buttons.cancel}</span>
            </Button>
          </>
        );
      case 'FINISHED':
        const finishedTooltip = (): string => {
          if (!availableModules.finances) return tooltipLocale.messages.availableInPremium;
          if (!shiftOpened) return locale.labels.paymentBlockedClosedShift;
          return null;
        };

        return (
          <Row style={{ gap: 15 }}>
            {appointmentBill?.remaining_amount > 0 && (
              <Tooltip trigger={'hover'} placement='topLeft' title={finishedTooltip()}>
                <Button
                  id='add-payment'
                  disabled={!shiftOpened || !availableModules.finances}
                  onClick={() => open(MODAL.addBillPayment)}
                  className='change-status-basic-button'
                >
                  <span className='icofont-money' />
                  <span>{locale.buttons.addPayment}</span>
                </Button>
              </Tooltip>
            )}
            <BillPrintButton bill={appointmentBill} size={40} iconSize={17} />
            {/* <Button  // next feature
              block
              type='primary'
              shape={'circle'}
              style={{
                width: 40,
                height: 40,
                fontSize: 17
              }}
            >
              <span className='icofont icofont-envelope' />
            </Button> */}
          </Row>
        );
      default:
        return null;
    }
  }, [
    appointment,
    shiftOpened,
    healthRecordsNotFinalized,
    inventoryItemsNotFinalized,
    availableModules.finances,
    appointmentBill
  ]);

  return (
    <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
      <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
        <Row className='cards-container'>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Col span={24} style={{ display: 'contents' }}>
                <Row align='middle'>
                  {!!appointment?.title && (
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginRight: 16 }}>
                      {appointment?.title}
                    </div>
                  )}
                  {renderAppointmentStatus(appointment?.status, localizedStatuses)}
                  {appointment?.status === 'cancelled' && (
                    <Popover
                      placement='topLeft'
                      title={locale.labels.appointmentCancelled}
                      content={<Cancellation appointment={appointment} locale={locale} />}
                    >
                      <span
                        className='icofont-info-circle'
                        style={{ fontSize: '15px', marginLeft: 4 }}
                      />
                    </Popover>
                  )}
                </Row>
              </Col>
              <Col style={{ marginTop: '10px' }}>{StatusButton}</Col>
            </Row>
            {availableModules.finances && (
              <Row style={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {appointment.status === 'finished' && (
                    <>
                      <span style={{ fontSize: '14px', fontWeight: '500', padding: '4px' }}>
                        {locale.labels.price}: {money(appointmentBill?.final_amount)}{' '}
                        {currencies.uah}
                        <Link to={`/clinic/${clinicId}/finances/bills/${appointmentBill?.id}`}>
                          <span className='icofont-ui-file'></span>
                        </Link>
                      </span>
                      {renderPaymentStatus(
                        appointmentBill?.payment_status,
                        localizedPaymentStatuses
                      )}
                    </>
                  )}
                </div>
                {appointmentBill?.payment_status === 'partially_paid' && (
                  <span style={{ fontSize: '14px', fontWeight: '500', padding: '4px' }}>
                    {locale.labels.remainedToPay}: {money(appointmentBill?.remaining_amount)}{' '}
                    {currencies.uah}
                  </span>
                )}
              </Row>
            )}
          </Card>
        </Row>
        <AppointmentCard
          appointment={appointment}
          appointmentClient={appointmentClient}
          editAppointmentBtn={<EditButton onClick={() => open(MODAL.editAppointment)} />}
          editClientBtn={<EditButton onClick={() => open(MODAL.clientItemModal)} />}
        />
      </div>
      {isOpened(MODAL.clientItemModal) && <ClientItemModal initialValues={appointmentClient} />}
      {isOpened(MODAL.editAppointment) && (
        <EditAppointmentModal
          disableTimeEditing={disableAppointmentEdit || appointment.status === 'in_progress'}
          initialValues={appointment}
        />
      )}
      {isOpened(MODAL.appointmentBillModal) && (
        <AppointmentBillModal appointment={appointment} bill={appointmentBill} />
      )}
      {availableModules.finances && <AddBillPayment bill={appointmentBill} hideAddButton />}
      {isOpened(MODAL.cancellationForm) && (
        <CancellationForm clinicId={clinicId} appointmentId={appointment.id} />
      )}
    </div>
  );
};

export default AppointmentDetailsTopContainer;
