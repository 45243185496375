import './Vertical.scss';

import { Menu, Select } from 'antd';
import { useSidebar } from 'api/store/sidebarStore';
import { IUserAccesses } from 'api/store/userAccesses';
import useUserStore from 'api/store/userStore';
import appointmentList from 'assets/img/appointmentList.svg';
import calendar from 'assets/img/calendar.svg';
import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import crossPaw from 'assets/img/crossPaw.svg';
import dashboard from 'assets/img/dashboard.svg';
import dollarCoin from 'assets/img/dollarCoin.svg';
import inventoryStorage from 'assets/img/inventoryStorage.svg';
import manageAdmin from 'assets/img/manageAdmin.svg';
import people from 'assets/img/people.svg';
import pharmacy from 'assets/img/pharmacy.svg';
import schedule from 'assets/img/schedule.svg';
import syringe from 'assets/img/syringe.svg';
import { PremiumSubscriptionTooltip } from 'components/PremiumSubscriptionTooltip';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { HeaderButtons } from '../components/actions/HeaderButtons';
import { Navbar } from '../components/navbar/Navbar';

import type { MenuProps } from 'antd';

const { Option } = Select;

const VerticalLayout = (): JSX.Element => {
  const locale = useLocale('private.side-menu');
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { sidebarToggle } = useSidebar();
  const { selectedClinicId, availableModules } = useAccess();
  const { id: authorized, userAccesses: clinics } = useUserStore(({ user }) => user);

  const [selectedItem, setSelectedItem] = useState([location.pathname]);

  useEffect(() => {
    setSelectedItem([location.pathname.split('/')[3]]);
  }, [location.pathname]);

  const onClinicSelect = (id: number): void => {
    dispatch({ type: 'RESET_STORE' });
    navigate(`/clinic/${id}/administration/info`);
  };

  const clinicsList = (clinics || []).map((item: IUserAccesses) => (
    <Option
      className='clinic-select-option'
      key={`${item.clinicName}${item.clinicId}`}
      title={item.clinicName}
      value={item.clinicId}
    >
      <div className='clinic-select-option__logo-container'>
        <img src={item?.clinicAvatar ? item?.clinicAvatar : clinicDefaultLogo} />
      </div>
      <div className='clinic-select-option__name-container'> {item.clinicName}</div>
    </Option>
  ));

  const clinicItems = useCallback(
    (clinicId: number) => {
      if (!selectedClinicId || !clinics.length) return [];
      return [
        {
          text: locale.labels.administration,
          icon: <img src={manageAdmin} style={{ width: 17, height: 17, marginTop: -1 }} />,
          route: `/clinic/${clinicId}/administration/info`
        },
        {
          text: locale.labels.calendar,
          icon: <img src={calendar} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/events-calendar`
        },
        {
          text: locale.labels.appointments,
          icon: <img src={appointmentList} style={{ width: 18, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/appointments`
        },
        {
          text: locale.labels.clients,
          icon: <img src={people} style={{ width: 18.3, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/clients`
        },
        {
          text: locale.labels.patients,
          icon: <img src={crossPaw} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/patients`,
          disableOnSelect: true
        },
        {
          text: locale.labels.services,
          icon: <img src={syringe} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/services`
        },
        {
          text: locale.labels.inventory,
          icon: <img src={inventoryStorage} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/inventory/info`,
          disabled: !availableModules?.inventory
        },
        {
          text: locale.labels.finances,
          icon: <img src={dollarCoin} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/finances/info`,
          disabled: !availableModules?.finances
        },
        {
          text: locale.labels.shopFarmacy,
          icon: <img src={pharmacy} style={{ width: 18.3, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/shop-farmacy`,
          disabled: !availableModules?.shopPharmacy
        }
      ];
    },
    [selectedClinicId, clinicId, locale, clinics]
  );

  const menuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'personal',
        type: 'group' as 'group',
        label: <span className='vertical-menu__personal-title'>{locale.labels.personal}</span>,
        children: [
          {
            label: (
              <NavLink onClick={sidebarToggle} to='/dashboard'>
                {locale.labels.dashboard}
              </NavLink>
            ),
            icon: (
              <img
                src={dashboard}
                style={{ width: 15, height: 15, marginTop: -1, marginRight: 3 }}
              />
            ),
            key: '/dashboard'
          },
          {
            label: (
              <NavLink onClick={sidebarToggle} to='/my-calendar'>
                {locale.labels.myCalendar}
              </NavLink>
            ),
            key: '/my-calendar',
            icon: <img src={schedule} style={{ width: 18, height: 19, marginTop: -2 }} />
          }
        ]
      },
      {
        key: 'clinics',
        type: 'group' as 'group',
        label: (
          <div className='vertical-menu-clinic-select'>
            <span className='vertical-menu__clinics-title'>{locale.labels.clinics}</span>
            <Select
              className='clinic-select'
              onSelect={onClinicSelect}
              value={selectedClinicId ? Number(selectedClinicId) : null}
              placeholder={locale.placeholders.chooseClinic}
            >
              {clinicsList}
            </Select>
          </div>
        ),
        children: clinicItems(selectedClinicId).map((e) => ({
          disabled: e.disabled,
          key: e.route.split('/')[3],
          label: (
            <PremiumSubscriptionTooltip display={e.disabled}>
              <NavLink
                aria-disabled={true}
                onClick={(event) => {
                  if (e.disabled || e.route === location.pathname) {
                    event.preventDefault();
                    return;
                  }
                  sidebarToggle();
                }}
                to={e.route}
              >
                {e.text}
              </NavLink>
            </PremiumSubscriptionTooltip>
          ),
          icon: <span className={e.disabled && 'locked-icon'}>{e.icon}</span>
        }))
      }
    ],
    [selectedClinicId, locale, clinics, location.pathname]
  );

  return (
    <div className={!authorized ? 'hide-on-hd' : ''}>
      <Navbar>
        {!!authorized && (
          <Menu
            className={'menu-opened vertical-menu'}
            openKeys={[String(selectedClinicId)]}
            selectedKeys={selectedItem}
            mode='inline'
            items={menuItems}
          />
        )}
        {!clinics && !!authorized && (
          <NavLink to='clinic-registration' className='ant-btn ant-btn-primary ant-btn-block'>
            <span>{locale.labels.createClinic}</span>
          </NavLink>
        )}
        {!authorized && <HeaderButtons containerClass='elem-list elem-list-mobile' />}
      </Navbar>
    </div>
  );
};

export default VerticalLayout;
