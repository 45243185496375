import '../Finances.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import { usePaymentsList } from 'api/hooks/payments/usePaymentsList';
import { IPayment } from 'api/interfaces/Payment';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddPaymentModal } from 'layout/modals/finances/addPayment/AddPaymentModal';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import FinanceIcon from '../helpers/FinanceIcon';
import { userData } from '../shifts/ShiftsBaseHelper';
import { getPaymentName } from './utils/utils';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

const PaymentsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { open, isOpened } = useModal();

  const locale = useLocale('private.finances.payments');
  const currencies = useLocale('private.currencies').labels;

  const shiftOpened = useShiftStore(({ shift }) => shift.open);
  const { payments, data, isPending, params, updateParams, updateSorting } = usePaymentsList(
    clinicId,
    defaultParams
  );

  const redirectToPaymentDetails = (record: IPayment): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IPayment>[] => [
      {
        key: 'payment_type',
        dataIndex: 'payment_type',
        title: '',
        sorter: () => null,
        render: (payment_type) => <FinanceIcon type={payment_type} />,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (e) => getPaymentName(e?.name, locale),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'id',
        title: locale.labels.id,
        sorter: () => null,
        render: (e) => e.id,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'date',
        dataIndex: 'date',
        title: locale.labels.date,
        sorter: () => null,
        render: (date) => `${date ? moment(date).format('DD.MM.YYYY HH:mm') : '-'}`,
        onCell: redirectToPaymentDetails
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: locale.labels.amount,
        sorter: () => null,
        render: (amount) => (amount ? `${money(amount)} ${currencies.uah}` : '-'),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'category',
        dataIndex: 'category',
        title: locale.labels.category,
        sorter: () => null,
        render: (category) => category ?? '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'financial_account',
        dataIndex: 'financial_account',
        title: locale.labels.account,
        sorter: () => null,
        render: (financial_account) => financial_account?.name ?? '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'created_by',
        dataIndex: 'created_by',
        title: locale.labels.createdBy,
        sorter: () => null,
        render: userData
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Spin spinning={isPending} className='payments-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header payments-list-table'
          rowKey='id'
          loading={isPending}
          dataSource={payments}
          onChange={updateSorting}
          columns={columns}
          rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        />
      </Spin>
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addPaymentModal)}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPayment}
          disabled={!shiftOpened}
          tooltip={!shiftOpened ? locale.labels.paymentBlockedClosedShift : null}
        />
      </Row>
      {isOpened(MODAL.addPaymentModal) && <AddPaymentModal />}
    </>
  );
};

export default PaymentsList;
