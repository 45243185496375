import { Form } from 'antd';
import { useDeleteProvider } from 'api/hooks/providers/useDeleteProvider';
import { IProvider } from 'api/interfaces/Provider';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddProviderForm } from './AddProviderForm';

type Props = {
  initialValues: IProvider;
};

export const EditProviderModal = ({ initialValues }: Props): JSX.Element => {
  const navigate = useNavigate();
  const userAccess = useAccess();
  const clinicId = useClinicId();
  const { closeModal } = useModal();
  const locale = useLocale('private.inventory.providers');
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onDelete = (): void => {
    navigate(`/clinic/${clinicId}/inventory/providers`);
    closeModal();
  };
  const deleteProvider = useDeleteProvider(clinicId, initialValues?.id, onDelete);

  const onFieldsChange = (e) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editProvider}
        centered
        form={form}
        open
        onModalClose={closeModal}
        width={800}
      >
        <AddProviderForm
          form={form}
          onFieldsChange={onFieldsChange}
          initialValues={initialValues}
          formEditMode
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.services}
            editMode={editMode}
            onEditClick={() => setEditMode(true)}
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </AddProviderForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={deleteProvider.mutate}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};
