import { Tag } from 'antd';
import { useLocaleCode } from 'api/store/localeContext';
import { allStatuses } from 'constants/dictionary/default/localizedStatuses';
import React from 'react';

type Props = {
  status: string;
};

export const StatusTag = ({ status }: Props): JSX.Element => {
  const { localeCode } = useLocaleCode();
  const localizedStatus = allStatuses[status];
  return (
    <Tag id='payment-status-tag' color={localizedStatus.color}>
      {localizedStatus[localeCode]}
    </Tag>
  );
};
