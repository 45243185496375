import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IToolPayload } from 'api/interfaces/Tool';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditTool = (clinicId: number, toolId: number, callbackFn?: (data) => void) => {
  const mutationFn = (payload: IToolPayload) =>
    axiosClient.put(`${API_ENDPOINTS.TOOLS(clinicId)}/${toolId}`, payload);

  const queryKeys = ['toolsList', ['toolDetails', clinicId, toolId]];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
