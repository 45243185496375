import {
  IAllShiftsProfit,
  IBilledOverPaid,
  IDebt,
  IEmployeesProfit,
  IFinancialStats,
  ILabeledData,
  IShiftProfitByAccounts,
  IShiftProfitLossByCategory
} from 'api/interfaces/Stats';
import dayjs from 'dayjs';
import { localDateTime } from 'helpers/DateTimeUtils';

const addAmountLabel = <T extends ILabeledData>(array: T[], currency): T[] =>
  array.map((item) => ({
    ...item,
    label: `${item.amount} ${currency}`
  }));

const remapAllShiftsProfit = (
  all_shifts_profit: IAllShiftsProfit[],
  currency: string,
  lineDatePeriod
): IAllShiftsProfit[] =>
  all_shifts_profit.map((item) => ({
    ...item,
    shift_opened_at: selectDateFormat(item.shift_opened_at, lineDatePeriod),
    label: `${item.amount} ${currency}`
  }));

const selectDateFormat = (date: string, lineDatePeriod: string): string => {
  switch (lineDatePeriod) {
    case 'week':
      return localDateTime(date);
    case 'month':
      return dayjs(date).week().toString();
    case 'year':
      return localDateTime(date, 'MMMM');
    default:
      return 'YYYY';
  }
};

export const prepareStats = (data: IFinancialStats, currency: string, lineDatePeriod: string) => {
  return !data.all_shifts_profit
    ? {}
    : {
        ...data,
        all_shifts_profit: remapAllShiftsProfit(data.all_shifts_profit, currency, lineDatePeriod),
        employees_profit: addAmountLabel<IEmployeesProfit>(data.employees_profit, currency),
        total_debts: addAmountLabel<IDebt>(data.total_debts, currency),
        billed_over_paid: addAmountLabel<IBilledOverPaid>(data.billed_over_paid, currency),
        shift_profit_by_accounts: addAmountLabel<IShiftProfitByAccounts>(
          data.shift_profit_by_accounts,
          currency
        ),
        shift_profit_by_category: addAmountLabel<IShiftProfitLossByCategory>(
          data.shift_profit_by_category,
          currency
        ),
        shift_loss_by_category: addAmountLabel<IShiftProfitLossByCategory>(
          data.shift_loss_by_category,
          currency
        )
      };
};
