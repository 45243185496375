import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IShiftDetails } from 'api/interfaces/Shift';

export const useShiftDetails = (clinicId: number, shiftId: string) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(`${API_ENDPOINTS.SHIFTS(clinicId)}/${shiftId}`);
    return data;
  };

  return useQuery<IShiftDetails>({ queryFn, queryKey: ['shiftDetails', clinicId, shiftId] });
};
