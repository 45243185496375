import './scss/StickyButton.scss';

import { Affix, Button, Row, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const StickyButton = ({
  onClick,
  offsetBottom,
  iconElement,
  textElement,
  customZIndex,
  disabled,
  tooltip
}: any): JSX.Element => {
  const [btnTxtVisible, setBtnTxtVisible] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0
  });

  const affixRef = useRef<any>();

  useEffect(() => {
    if (inView && !btnTxtVisible) {
      setBtnTxtVisible(true);
    } else if (!inView && btnTxtVisible) {
      setBtnTxtVisible(false);
    }
  }, [inView, btnTxtVisible]);

  useEffect(() => {
    const handler = () => {
      affixRef?.current.updatePosition();
    };
    window.addEventListener('scroll', handler, true);
    return () => window.removeEventListener('scroll', handler, true);
  }, []);

  return (
    <div ref={ref}>
      <Affix
        offsetBottom={offsetBottom}
        style={{ zIndex: customZIndex && customZIndex }}
        ref={affixRef}
      >
        <div className='animated-btn-container'>
          {!tooltip ? (
            <Button type='primary' onClick={onClick}>
              {iconElement}
              <span
                style={{ display: btnTxtVisible ? 'block' : 'none' }}
                className='sticky-btn-text'
              >
                {textElement}
              </span>
            </Button>
          ) : (
            <Tooltip trigger={'hover'} placement='topLeft' title={tooltip}>
              <Row>
                <Button type='primary' onClick={onClick} disabled={disabled}>
                  {iconElement}
                  <span
                    style={{ display: btnTxtVisible ? 'block' : 'none' }}
                    className='sticky-btn-text'
                  >
                    {textElement}
                  </span>
                </Button>
              </Row>
            </Tooltip>
          )}
        </div>
      </Affix>
    </div>
  );
};

export default StickyButton;
