import { Modal } from 'antd';
import React, { useState } from 'react';

import ConfirmCancelModal from './ConfirmCancelModal';

const DEFAULT_WIDTH = 800;
const DEFAULT_Z_INDEX = 1000;

const VLXModal = ({
  centered = false,
  title,
  open,
  form,
  onModalClose,
  width = DEFAULT_WIDTH,
  children,
  defZindex,
  formIsTouched,
  newModalStyle
}: any): JSX.Element => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  function onAbort() {
    if (form?.isFieldsTouched() || formIsTouched) {
      setShowCancelModal(true);
    } else {
      onModalClose();
    }
  }

  function onCancelOk() {
    if (form) {
      setShowCancelModal(false);
      form.resetFields();
    }
    onModalClose();
  }

  return (
    <>
      <Modal
        forceRender
        zIndex={defZindex || DEFAULT_Z_INDEX}
        title={title}
        centered={centered}
        open={open}
        onCancel={onAbort}
        footer={null}
        width={width}
        destroyOnClose
        className={newModalStyle && 'vlx-modal'}
      >
        {children}
      </Modal>
      <ConfirmCancelModal
        defZindex={defZindex}
        open={showCancelModal}
        centered={false}
        onCancel={() => setShowCancelModal(false)}
        onOk={onCancelOk}
      />
    </>
  );
};

export default VLXModal;
