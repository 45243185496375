import { notification } from 'antd';
import axios, { AxiosError } from 'axios';
import * as config from 'config/config-manager';
import * as ls from 'local-storage';

import { API_ENDPOINTS } from './constants/api_endpoints';
import userStore from './store/userStore';

const locale = () => {
  return (ls.get('locale') as string) || 'ukUA';
};

const authHeader = (token: string): string => `Bearer ${token}`;

const axiosClient = axios.create({
  baseURL: config.baseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    APIKEY: config.apiKey
  }
});

const timeoutErrorMessage: object = {
  enGB: 'Timeout error',
  ukUA: 'Помилка часу очікування'
};
const loginFailedMessage: object = {
  enGB: 'Invalid Credentials',
  ukUA: 'Неправильний логін чи пароль'
};
const invalidPasswordMessage: object = {
  enGB: 'Invalid password',
  ukUA: 'Неправильний пароль'
};
const accountLockedMessage: object = {
  enGB: 'Account is locked',
  ukUA: 'Користувача заблоковано'
};

async function refreshToken() {
  const basicToken = ls.get('token') as string;

  if (!basicToken) {
    userStore.getState().clearUser();
    ls.clear();
    return Promise.reject();
  }
  try {
    const { data } = await axiosClient.post(`${API_ENDPOINTS.AUTH}/login`, null, {
      headers: { Authorization: basicToken }
    });

    ls.set('auth_token', data.auth_token);
    return Promise.resolve();
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      ls.clear();
    }
    return Promise.reject(error);
  }
}

axiosClient.interceptors.request.use((config) => {
  let token = ls.get('auth_token') as string;

  if (token && !config.headers?.Authorization) {
    config.headers.set('Authorization', authHeader(token));
  }

  config.headers.set('Accept-Language', locale());

  return config;
}, undefined);

axiosClient.interceptors.response.use(undefined, async (error) => {
  // TODO: read expected login failed message from the localization file, so it will be localized

  // Timeout Error
  if (error.code === 'ECONNABORTED') {
    notification.error({ message: timeoutErrorMessage[locale()] });
    return Promise.reject(error);
  }

  // If we have network error there will be no error response. This condition prevents user from seeing app crashes.
  if (!error?.response) {
    return Promise.resolve({});
  }
  const loginFailed: boolean = error.response.data.message.includes(loginFailedMessage[locale()]);
  const loginLocked: boolean = error.response.data.message.includes(accountLockedMessage[locale()]);
  const invalidPassword: boolean = error.response.data.message.includes(
    invalidPasswordMessage[locale()]
  );

  if (error.response.status === 401 && !loginFailed && !loginLocked && !invalidPassword) {
    return refreshToken().then(() => {
      const newToken = ls.get('auth_token');
      error.config.headers.Authorization = `Bearer ${newToken}`;
      return axiosClient.request(error.config);
    });
  }

  return Promise.reject(error);
});

export default axiosClient;
