import './Actions.scss';

import useUserStore from 'api/store/userStore';

import { HeaderButtons } from './HeaderButtons';
import SettingsDropdown from './SettingsDropdown';

export const Actions = (): JSX.Element => {
  const loggedIn = useUserStore(({ user }) => user.id);

  return loggedIn ? (
    <SettingsDropdown />
  ) : (
    <HeaderButtons containerClass='elem-list hide-on-mobile' />
  );
};
