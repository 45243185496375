import { Button, Form, Modal, Row, Table, TableColumnProps } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocale } from 'hooks/useLocale';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { createAuditReport } from 'redux/inventory/audit/actions';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { itemColumnTitle } from '../AuditDetails/utils/itemColumnTitle';
import { Stat } from '../components/Stat';

dayjs.extend(customParseFormat);

const CloseAuditModal = ({
  selectedAuditItems,
  auditFinished,
  setShowCloseModal,
  locale
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const currencies = useLocale('private.currencies').labels;
  const { clinicId, auditId } = useParams();
  const { packageFormat } = usePlural();

  const onConfirmCancelClick = (): void => {
    setShowCancelModal(false);
    setShowCloseModal(false);
  };

  const onSaveClick = (): void => {
    dispatch(createAuditReport(+clinicId, +auditId));
    setShowCloseModal(false);
  };

  const onSaveModalClick = (): void => {
    Modal.confirm(modalParams);
  };

  const modalParams = {
    title: locale.labels.confirmModalTitle,
    content: locale.messages.confirmModalMessage,
    okText: locale.buttons.confirm,
    cancelText: locale.buttons.cancel,
    centered: true,
    onOk: onSaveClick
  };

  const toIncrease = (stock: ISelectedAuditItem): JSX.Element =>
    Number(stock.global_stats.items_surplus_packages) > 0 && (
      <Stat color='green'>
        {`+${stock.global_stats.items_surplus_packages} ${packageFormat(
          stock.global_stats.items_surplus_packages,
          stock.packaging_format
        )} (~${stock.global_stats.money_surplus_packages.toFixed(2)} ${currencies.uah})`}
      </Stat>
    );

  const toDecrease = (stock: ISelectedAuditItem): JSX.Element =>
    Number(stock.global_stats.items_shortage_packages) < 0 && (
      <Stat color='red'>
        {`-${Math.abs(stock.global_stats.items_shortage_packages)} ${packageFormat(
          stock.global_stats.items_shortage_packages,
          stock.packaging_format
        )} ~${Math.abs(stock.global_stats.money_shortage_packages).toFixed(2)} ${currencies.uah}`}
      </Stat>
    );

  const columns = useMemo(
    (): TableColumnProps<ISelectedAuditItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (name, e) => (
          <Link className={'custom-links'} to={`/clinic/${clinicId}/inventory/stock/${e?.id}`}>
            {e?.name}
          </Link>
        )
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        render: (e) => e.manufacturer || '-'
      },
      {
        key: 'totalWriteOff',
        title: auditFinished ? locale.labels.writtenOff : locale.labels.writeOff,
        render: (e) => toDecrease(e) || '-'
      },
      {
        key: 'totalSurplus',
        title: auditFinished ? locale.labels.added : locale.labels.toBeAdded,
        render: (e) => toIncrease(e) || '-'
      },
      {
        key: 'to_archive',
        title: auditFinished
          ? itemColumnTitle(locale.labels.removed)
          : itemColumnTitle(locale.labels.toBeRemoved),
        render: (e) => e.totalRemove || '-'
      }
    ],
    [selectedAuditItems]
  );

  return (
    <>
      <VLXModal
        form={form}
        centered
        title={auditFinished ? locale.labels.auditReport : locale.labels.finalizationTitle}
        open
        onModalClose={() => setShowCloseModal(false)}
        width={1000}
      >
        <Table
          dataSource={selectedAuditItems}
          columns={columns}
          rowKey='id'
          pagination={false}
          className='accent-header audits-list-table'
        />
        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {!auditFinished && (
            <>
              <Button
                onClick={onSaveModalClick}
                block
                type='primary'
                shape={'circle'}
                style={{ width: '60px', height: '60px', fontSize: '20px' }}
              >
                <span className='icofont icofont-save' />
              </Button>
              <Button
                onClick={() => setShowCloseModal(false)}
                danger
                type='primary'
                shape={'circle'}
                style={{ width: '60px', height: '60px', fontSize: '20px', marginLeft: '5px' }}
              >
                <span className='icofont icofont-ban' />
              </Button>
            </>
          )}
        </Row>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default CloseAuditModal;
