import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEquipment, IEquipmentPayload } from 'api/interfaces/Equipment';
import { useModal } from 'api/store/modalStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateEquipment = (clinicId: number, callbackFn?: (data: IEquipment) => void) => {
  const { closeModal } = useModal();
  return useCustomMutation(
    (payload: IEquipmentPayload) => axiosClient.post(`${API_ENDPOINTS.EQUIPMENT(clinicId)}`, payload),
    ['equipmentList', clinicId],
    closeModal
  );
};