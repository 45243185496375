import './Dictionary.scss';

import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  List,
  Pagination,
  Popconfirm,
  Row,
  Skeleton,
  Tooltip
} from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useCreateDictionary } from 'api/hooks/dictionaries/useCreateDictionary';
import { useDeleteDictionary } from 'api/hooks/dictionaries/useDeleteDictionary';
import { useDictionary } from 'api/hooks/dictionaries/useDictionary';
import { useEditDictionary } from 'api/hooks/dictionaries/useEditDictionary';
import { IDictionary } from 'api/interfaces/Dictionary';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

const { TextArea, Search } = Input;

type Props = {
  id: string;
  dictionaryName: string;
  dictionaryKey: DICTIONARY;
};

const Dictionary = ({ dictionaryName, dictionaryKey, id }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [editModeEntityId, setEditModeEntityId] = useState(null);
  const [searchArray, setSearchArray] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState(null);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const locale = useLocale('private.administration.dictionaries');

  const { dictionary, isLoading, data, params } = useDictionary(clinicId, dictionaryKey, {
    page,
    composite: searchArray
  });
  const destroy = useDeleteDictionary(clinicId, dictionaryKey);
  const edit = useEditDictionary(clinicId, dictionaryKey, () => setEditModeEntityId(null));
  const create = useCreateDictionary(clinicId, dictionaryKey, () => setCreateMode(false));

  useEffect(() => {
    form.resetFields();
  }, [dictionary]);

  const onEditClick = (item: IDictionary): void => {
    setEditModeEntityId(item.id);
  };

  const onCancelClick = (): void => {
    setEditModeEntityId(null);
  };

  const onCreateClick = (): void => {
    setCreateMode(true);
  };

  const onCreateCancelClick = (): void => {
    form.resetFields();
    setCreateMode(false);
  };

  const onCreateSaveClick = (): void => {
    form.validateFields().then((values) => {
      create.mutate(values);
    });
  };

  const onSaveClick = (index: number, item: IDictionary): void => {
    form.validateFields().then(() => {
      const payload = form.getFieldsValue()[index];
      edit.mutate({ payload, id: item.id });
    });
  };

  const onSearch = (value: string): void => {
    setSearchArray(value.split(' '));
    setPage(1);
  };

  const getActions = (item: IDictionary, index: number): JSX.Element => {
    if (editModeEntityId !== item?.id) {
      return (
        <>
          {!item.default && (
            <div>
              <a href='#' key='dictionary-item-edit' onClick={() => onEditClick(item)}>
                {locale.labels.edit}
              </a>
              <Divider type='vertical' />
              {item.can_delete ? (
                <Popconfirm
                  style={{ maxWidth: '150px' }}
                  title={locale.messages.deleteConfirmation}
                  onConfirm={() => destroy.mutate(item.id)}
                  cancelText={locale.labels.no}
                  okText={locale.labels.yes}
                >
                  <a href='#' key='dictionary-item-delete'>
                    {locale.labels.delete}
                  </a>
                </Popconfirm>
              ) : (
                <Tooltip title={locale.messages.cannotDelete}>
                  <a href='#' key='dictionary-item-delete' style={{ color: 'gray' }}>
                    {locale.labels.delete}
                  </a>
                </Tooltip>
              )}
            </div>
          )}
        </>
      );
    }

    return (
      <div>
        <a key='dictionary-item-save' onClick={() => onSaveClick(index, item)}>
          {locale.labels.save}
        </a>
        <Divider type='vertical' />
        <a key='dictionary-item-cancel' onClick={() => onCancelClick()}>
          {locale.labels.cancel}
        </a>
      </div>
    );
  };

  return (
    <Collapse
      items={[
        {
          key: 1,
          id,
          label: dictionaryName,
          children: (
            <Form layout='vertical' form={form}>
              <Search
                placeholder={locale.placeholders.specifySearchCriteria}
                className='dictionary-search-field'
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                enterButton
                allowClear
              />
              <List
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  border: '1px solid #f0f0f0'
                }}
                size='small'
                loading={isLoading}
                itemLayout='horizontal'
                dataSource={dictionary}
                renderItem={(item: IDictionary, index) => (
                  <List.Item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Skeleton avatar title={false} loading={isLoading} active>
                      <Row style={{ width: '100%' }}>
                        {editModeEntityId !== item?.id ? (
                          <List.Item.Meta
                            title={<span>{item?.name}</span>}
                            description={item?.description}
                          />
                        ) : (
                          <List.Item.Meta
                            title={
                              <>
                                <Form.Item
                                  name={[index, 'name']}
                                  initialValue={item?.name}
                                  rules={[requiredRule(locale.messages.specifyName)]}
                                >
                                  <Input placeholder={locale.labels.name} />
                                </Form.Item>
                                <Form.Item
                                  name={[index, 'description']}
                                  initialValue={item?.description}
                                >
                                  <TextArea placeholder={locale.labels.description} rows={3} />
                                </Form.Item>
                              </>
                            }
                          />
                        )}
                      </Row>
                      <Row style={{ width: '100%' }} justify={'end'}>
                        {getActions(item, index)}
                      </Row>
                    </Skeleton>
                  </List.Item>
                )}
              />
              <Row
                style={{
                  display: 'flex'
                }}
              >
                <Col span={24}>
                  {createMode && (
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Form.Item
                        label={locale.labels.name}
                        name='name'
                        rules={[requiredRule(locale.messages.specifyName)]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label={locale.labels.description} name='description'>
                        <TextArea rows={3} />
                      </Form.Item>
                    </Row>
                  )}
                </Col>
                <Pagination
                  current={+params.page}
                  total={data.metadata.total}
                  pageSize={10}
                  onChange={(page) => setPage(page)}
                />
                <Row justify={'end'} style={{ width: '100%' }}>
                  {!createMode ? (
                    <Button
                      id='add'
                      onClick={onCreateClick}
                      shape={'circle'}
                      className='icofont icofont-plus'
                      type={'primary'}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                      }}
                    >
                      <Button
                        id='cancel-create-dictionary-item'
                        onClick={onCreateCancelClick}
                        shape={'circle'}
                        danger
                        className='icofont icofont-ban'
                        style={{ marginLeft: '5px' }}
                        type={'primary'}
                      />
                      <Button
                        id='save-new-dictionary-item'
                        onClick={onCreateSaveClick}
                        shape={'circle'}
                        className='icofont icofont-save'
                        type={'primary'}
                      />
                    </div>
                  )}
                </Row>
              </Row>
            </Form>
          )
        }
      ]}
    ></Collapse>
  );
};

export default Dictionary;
