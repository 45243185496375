import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import { useCustomMutation } from '../useCustomMutation';

export const useDeleteProvider = (
  clinicId: number,
  providerId: number,
  callbackFn?: () => void
) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.PROVIDERS(clinicId)}/${providerId}`);

  return useCustomMutation(mutationFn, ['ProviderDetails', clinicId, providerId], callbackFn);
};
