import { Menu, MenuProps } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PatientsTab } from './PatientsTab';
import { VaccinationsTab } from './VaccinationsTab';

enum TABS {
  vaccinations = 'vaccinations',
  patients = 'patients'
}

export const PatientsList = (): JSX.Element => {
  const locale = useLocale('private.patients.patients-list');
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState(activeTab || TABS.patients);

  useEffect(() => {
    if (activeTab && activeTab !== currentTab) {
      setCurrentTab(activeTab);
    }
  }, [activeTab]);

  const items: MenuProps['items'] = useMemo(
    () => [
      { label: locale.tabs.patients, key: TABS.patients },
      { label: locale.tabs.vaccinations, key: TABS.vaccinations }
    ],
    [currentTab, activeTab]
  );

  const changeTab: MenuProps['onClick'] = (e): void => {
    if (e.key !== currentTab) {
      setSearchParams({ tab: e.key });
      setCurrentTab(e.key);
    }
  };

  return (
    <>
      <Menu
        onClick={changeTab}
        selectedKeys={[currentTab]}
        mode='horizontal'
        items={items}
        style={{ marginBottom: 30, marginTop: 10 }}
      />
      <div key='patients-list-patient-tab'>{currentTab === TABS.patients && <PatientsTab />}</div>
      <div key='patients-list-vaccination-tab'>
        {currentTab === TABS.vaccinations && <VaccinationsTab />}
      </div>
    </>
  );
};
