import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPrintConfiguration } from 'api/interfaces/Print';

export const usePrintConfiguration = (clinicId: number) => {
  return useQuery<IPrintConfiguration>({
    queryKey: ['printConfiguration', clinicId],
    queryFn: async () => {
      const { data } = await axiosClient.get(API_ENDPOINTS.PRINT(clinicId));
      let res = {};
      if (data && !Array.isArray(data)) {
        const params = JSON.parse(data.additional_parameters);
        res = {logo: data.logo, ...params, id: data.id};
      }
      return res;
    }
  });
};
