import './scss/PrintPricesForm.scss';

import { Row } from 'antd';
import { IPrice, IStock } from 'api/interfaces/Stock';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import React from 'react';
import Barcode from 'react-barcode';
import { IStockPricing } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { generateUniqueBarcode } from 'utils/generate-unique-barcode';

import { IPriceComponent } from './types';

type Props = React.PropsWithChildren<{
  item: IPriceComponent;
  printConfig: Array<string>;
  locale: ILocale;
  price: IPrice | IStockPricing;
  stock: IStock;
}>;

export const PricesComponent = ({
  item,
  printConfig,
  locale,
  price,
  stock
}: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;

  const { unitFormat, packageFormat } = usePlural(stock);

  const printName = printConfig.some((el) => el === 'name');
  const printBarcode = printConfig.some((el) => el === 'barcode');
  const printPrice = printConfig.some((el) => el === 'price');
  const printArticle = printConfig.some((el) => el === 'sku');

  return (
    <div className='price-block' key={generateUniqueBarcode()}>
      {printName && (
        <div className='price-block__top-cont'>
          <div className='price-block__title'>{stock?.name}</div>
          <div className='price-block__manufacturer'>{stock?.manufacturer}</div>
        </div>
      )}
      <Row className='price-block__row'>
        <div className='price-block__barcode'>
          {printBarcode && (stock?.barcode || stock?.sku) && (
            <Barcode value={stock?.barcode ?? stock?.sku} />
          )}
        </div>
        <div className='price-block__price-column'>
          {printPrice && (
            <>
              {item.print !== 'piece' && stock?.packaging_format !== 'none' && (
                <div className='price-block__price-row'>
                  <span className='price-block__price-label'>{packageFormat()}:</span>
                  <div className='price-block__price-row__price'>
                    <span className='price-block__price-value'>
                      {money(price?.price_per_package)}
                    </span>
                    <span className='price-block__price-currencies'>{currencies.uah}</span>
                  </div>
                </div>
              )}
              {printPrice && item.print !== 'package' && (
                <div className='price-block__price-row'>
                  <span className='price-block__price-label'>{unitFormat()}:</span>
                  <div className='price-block__price-row__price'>
                    <span className='price-block__price-value'>{money(price?.price_per_unit)}</span>
                    <span className='price-block__price-currencies'>{currencies.uah}</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Row>
      <div className='price-block__article'>
        {printArticle && stock?.sku && (
          <>
            {locale.labels.article}: {stock?.sku}
          </>
        )}
      </div>
    </div>
  );
};
