import './EditAppointmentForm.scss';

import { Col, Form, FormInstance, Input, Row, Select, Spin } from 'antd';
import { useEditAppointment } from 'api/hooks/appointments/useEditAppointment';
import { useEmployeesList } from 'api/hooks/employees/useEmployeesList';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { useServicesList } from 'api/hooks/services/useServicesList';
import { IAppointmentDetails, IEditAppointmentPayload } from 'api/interfaces/Appointment';
import DateRangePicker from 'components/DateRangePicker';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IAppointmentDetails;
  locale: ILocale;
  onFieldsChange: (changed, data) => void;
  disableTimeEditing: boolean;
  closeModal: () => void;
}>;

export const EditAppointmentForm = ({
  form,
  initialValues,
  children,
  locale,
  onFieldsChange,
  disableTimeEditing,
  closeModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locations = useLocalizedList(locationTypes);

  const { services } = useServicesList(clinicId, { page: 0 });
  const { employees } = useEmployeesList(clinicId, { page: 0 });
  const { rooms } = useRoomsList(clinicId, { page: 0 });
  const editAppointment = useEditAppointment(clinicId, initialValues.id, closeModal);

  const [selectedDoctorId, setSelectedDoctorId] = useState(initialValues.doctor?.id);
  const [selectedAttendees, setSelectedAttendees] = useState(
    (initialValues.participants || []).map((el) => el.id)
  );
  const [selectedRoomId, setSelectedRoomId] = useState(initialValues.location?.id);
  const [locationType, setLocationType] = useState<string>(initialValues.location?.type || 'other');

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);

  const onSubmit = (payload: IEditAppointmentPayload): void => {
    payload.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
    payload.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);
    payload.status = initialValues.status;

    editAppointment.mutate(payload);
  };

  useEffect(() => {
    const { start_time, end_time } = initialValues;

    if (start_time) {
      setStartDate(dayjs(start_time));
      setEndDate(dayjs(end_time));
      form.setFieldValue('date_from', dayjs(start_time));
      form.setFieldValue('time_from', dayjs(start_time));
      form.setFieldValue('end_time', dayjs(end_time));

      const duration = dayjs(dayjs(end_time).diff(dayjs(start_time)) - 10800000);
      setDuration(duration);
      form.setFieldValue('duration', duration);
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
  }, []);

  const employeesList = employees.map((item) => (
    <Option key={item.full_name} title={item.full_name} value={item.id}>
      {item.full_name}
    </Option>
  ));

  const attendeesList = employees
    .filter((el) => el.id !== selectedDoctorId)
    .map(
      (item) =>
        item.id !== selectedDoctorId && (
          <Option key={item.full_name} title={item.full_name} value={item.id}>
            {item.full_name}
          </Option>
        )
    );

  const roomsList = useMemo(
    (): JSX.Element[] =>
      rooms
        .filter(
          (room) =>
            (!room.not_for_appointment && !room.deleted) ||
            (room.id === initialValues.location?.id &&
              selectedRoomId === initialValues.location?.id)
        )
        .map((item) => (
          <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
            {item.name}
          </Option>
        )),
    [initialValues.location?.id, selectedRoomId, rooms]
  );

  const servicesList = useMemo(
    () =>
      getOptions(
        services.filter((s) => !s.deleted || initialValues.services.some(({ id }) => id === s.id))
      ),
    [services]
  );

  return (
    <Spin spinning={editAppointment.isPending}>
      <Form
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={{
          ...initialValues,
          services: (initialValues.services || []).map(({ id }) => id),
          attendees: (initialValues.participants || []).map(({ id }) => id),
          doctor_id: initialValues.doctor?.id
        }}
      >
        <Form.Item name='clinic_id' initialValue={clinicId} hidden>
          <Input />
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.visitReason}
              name='title'
              rules={[
                requiredRule(locale.placeholders.mandatoryField),
                patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.service}
              name='services'
            >
              <Select
                mode='multiple'
                filterOption={(input, option) =>
                  option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                }
                showSearch
                allowClear
              >
                {servicesList}
              </Select>
            </Form.Item>
            <Form.Item
              className='add-appointment-form__doctor-field'
              label={locale.labels.doctor}
              name='doctor_id'
              rules={[requiredRule(locale.errors.doctorNotValid)]}
            >
              <Select
                onChange={setSelectedDoctorId}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {employeesList}
              </Select>
            </Form.Item>
            <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.attendees}
              name='attendees'
            >
              <Select
                className='attendees-select'
                mode='multiple'
                value={selectedAttendees}
                onSelect={(value) => setSelectedAttendees([...selectedAttendees, value])}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {attendeesList?.length && attendeesList}
              </Select>
            </Form.Item>
            <Form.Item label={''}>
              <Form.Item
                className='add-equipment-form__single-select-field'
                name={['location', 'type']}
                label={locale.labels.location}
                rules={[requiredRule(locale.errors.enterLocationType)]}
              >
                <Select
                  className='appointment-form-room-select'
                  filterOption={false}
                  onSelect={setLocationType}
                  options={locations}
                />
              </Form.Item>
              <Form.Item
                name={['location', 'details']}
                label={locale.labels.details}
                hidden={locationType !== 'other'}
                rules={
                  locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['location', 'id']}
                className='add-equipment-form__single-select-field'
                hidden={locationType !== 'room'}
                label={locale.labels.room}
                rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
              >
                <Select
                  onChange={setSelectedRoomId}
                  filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {roomsList}
                </Select>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <DateRangePicker
              disableTimeEditing={disableTimeEditing}
              startDate={startDate}
              endDate={endDate}
              duration={duration}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDuration={setDuration}
            />
          </Col>
        </Row>
        <TextEditor label={locale.labels.notes} name={['notes']} />
        {children}
      </Form>
    </Spin>
  );
};
