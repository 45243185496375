import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEventPayload, IEventUpdateResponse } from 'api/interfaces/CalendarEvent';

import { useCustomMutation } from '../useCustomMutation';

export const useUpdateEvent = (eventId: number, callbackFn?: () => void) => {
  return useCustomMutation<IEventPayload, IEventUpdateResponse>(
    (payload: IEventPayload) => axiosClient.put(`${API_ENDPOINTS.EVENTS}/${eventId}`, payload),
    [['calendar-event', eventId], 'calendar-events'],
    callbackFn
  );
};
