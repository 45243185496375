import React from 'react';

type Props = React.PropsWithChildren<{
  onClick?: () => void;
}>;

export const LinkButton = ({ children, onClick }: Props): JSX.Element => {
  const onClickAction = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a href='' onClick={onClickAction}>
      {children}
    </a>
  );
};
