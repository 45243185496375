import { Tooltip } from 'antd';
import { IClient } from 'api/interfaces/Client';
import * as ls from 'local-storage';
import React from 'react';

const DEFAULT_SIZE = 14;

export const blacklistedStatus = (client: IClient, size: number = DEFAULT_SIZE): JSX.Element => {
  const localeCode = ls.get('locale') as string;

  const blacklistedStatus = {
    enGB: 'Blacklisted',
    ukUA: 'У чорному списку'
  }[localeCode];

  return (
    client?.blacklisted?.status && (
      <Tooltip
        title={`${blacklistedStatus}: ${client.blacklisted.reason}`}
        trigger={'hover'}
        placement='topRight'
      >
        <span
          className={'icofont-ui-block'}
          style={{
            fontSize: `${size}px`,
            marginLeft: '5px',
            color: 'red',
            opacity: '80%'
          }}
        />
      </Tooltip>
    )
  );
};
