import { ErrorBoundary } from '@highlight-run/react';
import * as Sentry from '@sentry/browser';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { notification } from 'antd';
import queryClient from 'api/queryClient';
import { LocaleProvider } from 'api/store/localeContext';
import { H } from 'highlight.run';
import { createHashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { env, highlightProjectId, sentryDSN } from './config/config-manager';
import { rootReducer } from './redux/store';
import { interceptor } from './services/baseClient';

const isLocalEnv = window.location.host.includes('localhost');

if (!isLocalEnv) {
  H.init(highlightProjectId, {
    serviceName: 'frontend-app',
    tracingOrigins: ['api.dev.vetlyx.com/api', 'api.vetlyx.com/api', 'api.qa.vetlyx.com/api'],
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com'
      ]
    }
  });

  Sentry.init({ dsn: sentryDSN, environment: env });
}

notification.config({
  placement: 'topRight',
  maxCount: 3,
  duration: 5,
  rtl: false
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers: any = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
/* eslint-enable */

export const history = createHashHistory();
interceptor(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <LocaleProvider>
        <Provider store={store}>
          <App />
          {isLocalEnv && <ReactQueryDevtools initialIsOpen={false} />}
        </Provider>
      </LocaleProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);
