export const keyMappings = {
  composite: 'q[composite][]',
  status: 'q[status]',
  paymentStatus: 'q[payment_status]',
  startTimeFrom: 'q[start_time_from]',
  startTimeTo: 'q[start_time_to]',
  actionRequired: 'q[action_required]',
  notStatus: 'q[not_status]',
  listRange: 'q[list_range]',
  timeFrom: 'q[time_from]',
  timeTo: 'q[time_to]',
  lineDatePeriod: 'q[date_period]'
};

export const transformKeysForAPI = (
  params: Record<string, string | number | string[]>
): Record<string, any> => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    const apiKey = keyMappings[key] || key;
    acc[apiKey] = value;
    return acc;
  }, {});
};

export const transformKeysForState = (
  params: Record<string, string | number>
): Record<string, any> => {
  const reverseMappings = Object.fromEntries(
    Object.entries(keyMappings).map(([stateKey, apiKey]) => [apiKey, stateKey])
  );
  return Object.entries(params).reduce((acc, [key, value]) => {
    const stateKey = reverseMappings[key] || key;
    acc[stateKey] = value;
    return acc;
  }, {});
};
