import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { IClinicInfo } from 'api/interfaces/Clinic';

import { useUserProfile } from '../user/useUserProfile';

const mutationFn = async (data: Partial<IClinicInfo>): Promise<{ clinic_id: number }> => {
  const response = await axiosClient.post('/v1/clinics', data);
  return response.data.clinic_id;
};

export const useClinicRegistration = (onSuccess: (clinicId) => void) => {
  const getUser = useUserProfile();

  return useMutation({
    mutationFn,
    onSuccess: (clinicId) => {
      getUser.mutate();
      onSuccess(clinicId);
    },
    onError: (error: any) => {
      notification.error({
        message: 'Registration Failed',
        description: error?.response?.data?.message || 'An error occurred.'
      });
    }
  });
};
