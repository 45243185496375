import './EventsCalendar.scss';

import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { useCancelEvent } from 'api/hooks/calendarEvents/useCancelEvent';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IEventDetails, ISelectedEvent } from 'api/interfaces/CalendarEvent';
import { MODAL, useModal } from 'api/store/modalStore';
import useUserStore from 'api/store/userStore';
import crossPawIcon from 'assets/img/crossPaw.svg';
import doctorIcon from 'assets/img/doctor.svg';
import doctorsIcon from 'assets/img/doctors.svg';
import locationIcon from 'assets/img/location.svg';
import personIcon from 'assets/img/person.svg';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import { ILocale } from 'interfaces/locale';
import { EditEventModal } from 'layout/modals/editEvent/EditEventModal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTime } from 'utils/get-time';
import {
  getAtendees,
  getParticipants,
  getPatients,
  getServices
} from 'utils/string-from-entities-list';

const { Title } = Typography;

type Props = {
  selectedEvent: ISelectedEvent;
  setSelectedEvent: (data) => void;
  selectedEventData: IEventDetails;
  selectedAppointmentData: IAppointmentDetails;
  locale: ILocale;
  showEventButtons?: boolean;
};

export const EventModal = ({
  selectedEvent,
  setSelectedEvent,
  selectedEventData,
  selectedAppointmentData,
  locale,
  showEventButtons
}: Props): JSX.Element => {
  const { event_type, location, clinic_id } = selectedEvent?.resource ?? {};
  const navigate = useNavigate();
  const { open, isOpened } = useModal();
  const userId = useUserStore(({ user }) => user.id);

  const [event, setEvent] = useState(null);
  const cancelEvent = useCancelEvent(event?.id, () => setSelectedEvent(null));

  const [showCalcelEventModal, setShowCalcelEventModal] = useState<boolean>(false);

  useEffect(() => {
    if (event_type === 'appointment' && selectedAppointmentData) {
      setEvent(selectedAppointmentData);
    } else if (event_type === 'event' && selectedEventData) {
      setEvent(selectedEventData);
    } else if (event_type === 'event' && location?.details === 'unavailable') {
      setEvent(selectedEvent.resource);
    } else if (!selectedEvent) {
      setEvent(null);
    }
  }, [selectedEvent, selectedAppointmentData, selectedEventData, event]);

  const onConfirmCancelEventClick = (): void => {
    cancelEvent.mutate();
  };

  return (
    !!event && (
      <>
        <Modal
          open={Boolean(selectedEvent)}
          onCancel={() => setSelectedEvent(null)}
          footer={null}
          width={'auto'}
          centered
          styles={{
            content: { minWidth: 350 },
            mask: { backgroundColor: 'transparent' }
          }}
        >
          <>
            {getTime(event)}
            <Title level={5}>
              {event_type === 'appointment'
                ? event.title || getServices(event.services)
                : event.title}
            </Title>
            <Row>
              <Col style={{ marginRight: 30, maxWidth: '50%' }}>
                {event.doctor?.first_name && (
                  <Row align='middle' className='event-modal-text-row'>
                    <Col className='event-modal-text-row__img-cont'>
                      <img src={doctorIcon} />
                    </Col>
                    <Col>
                      {event.doctor?.first_name} {event.doctor?.last_name}
                    </Col>
                  </Row>
                )}
                {!!(event.participants?.length || event.attendees?.length) && (
                  <Row align='middle' className='event-modal-text-row'>
                    <Col className='event-modal-text-row__img-cont'>
                      <img src={doctorsIcon} />
                    </Col>
                    <Col style={{ maxWidth: 'calc(100% - 26px)' }}>
                      {event_type === 'appointment'
                        ? getParticipants(event.participants)
                        : getAtendees(event.attendees)}
                    </Col>
                  </Row>
                )}
                {event.location?.details !== 'unavailable' && (
                  <Row align='middle' className='event-modal-text-row'>
                    <Col className='event-modal-text-row__img-cont'>
                      <img src={locationIcon} />
                    </Col>
                    <Col>{event.location?.details}</Col>
                  </Row>
                )}
              </Col>
              <Col>
                {event.client && (
                  <Row align='middle' className='event-modal-text-row'>
                    <Col className='event-modal-text-row__img-cont'>
                      <img src={personIcon} />
                    </Col>
                    <Col>
                      {`${event.client?.name} ${event.client?.phone_number 
                        ? `(+${event.client?.country_code}${event.client?.phone_number})` 
                        : ''}`}
                    </Col>
                  </Row>
                )}
                {event.patients?.length && (
                  <Row align='middle' className='event-modal-text-row'>
                    <Col className='event-modal-text-row__img-cont'>
                      <img src={crossPawIcon} />
                    </Col>
                    <Col>
                      <Typography>{getPatients(event.patients)}</Typography>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {event_type === 'appointment' && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Button
                  type='primary'
                  onClick={() =>
                    navigate(`/clinic/${clinic_id}/appointments/${selectedAppointmentData.id}`)
                  }
                  style={{ borderRadius: 30, whiteSpace: 'normal', minWidth: '80%' }}
                >
                  {locale.buttons.viewAppointment}
                  <span className='icofont icofont-external-link icon-after-btn' />
                </Button>
              </div>
            )}
            {event_type === 'event' &&
              selectedEvent?.resource?.title !== 'Busy' &&
              showEventButtons && (
                <div className='event-modal-buttons'>
                  <Tooltip
                    title={
                      selectedEventData?.organiser_id !== userId && locale.errors.eventCantBeEdited
                    }
                    trigger={'hover'}
                    style={{ display: 'flex' }}
                  >
                    <Row>
                      <Button
                        onClick={() => open(MODAL.editEventModal)}
                        type='primary'
                        className={'inventory-update-buttons'}
                        disabled={selectedEventData?.organiser_id !== userId}
                        shape={'circle'}
                      >
                        <span className='icofont icofont-edit-alt' />
                      </Button>
                      <Button
                        onClick={() => setShowCalcelEventModal(true)}
                        danger
                        type='primary'
                        className={'inventory-update-buttons'}
                        disabled={selectedEventData?.organiser_id !== userId}
                        shape={'circle'}
                      >
                        <span className='icofont icofont-ban' />
                      </Button>
                    </Row>
                  </Tooltip>
                </div>
              )}
          </>
        </Modal>
        {isOpened(MODAL.editEventModal) && (
          <EditEventModal
            initialValues={selectedEventData}
            closeEventModal={() => setSelectedEvent(null)}
          />
        )}
        <ConfirmCancelModal
          cancelText={`${locale.labels.cancelEvent} "${selectedEvent?.title}"?`}
          open={showCalcelEventModal}
          onOk={onConfirmCancelEventClick}
          onCancel={() => setShowCalcelEventModal(false)}
        />
      </>
    )
  );
};
