import '../scss/StockDetails.scss';

import { Button, Card, Col, List, Menu, MenuProps, Row, Spin } from 'antd';
import { useDeleteStock } from 'api/hooks/stock/useDeleteStock';
import { useStockDetails } from 'api/hooks/stock/useStockDetails';
import { MODAL, useModal } from 'api/store/modalStore';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { DeletedInfo } from 'components/EntityDetails/DeletedInfo';
import { StatusTag } from 'components/StatusTag';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { money } from 'helpers/list-helpers';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useStockId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import { PrintPricesModal } from 'layout/modals/printPrices/PrintPricesModal';
import React, { useMemo, useState } from 'react';
import Barcode from 'react-barcode';

import { AddItemsToStockModal } from '../modals/AddItemsToStockModal';
import { EditStockItemModal } from '../modals/EditStockItemModal';
import { ReassignLocationModal } from '../modals/ReassignLocationModal';
import { ReassignPriceModal } from '../modals/ReassignPriceModal';
import { useGetResidual } from '../utils/useGetResidual';
import { StockDetailsHeader } from './StockDetailsHeader';
import { StockHistoryTab } from './StockHistoryTab';

dayjs.extend(customParseFormat);

const StockDetails = (): JSX.Element => {
  const { open, isOpened, closeModal } = useModal();
  const clinicId = useClinicId();
  const stockId = useStockId();
  const userAccess = useAccess();
  const locale = useLocale('private.inventory.stock');
  const currencies = useLocale('private.currencies').labels;

  const { data: stockDetails, isLoading } = useStockDetails(clinicId, stockId);
  const deleteStock = useDeleteStock(clinicId, stockId, closeModal);

  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stockDetails);
  const formatLabel = noPackageFormat
    ? `(${unitOfMeasurement || locale.labels.psc})`
    : `(${packagingFormat}/${unitOfMeasurement})`;

  const filteredLocations = stockDetails?.locations.filter((el) => !el.deleted && el.residual);
  const filteredPrices = stockDetails?.prices.filter((el) => !el.deleted && el.residual);

  const [currentTab, setCurrentTab] = useState('general');
  const stockDeleted = stockDetails?.deleted;

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.general,
      key: 'general'
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = ({ key }: { key: string }): void => {
    setCurrentTab(key);
  };

  const stockLocations = useMemo(
    (): string[] =>
      (filteredLocations || [])
        .sort((a) => (a.name === 'unassigned' ? 1 : -1))
        .map(
          (el) =>
            `${el.name === 'unassigned' ? locale.labels.unassigned : el.name}: ${getResidual(
              el.residual
            )}`
        ),
    [stockDetails?.locations]
  );

  const getStockPrices = (): string[] =>
    (filteredPrices || [])
      .sort((el) => (el.type === 'default' ? -1 : 1))
      .map((el) => {
        const isDefaultPrice = el.type === 'default' ? `(${locale.labels.default})` : '';
        const prices = noPackageFormat
          ? money(el.price_per_unit)
          : `${money(el.price_per_package)}/${money(el.price_per_unit)}`;

        return `${prices} ${currencies.uah} ${isDefaultPrice}: ${getResidual(el.residual)}`;
      });

  return (
    <Spin spinning={isLoading} className='stock-details-page-spin-container'>
      {!!stockDetails && (
        <>
          <Row className='patient-details'>
            <Col span={24} md={11} lg={8} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container'>
                <Row justify='center'>
                  <div className={'name-statuses-form-header'}>
                    <h4 id='stock-name'>{stockDetails.name}</h4>
                    <StatusTag status={stockDetails.status} />
                  </div>
                  {stockDeleted && (
                    <DeletedInfo
                      deleted_at={stockDetails?.deleted_at}
                      deleted_by={stockDetails?.deleted_by}
                    />
                  )}
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontWeight: 600 }}>{locale.labels.sku}:&nbsp;</span>
                    <span id='sku'>{stockDetails.sku || '-'}</span>
                  </div>
                  <div className='stock-barcode'>
                    {stockDetails.barcode && <Barcode value={stockDetails.barcode} />}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginBottom: 20,
                      marginTop: 20
                    }}
                  >
                    <List
                      id={'stock-quantity'}
                      header={`${locale.labels.quantity}`}
                      style={{ marginBottom: 20, fontSize: 14 }}
                      dataSource={[getResidual(stockDetails?.residual)]}
                      bordered
                      renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
                    />
                    {!!filteredPrices?.length && (
                      <List
                        id={'stock-prices'}
                        header={
                          <div className='reassign-header' style={{ flexWrap: 'wrap', gap: 12 }}>
                            <span>{`${locale.labels.prices} ${formatLabel}`}</span>
                            <Row style={{flexWrap: 'nowrap'}}>
                              <Button
                                type='primary'
                                onClick={() => {
                                  open(MODAL.reassignPriceModal);
                                }}
                                style={{ borderRadius: 30, width: 44, height: 30 }}
                              >
                                <span className='icofont icofont-edit-alt' />
                              </Button>
                              <Button
                                type='primary'
                                onClick={() => {
                                  open(MODAL.printPricesModal);
                                }}
                                style={{ borderRadius: 30, width: 44, height: 30 }}
                              >
                                <span className='icofont icofont-print' />
                              </Button>
                            </Row>
                          </div>
                        }
                        style={{ marginBottom: 20, fontSize: 14 }}
                        bordered
                        dataSource={getStockPrices()}
                        renderItem={(item) => (
                          <List.Item style={{ fontSize: 14 }}>{item}</List.Item>
                        )}
                      />
                    )}
                    {!!filteredLocations?.length && (
                      <List
                        id={'stock-locations'}
                        header={
                          <div className='reassign-header'>
                            <span>{locale.labels.locations}</span>
                            <Button
                              type='primary'
                              onClick={() => {
                                open(MODAL.reassignLocationModal);
                              }}
                              style={{ borderRadius: 30, width: 44, height: 30 }}
                            >
                              <span className='icofont icofont-loop' />
                            </Button>
                          </div>
                        }
                        style={{ marginBottom: 20 }}
                        bordered
                        dataSource={stockLocations}
                        renderItem={(item) => (
                          <List.Item style={{ fontSize: 14 }}>{item}</List.Item>
                        )}
                      />
                    )}
                  </div>
                  {!stockDeleted && (
                    <Button
                      type='primary'
                      style={{ borderRadius: 30 }}
                      onClick={() => open(MODAL.addItemsToStockModal)}
                    >
                      <span
                        className='icofont icofont-plus mr-2 sticky-btn-icon'
                        style={{ fontSize: '1.3em' }}
                      />
                      <span>{locale.buttons.add}</span>
                    </Button>
                  )}
                </Row>
              </Card>
            </Col>
            <Col span={24} md={13} lg={16} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow'>
                <Menu
                  onClick={changeTab}
                  selectedKeys={[currentTab]}
                  mode='horizontal'
                  items={items}
                  style={{ marginBottom: 20 }}
                />
                {currentTab === 'general' && (
                  <div className='stock-row-container__form' style={{ padding: '0 15px' }}>
                    <StockDetailsHeader stock={stockDetails} locale={locale} />
                    {!stockDeleted && !isOpened(MODAL.editStockItemModal) && (
                      <ViewDetailsButtonsGroup
                        hideCancelBtn
                        fixed
                        accessGroup={userAccess.accesses.stock}
                        editMode={false}
                        onEditClick={() => open(MODAL.editStockItemModal)}
                        onDeleteClick={() => open(MODAL.confirmDeleteModal)}
                      />
                    )}
                  </div>
                )}
                {currentTab === 'history' && (
                  <StockHistoryTab locale={locale} stock={stockDetails} />
                )}
              </Card>
            </Col>
          </Row>
          {isOpened(MODAL.reassignPriceModal) && (
            <ReassignPriceModal stock={stockDetails} locale={locale} />
          )}
          {isOpened(MODAL.reassignLocationModal) && (
            <ReassignLocationModal stock={stockDetails} locale={locale} />
          )}
          {isOpened(MODAL.editStockItemModal) && (
            <EditStockItemModal locale={locale} stock={stockDetails} />
          )}
          {isOpened(MODAL.addItemsToStockModal) && (
            <AddItemsToStockModal
              accessGroup={userAccess.accesses.stock}
              locale={locale}
              stock={stockDetails}
            />
          )}
          {isOpened(MODAL.printPricesModal) && (
            <PrintPricesModal stock={stockDetails} prices={filteredPrices} />
          )}
          {isOpened(MODAL.confirmDeleteModal) && (
            <ConfirmDeleteModal
              type={'device'}
              open
              onOk={deleteStock.mutate}
              onCancel={closeModal}
            />
          )}
        </>
      )}
    </Spin>
  );
};

export default StockDetails;
