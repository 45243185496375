import { Pagination, Row, Table, TableColumnProps } from 'antd';
import { useBillTransactions } from 'api/hooks/bills/useBillTransactions';
import { IBillTransaction } from 'api/interfaces/Bills';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useBillId } from 'hooks/useId';
import { ILocale } from 'interfaces/locale';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

type Props = React.PropsWithChildren<{
  currency: string;
  locale: ILocale;
}>;

export const BillTransactions = ({ locale, currency, children }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const billId = useBillId();
  const navigate = useNavigate();

  const { billTransactions, data, isLoading, params, updateParams, updateSorting } =
    useBillTransactions(clinicId, billId, defaultParams);

  const redirectToPaymentDetails = (record: IBillTransaction) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IBillTransaction>[] => [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: locale.labels.date,
        sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
        render: (created_at) =>
          created_at !== undefined ? dayjs(created_at).format('DD.MM.YYYY HH:mm') : '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        title: locale.labels.amount,
        sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
        render: (amount) => (amount ? `${money(amount)} ${currency}` : '-'),
        onCell: redirectToPaymentDetails
      },
      {
        key: 'financial_account.name',
        dataIndex: 'accountName',
        title: locale.labels.accountName,
        sorter: (a, b) => (a?.financial_account?.name > b?.financial_account?.name ? 1 : -1),
        render: (_notes, e) => e?.financial_account?.name || '-',
        onCell: redirectToPaymentDetails
      },
      {
        key: 'financial_account.account_number',
        title: locale.labels.accountNumber,
        sorter: (a, b) =>
          a?.financial_account?.account_number > b?.financial_account?.account_number ? 1 : -1,
        render: (e) => e?.financial_account?.account_number || '-',
        onCell: redirectToPaymentDetails
      }
    ],
    [billTransactions]
  );

  return (
    <>
      <h4>{locale.labels.payments}</h4>

      <Table
        pagination={false}
        className='accent-header bills-payments-list-table'
        rowKey='id'
        dataSource={billTransactions}
        loading={isLoading}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        onChange={updateSorting}
      />
      <Row justify={'space-between'} style={{ marginTop: 20 }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        {children}
      </Row>
    </>
  );
};
