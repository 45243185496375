import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IProvidersHistoryItem, IProvidersHistoryList } from 'api/interfaces/Provider';

import { useTableData } from '../useTableData';

export const useProvidersHistory = (
  clinicId: number,
  providerId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = `${API_ENDPOINTS.PROVIDERS(clinicId)}/${providerId}/invoices`;

  const result = useTableData<IProvidersHistoryList>(
    endpoint,
    defaultParams,
    ['providersHistoryList', clinicId, providerId],
    searchDisabled
  );

  return {
    ...result,
    providersHistory: (result.data.data || []) as IProvidersHistoryItem[]
  };
};
