import { Modal } from 'antd';
import React from 'react';

import { useLocale } from '../hooks/useLocale';

const ConfirmDeleteModal = ({ open, onOk, onCancel, customTitle, okText, cancelText }: any) => {
  const locale = useLocale('private.confirmation-modal');

  return (
    <Modal
      title={customTitle || locale.messages.deleteItem}
      centered
      open={open}
      onOk={onOk}
      okButtonProps={{
        style: {
          backgroundColor: '#d66145',
          borderColor: '#d66145'
        }
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: '#0e3048',
          borderColor: '#0e3048'
        }
      }}
      okText={okText || locale.buttons.yes}
      cancelText={cancelText || locale.buttons.no}
      onCancel={onCancel}
    />
  );
};

export default ConfirmDeleteModal;
