import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAccountPayload } from 'api/interfaces/Finances';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditFinancialAccount = (
  clinicId: number,
  accountId: number,
  callbackFn?: () => void
) => {
  const mutationFn = (payload: IAccountPayload) =>
    axiosClient.put(`${API_ENDPOINTS.ACCOUNTS(clinicId)}/${accountId}`, payload);

  const queryKeys = [
    ['bankAccountsList', clinicId],
    ['bankAccountDetails', clinicId, accountId]
  ];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
