import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPaymentPayload } from 'api/interfaces/Payment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditPayment = (clinicId: number, paymentId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IPaymentPayload) =>
    axiosClient.put(`${API_ENDPOINTS.PAYMENTS(clinicId)}/${paymentId}`, payload);

  const queryKeys = [
    ['paymentsList', clinicId],
    ['paymentDetails', clinicId, paymentId]
  ];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
