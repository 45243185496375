import './PatientList.scss';

import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Pagination, Row, Table, TableColumnProps, Tooltip, Typography } from 'antd';
import { usePatientsList } from 'api/hooks/patients/usePatientsList';
import { IPatient } from 'api/interfaces/Patient';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import tomb from 'assets/img/tomb.svg';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { animalIdUrl } from 'constants/common';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddPatientModal } from 'layout/modals/patient/AddPatientModal';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge } from './utils/PatientHelper';

const AnimalIcon = ({ sex }: { sex: string }): JSX.Element => {
  switch (sex) {
    case 'male':
      return <ManOutlined />;
    case 'female':
      return <WomanOutlined />;
    default:
      return <>-</>;
  }
};

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const PatientsTab = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { localeCode } = useLocaleCode();
  const { open, isOpened } = useModal();
  const locale = useLocale('private.patients.patients-list');
  const localePage = useLocale('private.patients.patient-page');

  const { data, patients, isLoading, params, updateParams, updateSorting } = usePatientsList(
    clinicId,
    defaultParams
  );

  const redirectToPatientDetails = ({ id }: IPatient): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/patients/${id}`);
    }
  });

  const getAge = (patient: IPatient): JSX.Element =>
    patient?.date_of_death ? (
      <Typography id='patient-death-icon'>
        <Tooltip
          trigger={'hover'}
          placement='topLeft'
          title={getAnimalAge(localePage, patient.birth_date, patient.date_of_death)}
        >
          {'\u00A0'}
          <img
            src={tomb}
            style={{
              width: 22,
              height: 22
            }}
          />
        </Tooltip>
      </Typography>
    ) : (
      <Typography id='list-pet-age'>
        {patient?.birth_date ? getAnimalAge(localePage, patient?.birth_date) : '-'}
      </Typography>
    );

  const getAnimalData = (data: IPatient): string => {
    // TODO API change type after client rework.
    const { species, breed } = localizedPetData(data as any, localeCode);

    if (species && breed) return `${species} (${breed})`;

    return species || '-';
  };

  const ownerPhoneNumber = (p: IPatient): JSX.Element => {
    const phone = p?.owner?.phone_number;
    if (!phone || phone.length < 9) return <span>-</span>;

    return (
      <Link
        to={`tel:${p?.owner.phone_number}`}
        className='custom-links d-flex align-baseline nowrap'
      >
        <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
        {ListFieldWithTooltip(`+${p?.owner.phone_number}`)}
      </Link>
    );
  };

  const columns = useMemo(
    (): TableColumnProps<IPatient>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name, e) => name,
        onCell: redirectToPatientDetails
      },
      {
        key: 'species',
        title: locale.labels.species,
        sorter: () => null,
        render: (e) => getAnimalData(e),
        onCell: redirectToPatientDetails
      },
      {
        key: 'blood_type',
        title: locale.labels.bloodType,
        sorter: () => null,
        onCell: redirectToPatientDetails,
        render: (e) => e.blood_type || '-'
      },
      {
        key: 'birth_date',
        title: locale.labels.age,
        sorter: () => null,
        onCell: redirectToPatientDetails,
        render: (e) => getAge(e)
      },
      {
        key: 'sex',
        title: locale.labels.sex,
        sorter: () => null,
        render: (e) => <AnimalIcon sex={e.sex} />,
        onCell: redirectToPatientDetails
      },
      {
        key: 'chip_number',
        dataIndex: 'chip_number',
        title: locale.labels.chip,
        sorter: () => null,
        render: (chip_number) =>
          chip_number
            ? ListFieldWithTooltip(
                <Link className={'custom-links'} to={animalIdUrl + chip_number}>
                  {chip_number}
                </Link>
              )
            : '-'
      },
      {
        key: 'owner_name',
        title: locale.labels.owner,
        sorter: () => null,
        render: (e) => (
          <>
            <Link to={`/clinic/${clinicId}/clients/${e.owner?.id}`} className={'custom-links'}>
              {ListFieldWithTooltip(e?.owner.name, 30)}
            </Link>
            {blacklistedStatus(e.owner)}
          </>
        )
      },
      {
        key: 'owner_phone_number',
        title: locale.labels.contact,
        sorter: () => null,
        render: (e) => ownerPhoneNumber(e)
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />

      <Table
        pagination={false}
        loading={isLoading}
        className='accent-header patients-list-table'
        rowKey={(row) => `${row.id}_${crypto.randomUUID()}`}
        dataSource={patients}
        onChange={updateSorting}
        columns={columns}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addPatientModal)}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPatient}
        />
        {isOpened(MODAL.addPatientModal) && <AddPatientModal />}
      </Row>
    </>
  );
};
