import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import { useCustomMutation } from '../useCustomMutation';

export const useDeteleInvoice = (clinicId: number, invoiceId: number, callbackFn?: () => void) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}`);

  return useCustomMutation(mutationFn, ['invoiceDetails', clinicId, invoiceId], callbackFn);
};
