import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IVaccinationPayload } from 'api/interfaces/Vaccination';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditVaccination = (
  clinicId: number,
  patientId: number,
  vaccinationId: number,
  callbackFn?: () => void
) => {
  const mutationFn = (payload: IVaccinationPayload) =>
    axiosClient.put(
      `${API_ENDPOINTS.PATIENT_VACCINATIONS(clinicId, patientId)}/${vaccinationId}`,
      payload
    );
  const queryKeys = ['vaccinationsList', ['patientVaccinationsList', clinicId, patientId]];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
