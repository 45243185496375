import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEmailChangePayload } from 'api/interfaces/User';
import { AxiosResponse } from 'axios';

const mutationFn = async (payload: IEmailChangePayload): Promise<{ phone_number: string }> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.USER}/email/change`, payload);
  return response.data?.email;
};

export const useChangeEmail = (onSuccess: (email) => void, onError: (error: Promise<AxiosResponse>) => void) =>
  useMutation({ mutationFn, onSuccess, onError });
