import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import VLXModal from 'components/VLXModal';
import { VLXModalFooter } from 'components/VLXModalFooter';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

import { AddAppointmentForm } from './AddAppointmentForm';

type Props = {
  // todo API type required
  initialAppointmentData?: any;
};

export const AddAppointment = ({ initialAppointmentData }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const locale = useLocale('private.appointments.event');

  const [addAppointmentForm] = Form.useForm();
  const userAccess = useAccess();

  return (
    <>
      <VLXModal
        title={locale.labels.createNewAppointment}
        form={addAppointmentForm}
        centered
        open
        onModalClose={closeModal}
        width={800}
        newModalStyle
      >
        <AddAppointmentForm
          form={addAppointmentForm}
          initialValues={initialAppointmentData}
          locale={locale}
          closeModal={closeModal}
        >
          <VLXModalFooter
            accessGroup={userAccess.accesses.patients}
            editMode
            form={addAppointmentForm}
          />
        </AddAppointmentForm>
      </VLXModal>
    </>
  );
};
