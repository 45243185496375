import { useLocation, useParams } from 'react-router-dom';

export default function useToolId (): number {
  const location = useLocation();
  const { toolId }: any = useParams();
  if (!location.pathname.includes('tools') && !toolId) {
    return null;
  }
  return Number(toolId) || Number(location.pathname.split('/')[4]);
}
