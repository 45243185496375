import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IVaccinationPayload } from 'api/interfaces/Vaccination';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateVaccination = (
  clinicId: number,
  patientId: number,
  callbackFn?: () => void
) => {
  const mutationFn = (payload: IVaccinationPayload) =>
    axiosClient.post(
      API_ENDPOINTS.PATIENT_VACCINATIONS(clinicId, patientId || payload.patient),
      payload
    );

  return useCustomMutation(
    mutationFn,
    ['vaccinationsList', ['patientVaccinationsList', clinicId, patientId]],
    callbackFn
  );
};
