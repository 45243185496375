import { Form } from 'antd';
import { IClient, IClientDetails } from 'api/interfaces/Client';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { ClientItemForm } from './ClientItemForm';

type Props = {
  initialValues?: IClientDetails;
  onSuccess?: (data) => void;
};

export const ClientItemModal = ({ initialValues, onSuccess }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const locale = useLocale('private.clients.client');
  const userAccess = useAccess();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={initialValues ? locale.labels.editClient : locale.labels.createNewClient}
        centered
        open
        form={form}
        onModalClose={closeModal}
        width={800}
      >
        <ClientItemForm
          form={form}
          locale={locale}
          initialValues={initialValues}
          onSuccess={
            onSuccess
              ? (client: IClient) => {
                  onSuccess(client);
                  closeModal();
                }
              : closeModal
          }
        >
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.clients}
            fixed
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </ClientItemForm>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
