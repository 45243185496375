import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPrintConfiguration } from 'api/interfaces/Print';

import { useCustomMutation } from '../useCustomMutation';

const headers = { 'Content-Type': 'multipart/form-data' };

export const useEditPrintConfiguration = (clinicId: number, printConfigurationId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: FormData): Promise<IPrintConfiguration> =>
    axiosClient.put(`${API_ENDPOINTS.PRINT(clinicId)}/${printConfigurationId}`, payload, { headers });

  return useCustomMutation(mutationFn, ['printConfiguration', clinicId], callbackFn);
};
