import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvitationPayload } from 'api/interfaces/Invitation';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateInvitation = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IInvitationPayload) =>
      axiosClient.post(`${API_ENDPOINTS.INVITATIONS(clinicId)}`, payload),
    [['invitationsList', clinicId]],
    callbackFn
  );
};
