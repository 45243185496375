import { Menu } from 'antd';
import { useAccess } from 'hooks/useAccess';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import useClinicId from '../../hooks/useClinicId';
import { useLocale } from '../../hooks/useLocale';

const InventoryActions = () => {
  const userAccess = useAccess();
  const scopes = userAccess.accesses;
  const clinicId = useClinicId();
  const params = useLocation().pathname.split('/')[4];
  const [current, setCurrent] = useState(params || '/');
  const locale = useLocale('private.inventory.tabs');

  useEffect(() => {
    setCurrent(params?.length ? params : '/');
  }, [params]);

  const tabs = [
    {
      name: 'info',
      key: 'inventory-dashboard',
      label: locale.tabs.dashboard,
      permission: true
    },
    {
      name: 'stock',
      label: locale.tabs.stock,
      key: 'stock',
      permission: scopes.stock.showList
    },
    {
      name: 'invoices',
      label: locale.tabs.invoices,
      key: 'invoice',
      permission: scopes.invoices.showList
    },
    {
      name: 'providers',
      label: locale.tabs.providers,
      key: 'providers',
      permission: scopes.invoices.showList
    },
    {
      name: 'audit',
      label: locale.tabs.audit,
      key: 'audit',
      permission: scopes.audit.showList
    },
    {
      name: 'equipment',
      label: locale.tabs.technicalEquipment,
      key: 'equipment',
      permission: scopes.equipment.showList
    },
    {
      name: 'tools',
      label: locale.tabs.tools,
      key: 'tools',
      permission: scopes.tools.showList
    },
    {
      name: 'generalSettings',
      label: locale.tabs.settings,
      permission: true
    }
  ];

  const generateOptions = () =>
    tabs.map((item) => {
      if (!item.permission) return null;
      return {
        key: item.name,
        label: <NavLink to={`/clinic/${clinicId}/inventory/${item.name}`}>{item.label}</NavLink>
      };
    });

  return (
    <>
      <Menu
        selectedKeys={[current]}
        mode='horizontal'
        items={generateOptions()}
        style={{ marginBottom: '10px' }}
      />
      <Outlet />
    </>
  );
};

export default InventoryActions;
