import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClinicInfo } from 'api/interfaces/Clinic';

export const useClinicInfo = (clinicId: number) => {
  return useQuery<IClinicInfo>({
    queryKey: ['clinicInfo', clinicId],
    queryFn: async () => {
      const { data } = await axiosClient.get(API_ENDPOINTS.CLINICS(clinicId));
      return data;
    }
  });
};
