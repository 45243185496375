import '../SaleDetails.scss';

import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { ISaleStock } from 'api/interfaces/Sale';
import { ILocation, IPrice, IStock } from 'api/interfaces/Stock';
import VLXInputNumber from 'components/VLXInputNumber';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import { StockSelect } from 'layout/components/stockSelect/StockSelect';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

import { FORMATS } from '../utils/constants';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  locale: ILocale;
  form: FormInstance;
  itemToEdit: ISaleStock;
  onCancel: () => void;
  setFieldsTouched: (val: boolean) => void;
}>;

export const SaleItemForm = ({
  locale,
  form,
  itemToEdit,
  onCancel,
  setFieldsTouched
}: Props): JSX.Element => {
  const clinicId = useClinicId();

  const [stockDetails, setStockDetails] = useState<IStock>(null);

  const { unitFormat, packageFormat } = usePlural(stockDetails);
  const stockDeleted = stockDetails?.deleted;

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const isLocationResidualSmaller = selectedLocation?.residual < selectedPrice?.residual;
  const residualErrorMsg = isLocationResidualSmaller
    ? locale.messages.notEnoughInLocation
    : locale.messages.notEnoughWithPrice;
  const [initialResidual, setInitialResidual] = useState(0);
  const [priceInputDisabled, setPriceInputDisabled] = useState(true);
  const [saleItemFormat, setSaleItemFormat] = useState(FORMATS.package);

  const presetSaleSelection = (
    id: number,
    selectionId: number,
    saleSelection: ILocation[] | IPrice[]
  ): ILocation | IPrice => {
    const isEditedItem = id === stockDetails.id;

    if (isEditedItem) return [...saleSelection]?.find((el) => el.id === selectionId);

    const selectionsWithResidual = [...saleSelection]?.filter((el) => el.residual && !el.deleted);

    if (selectionsWithResidual.length === 1) return selectionsWithResidual[0];

    return null;
  };

  useEffect((): void => {
    if (!stockDetails) return;

    const {
      id,
      stock_price,
      stock_location
    } = itemToEdit || {};
    const price = presetSaleSelection(id, stock_price?.id, stockDetails.prices);
    const location = presetSaleSelection(id, stock_location?.id, stockDetails.locations);

    setSelectedPrice(price);
    setSelectedLocation(location);
    setSaleItemFormat(
      stockDetails?.packaging_format === 'none' || itemToEdit?.format === 'unit'
        ? FORMATS.unit
        : FORMATS.package
    );

    form.setFieldValue(['stock_price'], price);
    form.setFieldValue(['stock_location'], location);

    if (location && price) {
      let quantity = 0;
      if (id === stockDetails.id) {
        quantity =
          itemToEdit?.quantity_in_packages * itemToEdit?.quantity_per_package +
          itemToEdit?.quantity_in_units;
      }

      setPriceInputDisabled(!quantity);
      setInitialResidual(Math.min(location.residual, price.residual) + quantity);
    }
  }, [stockDetails]);

  const locationSelectLabel = (item: ILocation): string => {
    const name = `${item.name === 'unassigned' ? locale.labels.unassigned : item.name}`;
    if (saleItemFormat === FORMATS.package) {
      return `${name} | ${item.quantity} ${packageFormat(item.quantity)}`;
    }
    return `${name} | ${item.residual} ${unitFormat(item.residual)}`;
  };

  const locationsList = useMemo(
    (): JSX.Element[] =>
      stockDetails?.locations
        .filter((el) => !el.deleted || selectedLocation?.id === el.id)
        .sort((item) => (item.residual > 0 ? -1 : 1))
        .map((item) => (
          <Option
            className='item-form-select'
            key={item.id}
            disabled={item.residual <= 0}
            title={item.id}
            value={item.id}
          >
            {locationSelectLabel(item)}
          </Option>
        )),
    [stockDetails?.locations, saleItemFormat, selectedLocation]
  );

  const priceSelectLabel = (item: IPrice): string => {
    const defaultPrice = item.type === 'default' ? locale.labels.defaultPrice : '';
    const placeholder = `${locale.labels.uah} ${defaultPrice}| `;
    if (saleItemFormat === FORMATS.package) {
      return `${money(item.price_per_package)} ${placeholder} ${item.quantity} ${packageFormat(
        item.quantity
      )}`;
    }
    return `${money(item.price_per_unit)} ${placeholder} ${item.residual} ${unitFormat(
      item.residual
    )}`;
  };

  const pricesList = useMemo(
    (): JSX.Element[] =>
      stockDetails?.prices
        ?.filter((el) => !el.deleted || selectedPrice?.id === el.id)
        .sort((item) => (item.residual > 0 ? -1 : 1))
        .map((item) => (
          <Option
            className='item-form-select'
            key={item.id}
            disabled={item.residual <= 0}
            title={item.id}
            value={item.id}
          >
            {priceSelectLabel(item)}
          </Option>
        )),
    [stockDetails?.prices, saleItemFormat, selectedPrice]
  );

  const onStockChange = (id: number, stockItem: IStock): void => {
    setStockDetails(stockItem);
    clearValues();
    form.setFieldValue(['id'], id);
  };

  const onFormatSelect = (format: FORMATS): void => {
    setSaleItemFormat(format);
    form.setFieldValue(['id'], stockDetails.id);
    clearValues();
  };

  const clearValues = (): void => {
    form.setFieldValue(['quantity_in_units'], null);
    form.setFieldValue(['quantity_in_packages'], null);
    form.setFieldValue(['price'], null);
    form.setFields([
      {
        name: ['stock_price', 'id'],
        errors: []
      }
    ]);
    setPriceInputDisabled(true);
  };

  const onNewLocationSelect = (id: number): void => {
    const location = stockDetails?.locations.find((el) => el.id === id);

    setSelectedLocation(location);
    setInitialResidual(Math.min(location.residual, selectedPrice?.residual));
    clearValues();
  };

  const onNewPriceSelect = (id: number): void => {
    const price = stockDetails?.prices.find((el) => el.id === id);

    setSelectedPrice(price);
    setInitialResidual(Math.min(price.residual, selectedLocation?.residual));
    clearValues();
  };

  const quantityTooltip = (): string => {
    if (!(selectedLocation && selectedPrice)) return null;

    const remainingIn = locale.labels[isLocationResidualSmaller ? 'inLocation' : 'inPrice'];
    const packagesResidual = Math.floor(initialResidual / stockDetails?.quantity_per_package);

    let formatLabel = '';
    if (saleItemFormat === FORMATS.package) {
      formatLabel = `${packagesResidual} ${packageFormat(packagesResidual)}`;
    } else {
      formatLabel = `${initialResidual} ${unitFormat(initialResidual)}`;
    }

    return locale.labels.remainingTooltip.replace(/%1/, formatLabel).replace(/%2/, remainingIn);
  };

  const onQuantityChange = (): void => {
    let price = 0;

    if (saleItemFormat === FORMATS.package) {
      price = form.getFieldValue('quantity_in_packages') * selectedPrice?.price_per_package || 0;
    } else {
      price = form.getFieldValue('quantity_in_units') * selectedPrice?.price_per_unit || 0;
    }

    form.setFieldsValue({
      price: price ? price.toFixed(2) : null
    });
    setPriceInputDisabled(false);

    residualValidate();
  };

  const residualValidate = (): boolean => {
    const { quantity_in_packages = 0, quantity_in_units = 0 } = form.getFieldsValue();
    const quantity = quantity_in_packages * stockDetails?.quantity_per_package + quantity_in_units;
    const residualError = quantity > initialResidual;

    form.setFields([
      {
        name: ['stock_price', 'id'],
        errors: residualError ? [residualErrorMsg] : []
      }
    ]);

    return residualError;
  };

  const QuantityInput = useCallback(
    ({ name }: { name: string }): JSX.Element => (
      <Col span={24} md={5} xl={5} xs={12}>
        <Form.Item style={{ width: '100%' }} label={locale.labels.quantity} name={name}>
          <VLXInputNumber
            disabled={!selectedPrice || !selectedLocation || stockDeleted}
            style={{ widht: '100%' }}
            onChange={onQuantityChange}
          />
        </Form.Item>
      </Col>
    ),
    [selectedPrice, selectedLocation, saleItemFormat, stockDeleted]
  );

  return (
    <Row gutter={[15, 10]} className='sale-row'>
      <Row gutter={[24, 12]} style={{ width: '100%', marginTop: 10, marginLeft: 0 }}>
        <Col span={24} md={10}>
          <Form.Item
            style={{ marginTop: '0px', marginBottom: '0px' }}
            label={locale.labels.name}
            rules={[requiredRule()]}
            className='long-value-form-item'
            name='id'
          >
            <StockSelect
              clinicId={clinicId}
              onInit={(id, stockItem) => setStockDetails(stockItem)}
              onChange={onStockChange}
              selectedStockId={itemToEdit?.id}
              locale={locale}
            />
          </Form.Item>
        </Col>
        <Col span={7} md={7} xs={12} xl={7}>
          <Form.Item
            label={`${locale.labels.saleItem}`}
            rules={[requiredRule()]}
            className='long-value-form-item'
          >
            <Select
              disabled={!stockDetails || stockDeleted}
              className='long-value-form-item'
              onSelect={onFormatSelect}
              id={'sale-item-format'}
              value={
                stockDetails
                  ? stockDetails?.packaging_format === 'none'
                    ? FORMATS.unit
                    : saleItemFormat
                  : null
              }
            >
              {stockDetails?.packaging_format !== 'none' && (
                <Option className='item-form-select' key='package' value={FORMATS.package}>
                  {packageFormat()}
                </Option>
              )}
              <Option className='item-form-select' key='unit' value={FORMATS.unit}>
                {unitFormat()}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7} md={7} xs={12} xl={7}>
          <Form.Item
            label={locale.labels.location}
            rules={[requiredRule(locale.messages.locationShouldBeSelected)]}
            className='long-value-form-item'
            name={['stock_location', 'id']}
          >
            <Select
              disabled={!stockDetails || stockDeleted}
              className='long-value-form-item'
              onSelect={onNewLocationSelect}
              value={selectedLocation?.id}
            >
              {locationsList}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 12]} style={{ width: '100%', marginBottom: 10, marginLeft: 0 }}>
        <Col span={8} md={8} xs={24} xl={8}>
          <Form.Item
            label={locale.labels.price}
            rules={[requiredRule(locale.messages.priceShouldBeSelected)]}
            className='long-value-form-item'
            name={['stock_price', 'id']}
            extra={quantityTooltip()}
          >
            <Select
              disabled={!stockDetails || stockDeleted}
              className='long-value-form-item'
              onSelect={onNewPriceSelect}
              value={selectedPrice?.id}
            >
              {pricesList}
            </Select>
          </Form.Item>
        </Col>
        {saleItemFormat === FORMATS.package && <QuantityInput name='quantity_in_packages' />}
        {saleItemFormat === FORMATS.unit && <QuantityInput name='quantity_in_units' />}
        <Col span={24} md={6} xl={6} xs={12}>
          <Form.Item label={locale.labels.sum} rules={[requiredRule()]} name='price'>
            <VLXInputNumber disabled={priceInputDisabled || stockDeleted} />
          </Form.Item>
        </Col>
        <Col span={24} md={5} xl={5}>
          <Form.Item label=' '>
            <Row>
              <Button
                id='add-more-stock'
                className={`icofont icofont-save`}
                disabled={!selectedLocation || !selectedPrice}
                type='primary'
                style={{ borderRadius: 30, marginRight: 12, width: 40 }}
                onClick={() => {
                  if (!residualValidate()) {
                    setFieldsTouched(false);
                    form.submit();
                  }
                }}
              ></Button>
              <Button
                onClick={() => {
                  setSelectedPrice(null);
                  setSelectedLocation(null);
                  setPriceInputDisabled(true);
                  onCancel();
                }}
                danger
                type='primary'
                shape={'circle'}
              >
                <span className='icofont icofont-ban' />
              </Button>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Row>
  );
};
