import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { useLocale } from 'hooks/useLocale';
import { useNavigate } from 'react-router-dom';

const resetPassword = async (data: any): Promise<any> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.AUTH}/password/reset`, data);
  return response.data;
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const locale = useLocale('public.modals.sign-up');

  return useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      notification.success({
        message: locale.messages.passwordResetSuccessful,
        description: locale.messages.youCanLogin
      });
      navigate('/');
    },
    onError: (error: any) => {
      notification.error({
        message: locale.messages.passwordResetFailed,
        description: error?.response?.data?.message || locale.messages.errorOccurred
      });
    }
  });
};
