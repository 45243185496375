import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { cleanParams } from 'api/utils/cleanParams';
import { transformKeysForAPI } from 'api/utils/transformKeys';
import { useMemo } from 'react';

import axiosClient from '../axiosClient';

export const useTable = <T>(
  endpoint: string,
  searchParams: Record<string, string | number | string[]>,
  queryKey: (string | number)[],
  items: number = 20
) => {
  const params = useMemo(() => {
    return cleanParams({ page: 0, items, ...searchParams });
  }, [searchParams]);

  const fetchTableData = async (): Promise<T> => {
    const { data } = await axiosClient.get<T>(endpoint, { params: transformKeysForAPI(params) });
    return data;
  };

  const query = useQuery({
    queryKey: [...queryKey, params], // When params is changed in query key, data is automatically refetched, thats why params has defaultParams, searchParams deps.
    queryFn: fetchTableData,
    enabled: !!endpoint,
    staleTime: 3000,
    placeholderData: keepPreviousData
  });

  return {
    ...query,
    data: (query.data as T) || ({ data: null, metadata: { total: 0 }, stats: {} } as T),
    params,
    isLoading: query.isFetching || query.status === 'pending'
  };
};
