import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  setWeekRange: (dateRange: [dayjs.Dayjs, dayjs.Dayjs]) => void;
  locale: ILocale;
};

export const WorkingWeekPicker = ({ setWeekRange, locale }: Props): JSX.Element => {
  const handleWeekChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const startDate = date.startOf('week');
      const endDate = date.endOf('week');
      setWeekRange([startDate, endDate]);
    } else {
      setWeekRange(null);
    }
  };

  return (
    <DatePicker
      placeholder={locale.placeholders.selectWorkingWeek}
      size='middle'
      picker='week'
      onChange={handleWeekChange}
    />
  );
};
