import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IUserProfile } from 'api/interfaces/User';
import { useLocaleCode } from 'api/store/localeContext';
import useUserStore from 'api/store/userStore';

export const fetchUserProfile = async (): Promise<IUserProfile> => {
  const { data } = await axiosClient.get<IUserProfile>(`${API_ENDPOINTS.USER}/profile`);
  return data;
};

export const useUserProfile = () => {
  const { setUser } = useUserStore();
  const { setLocale } = useLocaleCode();

  const onSuccess = (userProfile: IUserProfile): void => {
    setUser(userProfile);
    setLocale(userProfile.locale);
  };

  return useMutation({ mutationFn: fetchUserProfile, onSuccess });
};
