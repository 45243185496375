import './AppointmentServices.scss';

import { Button, Col, Divider, Form, FormInstance, Input, Row, Typography } from 'antd';
import { useAddPatientInventoryHealthRecord } from 'api/hooks/appointments/useAddPatientInventoryHealthRecord';
import {
  IAppointmentPatientSericeForm,
  IHealthRecordService,
  IStock
} from 'api/interfaces/Patient';
import VLXInputNumber from 'components/VLXInputNumber';
import { money } from 'helpers/list-helpers';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { useParams } from 'react-router-dom';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { AppointmentInventoryFormItem } from './AppointmentInventoryFormItem';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  disabled: boolean;
  onFieldsChange: (e) => void;
  setFormTouched: (e) => void;
  healthRecordId: number;
  serviceData: IHealthRecordService;
  addedItems: number[];
  setAddedItems: (e) => void;
  initialServices: IStock[];
  setInitialServices: (e) => void;
}>;

export const AppointmentServicesFormContainer = ({
  form,
  children,
  disabled,
  onFieldsChange,
  healthRecordId,
  setFormTouched,
  serviceData,
  addedItems,
  setAddedItems,
  initialServices,
  setInitialServices
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { appointmentId } = useParams();
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.appointments.event');
  const appModules = useAccess().availableModules;

  const serviceId = serviceData?.id;

  const addPatientInventoryRecord = useAddPatientInventoryHealthRecord(
    clinicId,
    +appointmentId,
    healthRecordId,
    () => setFormTouched(false)
  );

  const onSubmit = (data: IAppointmentPatientSericeForm): void => {
    setFormTouched(false);

    const payload = {
      service_id: serviceId,
      price: Number(data.price).toFixed(2),
      total_price: Number(data.total_price).toFixed(2),
      service_count: data.service_count,
      items:
        data?.stock?.map((el) => ({
          ...el,
          stock_id: el.stock.id,
          location_id: el.stock_location_id,
          price_id: el.stock_price_id,
          price_per_item: Number(el.price).toFixed(2)
        })) || []
    };

    addPatientInventoryRecord.mutate(payload);
  };

  const selectItemsMessage = (): string =>
    disabled || serviceData?.stock_saved ? locale.errors.noStockUsed : locale.errors.addItemsOrSave;

  const onServicePriceChange = (value: number): void => {
    const serviceCount = form.getFieldValue('service_count');
    const price = money(value * serviceCount);
    form.setFieldValue('total_price', price);
  };

  const onServiceQuantityChange = (value: number): void => {
    const pricePerServise = form.getFieldValue('price');
    const price = money(value * pricePerServise);
    form.setFieldValue('total_price', price);
  };

  return (
    <Form
      key={serviceData?.id + serviceData.name}
      onFieldsChange={onFieldsChange}
      disabled={disabled}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Row gutter={[20, 10]}>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='servicePrice'
            rules={[requiredRule()]}
            name='price'
            label={`${locale.labels.servicePrice} (${currencies.uah})`}
            initialValue={money(serviceData?.price)}
          >
            <VLXInputNumber disabled={disabled} onChange={onServicePriceChange} min={0.01} />
          </Form.Item>
        </Col>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='service_count'
            rules={[
              requiredRule(),
              patternRule(PATTERNS.DIGITS_1_9, locale.errors.onlyNumberAllowed)
            ]}
            name='service_count'
            label={locale.labels.count}
            initialValue={serviceData?.service_count || 1}
          >
            <VLXInputNumber
              disabled={disabled}
              precision={0}
              step={1}
              min={1}
              onChange={onServiceQuantityChange}
            />
          </Form.Item>
        </Col>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='servicePrice'
            rules={[requiredRule(locale.errors.enterRoom)]}
            name='total_price'
            label={`${locale.labels.sum} (${currencies.uah})`}
            initialValue={
              serviceData?.total_price ? money(serviceData?.total_price) : money(serviceData?.price)
            }
          >
            <Input disabled type={'number'} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      {!appModules.inventory ? (
        <Typography.Text type='secondary'>{locale.messages.inventoryInPremium}</Typography.Text>
      ) : (
        <>
          <Typography.Title style={{ marginBottom: '20px' }} level={5}>
            {locale.labels.stockItems}
          </Typography.Title>
          {!addedItems?.length ? (
            <Typography.Text type='secondary'>{selectItemsMessage()}</Typography.Text>
          ) : null}
          <Form.List name='stock' initialValue={initialServices}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <AppointmentInventoryFormItem
                    setFormTouched={setFormTouched}
                    key={key}
                    name={name}
                    initialData={initialServices[name]}
                    remove={() => {
                      setAddedItems(addedItems.filter((_el, index) => index !== name));
                      setInitialServices(initialServices.filter((_, index) => index !== name));
                      remove(name);
                    }}
                    form={form}
                    disabled={disabled}
                    setAddedItems={setAddedItems}
                  />
                ))}
                <Row>
                  <Form.Item key='add-more-stock'>
                    {!disabled && (
                      <Button
                        id='add-more-stock'
                        className='icofont icofont-plus'
                        type={'primary'}
                        style={{ borderRadius: 30, marginTop: '10px' }}
                        onClick={() => add()}
                      >
                        {locale.buttons.addNewItem}
                      </Button>
                    )}
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        </>
      )}
      {children}
    </Form>
  );
};
