import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IShiftDetails } from 'api/interfaces/Shift';
import useShiftStore from 'api/store/shiftStore';
import { consumeShiftsChannel } from 'lib/ShiftsConsumer';

type MutationProps = {
  clinicId: number;
  token?: string;
};

export const useOpenedShift = () => {
  const { setOpenedShift } = useShiftStore();

  const mutationFn = async ({ clinicId, token }: MutationProps): Promise<IShiftDetails> => {
    const { data } = await axiosClient.get<IShiftDetails>(
      `${API_ENDPOINTS.SHIFTS(clinicId)}/opened`
    );

    if (token) consumeShiftsChannel(clinicId, token, setOpenedShift);

    return data;
  };

  return useMutation({ mutationFn, onSuccess: setOpenedShift });
};
