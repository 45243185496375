import { Form } from 'antd';
import { IPatientDetails } from 'api/interfaces/Patient';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddAppointmentPatientForm } from './AddAppointmentPatientForm';

type Props = {
  clientId: number;
  appointmentId: number;
  appointmentPatients: IPatientDetails[];
};

export const AddAppointmentPatient = ({
  clientId,
  appointmentId,
  appointmentPatients
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const locale = useLocale('private.appointments.event');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      title={locale.labels.addNewPatientToAppointment}
      centered
      open
      form={form}
      onModalClose={closeModal}
      width={800}
    >
      <AddAppointmentPatientForm
        form={form}
        locale={locale}
        clientId={clientId}
        appointmentId={appointmentId}
        appointmentPatients={appointmentPatients}
        closeModal={closeModal}
      >
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.appointments}
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
        />
      </AddAppointmentPatientForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
