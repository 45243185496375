import '../Finances.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import { useBillsList } from 'api/hooks/bills/useBillsList';
import { IBill } from 'api/interfaces/Bills';
import { MODAL, useModal } from 'api/store/modalStore';
import { StatusTag } from 'components/StatusTag';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddBillModal } from 'layout/modals/finances/addBill/AddBillModal';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import FinanceIcon from '../helpers/FinanceIcon';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

const BillsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;
  const { open, isOpened } = useModal();

  const { bills, data, isPending, params, updateParams, updateSorting } = useBillsList(
    clinicId,
    defaultParams
  );

  const redirectToBillDetails = (record: IBill): { onClick: () => void } => ({
    onClick: () => navigate(`/clinic/${clinicId}/finances/bills/${record.id}`)
  });

  const columns = useMemo(
    (): TableColumnProps<IBill>[] => [
      {
        key: 'bill_type',
        dataIndex: 'bill_type',
        title: '',
        sorter: () => null,
        render: (bill_type) => <FinanceIcon type={bill_type} />,
        onCell: redirectToBillDetails
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name) => name,
        onCell: redirectToBillDetails
      },
      {
        key: 'id',
        dataIndex: 'id',
        title: locale.labels.id,
        sorter: () => null,
        render: (_name, e) => e.id,
        onCell: redirectToBillDetails
      },
      {
        key: 'category',
        dataIndex: 'category',
        title: locale.labels.category,
        sorter: () => null,
        render: (category) => category ?? '-',
        onCell: redirectToBillDetails
      },
      {
        key: 'date',
        dataIndex: 'date',
        title: locale.labels.date,
        sorter: () => null,
        render: (date) => `${date ? dayjs(date).local().format('DD.MM.YYYY HH:mm') : '-'}`,
        onCell: redirectToBillDetails
      },
      {
        key: 'final_amount',
        dataIndex: 'final_amount',
        title: locale.labels.amount,
        sorter: () => null,
        render: (total_paid_amount) =>
          total_paid_amount !== null ? `${money(total_paid_amount)} ${currencies.uah}` : '-',
        onCell: redirectToBillDetails
      },
      {
        key: 'status',
        title: locale.labels.paymentStatus,
        sorter: () => null,
        render: (e) => <StatusTag status={e.payment_status} />,
        onCell: redirectToBillDetails
      },
      {
        key: 'remaining_amount',
        dataIndex: 'remaining_amount',
        title: locale.labels.remainingAmount,
        sorter: () => null,
        render: (remaining_amount) =>
          remaining_amount !== null ? `${money(remaining_amount)} ${currencies.uah}` : '-',
        onCell: redirectToBillDetails
      }
      // {
      //   key: 'fiscal',
      //   dataIndex: 'fiscal',
      //   title: locale.labels.fiscal,
      //   sorter: () => null,
      //   render: (fiscal, e) => (!fiscal ? locale.labels.no : locale.labels.yes),
      //   onCell: redirectToBillDetails
      // }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Spin spinning={isPending} className='bills-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header bills-list-table'
          rowKey='id'
          onChange={updateSorting}
          dataSource={bills?.filter((item) => item.final_amount !== null)}
          columns={columns}
          rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        />
      </Spin>
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addBillModal)}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addBill}
        />
      </Row>
      {isOpened(MODAL.addBillModal) && <AddBillModal />}
    </>
  );
};

export default BillsList;
