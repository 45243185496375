import { Checkbox, Form, FormInstance, Input, Select, Spin } from 'antd';
import { useUpdateEvent } from 'api/hooks/calendarEvents/useUpdateEvent';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { IEventDetails } from 'api/interfaces/CalendarEvent';
import { useModal } from 'api/store/modalStore';
import useUserStore from 'api/store/userStore';
import { AllDayPicker } from 'components/AllDayPicker';
import DateRangePicker from 'components/DateRangePicker';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useEffect, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { EmployeesSelect } from '../../components/employeesSelect/EmployeesSelect';

const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = React.PropsWithChildren<{
  initialValues: IEventDetails;
  form: FormInstance;
  children: React.ReactNode;
  closeEventModal: () => void;
}>;

export const EditEventForm = ({
  form,
  initialValues,
  closeEventModal,
  children
}: Props): JSX.Element => {
  const { closeModal } = useModal();
  const clinicId = useClinicId();
  const locale = useLocale('private.appointments.event');
  const locations = useLocalizedList(locationTypes);

  const userId = useUserStore(({ user }) => user.id);

  const { rooms } = useRoomsList(clinicId);
  const editEvent = useUpdateEvent(initialValues.id, () => {
    closeEventModal();
    closeModal();
  });

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);
  const [locationType, setLocationType] = useState<string>(initialValues?.location?.type);
  const [allDayChecked, setAllDayChecked] = useState(initialValues?.all_day);
  useEffect(() => {
    const { all_day, start_time, end_time, date_range } = initialValues || {};

    if (all_day) {
      form.setFieldValue('date_range', date_range || [dayjs(start_time), dayjs(end_time)]);
    }
    if (start_time) {
      const eventDuration = dayjs(dayjs(end_time).diff(dayjs(start_time)) - 10800000);
      setStartDate(dayjs(start_time));
      setEndDate(dayjs(end_time));
      setDuration(dayjs(eventDuration));
      form.setFieldValue('date_from', dayjs(start_time));
      form.setFieldValue('time_from', dayjs(start_time));
      form.setFieldValue('end_time', dayjs(end_time));
      form.setFieldValue('duration', dayjs(eventDuration));
    }
    setLocationType(initialValues?.location?.type);
  }, [initialValues]);

  const onSubmit = (data: any): void => {
    const payload = {
      ...data,
      clinic_id: clinicId
    };
    if (data.all_day) {
      payload.start_time = data.date_range[0].tz('utc').format(DATE_TIME_FORMAT);
      payload.end_time = data.date_range[1].add(1, 'm').tz('utc').format(DATE_TIME_FORMAT);
    } else {
      payload.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
      payload.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);
    }

    delete payload.date_from;
    delete payload.time_from;
    delete payload.duration;
    delete payload.date_range;
    editEvent.mutate(payload);
  };

  const roomsList = rooms
    .filter((room) => !room.deleted)
    .map((item) => (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        {item.name}
      </Option>
    ));

  const mapValues = () => {
    if (initialValues?.attendees?.length) {
      return { ...initialValues, attendees: initialValues.attendees.map((el) => el.user_id) };
    }
    return initialValues;
  };

  return (
    <Spin spinning={editEvent.isPending}>
      <Form
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={mapValues()}
      >
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label={locale.labels.title}
              name='title'
              rules={[
                requiredRule(locale.messages.mandatoryField),
                patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
              ]}
            >
              <Input hidden />
            </Form.Item>
            <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.attendees}
              name='attendees'
            >
              <EmployeesSelect selectedDoctorId={userId} />
            </Form.Item>
            <Form.Item name='all_day' label={locale.labels.allDay} valuePropName='checked'>
              <Checkbox onChange={(e) => setAllDayChecked(e.target.checked)} />
            </Form.Item>
            {!allDayChecked && (
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                duration={duration}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDuration={setDuration}
              />
            )}
            {allDayChecked && <AllDayPicker />}
            <Form.Item label={''}>
              <Form.Item
                className='add-equipment-form__single-select-field'
                name={['location', 'type']}
                label={locale.labels.location}
                initialValue={'room'}
                rules={[requiredRule(locale.errors.enterLocationType)]}
              >
                <Select filterOption={false} onSelect={setLocationType} options={locations} />
              </Form.Item>
              <Form.Item
                name={['location', 'details']}
                label={locale.labels.details}
                hidden={locationType !== 'other'}
                rules={
                  locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['location', 'id']}
                className='add-equipment-form__single-select-field'
                hidden={locationType !== 'room'}
                label={locale.labels.room}
                rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
              >
                <Select
                  filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {roomsList}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <TextEditor label={locale.labels.notes} name={['notes']} />
          </div>
        </div>
        {children}
      </Form>
    </Spin>
  );
};
