import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IShiftList } from 'api/interfaces/Shift';

import { useTableData } from '../useTableData';

export const useShiftsList = (
  clinicId: number,
  defaultParams?: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.SHIFTS(clinicId);
  const result = useTableData<IShiftList>(endpoint, defaultParams, ['shiftsList', clinicId]);

  return {
    ...result,
    shifts: result.data.data || []
  };
};
