import { Button, Form, Input, Modal, Spin } from 'antd';
import { useChangePhone } from 'api/hooks/user/useChangePhone';
import useUserStore from 'api/store/userStore';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';

import MessageModal from '../components/messageModal/MessageModal';

export const ChangeUserPhoneNumber = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const locale = useLocale('private.user.user-profile');
  const { setUser, user } = useUserStore((store) => store);

  const onSuccess = (phone: string): void => {
    MessageModal('success', locale.messages.phoneNumberChanged);
    setUser({ ...user, phone_number: phone });
    setVisible(false);
  };

  const onError = (): void => {
    form.setFields([{ name: 'password', errors: [locale.messages.wrongPassword] }]);
  };

  const changePhone = useChangePhone(onSuccess, onError);

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changePhone}
      </Button>
      <Modal
        title={locale.labels.changePhoneNumberTitle}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
        destroyOnClose
      >
        <Spin spinning={changePhone.isPending}>
          <Form form={form} onFinish={changePhone.mutate} autoComplete='off' layout='vertical'>
            <Form.Item
              label={locale.labels.password}
              name='password'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Input.Password />
            </Form.Item>
            <PhoneFieldFormItem />
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
