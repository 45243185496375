import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { SorterResult } from 'antd/es/table/interface';
import { cleanParams } from 'api/utils/cleanParams';
import { createSearchParams } from 'api/utils/createSearchParams';
import { transformKeysForAPI, transformKeysForState } from 'api/utils/transformKeys';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import axiosClient from '../axiosClient';

export const useTableData = <T>(
  endpoint: string,
  defaultParams: Record<string, string | number>,
  queryKey: (string | number)[],
  invokeImmidiately?: boolean // If we don't use it for the VLXSearches, we ingore searhParams
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(invokeImmidiately);
  }, [invokeImmidiately]);

  const params = useMemo(() => {
    const transformedParams = invokeImmidiately
      ? {}
      : transformKeysForState(Object.fromEntries(searchParams.entries()));

    return cleanParams({ ...defaultParams, ...transformedParams });
  }, [defaultParams, searchParams]);

  const fetchTableData = async (): Promise<T> => {
    const transformedParams = transformKeysForAPI(params);

    const { data } = await axiosClient.get<T>(endpoint, { params: transformedParams });
    return data;
  };

  const setSorting = (params): Record<string, string | number | string[]> => {
    if (!defaultParams) return params;

    if (!params.sort_order) {
      params.sort_order = defaultParams.sort_order;
      params.sort_key = defaultParams.sort_key;
    }

    params.sort_order = params.sort_order.includes('asc') ? 'asc' : 'desc';
    return params;
  };

  const updateParams = (newParams?: Record<string, string | number | string[]>): void => {
    const isReset = !newParams; // Reset filters sends newParams === null, in this case we set default params.
    const updatedParams = cleanParams(
      isReset ? defaultParams : transformKeysForAPI({ ...params, ...newParams })
    );
    setSorting(updatedParams);

    setSearchParams(createSearchParams(updatedParams));
    setEnabled(true);
  };

  const query = useQuery({
    queryKey: [...queryKey, params], // When params is changed in query key, data is automatically refetched, thats why params has defaultParams, searchParams deps.
    queryFn: fetchTableData,
    enabled: enabled && !!endpoint,
    staleTime: 3000,
    placeholderData: keepPreviousData
  });

  return {
    ...query,
    data: (query.data as T) || ({ data: null, metadata: { total: 0 }, stats: {} } as T),
    params,
    updateParams, // this method is passed to VLXSearch to change searchQuery and trigger fetch.
    updateSorting: <S>(pagination, _filters, sorter: SorterResult<S> | SorterResult<S>[]) => {
      updateParams({
        page: pagination.current,
        sort_key: ((sorter as SorterResult<S>).columnKey as string) || params.sort_key,
        sort_order: (sorter as SorterResult<S>).order
      });
    },
    isLoading: query.isFetching || query.status === 'pending'
  };
};
