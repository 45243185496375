import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClinicRegistrationRequestPayload } from 'api/interfaces/Clinic';

const mutationFn = async (data: IClinicRegistrationRequestPayload): Promise<void> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.ORGANISATION_REQ}`, data);
  return response.data;
};

export const useClinicRegistrationRequest = (onSuccess?: () => void) =>
  useMutation({
    mutationFn,
    onSuccess,
    onError: (error: any) => {
      notification.error({
        message: 'Registration Failed',
        description: error?.response?.data?.message || 'An error occurred.'
      });
    }
  });
