import { IPatient, IPatientDetails } from 'api/interfaces/Patient';
import animalsList from 'constants/dictionary/animals';

export const localizedPetData = (
  data: IPatient | IPatientDetails,
  localeCode: string,
  speciesOnly?: boolean
): Record<string, string> => {
  const obj = {
    color: '',
    coat: '',
    breed: '',
    species: ''
  };
  if (!data?.species) return obj;

  const species = animalsList.find((el) => el.value === data.species);

  obj.species = species?.[localeCode];

  if (!species || speciesOnly) return obj;

  obj.breed = species.breeds.find((el) => el.value === data.breed)?.[localeCode] ?? '';
  obj.coat = species.coats?.find((el) => el.value === data.fur)?.[localeCode] ?? '';
  obj.color = species.colors?.find((el) => el.value === data.color)?.[localeCode] ?? '';

  return obj;
};

export const localizeList = (list: any, localeCode: string) => {
  return list.map((el) => ({ ...el, label: el[localeCode] }));
};

export const getLocalisedLabel = (
  locale: Record<string, any>[],
  value: string | boolean,
  localeCode: string
): string => locale.find((el) => el.value === value)?.[localeCode] ?? '';
