import { IMetadata } from './Metadata';

export interface IRoom {
  id: number;
  name: string;
  occupied: boolean;
  clinic_id: number;
  created_at: string;
  updated_at: string;
  deleted: boolean;
  location: string;
  status: ROOM_STATUSES;
  not_for_appointment: boolean;
  equipment: string[];
}

export interface IRoomList {
  data: IRoom[];
  metadata: IMetadata;
}

export type IRoomPayload = Pick<IRoom, 'location' | 'name' | 'not_for_appointment' | 'status'>;

export enum ROOM_STATUSES {
  maintenance = 'maintenance',
  closed = 'closed',
  active = 'active'
}
