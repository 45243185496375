import { Col } from 'antd';
import { IUser } from 'api/interfaces/User';
import { localDateTime } from 'helpers/DateTimeUtils';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

type Props = {
  deleted_at: string;
  deleted_by: IUser;
};

export const DeletedInfo = ({ deleted_at, deleted_by }: Props): JSX.Element => {
  const locale = useLocale('private.global-components.deleted-info');

  return (
    <>
      <Col>
        <span style={{ fontWeight: 600 }}>{locale.labels.deletedBy}: </span>
        <span id='deleted_by'>
          {`${deleted_by?.name} ${deleted_by?.title ? `(${deleted_by?.title})` : ''}`}
        </span>
      </Col>
      <Col>
        <span style={{ fontWeight: 600 }}>{locale.labels.deletedAt}: </span>
        <span id='deleted_at'>{localDateTime(deleted_at)}</span>
      </Col>
    </>
  );
};
