import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { GenerateVaccinationReportForm } from './GenerateVaccinationReportForm';

export const GenerateVaccinationReport = (): JSX.Element => {
  const userAccess = useAccess();
  const { closeModal } = useModal();
  const locale = useLocale('private.patients.patient-page');
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.generateVaccinationReport}
        form={form}
        centered
        open
        onModalClose={closeModal}
      >
        <GenerateVaccinationReportForm locale={locale} form={form}>
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.patients}
            fixed
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
            }}
          />
        </GenerateVaccinationReportForm>
      </VLXModal>
      <ConfirmCancelModal
        defZindex={9999}
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
