import { Form, FormInstance, Select } from 'antd';
import { useClientBalanceReplenishment } from 'api/hooks/clients/useClientBalanceReplenishment';
import { useBankAccountsList } from 'api/hooks/finances/useBankAccountsList';
import { IReplenishmentBalance } from 'api/interfaces/Client';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useClientId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import React, { useMemo } from 'react';
import { requiredRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
}>;

export const ReplenishmentPopupForm = ({ form, children, locale }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const clientId = useClientId();
  const currencies = useLocale('private.currencies').labels;

  const { bankAccounts } = useBankAccountsList(clinicId);
  const replenishBalance = useClientBalanceReplenishment(clinicId, clientId);

  const onSubmit = (data: IReplenishmentBalance): void => {
    replenishBalance.mutate(data);
  };

  const accountList = useMemo(
    () =>
      bankAccounts.map((item) => (
        <Option key={item.name} title={item.name} value={item.id}>
          {item?.name} ({item?.current_balance} {currencies.uah})
        </Option>
      )),
    [bankAccounts]
  );

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item
        rules={[requiredRule()]}
        className='add-equipment-form__multi-select-field'
        label={locale.labels.account}
        name='account_id'
      >
        <Select
          style={{ overflow: 'hidden' }}
          filterOption={(input, option) =>
            option?.title.toString().toLowerCase().includes(input?.toLowerCase())
          }
          showSearch
        >
          {accountList}
        </Select>
      </Form.Item>
      <Form.Item label={locale.labels.sum} rules={[requiredRule()]} name='amount'>
        <VLXInputNumber />
      </Form.Item>
      {children}
    </Form>
  );
};
