import '../scss/StockDetails.scss';

import { Col, Row } from 'antd';
import { IStock } from 'api/interfaces/Stock';
import { TextEditorView } from 'components/TextEditorView';
import { Title } from 'components/Title';
import { ILocale } from 'interfaces/locale';
import React from 'react';

import { usePlural } from '../utils/usePlural';

type Props = {
  stock: IStock;
  locale: ILocale;
};

export const StockDetailsHeader = ({ stock, locale }: Props): JSX.Element => {
  const { unitFormat, packageFormat } = usePlural(stock);
  return (
    <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)', marginBottom: 20 }}>
      <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
        <Row>
          <Col span={24} md={12} className='appointment-details-top-container__left-column'>
            <Col>
              <Title title={`${locale.labels.manufacturer}: `} />
              <span id='manufacturer'>{stock?.manufacturer || '-'}</span>
            </Col>
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.stockType}: `} />*/}
            {/*  <span id='stock-type'>{stock?.type || '-'}</span>*/}
            {/*</Col>*/}
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.category}: `} />*/}
            {/*  <span id='stock-category'>{stock?.category || '-'}</span>*/}
            {/*</Col>*/}
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.subCategory}: `} />*/}
            {/*  <span id='stock-sub-category'>{stock?.sub_category || '-'}</span>*/}
            {/*</Col>*/}
            <Col>
              <Title title={`${locale.labels.uniqueCode}: `} />
              <span id='unique-code'>{stock?.unique_code || '-'}</span>
            </Col>
            <Col>
              <Title title={`${locale.labels.barcode}: `} />
              <span id='barcode'>{stock?.barcode || '-'}</span>
            </Col>
          </Col>
          <Col
            span={24}
            md={12}
            className='appointment-details-top-container__right-column'
            style={{ marginTop: 0 }}
          >
            <Col>
              <Title title={`${locale.labels.usageMeasurement}: `} />
              <span id='packaging-format'>{unitFormat()}</span>
            </Col>
            <Col>
              <Title title={`${locale.labels.packagingFormat}: `} />
              <span id='packaging-format'>{packageFormat()}</span>
            </Col>
            {stock.packaging_format !== 'none' && (
              <Col>
                <Title title={`${locale.labels.quantityPerPackage}: `} />
                <span id='quantity-per-package'>{stock?.quantity_per_package || '-'} </span>
                <span id='measumenent-unit'>{unitFormat(stock?.residual)}</span>
              </Col>
            )}
            <Col>
              {stock?.reorder_point !== null && (
                <>
                  <Title title={`${locale.labels.reorderPoint}: `} />
                  <span id='reorder-point'>{stock?.reorder_point}</span>{' '}
                  <span id='measumenent-unit'>{unitFormat()}</span>
                </>
              )}
            </Col>
          </Col>
        </Row>
        <Row>
          <Title title={`${locale.labels.description}: `} />
          <TextEditorView value={stock?.description} id='description' />
        </Row>
        <Row>
          {stock?.ingredients && (
            <>
              <Title title={`${locale.labels.ingredients}: `} />
              <TextEditorView value={stock?.ingredients} id='ingredients' />
            </>
          )}
        </Row>
        <Row>
          <Title title={`${locale.labels.instructions}: `} />
          <TextEditorView value={stock?.instructions} id='instructions' />
        </Row>
        <Row>
          <Title title={`${locale.labels.notes}: `} />
          <TextEditorView value={stock?.notes} id='notes' />
        </Row>
      </div>
    </div>
  );
};
