import { Checkbox, Form, Input, Select, Spin } from 'antd';
import { useCreateEvent } from 'api/hooks/calendarEvents/useCreateEvent';
import useUserStore from 'api/store/userStore';
import { AllDayPicker } from 'components/AllDayPicker';
import DateRangePicker from 'components/DateRangePicker';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useEffect, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { EmployeesSelect } from '../../components/employeesSelect/EmployeesSelect';
import { RoomsSelect } from '../../components/roomsSelect/RoomsSelect';

dayjs.extend(utc);
dayjs.extend(timezone);

export const AddEventForm = ({
  form,
  closeModal,
  initialValues,
  children,
  locale,
  eventTypeSelect
}: any): JSX.Element => {
  const clinicId = useClinicId();

  const userId = useUserStore(({ user }) => user.id);
  const createEvent = useCreateEvent(clinicId, closeModal);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);
  const [locationType, setLocationType] = useState<string>('room');
  const [allDayChecked, setAllDayChecked] = useState(initialValues?.allDay);

  useEffect(() => {
    form.resetFields();

    if (initialValues?.start_time) {
      form.setFieldValue('all_day', initialValues.allDay);
      form.setFieldValue('date_range', [
        dayjs(initialValues.start_time),
        dayjs(initialValues.start_time)
      ]);

      form.setFieldValue('attendees', initialValues.attendees);
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(initialValues?.duration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(initialValues?.duration));
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
    if (initialValues?.doctor_id && initialValues?.doctor_id !== userId) {
      form.setFieldValue('attendees', [initialValues.doctor_id]);
    }
  }, [initialValues]);

  const onSubmit = (payload: any): void => {
    if (payload.all_day) {
      payload.start_time = payload.date_range[0].tz('utc').format(DATE_TIME_FORMAT);
      payload.end_time = payload.date_range[1].add(1, 'm').tz('utc').format(DATE_TIME_FORMAT);
    } else {
      payload.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
      payload.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);
    }
    delete payload.date_from;
    delete payload.date_range;
    delete payload.time_from;
    delete payload.duration;

    createEvent.mutate(payload);
  };

  const onLocationTypeSelect = (value: string): void => {
    setLocationType(value);
  };

  const locations = useLocalizedList(locationTypes);

  return (
    <>
      <Spin spinning={createEvent.isPending}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
          <div className='modal-content'>
              {eventTypeSelect}
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <Form.Item name='clinic_id' initialValue={clinicId} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={locale.labels.title}
                    name='title'
                    rules={[
                      requiredRule(locale.messages.mandatoryField),
                      patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
                    ]}
                  >
                    <Input hidden={true} />
                  </Form.Item>
                  <Form.Item label={locale.labels.attendees} name='attendees'>
                    <EmployeesSelect selectedDoctorId={userId} />
                  </Form.Item>
                  <Form.Item name='all_day' label={locale.labels.allDay} valuePropName='checked'>
                    <Checkbox onChange={(e) => setAllDayChecked(e.target.checked)} />
                  </Form.Item>
                  {!allDayChecked && (
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      duration={duration}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      setDuration={setDuration}
                    />
                  )}
                  {allDayChecked && <AllDayPicker />}
                  <Form.Item label={''}>
                    <Form.Item
                      className='add-equipment-form__single-select-field'
                      name={['location', 'type']}
                      label={locale.labels.location}
                      initialValue={'room'}
                      rules={[requiredRule(locale.errors.enterLocationType)]}
                    >
                      <Select
                        filterOption={false}
                        onSelect={onLocationTypeSelect}
                        options={locations}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'details']}
                      label={locale.labels.details}
                      hidden={locationType !== 'other'}
                      rules={
                        locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'id']}
                      className='add-equipment-form__single-select-field'
                      hidden={locationType !== 'room'}
                      label={locale.labels.room}
                      rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
                    >
                      <RoomsSelect />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <TextEditor label={locale.labels.notes} name={['notes']} />
                </div>
              </div>
            </div>
            {children}
          </Form>
      </Spin>
    </>
  );
};
