import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import React, { useState } from 'react';

import { ServiceItemForm } from './ServiceItemForm';

type Props = {
  title: string;
};

export const AddServiceModal = ({ title }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useAccess();

  return (
    <VLXModal title={title} form={form} centered open onModalClose={closeModal} width={800}>
      <ServiceItemForm form={form}>
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.services}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </ServiceItemForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
