import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEvent } from 'api/interfaces/CalendarEvent';

interface QueryParams {
  end_time_from: string;
  start_time_to: string;
}

export const useFetchEvents = (
  clinicId: number,
  params: QueryParams
): UseQueryResult<IEvent[], Error> => {
  const fetchEvents = async (): Promise<IEvent[]> => {
    const endpoint = API_ENDPOINTS.CALENDAR_EVENTS(clinicId);
    const { data } = await axiosClient.get<IEvent[]>(endpoint, {
      params: {
        'q[end_time_from]': params.end_time_from,
        'q[start_time_to]': params.start_time_to
      }
    });
    return data;
  };

  return useQuery<IEvent[], Error>({
    queryKey: ['calendar-events', clinicId, params],
    queryFn: fetchEvents,
    enabled: !!params,
    staleTime: 3000
  });
};
