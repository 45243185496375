import './UserRegistration.scss';

import { Button, Col, Form, Input, InputNumber, Row, Spin } from 'antd';
import { useClinicRegistrationRequest } from 'api/hooks/clinic/useClinicRegistrationRequest';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { useLocale } from 'hooks/useLocale';
import * as ls from 'local-storage';
import React, { useEffect } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import MessageModal from '../messageModal/MessageModal';

const { TextArea } = Input;

const RegistrationRequestForm = ({ closeModal, form }): JSX.Element => {
  const locale = useLocale('public.modals.registration-request');
  const { labels, messages, buttons } = locale;

  const onSuccess = () => {
    closeModal();
    MessageModal('success', messages.requestSuccess);
  };
  const registration = useClinicRegistrationRequest(onSuccess);

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Spin spinning={registration.isPending}>
      <Form form={form} onFinish={registration.mutate} autoComplete='off' layout='vertical'>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.organisationName}
              name='organization_name'
              rules={[requiredRule(messages.organisationNameEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.country}
              name='country'
              rules={[requiredRule(messages.countryEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={labels.city} name='city'>
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.numberOfEmployees}
              name='number_of_employees'
              rules={[requiredRule(messages.numberOfEmployeesEmpty)]}
            >
              <InputNumber placeholder='0' type='number' precision={0} step={1} />
            </Form.Item>
            <Form.Item label={labels.questions} name='comments'>
              <TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.firstName}
              name='first_name'
              rules={[
                requiredRule(messages.firstNameEmpty),
                patternRule(PATTERNS.NAME, messages.firstNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.middleName}
              name='middle_name'
              rules={[patternRule(PATTERNS.NAME, messages.firstNameNotValid)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.lastName}
              name='last_name'
              rules={[
                requiredRule(messages.lastNameEmpty),
                patternRule(PATTERNS.NAME, messages.lastNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.title}
              name='title'
              rules={[requiredRule(messages.titleEmpty)]}
            >
              <Input />
            </Form.Item>
            <PhoneFieldFormItem />
            <Form.Item
              label={labels.email}
              name='email'
              rules={[
                requiredRule(messages.emailNotValid),
                patternRule(PATTERNS.EMAIL, messages.emailNotValid)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item hidden={true} name='locale' initialValue={ls.get('locale')}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default RegistrationRequestForm;
