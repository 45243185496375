import './ClientDetails.scss';

import { Button, Card, Col, Menu, MenuProps, Row, Spin, Typography } from 'antd';
import { useClientDetails } from 'api/hooks/clients/useClientDetails';
import { useClientDocuments } from 'api/hooks/documents/useDocuments';
import { useUploadClientDocument } from 'api/hooks/documents/useUploadDocument';
import { useClientPatientsList } from 'api/hooks/patients/useClientPatientsList';
import { usePatientDetails } from 'api/hooks/patients/usePatientDetails';
import { ICreatePatientFormData } from 'api/interfaces/Patient';
import { MODAL, useModal } from 'api/store/modalStore';
import { ClientHistory } from 'components/ClientHistory';
import DocumentsTab from 'components/DocumentsTab';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useClientId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import { AddPatientModal } from 'layout/modals/patient/AddPatientModal';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';
import { ClientDetailsOverview } from './ClientDetailsOverview';
import { ClientDetailsPetsTab } from './ClientDetailsPetsTab';
import { ClientPatientsList } from './ClientPatientsList';

const ClientDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const clientId = useClientId();
  const { availableModules, accesses } = useAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPatientId = searchParams.get('patient');
  const activeTabSearchParam = searchParams.get('tab');
  const { open, isOpened } = useModal();

  const { data: client, isPending } = useClientDetails(clinicId, clientId);
  const { data: clientPatients, isPending: clientPatientsLoading } = useClientPatientsList(
    clinicId,
    clientId
  );
  const { data: selectedPatient } = usePatientDetails(clinicId, +currentPatientId);
  const { data: clientDocuments } = useClientDocuments(clinicId, clientId);
  const uploadDocument = useUploadClientDocument(clinicId, clientId);
  const locale = useLocale('private.clients.client');

  useEffect(() => {
    if (!clientPatients?.data?.length) {
      updateSearchParams(activeTabSearchParam, currentPatientId ?? '');
      return;
    }

    if (currentPatientId) {
      const petId = clientPatients?.data.find(({ id }) => id === +currentPatientId)?.id;
      updateSearchParams(activeTabSearchParam, petId?.toString());
    } else {
      updateSearchParams(activeTabSearchParam, clientPatients?.data[0]?.id?.toString());
    }
  }, [clientPatients]);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.pets,
      key: 'pets'
    },
    {
      label: (
        <PremiumSubscriptionTooltip display={!availableModules.documents}>
          {locale.tabs.documents}
        </PremiumSubscriptionTooltip>
      ),
      key: 'documents',
      disabled: !availableModules.documents
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const updateSearchParams = (tab?: string, patient?: string) => {
    const params: { tab?: string, patient?: string } = {
      tab: tab ?? activeTabSearchParam ?? 'pets'
    };
    if (patient) params.patient = patient;
    setSearchParams(params);
  };

  const changeTab: MenuProps['onClick'] = (e): void => {
    updateSearchParams(e.key, currentPatientId);
  };

  const clientNoPets = (): JSX.Element => (
    <Col className='client-details__no-pets-list-container'>
      <Typography>{locale.labels.clientNoPets}</Typography>
      <Button
        type='primary'
        onClick={() => open(MODAL.addPatientModal)}
        className='icofont icofont-plus'
        style={{ borderRadius: 30 }}
      >
        {locale.buttons.addNewPet}
      </Button>
    </Col>
  );

  return (
    <Spin spinning={isPending}>
      <Row className='client-details'>
        <Col span={24} md={10} lg={7} style={{ padding: '0 12px' }}>
          <ClientDetailsOverview
            clinicId={clinicId}
            clientId={clientId}
            data={client}
            locale={locale}
          />
        </Col>
        <Col span={24} md={14} lg={17} style={{ padding: '0 12px' }}>
          <Card
            className='ant-card-bordered personal-info-card with-shadow'
            id='client-pets-history-card'
          >
            <Menu onClick={changeTab} selectedKeys={[activeTabSearchParam]} mode='horizontal' items={items} />
            {activeTabSearchParam === 'pets' && (
              <Spin spinning={clientPatientsLoading}>
                {clientPatients?.data.length ? (
                  <Row>
                    <ClientPatientsList
                      clientPatients={clientPatients.data}
                      selectedPatientId={selectedPatient?.id}
                      setSelectedPatient={id => updateSearchParams(activeTabSearchParam, id)}
                      addNewPetAction={() => open(MODAL.addPatientModal)}
                    />
                    <Col span={24} lg={14} className='client-details__patient-details'>
                      {!!selectedPatient && (
                        <ClientDetailsPetsTab
                          accessGroup={accesses.clinics.edit}
                          data={selectedPatient}
                        />
                      )}
                    </Col>
                  </Row>
                ) : (
                  clientNoPets()
                )}
              </Spin>
            )}
            {activeTabSearchParam === 'documents' && (
              <Row className='documents-tab'>
                <Col span={24} lg={24} className='client-details__patient-details'>
                  <DocumentsTab data={clientDocuments} uploadDocument={uploadDocument} />
                </Col>
              </Row>
            )}
            {activeTabSearchParam === 'history' && (
              <Row className='history-tab'>
                <Col span={24} lg={24} className='client-details__patient-details'>
                  <ClientHistory clientId={clientId} clinicId={clinicId} />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      {isOpened(MODAL.addPatientModal) && (
        <AddPatientModal initialValues={{ owner: client?.id } as ICreatePatientFormData} />
      )}
    </Spin>
  );
};

export default ClientDetails;
