import { Column } from '@ant-design/charts';
import { IEmployeesProfit } from 'api/interfaces/Stats';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  employees_profit?: IEmployeesProfit[];
  locale: ILocale;
};

export const ShiftBilledAmountByEmployeeColumnChart = ({
  employees_profit,
  locale
}: Props): JSX.Element => {
  return (
    <Column
      title={locale.labels.billedByEmployees}
      data={employees_profit || []}
      isStack={false}
      xField='user_name'
      yField='amount'
      colorField='type'
      seriesField='type'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
