import './ServicesList.scss';

import { Pagination, Row, Table, TableColumnProps } from 'antd';
import { useServicesList } from 'api/hooks/services/useServicesList';
import { IService } from 'api/interfaces/Service';
import { MODAL, useModal } from 'api/store/modalStore';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddServiceModal } from 'layout/modals/addService/AddServiceModal';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const getDuration = (duration: number, durations: Record<string, string>): string => {
  if (duration && duration > 0) {
    const minutes = duration % 60 ? (duration % 60) + durations.m : '';
    const hours =
      (duration - (duration % 60)) / 60 ? (duration - (duration % 60)) / 60 + durations.h : '';
    return hours + ' ' + minutes;
  }

  return '-';
};

const defaultParams = {
  page: 1,
  sort_key: 'name',
  sort_order: 'asc',
  'q[deleted]': 'false'
};

const ServicesList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { open, isOpened } = useModal();
  const locale = useLocale('private.services.services-list');
  const currencies = useLocale('private.currencies').labels;
  const durations = useLocale('private.duration').labels;

  const { services, data, isLoading, params, updateParams, updateSorting } = useServicesList(
    clinicId,
    defaultParams,
    false
  );

  const redirectToServicsDetails = (record: IService) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/services/${record.id}`);
    }
  });

  const columns: TableColumnProps<IService>[] = useMemo(
    () => [
      {
        key: 'code',
        dataIndex: 'code',
        title: locale.labels.code,
        sorter: () => null,
        render: (code) => code || '-',
        onCell: redirectToServicsDetails
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name) => name,
        onCell: redirectToServicsDetails
      },
      {
        key: 'group',
        dataIndex: 'group',
        title: locale.labels.group,
        sorter: () => null,
        render: (group) => group ?? '-',
        onCell: redirectToServicsDetails
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        title: locale.labels.duration,
        sorter: () => null,
        render: (duration) => getDuration(duration, durations),
        onCell: redirectToServicsDetails
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: locale.labels.price,
        sorter: () => null,
        render: (price) => (price ? `${money(price)} ${currencies.uah}` : '-'),
        onCell: redirectToServicsDetails
      }
    ],
    [services]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />

      <Table<IService>
        pagination={false}
        className='accent-header services-list-table'
        rowKey='id'
        loading={isLoading}
        dataSource={services}
        columns={columns}
        onChange={updateSorting}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          current={+params.page}
          total={data?.metadata.total || 0}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
      </Row>
      {isOpened(MODAL.addServiceModal) && <AddServiceModal title={locale.labels.createService} />}
      <StickyButton
        offsetBottom={10}
        onClick={() => open(MODAL.addServiceModal)}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addService}
      />
    </>
  );
};

export default ServicesList;
