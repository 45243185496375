import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import { useCustomMutation } from '../useCustomMutation';

const forgotPassword = async (payload: { email: string }): Promise<void> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.AUTH}/password/forgot`, payload);
  return response.data;
};

export const useForgotPassword = (callbackFn?: () => void) =>
  useCustomMutation(forgotPassword, null, callbackFn);
