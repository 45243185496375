import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientsList } from 'api/interfaces/Patient';

import { useTableData } from '../useTableData';

export const usePatientsList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.PATIENTS(clinicId);
  const result = useTableData<IPatientsList>(endpoint, defaultParams, ['patientsList', clinicId]);

  return {
    ...result,
    patients: result.data.data
  };
};
