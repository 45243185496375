import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPayment } from 'api/interfaces/Payment';

export const usePaymentDetails = (clinicId: number, paymentId: number) => {
  return useQuery<IPayment>({
    queryKey: ['paymentDetails', clinicId, paymentId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.PAYMENTS(clinicId)}/${paymentId}`);
      return data;
    },
    enabled: !!paymentId
  });
};
