import { Col, Row } from 'antd';
import { IBillDetails } from 'api/interfaces/Bills';
import { DeletedInfo } from 'components/EntityDetails/DeletedInfo';
import { localDateTime } from 'helpers/DateTimeUtils';
import { money } from 'helpers/list-helpers';
import { ILocale } from 'interfaces/locale';

type Props = {
  bill: IBillDetails;
  locale: ILocale;
  currency: string;
};

export const SaleBillHeaderContent = ({ bill, locale, currency }: Props): JSX.Element => {
  return (
    <Row>
      <Col span={24} md={12} className='appointment-details-top-container__left-column'>
        {bill?.deleted && (
          <DeletedInfo deleted_at={bill?.deleted_at} deleted_by={bill?.deleted_by} />
        )}
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
          <span id='date'>{localDateTime(bill?.date)}</span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.amount}: </span>
          <span id='price'>
            {money(bill.sale.total_billed_amount)} {currency}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.totalPaidAmount}: </span>
          <span id='total_paid'>
            {money(bill.sale.total_payed_amount)} {currency}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.stockDiscount}: </span>
          <span id='stock_discount_percentage'>{bill.sale.stock_discount.percentage || 0}% </span>
          <span id='stock_discount_amount'>
            ({bill.sale.stock_discount.amount || 0} {currency})
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.remainingAmount}: </span>
          <span id='remaining_amount'>
            {money(bill?.sale.remaining_amount)} {currency}
          </span>
        </Col>
      </Col>
      <Col span={24} md={12} className='appointment-details-top-container__right-column'>
        <Col>
          {!!bill?.sale?.client?.name && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
              <span id='client'>{bill?.sale?.client?.name}</span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.client?.phone_number && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.clientPhone}: </span>
              <span id='client_phone_number'>{`+${bill?.client?.phone_number}`}</span>
            </>
          )}
        </Col>
      </Col>
    </Row>
  );
};
