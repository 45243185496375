import './AddRoom.scss';

import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import { useCreateRoom } from 'api/hooks/rooms/useCreateRoom';
import { useEditRoom } from 'api/hooks/rooms/useEditRoom';
import { IRoom, IRoomPayload, ROOM_STATUSES } from 'api/interfaces/Room';
import { roomStatuses } from 'constants/dictionary/default/selectOptions';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = React.PropsWithChildren<{
  clinicId: number;
  form: FormInstance;
  onFieldsChange: (e) => void;
  selectedRoom: IRoom | null;
  closeModal: () => void;
}>;

export const AddRoomForm = ({
  clinicId,
  form,
  onFieldsChange,
  selectedRoom,
  closeModal,
  children
}: Props): JSX.Element => {
  const locale = useLocale('private.administration.rooms');

  const createRoom = useCreateRoom(clinicId, closeModal);
  const editRoom = useEditRoom(clinicId, closeModal);

  const onSubmit = (data: IRoomPayload) => {
    const payload = { ...data, not_for_appointment: !data.not_for_appointment };

    if (selectedRoom) editRoom.mutate({ payload, id: selectedRoom.id });
    else createRoom.mutate(payload);
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item
        label={locale.labels.name}
        name='name'
        rules={[requiredRule(locale.messages.nameEmpty)]}
        initialValue={selectedRoom?.name}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={locale.labels.status}
        name='status'
        rules={[requiredRule(locale.messages.roomStatusEmpty)]}
        initialValue={selectedRoom?.status ? selectedRoom?.status : ROOM_STATUSES.active}
      >
        <Select className='patient-select' options={useLocalizedList(roomStatuses)} />
      </Form.Item>
      <Form.Item
        label={locale.labels.location}
        name='location'
        initialValue={selectedRoom?.location}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={locale.labels.forAppointments}
        name='not_for_appointment'
        className='flat-checkbox-form-item'
        valuePropName='checked'
        initialValue={!selectedRoom?.not_for_appointment}
      >
        <Checkbox defaultChecked={true} />
      </Form.Item>
      {children}
    </Form>
  );
};
