import { Button, Col, Form, FormInstance, Input, Row, Select, Spin } from 'antd';
import { useAddPatientToAppointment } from 'api/hooks/appointments/useAddPatientToAppointment';
import { useClientPatientsList } from 'api/hooks/patients/useClientPatientsList';
import { IAddAppointmentPatients } from 'api/interfaces/Appointment';
import { IPatientDetails } from 'api/interfaces/Patient';
import animalsList from 'constants/dictionary/animals';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  clientId: number;
  appointmentId: number;
  appointmentPatients: IPatientDetails[];
  closeModal: () => void;
}>;

export const AddAppointmentPatientForm = ({
  form,
  children,
  locale,
  clientId,
  appointmentId,
  appointmentPatients,
  closeModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const localizedAnimalsList = useLocalizedList(animalsList);

  const [createNewPatient, setCreateNewPatient] = useState(false);

  const addAppointmentPatient = useAddPatientToAppointment(clinicId, appointmentId, closeModal);
  const { data: patients, isPending } = useClientPatientsList(clinicId, clientId);

  const onSubmit = (data: IAddAppointmentPatients): void => {
    const oldPatients = appointmentPatients?.map(({ id }) => id);
    const payload = {
      client_id: clientId,
      patients: data.patients.filter(({ name }) => name),
      patients_ids: [...oldPatients, ...(data.patients_ids || [])]
    };

    addAppointmentPatient.mutate(payload);
  };

  const patientsList = (patients?.data || [])
    .filter((patient) => !appointmentPatients.some(({ id }) => id === patient.id))
    .map(
      (item): JSX.Element => (
        <Option key={`patient_${item.id}`} value={item.id}>
          {item.name}
        </Option>
      )
    );

  return (
    <Spin spinning={isPending || addAppointmentPatient.isPending}>
      <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
        <Form.Item name='clinic_id' initialValue={clinicId} hidden>
          <Input />
        </Form.Item>
        <Row>
          <Col md={16} span={24}>
            <Form.Item className='add-new-patient__owner-select' name={['patients_ids']}
              rules={!createNewPatient && [requiredRule(locale.errors.patientNotValid)]}>
              <Select
                filterOption={false}
                className='patient-select'
                mode='multiple'
                placeholder={locale.placeholders.searchPatient}
              >
                {patientsList}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button
            type={'primary'}
            style={{ borderRadius: 30, marginBottom: 20 }}
            onClick={() => {
              setCreateNewPatient(true);
              if (!form.getFieldValue('patients').length)
                form.setFieldValue('patients', [{ name: '', species: '' }]);
            }}
          >
            {locale.buttons.addNew}
          </Button>
        </Row>
        <Form.Item name='patients' initialValue={[{ name: '', species: '' }]} hidden>
          <Input />
        </Form.Item>
        {createNewPatient && (
          <Form.List name='patients'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[20, 10]}>
                    <Col span={22} md={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[requiredRule(locale.errors.animalNameNotValid)]}
                      >
                        <Input placeholder={locale.labels.name} />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'species']}
                        rules={[requiredRule(locale.errors.animalTypeNotValid)]}
                      >
                        <Select filterOption={false} options={localizedAnimalsList} />
                      </Form.Item>
                    </Col>
                    {
                      <Col span={1} md={1}>
                        <Button
                          onClick={() => remove(name)}
                          danger
                          type='primary'
                          shape={'circle'}
                          className={'inventory-update-buttons'}
                        >
                          <span className='icofont icofont-trash' />
                        </Button>
                      </Col>
                    }
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button
                      className='icofont icofont-plus'
                      type={'primary'}
                      style={{ borderRadius: 30 }}
                      onClick={() => add()}
                    >
                      {locale.buttons.addMore}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        )}
        {children}
      </Form>
    </Spin>
  );
};
