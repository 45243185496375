import { Button, Form, Input } from 'antd';
import { useResetPassword } from 'api/hooks/user/useResetPassword';
import React from 'react';
import { useParams } from 'react-router-dom';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { useLocale } from '../../hooks/useLocale';

const SetNewPassword = () => {
  const { token } = useParams() as any;
  const locale = useLocale('public.modals.sign-up');

  const forgotPassword = useResetPassword();

  return (
    <div style={{ maxWidth: 690, margin: '0 auto' }}>
      <Form onFinish={forgotPassword.mutate} autoComplete='off' layout='vertical'>
        <Form.Item name='auth_code' initialValue={token} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label='Enter Password'
          name='password'
          rules={[
            requiredRule(locale.messages.inputPassword),
            patternRule(PATTERNS.PASSWORD, locale.messages.inputPassword)
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label='Repeat Password'
          name='repeatPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            requiredRule(locale.messages.passwordsDontMatch),
            ({ getFieldValue }) => ({
              async validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(locale.messages.passwordsDontMatch));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetNewPassword;
