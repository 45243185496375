import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPaymentPayload } from 'api/interfaces/Payment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreatePayment = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IPaymentPayload) => axiosClient.post(`${API_ENDPOINTS.PAYMENTS(clinicId)}`, payload),
    ['paymentsList', clinicId],
    callbackFn
  );
};
