import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientsList } from 'api/interfaces/Patient';

export const useClientPatientsList = (clinicId: number, clientId: number) => {
  const endpoint = API_ENDPOINTS.CLIENT_PATIENTS(clinicId, clientId);
  const queryKey = ['clientPatients', clinicId, clientId];

  return useQuery<IPatientsList>({
    queryFn: async () => {
      const { data } = await axiosClient.get(endpoint);
      return data;
    },
    queryKey,
    enabled: !!clientId
  });
};
