import '../scss/StockDetails.scss';

import { Form } from 'antd';
import { IInvoice } from 'api/interfaces/Invoice';
import { IStock } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import { IAccesses } from 'api/store/userAccesses';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { PurchaseItemsToStockForm } from '../forms/PurchaseItemsToStockForm';

dayjs.extend(customParseFormat);

type Props = {
  accessGroup: IAccesses;
  locale: ILocale;
  invoice?: IInvoice;
  stock?: IStock;
};

export const AddItemsToStockModal = ({
  accessGroup,
  locale,
  invoice,
  stock
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();
  const [showItemCancelModal, setShowItemCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.createStockItem}
        form={form}
        centered
        open
        onModalClose={closeModal}
        width={800}
      >
        <PurchaseItemsToStockForm invoice={invoice} form={form} stock={stock}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={accessGroup}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowItemCancelModal(true);
              } else {
                closeModal();
              }
            }}
          />
        </PurchaseItemsToStockForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showItemCancelModal}
        onOk={closeModal}
        onCancel={() => setShowItemCancelModal(false)}
      />
    </>
  );
};
