import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IServiceList } from 'api/interfaces/Service';

import { useTableData } from '../useTableData';

export const useServicesList = (
  clinicId: number,
  params: Record<string, number | string> = { page: 0 },
  disableSearch: boolean = true
) => {
  const endpoint = API_ENDPOINTS.SERVICES(clinicId);

  const result = useTableData<IServiceList>(
    endpoint,
    params,
    ['servicesList', clinicId],
    disableSearch || undefined
  );

  return {
    ...result,
    services: result.data.data || []
  };
};
