import { QueryClient } from '@tanstack/react-query';
import { notification } from 'antd';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2
      // onError: (error: any) => {
      //   // Show notification on query error
      //   const message = error.response?.data?.message';
      //   notification.error({ type: 'error', message });
      // }
    },
    mutations: {
      onError: (error: any) => {
        const message = error.response?.data?.message || 'Something went wrong with the mutation!';
        notification.error({ type: 'error', message });
      }
    }
  }
});

// queryClient.getQueryCache().subscribe((event) => {
// if (event?.type === 'query' && event?.query.getObserversCount() > 0 && event?.query.state.error) {
//   const error = event.query.state.error as any;
//   const message = error?.response?.data?.message || 'Something went wrong with the query!';
//   notification.error({ message, type: 'error' });
// }
// });

export default queryClient;
