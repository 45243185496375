import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteAppointmentPatient = (
  clinicId: number,
  appointmentId: number,
  callbackFn?: (patient) => void
) => {
  const mutationFn = (patientId: number) =>
    axiosClient.delete(
      `${API_ENDPOINTS.APPOINTMENT_PATIENTS(clinicId, appointmentId)}/${patientId}`
    );

  return useCustomMutation(mutationFn, ['appointmentPatients', 'appointmentBill'], callbackFn);
};
