import 'assets/scss/pagination.scss';

import { Col, Form, Pagination, Row, Spin } from 'antd';
import { useEmployeesList } from 'api/hooks/employees/useEmployeesList';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import Contact from 'layout/components/doctor/Contact';
import React, { useState } from 'react';
import className from 'utils/class-names';

const getClass = (index: number, length: number): string =>
  className({
    'mb-0': index === length - 1,
    'mb-md-0': index === length - 2 || index === length - 3
  });

const EmployeesList = (): JSX.Element => {
  const clinicId = useClinicId();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(6);
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.employees');

  const { employees, data, isLoading, params, updateParams } = useEmployeesList(clinicId, {
    page: currentPage,
    items: currentPageSize
  });

  const searchItems = [
    { name: 'title', placeholder: locale.placeholders.title },
    { name: 'role', placeholder: locale.placeholders.role },
    { name: 'skill', placeholder: locale.placeholders.skill },
    { name: 'first_name', placeholder: locale.placeholders.firstName },
    { name: 'last_name', placeholder: locale.placeholders.lastName },
    { name: 'email', placeholder: locale.placeholders.email },
    { name: 'phone_number', placeholder: locale.placeholders.phone }
  ];

  const onFinish = (): void => {
    const searchArray = form.getFieldsValue();
    const filters = Object.entries(searchArray).reduce(
      (acc, [key, value]) => (value ? { ...acc, [`q[${key}]`]: value } : acc),
      {}
    );

    setCurrentPage(1);
    updateParams({ page: 1, ...filters });
  };

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Spin spinning={isLoading}>
        <div style={{ textAlign: 'end' }}>
          <Row justify='start' gutter={[20, 20]}>
            {employees.map((employee, index) => (
              <Col key={index} span={24} sm={12} md={8} xl={6} xxl={5}>
                <Contact
                  locale={locale}
                  className={getClass(index, employees.length)}
                  clinicId={clinicId}
                  {...employee}
                />
              </Col>
            ))}
          </Row>
          <Row justify='space-between' style={{ marginTop: 20, alignItems: 'flex-end' }}>
            <Pagination
              className='employees-pagination'
              responsive
              showSizeChanger={false}
              showLessItems
              current={+params.page}
              defaultPageSize={6}
              pageSize={+params.items || currentPageSize}
              total={data?.metadata.total}
              showTotal={(total) => `${locale.labels.total} ${total}`}
              onChange={(page) => {
                setCurrentPage(page);
                updateParams({ page });
              }}
              onShowSizeChange={(_, size) => {
                setCurrentPageSize(size);
                updateParams({ items: size });
              }}
            />
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default EmployeesList;
