import { Col, DatePicker, Form, FormInstance, Select } from 'antd';
import { useGenerateVaccinationReport } from 'api/hooks/vaccinations/useGenerateVaccinationReport';
import { DATE_FORMAT } from 'constants/common';
import { vaccinationsReportOptions } from 'constants/dictionary/default/selectOptions';
import { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
}>;

type IVaccinationReport = {
  time_from: Dayjs;
  time_to: Dayjs;
  report_type: string;
};

export const GenerateVaccinationReportForm = ({ form, children, locale }: Props): JSX.Element => {
  const clinicId = useClinicId();

  const generateReport = useGenerateVaccinationReport(clinicId);

  const onSubmit = (data: IVaccinationReport): void => {
    const payload = {
      timeFrom: data.time_from.format(DATE_FORMAT),
      timeTo: data.time_to.format(DATE_FORMAT)
    };

    generateReport.mutate(payload);
  };

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Col>
        <Form.Item name='report_type' label={locale.labels.reportType}>
          <Select
            filterOption={false}
            options={useLocalizedList(vaccinationsReportOptions)}
            defaultValue='report'
          />
        </Form.Item>
        <Form.Item rules={[requiredRule()]} name='time_from' label={locale.labels.dateFrom}>
          <DatePicker />
        </Form.Item>
        <Form.Item rules={[requiredRule()]} name='time_to' label={locale.labels.dateTo}>
          <DatePicker />
        </Form.Item>
      </Col>
      {children}
    </Form>
  );
};
