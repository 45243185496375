import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { ReplenishmentPopupForm } from './ReplenishmentPopupForm';

export const ReplenishmentPopup = (): JSX.Element => {
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const { closeModal } = useModal();
  const locale = useLocale('private.clients.client');

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      title={locale.labels.replenishmentClientBalance}
      centered
      form={form}
      open
      onModalClose={closeModal}
    >
      <ReplenishmentPopupForm locale={locale} form={form}>
        <ViewDetailsButtonsGroup
          saveButtonDisabled={!shiftOpened}
          accessGroup={userAccess.accesses.financialAccounts}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </ReplenishmentPopupForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
