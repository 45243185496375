import { Form } from 'antd';
import { IPrice, IStock } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { PrintMultipleStocksForm } from './PrintMultipleStocksForm';
import { PrintPricesForm } from './PrintPricesForm';

type Props = {
  stock: IStock[] | IStock;
  prices?: IPrice[];
  multipleStocksMode?: boolean;
};

export const PrintPricesModal = ({ prices, stock, multipleStocksMode }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const [form] = Form.useForm();
  const locale = useLocale('public.modals.printPrices');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const modalButtons = (): JSX.Element => (
    <ViewDetailsButtonsGroup
      accessGroup={userAccess.accesses.invitations}
      fixed
      editMode
      submitButtonIcon={'icofont-print'}
      hideDeleteBtn
      onCancelClick={() => {
        if (form.isFieldsTouched()) {
          setShowCancelModal(true);
        } else {
          closeModal();
        }
      }}
    />
  );

  return (
    <VLXModal
      title={locale.labels.pricePrint}
      centered
      open
      width={1100}
      form={form}
      onModalClose={closeModal}
    >
      {multipleStocksMode ? (
        <PrintMultipleStocksForm
          form={form}
          locale={locale}
          stockData={stock as IStock[]}
          showChooseNewStockBtn
        >
          {modalButtons()}
        </PrintMultipleStocksForm>
      ) : (
        <PrintPricesForm prices={prices} stock={stock as IStock} form={form} locale={locale}>
          {modalButtons()}
        </PrintPricesForm>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
