import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IUserProfile } from 'api/interfaces/User';

const mutationFn = async (data: Partial<IUserProfile>): Promise<void> => {
  const response = await axiosClient.post(`${API_ENDPOINTS.AUTH}/register`, data);
  return response.data;
};

export const useUserRegistration = (onSuccess?: () => void) =>
  useMutation({
    mutationFn,
    onSuccess,
    onError: (error: any) => {
      notification.error({
        message: 'Registration Failed',
        description: error?.response?.data?.message || 'An error occurred.'
      });
    }
  });
