import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteTool = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = (toolId: number) =>
    axiosClient.delete(`${API_ENDPOINTS.TOOLS(clinicId)}/${toolId}`);

  return useCustomMutation(mutationFn, ['toolsList', clinicId], callbackFn);
};
