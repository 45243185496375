import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ITransferPayload } from 'api/interfaces/Finances';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useTransfer = (clinicId: number, accountId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: ITransferPayload) =>
      axiosClient.post(`${API_ENDPOINTS.ACCOUNTS(clinicId)}/${accountId}/transfers`, payload),
    [
      ['bankAccountsList', clinicId],
      ['bankAccountsTransactions', clinicId, accountId]
    ],
    callbackFn
  );
};
