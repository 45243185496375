import 'layout/modals/finances/addBill/AddBill.scss';
import '../SaleDetails.scss';

import { Button, Col, Form, notification, Popover, Row, Tag, Tooltip } from 'antd';
import { useBillDetails } from 'api/hooks/bills/useBillDetails';
import { useCreateSale } from 'api/hooks/sales/useCreateSale';
import { useDeleteSale } from 'api/hooks/sales/useDeleteSale';
import { useEditSale } from 'api/hooks/sales/useEditSale';
import { IClient } from 'api/interfaces/Client';
import { ISaleDetails } from 'api/interfaces/Sale';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import { AxiosResponse } from 'axios';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { DeletedInfo } from 'components/EntityDetails/DeletedInfo';
import { StatusTag } from 'components/StatusTag';
import VLXInputNumber from 'components/VLXInputNumber';
import dayjs from 'dayjs';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import { ClientsSelect } from 'layout/components/clientsSelect/ClientsSelect';
import { EmployeesSelect } from 'layout/components/employeesSelect/EmployeesSelect';
import { ClientItemModal } from 'layout/modals/addClient/ClientItemModal';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import { BillPrintButton } from 'pages/finances/bills/print/BillPrintButton';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formPayloadItems } from '../utils/formPayloadItems';

type Props = {
  saleDetails: ISaleDetails;
  locale: ILocale;
  setSaleEditable: (boolean) => void;
  selectedClient: IClient;
  setSelectedClient: (IClient) => void;
  saleEditable: boolean;
  setFieldsTouched: (boolean) => void;
  fieldsTouched: boolean;
  setSelectedEmployee: (id: number) => void;
  selectedEmployee: number;
};

type TotalInfoProps = {
  id: string;
  content: string;
  title: string;
};

const TotalInfo = ({ id, content, title }: TotalInfoProps): JSX.Element => (
  <Row className='sale-form__top-cont__count-item'>
    <div className='sale-form__top-cont__count-item__cont'>
      <span className='price-label'>{title}</span>
    </div>
    <div className='sale-form__top-cont__count-item__cont' style={{ marginLeft: '10px' }}>
      <span id={id}>{content}</span>
    </div>
  </Row>
);

export const SaleDetailsOverview = ({
  saleDetails,
  locale,
  saleEditable,
  setSaleEditable,
  selectedClient,
  setSelectedClient,
  setFieldsTouched,
  fieldsTouched,
  selectedEmployee,
  setSelectedEmployee
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const { isOpened, open, closeModal } = useModal();

  const { data: billDetails } = useBillDetails(clinicId, saleDetails?.bill_id);
  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const onCreate = (data): void => {
    if (data?.bill_id) {
      const newUrl = `/clinic/${clinicId}/shop-farmacy/${data.id}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      notification.success({ message: locale.messages.dataSavedSuccessfully });
    }
    closeModal();
  };

  const onEdit = (): void => {
    notification.success({ message: locale.messages.dataSavedSuccessfully });
    closeModal();
  };

  const createSale = useCreateSale(clinicId, onCreate);
  const editSale = useEditSale(clinicId, saleDetails, onEdit);
  const deleteSale = useDeleteSale(clinicId, saleDetails?.id, () => setShowDeleteModal(false));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const showRemainingPrice = saleDetails?.payment_status === 'partially_paid';

  useEffect((): void => {
    if (saleDetails?.client) {
      setSelectedClient(saleDetails?.client);
    }
    if (saleDetails?.user?.id) {
      setSelectedEmployee(saleDetails?.user?.id);
    }
  }, [saleDetails]);

  useEffect((): void => {
    if (saleDetails) {
      form.setFieldValue(['stock_discount', 'amount'], saleDetails.stock_discount.amount || 0);
      form.setFieldValue(
        ['stock_discount', 'percentage'],
        saleDetails.stock_discount.percentage || 0
      );
      setDiscountPrice(saleDetails.stock_discount.amount);
    }
    setSaleEditable(saleDetails?.payment_status === 'unpaid' || 'payment_not_required');
  }, [saleDetails]);

  const selectClient = (_id: number, client: IClient): void => {
    setSelectedClient(client);
    setSaleEditable(true);
    setFormTouched();
  };

  const selectEmployee = (id: number): void => {
    setSelectedEmployee(id);
    setSaleEditable(true);
    setFormTouched();
  };

  const createClientCallback = (data: AxiosResponse<IClient>): void => {
    setSelectedClient(data.data);
    closeModal();
    setFormTouched();
  };

  const setFormTouched = (): void => {
    if (!fieldsTouched) {
      setFieldsTouched(true);
    }
  };

  const onDiscountPercantageChange = (val: number): void => {
    const priceWithDiscount = saleDetails?.total_billed_amount * val * 0.01;
    form.setFieldValue(['stock_discount', 'amount'], +priceWithDiscount.toFixed(2));
    setDiscountPrice(priceWithDiscount);
    setFormTouched();
  };

  const onDiscountAmountChange = (val: number): void => {
    const priceWithDiscount = (val * 100) / saleDetails?.total_billed_amount;
    form.setFieldValue(['stock_discount', 'percentage'], +priceWithDiscount.toFixed(0));
    setDiscountPrice(val);
    setFormTouched();
  };

  const onConfirmCancelClick = (): void => {
    setShowCancelModal(false);
    form.resetFields();
  };

  const onSubmit = (data): void => {
    const payload = {
      client_id: selectedClient?.id ?? null,
      employee_id: selectedEmployee ?? null,
      items: saleDetails?.stock.map(formPayloadItems) || [],
      vat_included: false,
      amount: 0,
      fiscal: true,
      discount_amount: data.stock_discount.amount,
      discount_percentage: data.stock_discount.percentage
    };

    if (saleDetails) {
      editSale.mutate(payload);
    } else {
      createSale.mutate(payload);
    }
  };

  const Content = (sale: ISaleDetails): JSX.Element => {
    return (
      <div>
        <p style={{ marginBottom: '2px' }}>
          {`${locale.labels.deletedBy}: ${sale?.deleted_by?.name} `}
          {sale?.deleted_by?.title && `(${sale?.deleted_by?.title})`}
        </p>
      </div>
    );
  };

  return (
    <>
      <Form
        key={'SaleOverviewForm'}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={{
          client_id: selectedClient?.id,
          employee_id: selectedEmployee,
          amount: saleDetails?.total_billed_amount || 0,
          stock_discount: {
            amount: null,
            percentage: null
          }
        }}
        className='sale-form'
      >
        <Row
          justify={'space-between'}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {/* SALE DATE */}
          {!!saleDetails?.created_at && (
            <span className='price-label' style={{ fontSize: '16px' }}>
              {dayjs(saleDetails?.created_at).format('DD.MM.YYYY HH:mm')}
            </span>
          )}
          {/* PAYMENT STATUS */}
          <Row align='middle'>
            {!!saleDetails?.stock.length && <StatusTag status={saleDetails?.payment_status} />}
            {saleDetails?.deleted && (
              <Popover
                placement='topLeft'
                title={locale.labels.saleDeleted}
                content={Content(saleDetails)}
              >
                <span className='icofont-info-circle' style={{ fontSize: '15px', marginLeft: 4 }} />
              </Popover>
            )}
          </Row>
        </Row>
        {saleDetails?.deleted && (
          <>
            <Row style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
              <DeletedInfo
                deleted_at={saleDetails?.deleted_at}
                deleted_by={saleDetails?.deleted_by}
              />
            </Row>
          </>
        )}
        {/* EMPLOYEE SELECTOR */}
        <Row style={{ marginTop: 20 }}>
          <Col className='sale-form__top-cont__client-select'>
            <Form.Item label={locale.labels.seller}>
              <EmployeesSelect
                mode={null}
                value={selectedEmployee}
                disabled={saleDetails && !saleEditable}
                onChange={selectEmployee}
                selectedDoctorId={selectedEmployee}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* CLIENT SELECTOR */}
        <Row style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
          <Col className='sale-form__top-cont__client-select'>
            <Form.Item label={locale.labels.client} className='long-value-form-item'>
              <ClientsSelect
                disabled={saleDetails && !saleEditable}
                onChange={selectClient}
                selectedClientId={selectedClient?.id}
              />
            </Form.Item>
          </Col>
          {/* ADD NEW CLIENT BUTTON */}
          {(!saleDetails || saleEditable) && (
            <>
              <Button
                id='add-new-client'
                type={'primary'}
                style={{ borderRadius: 30, marginTop: 27, marginBottom: 20 }}
                className='sale-form__top-cont__add-new-client-btn'
                icon={
                  <span
                    className='icofont icofont-plus mr-2 sticky-btn-icon'
                    style={{ fontSize: '1.3em' }}
                  />
                }
                onClick={() => open(MODAL.clientItemModal)}
              >
                <span className='create-client-txt'>{locale.buttons.createNew}</span>
              </Button>
              {isOpened(MODAL.clientItemModal) && (
                <ClientItemModal onSuccess={createClientCallback} />
              )}
            </>
          )}
        </Row>
        {/* CLIENT INFO */}
        {selectedClient && (
          <div className='card-content card-content--client-info'>
            {selectedClient.name && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
                <Link
                  id='client-name'
                  className='custom-links'
                  to={`/clinic/${clinicId}/clients/${selectedClient.id}`}
                >
                  {selectedClient.name}
                  {blacklistedStatus(selectedClient)}
                </Link>
              </Col>
            )}
            {selectedClient.phone_number && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.phoneNumber}: </span>
                <Link
                  id='client-phone'
                  className='custom-links'
                  to={`tel:${selectedClient.country_code}${selectedClient.phone_number}`}
                >
                  {selectedClient.phone_number
                    ? `+${selectedClient.country_code}${selectedClient.phone_number}`
                    : '-'}
                </Link>
              </Col>
            )}
            {selectedClient.number_of_pets > 0 && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.numberOfPets}: </span>
                <span id='numberOfPets'>{selectedClient.number_of_pets}</span>
              </Col>
            )}
          </div>
        )}
        <Row
          className='sale-form__top-cont__count-item__cont'
          style={{
            justifyContent: 'space-between',
            marginTop: -5
          }}
        ></Row>
        <Row justify={'space-between'}>
          <Col
            sm={24}
            md={12}
            xl={15}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20
            }}
          >
            {' '}
            {/* DISCOUNT PERCENTAGE */}
            <Col sm={10} xl={15} style={{ marginRight: '10px' }}>
              <Form.Item
                label={`${locale.labels.discount} (%)`}
                name={['stock_discount', 'percentage']}
              >
                <VLXInputNumber
                  disabled={!saleDetails?.stock.length || !saleEditable}
                  id='discount-percentage'
                  precision={0}
                  max={100}
                  onChange={onDiscountPercantageChange}
                />
              </Form.Item>
            </Col>
            {/* DISCOUNT AMOUNT */}
            <Col sm={10} xl={15}>
              <Form.Item
                label={`${locale.labels.discount} (${locale.labels.uah})`}
                name={['stock_discount', 'amount']}
              >
                <VLXInputNumber
                  disabled={!saleDetails?.stock.length || !saleEditable}
                  id='discount-amount'
                  max={saleDetails?.total_billed_amount}
                  onChange={onDiscountAmountChange}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        {/* SALE ITEMS COUNT */}
        <TotalInfo
          title={`${locale.labels.itemsCount}: `}
          id='itemsCount'
          content={`${saleDetails?.stock.length || 0}`}
        />
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap'
          }}
        >
          {/* TOTAL PRICE */}
          <TotalInfo
            title={`${locale.labels.totalPrice}: `}
            id='totalPrice'
            content={`${(saleDetails?.total_billed_amount || 0).toFixed(2)} ${locale.labels.uah}`}
          />
          {!!saleDetails?.stock.length && (
            <Link
              to={`/clinic/${clinicId}/finances/bills/${saleDetails.bill_id}`}
              style={{ marginLeft: 10 }}
            >
              {' '}
              <span className='icofont-ui-file'></span>
            </Link>
          )}
        </Row>
        <Form.Item>
          {/* PRICE WITH DISCOUNT */}
          <TotalInfo
            title={`${locale.labels.priceWithDiscount}: `}
            id='priceWithDiscount'
            content={`${(saleDetails?.total_billed_amount - discountPrice || 0).toFixed(2)} ${
              locale.labels.uah
            }`}
          />
        </Form.Item>
        {/* PAID AMOUNT */}
        <TotalInfo
          title={`${locale.labels.paid}: `}
          id='totalPaidAmount'
          content={`${(
            saleDetails?.amount_with_discount - saleDetails?.remaining_amount || 0
          ).toFixed(2)}
          ${locale.labels.uah}`}
        />
        {/* REMAINING PRICE */}
        {showRemainingPrice && (
          <Form.Item>
            <TotalInfo
              title={`${locale.labels.remainingAmount}: `}
              id='remainingAmount'
              content={`${(saleDetails?.remaining_amount).toFixed(2)} ${locale.labels.uah}`}
            />
          </Form.Item>
        )}
        <>
          {!saleDetails?.deleted && (
            <>
              {/* ADD PAYMENT BUTTON */}
              {saleDetails?.payment_status !== 'paid' && (
                <Row className='sale-form__top-cont__count-item'>
                  <Tooltip
                    trigger={'hover'}
                    placement='topLeft'
                    title={!shiftOpened ? locale.labels.paymentBlockedClosedShift : null}
                  >
                    <Row>
                      <Button
                        style={{
                          marginRight: 0,
                          marginTop: 10
                        }}
                        disabled={!shiftOpened || !saleDetails || fieldsTouched}
                        onClick={() => open(MODAL.addBillPayment)}
                        className='change-status-basic-button'
                      >
                        <span className='icofont-money' />
                        <span>{locale.buttons.addPayment}</span>
                      </Button>
                    </Row>
                  </Tooltip>
                </Row>
              )}
              {/* UNSAVED CHANGES LABEL */}
              <Row
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                className='sale-form__top-cont__count-item action-buttons'
              >
                <Col className='unsaved-changes'>
                  {fieldsTouched && (
                    <Tag
                      id='bill-status-type'
                      color={'red'}
                      style={{
                        fontWeight: 'bold',
                        maxHeight: 30
                      }}
                    >
                      {locale.labels.unsavedChanges}
                    </Tag>
                  )}
                </Col>
                {/* SAVE DATA BUTTON */}
                <Col style={{ display: 'flex' }}>
                  <Button
                    id='add-more-stock'
                    className='icofont icofont-save inventory-update-buttons'
                    type='primary'
                    disabled={!saleEditable}
                    style={{
                      borderRadius: 30,
                      marginRight: 12,
                      width: 50,
                      height: 50
                    }}
                    onClick={() => {
                      form.submit();
                      setFieldsTouched(false);
                    }}
                  ></Button>
                  {/* PRINT BUTTON */}
                  <BillPrintButton bill={billDetails} size={50} iconSize={17} />
                  {/* CANCEL/DELETE BUTTON */}
                  <Button
                    style={{
                      width: 50,
                      height: 50,
                      marginLeft: 12
                    }}
                    onClick={() => {
                      if (saleDetails) {
                        setShowDeleteModal(true);
                      } else {
                        setShowCancelModal(true);
                      }
                    }}
                    className={`inventory-update-buttons icofont icofont-${
                      saleDetails ? 'trash' : 'ban'
                    }`}
                    danger
                    type='primary'
                    shape={'circle'}
                  ></Button>
                </Col>
              </Row>
            </>
          )}
        </>

        {!!saleDetails && <AddBillPayment bill={billDetails} hideAddButton />}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
        <ConfirmDeleteModal
          type={'device'}
          open={showDeleteModal}
          onOk={deleteSale.mutate}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Form>
    </>
  );
};
