import { Form } from 'antd';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IBillDetails } from 'api/interfaces/Bills';
import { useModal } from 'api/store/modalStore';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

import { AppointmentBill } from './AppointmentBill';

type Props = {
  bill: IBillDetails;
  appointment: IAppointmentDetails;
};

export const AppointmentBillModal = ({ bill, appointment }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();
  const locale = useLocale('private.appointments.event');

  return (
    <VLXModal
      title={`${locale.labels.appointment}: ${bill?.id}`}
      form={form}
      centered
      open
      onModalClose={closeModal}
      width={800}
    >
      <AppointmentBill form={form} appointment={appointment} bill={bill} />
    </VLXModal>
  );
};
