import './scss/VLXSearch.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import { getInitialDate } from 'api/utils/getInitialDate';
import { groupedFilters } from 'api/utils/groupFilters';
import { transformKeysForState } from 'api/utils/transformKeys';
import { DATE_TIME_WITH_ZONE } from 'constants/common';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = React.PropsWithChildren<{
  selectOptions?: Array<{ value: string; label: string; filter?: string }>;
  selectPlaceholder?: string;
  dateRangeEnabled?: boolean;
  savedFiltersName?: string;
  onFilterChange?: (filters?: Record<string, any>) => void;
}>;

enum DATE_FILTER {
  range = 'range',
  today = 'today',
  all = 'all'
}

export const VLXSearchNEW = ({
  selectPlaceholder,
  selectOptions = [],
  dateRangeEnabled = false,
  onFilterChange,
  children
}: Props): JSX.Element => {
  const locale = useLocale('private.composite-search');
  const [searchParams] = useSearchParams();
  const searchEntries = Object.fromEntries(searchParams.entries());
  const { composite, startTimeFrom, startTimeTo } = transformKeysForState(searchEntries);

  const [searchValue, setSearchValue] = useState<string>(composite || null);
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState(getInitialDate(startTimeFrom, dateRangeEnabled));
  const [dateTo, setDateTo] = useState(getInitialDate(startTimeTo, dateRangeEnabled));
  const [dateFilter, setDateFilter] = useState<DATE_FILTER>(DATE_FILTER.today);

  // Load filters from Locale Storage
  // useEffect(() => {
  // todo API - reserved for future purposes
  // const storedFilters = ls.get(savedFiltersName)?.[clinicId] || {};
  // const filters = transformKeysForState(storedFilters);
  // setSearchValue(filters.composite || '');
  // setSelectValue(joinStringArrays(filters.status, filters.paymentStatus));
  // setDateFrom(filters.startTimeFrom || null);
  // setDateTo(filters.startTimeTo || null);
  // onFilterChange?.(transformKeysForAPI(filters));
  // }, [savedFiltersName, clinicId]);
  //
  // todo API - reserved for future purposes
  // const saveFiltersToLocalStorage = (filters) => {
  //   const currentFilters = (ls.get(savedFiltersName) as object) || {};
  //   ls.set(savedFiltersName, {
  //     ...currentFilters,
  //     [clinicId]: filters
  //   });
  // };

  useEffect(() => {
    handleSearch();
  }, [dateFrom, dateTo]);

  // On Search function
  const handleSearch = (): void => {
    const filters = {
      ...groupedFilters(selectOptions, selectValue),
      composite: searchValue,
      startTimeFrom: dateFrom
        ? dayjs(dateFrom).hour(0).minute(0).local().tz().format(DATE_TIME_WITH_ZONE)
        : undefined,
      startTimeTo: dateTo
        ? dayjs(dateTo).hour(23).minute(59).local().tz().format(DATE_TIME_WITH_ZONE)
        : undefined
    };

    // saveFiltersToLocalStorage(filters); todo API - reserved for future purposes
    onFilterChange?.(filters);
  };

  // On Reset function
  const handleReset = (): void => {
    setSearchValue(null);
    setSelectValue([]);

    if (dateRangeEnabled) {
      onTodayClick();
    } else {
      onFilterChange?.();
    }

    // saveFiltersToLocalStorage({}); todo API - reserved for future purposes
  };

  const onRangeChange = (data: dayjs.Dayjs[]): void => {
    if (!data) {
      onAllTimeClick();
      return;
    }

    setDateFrom(data?.[0]);
    setDateTo(data?.[1]);
    setDateFilter(data ? DATE_FILTER.range : DATE_FILTER.today);
  };

  const onTodayClick = (): void => {
    setDateFrom(dayjs());
    setDateTo(dayjs());
    setDateFilter(DATE_FILTER.today);
  };

  const onAllTimeClick = (): void => {
    setDateFrom(null);
    setDateTo(null);
    setDateFilter(DATE_FILTER.all);

    onFilterChange?.();
  };

  const getDateLabel = (): string => {
    const from = dateFrom?.format('DD MMM YYYY');
    const to = dateTo?.format('DD MMM YYYY');
    if (!from && !to) return '';
    if (from === to) return from;
    return `${from} - ${to}`;
  };

  return (
    <>
      <Row
        className='composit-search-select-option-search'
        justify={dateRangeEnabled ? 'end' : 'start'}
      >
        <Input
          className='search-field composit-search-select-option-search-input'
          allowClear
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onPressEnter={handleSearch}
          style={{ margin: 0 }}
          placeholder={locale.placeholders.searchCriteria}
        />

        {selectOptions.length > 0 && (
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            className='composit-search-select-option-search-select'
            placeholder={selectPlaceholder}
            mode='multiple'
            showSearch={false}
            onChange={setSelectValue}
            value={selectValue}
          >
            {selectOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}

        <Button
          className='composit-search-select-option-search-button'
          type='primary'
          onClick={handleSearch}
          // disabled={dataLoading}
        >
          {locale.buttons.search}
        </Button>
        <Button
          className='composit-search-select-option-reset-button'
          disabled={searchValue === null && !selectValue?.length && !dateFrom && !dateTo}
          icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
          type='primary'
          onClick={handleReset}
        >
          {locale.buttons.resetFilters}
        </Button>
        {children}
      </Row>

      {dateRangeEnabled && (
        <Row
          className='composit-search-select-option-search composit-search-select-option-search__date'
          justify='space-between'
        >
          <div style={{ fontWeight: '600', fontSize: 17 }}>{getDateLabel()}</div>
          <Row style={{ gap: 20 }}>
            <RangePicker
              format={'DD-MM-YYYY'}
              onChange={onRangeChange}
              value={dateFrom && dateTo ? [dateFrom, dateTo] : null}
            />
            <Button
              className={`composit-search-time-range-button${
                dateFilter === DATE_FILTER.today ? '-selected' : ''
              }`}
              // disabled={dataLoading}
              type='primary'
              onClick={onTodayClick}
            >
              {locale.buttons.today}
            </Button>
            <Button
              className={`composit-search-time-range-button${
                dateFilter === DATE_FILTER.all ? '-selected' : ''
              }`}
              // disabled={dataLoading}
              type='primary'
              onClick={onAllTimeClick}
            >
              {locale.buttons.allTime}
            </Button>
          </Row>
        </Row>
      )}
    </>
  );
};
