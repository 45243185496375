import axiosClient from 'api/axiosClient';

import { API_ENDPOINTS } from '../../constants/api_endpoints';
import { useCustomMutation } from '../useCustomMutation';

export const useCancelEvent = (eventId: number, callbackFn?: () => void) => {
  return useCustomMutation<void, void>(
    () => axiosClient.post(API_ENDPOINTS.CANCEL_EVENT(eventId)),
    ['calendar-events'],
    callbackFn
  );
};
