import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEditAppointmentPayload } from 'api/interfaces/Appointment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditAppointment = (clinicId: number, appointmentId, onSuccess?: () => void) => {
  const mutationFn = (payload: IEditAppointmentPayload): Promise<void> =>
    axiosClient.put(`${API_ENDPOINTS.APPOINTMENTS(clinicId)}/${appointmentId}`, payload);

  return useCustomMutation(
    mutationFn,
    ['calendar-events', 'appointmentsList', ['appointment', clinicId, appointmentId]],
    onSuccess
  );
};
