import { breadcrubmsLocale } from 'i18n/breadcrumbs';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useI18n } from './usei18n';

export interface IBreadcrumb {
  title: React.ReactNode | string;
}

export const useBreadcrumbs = (clinicName: string): IBreadcrumb[] => {
  const locale: typeof breadcrubmsLocale['enGB'] = useI18n(breadcrubmsLocale);
  const location = useLocation();

  return useMemo((): IBreadcrumb[] => {
    const path = location.pathname;
    const segments = path.split('/').filter(Boolean);

    if (segments?.[0] !== 'clinic') return [];

    const breadcrumbs = [{ title: <Link to={'/dashboard'}>{clinicName}</Link> }];

    const getDetailsSegment = (index: number) => {
      const link = segments.slice(0, -1).join('/');
      return [
        ...breadcrumbs,
        { title: <Link to={`/${link}`}>{locale[segments?.[index]]}</Link> },
        { title: locale.details }
      ];
    };

    if (segments?.[4]) {
      return getDetailsSegment(3);
    }

    if (Number.isInteger(Number(segments?.[3]))) {
      return getDetailsSegment(2);
    }

    if (locale[segments?.[3]]) {
      const title = locale[segments?.[3]];
      return [...breadcrumbs, { title }];
    }

    if (locale[segments?.[2]]) {
      const title = locale[segments?.[2]];
      return [...breadcrumbs, { title }];
    }

    return [{ title: clinicName }];
  }, [location.pathname, clinicName]);
};
