import './Navbar.scss';

import { Button, Layout } from 'antd';
import { useSidebar } from 'api/store/sidebarStore';
import wrapMenuLeftIcon from 'assets/img/wrapMenuLeftIcon.svg';
import wrapMenuRightIcon from 'assets/img/wrapMenuRightIcon.svg';
import useOutsideClick from 'hooks/useClickOutside';
import * as ls from 'local-storage';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'utils/class-names';

export const Navbar = ({ children }: React.PropsWithChildren): JSX.Element => {
  const containerRef = useRef(null);
  const { sidebarToggle, sidebarOpened } = useSidebar();
  const className = '';
  const [menuWrapped, setMenuWrapped] = useState(false);

  const handleClickOutside = () => (sidebarOpened ? sidebarToggle() : null);

  useOutsideClick(containerRef, handleClickOutside);
  const navClasses = classNames({
    opened: sidebarOpened,
    'menu-wrapped': menuWrapped,
    [className]: !!className
  });

  useEffect(() => {
    const val = ls.get('manuWrapped');
    setMenuWrapped(!!val);
  }, []);

  const wrapSidebar = () => {
    setMenuWrapped(!menuWrapped);
    ls.set('manuWrapped', !menuWrapped);
  };

  return (
    <div>
      <Layout.Sider theme='light' ref={containerRef} className={`navbar ${navClasses}`}>
        <Button
          onClick={() => {
            wrapSidebar();
          }}
          className='menu-wrapper-btn'
        >
          <img src={menuWrapped ? wrapMenuRightIcon : wrapMenuLeftIcon} />
        </Button>
        {children}
      </Layout.Sider>
    </div>
  );
};
