import { Column } from '@ant-design/charts';
import { IAccountsBalances } from 'api/interfaces/Stats';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  accountsBalances?: IAccountsBalances;
  locale: ILocale;
};

export const AccountsBalancesPieChart = ({ accountsBalances, locale }: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;

  const remapAccounts = (): Array<{
    account_id: number;
    account_name: string;
    balance: number;
    label: string;
  }> =>
    accountsBalances?.data.map((item) => ({
      ...item,
      label: `${item.balance} ${currencies.uah}`
    }));

  return (
    <Column
      title={`${locale.labels.accountsCurrentBalances}: ${accountsBalances?.full_balance} ${currencies.uah}`}
      data={remapAccounts() || []}
      isStack={false}
      xField='account_name'
      yField='balance'
      colorField='account_name'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      tooltip={{ field: 'label' }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
