import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { useCustomMutation } from 'api/hooks/useCustomMutation';

import axiosClient from '../../axiosClient';

interface SkillPayload {
  name: string;
}

export const useAddSkill = (callbackFn?: () => void) => {
  const mutationFn = (payload: SkillPayload) => axiosClient.post(API_ENDPOINTS.SKILLS, payload);

  return useCustomMutation(mutationFn, ['skills'], callbackFn);
};
