import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IService } from 'api/interfaces/Service';

export const useServiceDetails = (clinicId: number, serviceId: number) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(`${API_ENDPOINTS.SERVICES(clinicId)}/${serviceId}`);
    return data;
  };

  return useQuery<IService>({
    queryFn,
    queryKey: ['serviceDetails', clinicId, serviceId],
    enabled: !!serviceId
  });
};
