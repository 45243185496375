import { BillType } from 'api/interfaces/Bills';
import income from 'assets/img/income.svg';
import spending from 'assets/img/spending.svg';
import React from 'react';

const FinanceIcon = ({ type }: { type: BillType }): JSX.Element => {
  switch (type) {
    case 'income':
      return <img src={income} className='finances-list-income-icon' />;
    case 'outcome':
      return <img src={spending} className='finances-list-income-icon' />;
  }
};

export default FinanceIcon;
