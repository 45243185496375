import { IShiftDetails } from 'api/interfaces/Shift';
import { create } from 'zustand';

interface IShiftState {
  shift: IShiftDetails;
  setOpenedShift: (data: IShiftDetails) => void;
  clearShift: () => void;
}

const useShiftStore = create<IShiftState>((set) => ({
  shift: { open: false } as IShiftDetails,
  setOpenedShift: (data: IShiftDetails) => set(({ shift }) => ({ shift: { ...shift, ...data } })),
  clearShift: () => set(() => ({ shift: null }))
}));

export default useShiftStore;
