import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IUserProfile } from 'api/interfaces/User';
import { useLocaleCode } from 'api/store/localeContext';
import useUserStore from 'api/store/userStore';

import { useCustomMutation } from '../useCustomMutation';
import { fetchUserProfile } from './useUserProfile';

export const useEditUser = (callback?: () => void) => {
  const setUser = useUserStore((state) => state.setUser);
  const { setLocale } = useLocaleCode();

  return useCustomMutation(
    (payload: Partial<IUserProfile>) => axiosClient.put(`${API_ENDPOINTS.USER}/profile`, payload),
    ['userProfile'],
    async () => {
      const userProfile = await fetchUserProfile();

      setUser(userProfile);
      setLocale(userProfile.locale);

      callback?.();
    }
  );
};
