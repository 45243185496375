import { FormInstance, Spin } from 'antd';
import { useCreateInvoice } from 'api/hooks/invoices/useCreateInvoice';
import { IInvoice, IInvoicePayload } from 'api/interfaces/Invoice';
import { useModal } from 'api/store/modalStore';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InvoiceForm } from './components/InvoiceForm';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  clinicId: number;
}>;

export const AddInvoiceForm = ({ clinicId, form, children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { closeModal } = useModal();

  const onSuccess = ({ data }: { data: IInvoice }): void => {
    closeModal();
    navigate(`/clinic/${clinicId}/inventory/invoices/${data?.id}`);
  };

  const createInvoice = useCreateInvoice(clinicId, onSuccess);

  const onSubmit = (payload: IInvoicePayload): void => {
    createInvoice.mutate({
      ...payload,
      purchase_date: (payload.purchase_date as dayjs.Dayjs)?.format('DD.MM.YYYY')
    });
  };

  return (
    <Spin spinning={createInvoice.isPending}>
      <InvoiceForm onFinish={onSubmit} form={form}>
        {children}
      </InvoiceForm>
    </Spin>
  );
};
