import { Form } from 'antd';
import { IStock } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { ReassignPriceForm } from '../forms/ReassignPriceForm';

type Props = {
  locale: ILocale;
  stock: IStock;
};

export const ReassignPriceModal = ({ locale, stock }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      title={`${locale.labels.reassignPrices} ${stock.name}`}
      centered
      open
      width={1100}
      form={form}
      onModalClose={closeModal}
    >
      <ReassignPriceForm form={form} locale={locale} stock={stock}>
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.invitations}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
        />
      </ReassignPriceForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
