import './ServiceItemForm.scss';

import { AutoComplete, Button, Col, Divider, Form, FormInstance, Input, Row, Select, Spin, Typography } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useDictionary } from 'api/hooks/dictionaries/useDictionary';
import { useEquipmentList } from 'api/hooks/equipment/useEquipmentList';
import { useCreateService } from 'api/hooks/services/useCreateService';
import { useEditService } from 'api/hooks/services/useEditService';
import { useToolsList } from 'api/hooks/tools/useToolsList';
import { IService } from 'api/interfaces/Service';
import { MODAL, useModal } from 'api/store/modalStore';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import { formatFloat } from 'helpers/NumbersFormatter';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { maxRule, requiredRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';

import { AddDictionaryModal } from '../addDictionary/AddDictionaryModal';
import { AddServiceFormStockListItem } from './AddServiceFormStockListItem';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues?: IService;
  setEditMode?: (val) => void;
  onFieldsChange?: (val) => void;
}>;

export const ServiceItemForm = ({
  form,
  initialValues,
  children,
  setEditMode = null,
  onFieldsChange
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { open, closeModal } = useModal();
  const locale = useLocale('private.services.services-list');
  const { availableModules } = useAccess();
  const formEditMode = Boolean(initialValues?.id);

  const { dictionary: serviceGroups } = useDictionary(clinicId, DICTIONARY.serviceGroups);
  const { tools } = useToolsList(clinicId);
  const { equipment } = useEquipmentList(clinicId);
  const createService = useCreateService(clinicId, closeModal);
  const editService = useEditService(clinicId, initialValues?.id, () => setEditMode(false));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState(null);
  const [selectedMinutes, setSelectedMinutes] = useState(null);
  const [duration, setDuration] = useState(null);
  const [minutesDropdownOpen, setMinutesDropdownOpen] = useState(false);
  const [groupSearchValue, setGroupSearchValue] = useState('');

  useEffect(() => {
    if (setEditMode != null) setEditMode(false);

    form.resetFields();

    if (initialValues?.duration) {
      const val = initialValues.duration;
      const minutes = val % 60;
      const hours = (val - minutes) / 60;
      setSelectedHours(hours.toString());
      setSelectedMinutes(minutes.toString());
    }
    if (initialValues?.stock) {
      form.setFieldValue('stock', initialValues?.stock);
    }
  }, [initialValues]);

  // todo API fix type
  const onSubmit = (data: any): void => {
    const payload = {
      ...data,
      tools: data?.tools?.map((el) => ({ id: el })),
      equipment: data?.equipment?.map((el) => ({ id: el })),
      duration
    };
    payload.stock = data?.stock?.length
      ? data.stock
          .filter((el) => el.id)
          .map((el) => ({ ...el, quantity: +formatFloat(el.quantity) }))
      : [];

    if (formEditMode) {
      payload.price = Number(formatFloat(payload?.price)).toFixed(2);
      editService.mutate(payload);
    } else {
      createService.mutate(payload);
    }
  };

  useEffect(() => {
    setDuration(Number(selectedHours) * 60 + Number(selectedMinutes));
  }, [selectedHours, selectedMinutes]);

  const equipmentList = getOptions(equipment);
  const toolsList = getOptions(tools);
  const serviceGroupsOptions = getOptions(serviceGroups);

  const getInitValues = () => {
    const data = {
      ...initialValues,
      tools: initialValues?.tools?.map((el) => el.id),
      equipment: initialValues?.equipment?.map((el) => el.id)
    };
    delete data.stock;
    return data;
  };

  const autocompleteHours = () =>
    Array.from({ length: 25 }, (_, index) => ({
      value: index.toString(),
      label: index
    }));

  const autocompleteMinutes = (): { value: string }[] =>
    Array.from({ length: 12 }, (_, index) => ({
      value: (index * 5).toString()
    }));

  const filterGroupOption = (input: string, option): boolean =>
    option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  return (
    <Spin spinning={createService.isPending || editService.isPending}>
      <Form
        onFinish={onSubmit}
        onFieldsChange={onFieldsChange}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={getInitValues()}
      >
        <Form.Item name='clinic_id' hidden>
          <Input />
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.code}
              name={'code'}
              rules={[maxRule(15, locale.messages.enterCode)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.serviseName}
              name={'name'}
              rules={[
                requiredRule(locale.messages.enterServiceName),
                maxRule(100, locale.messages.enterServiceName)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.serviseGroup}
              name={'service_group_id'}
              rules={[requiredRule(locale.messages.enterServiceGroup)]}
            >
              <Select
                showSearch
                virtual={false}
                onSearch={setGroupSearchValue}
                filterOption={filterGroupOption}
                notFoundContent={
                  <div onClick={() => open(MODAL.addDictionary)} id='addNewServiceGroupBtn'>
                    + {locale.labels.addServiceGroup}
                  </div>
                }
              >
                {serviceGroupsOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.price}
              rules={[requiredRule(locale.messages.enterServicePrice)]}
              name='price'
            >
              <VLXInputNumber />
            </Form.Item>
            <p style={{ fontWeight: '600', marginBottom: 8 }}>{locale.labels.duration}</p>
            <Row
              gutter={8}
              style={{
                width: '100%',
                alignItems: 'center',
                marginBottom: 20
              }}
            >
              <Col span={5}>
                <AutoComplete
                  id='duration-hours'
                  options={autocompleteHours()}
                  filterOption={(inputValue, option) =>
                    option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
                  }
                  onSelect={() => setDropdownOpen(false)}
                  value={selectedHours}
                  allowClear
                  placeholder='0'
                  showSearch
                  onChange={(e) => {
                    setSelectedHours(e);
                    formEditMode && onFieldsChange([{ e }]);
                  }}
                  open={dropdownOpen}
                  onSearch={() => setDropdownOpen(true)}
                  onBlur={() => setDropdownOpen(false)}
                  onFocus={() => setDropdownOpen(true)}
                />
              </Col>
              <Col span={5}>{locale.labels.hours}</Col>
              <Col span={5}>
                <AutoComplete
                  id='duration-minutes'
                  options={autocompleteMinutes()}
                  filterOption={(inputValue, option) =>
                    option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
                  }
                  onSelect={() => setMinutesDropdownOpen(false)}
                  value={selectedMinutes}
                  allowClear
                  placeholder='0'
                  showSearch
                  onChange={(e) => {
                    setSelectedMinutes(e);
                    formEditMode && onFieldsChange([{ e }]);
                  }}
                  open={minutesDropdownOpen}
                  onSearch={() => setMinutesDropdownOpen(true)}
                  onBlur={() => setMinutesDropdownOpen(false)}
                  onFocus={() => setMinutesDropdownOpen(true)}
                />
              </Col>
              <Col span={5}>{locale.labels.minutes}</Col>
            </Row>
            <TextEditor label={locale.labels.description} name={['description']} />
          </Col>
        </Row>
        {!availableModules.inventory ? (
          <>
            <Divider />
            <Typography.Text type='secondary'>{locale.messages.inventoryInPremium}</Typography.Text>
          </>
        ) : (
          <>
            <Form.Item
              name='stock'
              initialValue={[{ id: '', quantity: '', included_in_price: true }]}
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<h5>{locale.labels.itemsAndDrugs}</h5>}
              className='add-service-form-stock-container'
            >
              <Col span={24}>
                <Form.List name='stock'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <React.Fragment key={key}>
                          <AddServiceFormStockListItem
                            restField={restField}
                            form={form}
                            initialValues={initialValues}
                            locale={locale}
                            formEditMode={formEditMode}
                            onFieldsChange={onFieldsChange}
                            remove={remove}
                            name={name}
                          />
                        </React.Fragment>
                      ))}
                      <Row>
                        <Form.Item>
                          <Button
                            id='add-more-stock'
                            className='icofont icofont-plus'
                            type={'primary'}
                            style={{ borderRadius: 30 }}
                            onClick={() => add()}
                          >
                            {initialValues && form.getFieldValue('stock').length
                              ? locale.buttons.addMore
                              : locale.buttons.add}
                          </Button>
                        </Form.Item>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Col>
            </Form.Item>
            <>
              <Col span={24} md={12}>
                <Form.Item
                  className='add-equipment-form__multi-select-field'
                  label={<h5>{locale.labels.equipment}</h5>}
                  name='equipment'
                >
                  <Select
                    mode='multiple'
                    filterOption={(input, option) =>
                      option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                    }
                    showSearch
                  >
                    {equipmentList}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  className='add-tools-form__multi-select-field'
                  label={<h5>{locale.labels.tools}</h5>}
                  name='tools'
                >
                  <Select
                    mode='multiple'
                    filterOption={(input, option) =>
                      option?.title.toString().toLowerCase().includes(input?.toLowerCase())
                    }
                    showSearch
                  >
                    {toolsList}
                  </Select>
                </Form.Item>
              </Col>
            </>
          </>
        )}
        {children}
        <AddDictionaryModal
          defaultDictionaryNameValue={groupSearchValue}
          title={locale.labels.addServiceGroup}
          dictionaryKey={DICTIONARY.serviceGroups}
          onSuccess={(id: number) => {
            form.setFieldValue('service_group_id', id);
            if (formEditMode) onFieldsChange([{ service_group_id: id }]);
          }}
        />
      </Form>
    </Spin>
  );
};
