import '../scss/EquipmentForm.scss';
import './EquipmentItemForm.scss';

import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { useEmployeesList } from 'api/hooks/employees/useEmployeesList';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { IEquipmentDetails, IEquipmentPayload } from 'api/interfaces/Equipment';
import VLXInputNumber from 'components/VLXInputNumber';
import { deviceStatuses, deviceTypes } from 'constants/dictionary/default/inventoryOptions';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useMemo, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { Option } = Select;

interface RoomType {
  value: number | string;
  label: string;
}

interface Props {
  form: FormInstance;
  children: JSX.Element;
  onFieldsChange?: (e) => void;
  onFinish: (data: IEquipmentPayload) => void;
  initialValues?: IEquipmentDetails;
  initialResponsible?: RoomType;
  initialRoom?: RoomType;
  initialLocationType?: string;
  initialLocationDetails?: string;
}

export const EquipmentItemForm = ({
  onFieldsChange,
  onFinish,
  form,
  initialValues,
  initialResponsible,
  initialRoom,
  initialLocationType,
  initialLocationDetails,
  children
}: Props): JSX.Element => {
  const [locationType, setLocationType] = useState<string>(initialLocationType || 'room');
  const clinicId = useClinicId();
  const locale = useLocale('private.inventory.technical-equipment');
  const locations = useLocalizedList(locationTypes);
  const { rooms } = useRoomsList(clinicId);
  const { employees } = useEmployeesList(clinicId, { page: 0 });

  const roomsList = rooms
    .filter((room) => !room.deleted || room.id === initialValues?.location?.id)
    .map((item) => (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        {item.name}
      </Option>
    ));

  const employeesList = useMemo(
    () =>
      employees.map((item) => (
        <Option key={item.full_name} title={item.full_name} value={item.id}>
          {item.full_name}
        </Option>
      )),
    [employees]
  );

  return (
    <Form
      onFieldsChange={onFieldsChange}
      key={'form'}
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
      className='add-equipment-form'
    >
      <Row gutter={[40, 0]} className={'form-row'}>
        <Col span={24} sm={20} md={12} xl={8} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.deviceDetails}</h5>}>
            <Form.Item
              name='name'
              label={locale.labels.name}
              rules={[
                requiredRule(locale.messages.nameNotValid),
                patternRule(PATTERNS.CHAR_100_MAX, locale.messages.nameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='serial_number'
              label={locale.labels.serialNumber}
              rules={[
                requiredRule(locale.messages.nameNotValid),
                patternRule(PATTERNS.CHAR_100_MAX, locale.messages.nameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='manufacturer' label={locale.labels.manufacturer}>
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.warrantyExpirationDate}
              name={'warranty_expiration_date'}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item name='equipment_type' label={locale.labels.deviceType}>
              <Select filterOption={false} options={useLocalizedList(deviceTypes)} />
            </Form.Item>
            <Form.Item
              name={'user_id'}
              label={locale.labels.responsibleContact}
              initialValue={initialResponsible}
              className='add-equipment-form__single-select-field'
            >
              <Select
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {employeesList}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.depreciationInfo}</h5>}>
            <Form.Item
              label={locale.labels.deviceUsefulLifetime}
              name='useful_life'
              rules={[patternRule(PATTERNS.DIGITS, locale.messages.onlyNumberAllowed)]}
            >
              <Input type={'number'} />
            </Form.Item>
            <Form.Item
              label={locale.labels.deviceDepreciationPeriod}
              name='depreciation_period'
              rules={[patternRule(PATTERNS.DIGITS, locale.messages.onlyNumberAllowed)]}
            >
              <Input type={'number'} />
            </Form.Item>
            <Form.Item
              label={locale.labels.deviceDepreciationRate}
              name='depreciation_rate'
              rules={[patternRule(PATTERNS.DIGITS, locale.messages.onlyNumberAllowed)]}
            >
              <Input type={'number'} />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={24} sm={20} md={12} xl={8} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.purchaseInformation}</h5>}>
            <Form.Item name='purchase_date' label={locale.labels.purchaseDate}>
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
              className={'purchase-price-form-item'}
              name='purchase_price'
              label={locale.labels.purchasePrice}
            >
              <VLXInputNumber />
            </Form.Item>
            {initialValues?.depreciated_price != null && true && (
              <Form.Item
                className={'purchase-price-form-item'}
                name='depreciated_price'
                label={locale.labels.depreciatedPrice}
              >
                <VLXInputNumber disabled />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.statusAndMaintenance}</h5>}>
            <Form.Item
              name={'device_status'}
              label={locale.labels.deviceStatus}
              initialValue={
                initialValues?.device_status ? initialValues?.device_status : 'operating'
              }
            >
              <Select filterOption={false} options={useLocalizedList(deviceStatuses)} />
            </Form.Item>
            <Form.Item label={locale.labels.nextMaintenanceDate} name={'next_maintenance_date'}>
              <DatePicker disabledDate={(d) => !d || d.isBefore(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.location}</h5>}>
            <Form.Item
              className='add-equipment-form__single-select-field'
              id='location_type'
              name={['location', 'type']}
              label={locale.labels.location}
              rules={[requiredRule(locale.messages.emptyLocation)]}
              initialValue={initialLocationType || 'room'}
            >
              <Select
                className='equipment-form-room-select'
                filterOption={false}
                onSelect={setLocationType}
                options={locations}
              />
            </Form.Item>
            <Form.Item
              name={['location', 'details']}
              label={locale.labels.details}
              hidden={locationType !== 'other'}
              rules={locationType === 'other' ? [requiredRule(locale.messages.emptyLocation)] : []}
              initialValue={initialLocationDetails}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['location', 'id']}
              className='add-equipment-form__single-select-field'
              hidden={locationType !== 'room'}
              label={locale.labels.room}
              initialValue={initialRoom?.value}
            >
              <Select
                allowClear={true}
                filterOption={(input, option) => {
                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {roomsList}
              </Select>
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      {children}
    </Form>
  );
};
