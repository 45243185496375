import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ITool, IToolPayload } from 'api/interfaces/Tool';
import { useModal } from 'api/store/modalStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateTool = (clinicId: number, callbackFn?: (data: ITool) => void) => {
  const { closeModal } = useModal();
  return useCustomMutation(
    (payload: IToolPayload) => axiosClient.post(`${API_ENDPOINTS.TOOLS(clinicId)}`, payload),
    ['toolsList', clinicId],
    closeModal
  );
};
