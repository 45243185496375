import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddInvoiceForm } from './AddInvoiceForm';

export const AddInvoiceModal = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();
  const locale = useLocale('private.inventory.invoices');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useAccess();

  return (
    <VLXModal
      form={form}
      title={locale.labels.createInvoice}
      open
      onModalClose={closeModal}
      width={800}
    >
      <AddInvoiceForm form={form} clinicId={clinicId}>
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.invoices}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </AddInvoiceForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
