import { Select, Spin } from 'antd';
import { useClientSearch } from 'api/hooks/clients/useClientSearch';
import { IClient } from 'api/interfaces/Client';
import * as config from 'config/config-manager';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';

const { Option } = Select;

type Props = {
  onChange?: (value, client) => void;
  selectedClientId?: number;
  disabled?: boolean;
  patientIds?: number[];
};

export const ClientsSelect = ({
  onChange,
  disabled,
  selectedClientId,
  patientIds
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.select-with-required-search');

  const [searchValue, setSearchValue] = useState(null);
  const { data: clients, isLoading } = useClientSearch(clinicId, searchValue);

  useEffect(() => {
    if (patientIds?.length || selectedClientId) {
      setSearchValue({ id: selectedClientId });
    }
  }, [patientIds, selectedClientId]);

  const onClientSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;
    setSearchValue({ name: value });
  };

  const clientsList: JSX.Element[] = useMemo(
    () =>
      clients?.data.map((item) => {
        const title = `${item.first_name || ''} ${item.last_name || ''} ${
          item.phone_number ? `(+${item.country_code}${item.phone_number})` : ''
        }`;
        return (
          <Option
            key={`${item.id}_${crypto.randomUUID()}`}
            data={item}
            value={item.id}
            title={title}
          >
            {title}
            {blacklistedStatus(item)}
          </Option>
        );
      }),
    [clients]
  );

  return (
    <Select
      placeholder={locale.placeholders.inputMinForSearch}
      id={'clients-select'}
      virtual={false}
      onChange={onChange}
      notFoundContent={isLoading ? <Spin size='small' /> : null}
      value={isLoading ? null : selectedClientId}
      disabled={disabled}
      filterOption={false}
      showSearch
      allowClear
      onSelect={(id, { data: client }: { data: IClient }) => {
        onChange(id, client);
      }}
      onSearch={debounce(onClientSearch, config.selectSearchDelay)}
    >
      {clientsList}
    </Select>
  );
};
