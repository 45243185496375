import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvoice } from 'api/interfaces/Invoice';

export const useInvoiceDetails = (clinicId: number, invoiceId: number) => {
  return useQuery<IInvoice>({
    queryKey: ['invoiceDetails', clinicId, invoiceId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}`);
      return data;
    },
    enabled: !!invoiceId
  });
};
