import './AddDictionary.scss';

import { Form, FormInstance, Input, Row } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useCreateDictionary } from 'api/hooks/dictionaries/useCreateDictionary';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { requiredRule } from 'utils/form-rules';

const { TextArea } = Input;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  onSuccess: (id: number) => void;
  defaultDictionaryNameValue: string;
  dictionaryKey: DICTIONARY;
}>;

export const AddDictionaryForm = ({
  defaultDictionaryNameValue,
  form,
  children,
  onSuccess,
  dictionaryKey
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.dictionaries');

  const createDictionary = useCreateDictionary(clinicId, dictionaryKey, onSuccess);

  return (
    <Form onFinish={createDictionary.mutate} autoComplete='off' layout='vertical' form={form}>
      <Row style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Form.Item
          label={locale.labels.name}
          name='name'
          initialValue={defaultDictionaryNameValue}
          rules={[requiredRule(locale.messages.specifyName)]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={locale.labels.description} name='description'>
          <TextArea rows={3} />
        </Form.Item>
      </Row>
      {children}
    </Form>
  );
};
