import './scss/VLXModalFooter.scss';

import { Col, Form, FormInstance, Row } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import { useLocale } from 'hooks/useLocale';
import { IAccesses } from 'interfaces/user-accesses';
import React, { useEffect, useState } from 'react';

import { VLXButton } from './VLXButton';

type Props = {
  accessGroup?: IAccesses;
  editMode?: boolean;
  onSaveModalClick?: () => void;
  hideCancelBtn?: boolean;
  showCreateBtn?: boolean;
  form: FormInstance;
  onClickCallback?: () => void;
};

export const VLXModalFooter = ({
  accessGroup,
  editMode,
  onSaveModalClick,
  hideCancelBtn,
  showCreateBtn,
  form,
  onClickCallback
}: Props) => {
  const locale = useLocale('public.modals.general');
  const values = Form.useWatch([], form);
  const { closeModal } = useModal();

  const [formInvalid, setFormInvalid] = useState<boolean>(false);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormInvalid(false);
      },
      () => {
        setFormInvalid(true);
      }
    );
  }, [values]);

  const onConfirmCancelClick = () => {
    setShowCancelModal(false);
    closeModal();
    if (onClickCallback) onClickCallback();
  };

  const onCancelClick = () => {
    if (form.isFieldsTouched()) {
      setShowCancelModal(true);
    } else {
      closeModal();
      if (onClickCallback) onClickCallback();
    }
  };

  const buttons = (
    <Row className='vlx-modal-footer__buttons-container'>
      {!hideCancelBtn && (
        <VLXButton onButtonClick={onCancelClick} buttonClass='vlx-cancel-button' hideButton={!editMode}
        buttonText={locale.buttons.cancel} />
      )}
      <Form.Item className={'submit-form-item'}>
        {(accessGroup.create || showCreateBtn) && (
          <VLXButton onButtonClick={onSaveModalClick} buttonClass='vlx-save-button' buttonDisabled={formInvalid}
            buttonHtmlType='submit' hideButton={!editMode} buttonText={locale.buttons.save} />
        )}
      </Form.Item>
    </Row>
  );

  return (
    <>
      <Row className='vlx-modal-footer' justify='space-between'>
        <Col span={24} md={12}>
          {formInvalid && <span className='wrong-form-text'>{locale.messages.fillAllRequiredFields}</span>}
        </Col>
        {buttons}
      </Row>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
