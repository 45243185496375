import { Select, Spin } from 'antd';
import { useStockSearch } from 'api/hooks/stock/useStockSearch';
import { IStock } from 'api/interfaces/Stock';
import * as config from 'config/config-manager';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';

const { Option } = Select;

type Props = {
  onChange?: (id, data) => void;
  onInit?: (id, data) => void;
  clinicId: number;
  selectedStockId?: number;
  locale: ILocale;
  trackOutOfStock?: boolean;
};

export const StockSelect = ({
  onChange,
  onInit,
  selectedStockId,
  locale,
  trackOutOfStock = true
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const commonLocaleProps = useLocale('private.select-with-required-search');

  const [searchValue, setSearchValue] = useState(null);
  const [stockId, setStockId] = useState(selectedStockId);

  const { data: stockList, isLoading } = useStockSearch(clinicId, searchValue);

  useEffect(() => {
    if (selectedStockId) {
      setSearchValue({ id: selectedStockId });
    }
  }, [selectedStockId]);

  useEffect(() => {
    if (selectedStockId && stockList?.data) {
      const selectedStock = findStockItem(selectedStockId);
      if (selectedStock) {
        onInit?.(selectedStockId, selectedStock);
      }
    }
  }, [selectedStockId, stockList]);

  const onStockSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;
    setSearchValue({ name: value });
  };

  const findStockItem = (id: number): IStock => stockList?.data.find((stock) => stock.id === id);

  const stockSelectLabel = (item: IStock): string => {
    const itemManufacturer = item?.manufacturer;
    const manufacturer = itemManufacturer ? ` (${itemManufacturer})` : '';
    const isOutOfStock = item.remaining_capacity === 0 ? `| ${locale.labels.outOfStock}` : '';
    return `${item?.name} ${manufacturer} ${trackOutOfStock ? isOutOfStock : ''}`;
  };

  const stockListOptions: JSX.Element[] = useMemo(
    (): JSX.Element[] =>
      (stockList?.data || [])
        ?.sort((a, b) => b.remaining_capacity - a.remaining_capacity)
        ?.filter((item) => !item?.deleted || item?.id === selectedStockId)
        ?.map((item) => (
          <Option
            className='item-form-select'
            disabled={trackOutOfStock && item.remaining_capacity <= 0}
            key={item.id}
            title={item.name + item.manufacturer}
            value={item.id}
          >
            {stockSelectLabel(item)}
          </Option>
        )),
    [stockList]
  );

  // Prevent selection of the first stock item in the drop-down when using barcode scanner
  const handleKeyDown = (event): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Select
        placeholder={commonLocaleProps.placeholders.inputMinForSearch}
        id={'stock-select'}
        virtual={false}
        onChange={(id, data) => {
          onChange(id, findStockItem(id));
        }}
        onSelect={(id) => setStockId(id)}
        onClear={() => setStockId(null)}
        notFoundContent={isLoading ? <Spin size='small' /> : null}
        value={isLoading ? null : stockId}
        filterOption={false}
        showSearch
        allowClear
        onInputKeyDown={handleKeyDown}
        onSearch={debounce(onStockSearch, config.selectSearchDelay)}
      >
        {stockListOptions}
      </Select>
    </div>
  );
};
