import { Button, Checkbox, Col, Form, FormInstance, FormItemProps, Row } from 'antd';
import { IService } from 'api/interfaces/Service';
import { IStock } from 'api/interfaces/Stock';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import { usePlural } from 'pages/inventory/stock/utils/usePlural';
import React, { useState } from 'react';

import { StockSelect } from '../../components/stockSelect/StockSelect';

interface Props {
  name: number;
  locale: ILocale;
  remove: (name: number) => void;
  onFieldsChange: (e) => void;
  formEditMode: boolean;
  form: FormInstance;
  initialValues: IService;
  restField: FormItemProps<string[]>;
}

export const AddServiceFormStockListItem = ({
  restField,
  locale,
  form,
  initialValues,
  formEditMode,
  onFieldsChange,
  remove,
  name
}: Props): JSX.Element => {
  const clinicId = useClinicId();

  const [selectedStock, setSelectedStock] = useState<IStock>(null);
  const { unitFormat } = usePlural();

  const getStockMeasureLabel = (): string => {
    const stockValues = form.getFieldValue('stock');
    if (stockValues[name]?.id) {
      return unitFormat(1, selectedStock?.unit_of_measurement);
    }
    return '';
  };

  const [stockLabel, setStockLabel] = useState(getStockMeasureLabel());

  const onStockSelect = (_id: number, selectedStock: IStock): void => {
    setSelectedStock(selectedStock);
    setStockLabel(unitFormat(1, selectedStock?.unit_of_measurement));
  };

  return (
    <Row className='add-service-form-stock-container__row'>
      <Col span={24} md={10}>
        <Form.Item
          {...restField}
          className='add-service-form-stock__name long-value-form-item'
          label={locale.labels.name}
          name={[name, 'id']}
        >
          <StockSelect
            trackOutOfStock={false}
            onInit={onStockSelect}
            onChange={onStockSelect}
            selectedStockId={initialValues?.stock?.[name]?.id}
            clinicId={clinicId}
            locale={locale}
          />
        </Form.Item>
      </Col>
      <Col span={24} md={5} className='measurement-quantity-container'>
        <Form.Item {...restField} name={[name, 'quantity']} label={locale.labels.quantity}>
          <VLXInputNumber precision={3} />
        </Form.Item>
        <span className='measurement-label'>{stockLabel}</span>
      </Col>
      <Col className='included-in-price-container'>
        <Form.Item
          {...restField}
          name={[name, 'included_in_price']}
          valuePropName='checked'
          initialValue={initialValues?.included_in_price ? initialValues.included_in_price : true}
        >
          <Checkbox defaultChecked>{locale.labels.includedInPrice}</Checkbox>
        </Form.Item>
      </Col>
      <Col span={1} md={1}>
        <Button
          onClick={() => {
            remove(name);
            formEditMode && onFieldsChange([{ deleteAction: 'deleteAction' }]);
          }}
          danger
          type='primary'
          shape={'circle'}
          className={'inventory-update-buttons'}
        >
          <span className='icofont icofont-trash' />
        </Button>
      </Col>
    </Row>
  );
};
