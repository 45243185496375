import 'assets/scss/permissions-search.scss';

import { Form, Input, List, Space, Spin } from 'antd';
import { useRoleDetails } from 'api/hooks/roles/useRoleDetails';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

const { Search } = Input;

type Props = React.PropsWithChildren<{
  clinicId: number;
  selectedRoleId: number;
}>;

type PermissionData = { description: string; chosen: boolean; title: string; key: number };

export const RoleDetails = ({ clinicId, children, selectedRoleId }: Props): JSX.Element => {
  const [form] = Form.useForm();

  const { data: selectedRole, isPending } = useRoleDetails(clinicId, selectedRoleId);
  const [permissionsData, setPermissionsData] = useState<PermissionData[]>([]);
  const locale = useLocale('private.administration.roles');

  const getData = (): void => {
    const data = selectedRole?.permissions?.map((item) => ({
      key: item.id,
      title: item.name,
      description: '',
      chosen: false
    }));
    setPermissionsData(data);
  };

  useEffect(() => {
    form.setFieldsValue(selectedRole);
  }, [form, selectedRole]);

  useEffect(() => {
    getData();
  }, [selectedRole]);

  const onSearch = (input: string): void => {
    if (input === '') {
      getData();
    }
    const data = permissionsData.filter((item) => item.title.includes(input));
    setPermissionsData(data);
  };

  return (
    <Spin spinning={isPending}>
      <Form
        autoComplete='off'
        form={form}
        layout={'vertical'}
        initialValues={selectedRole}
        style={{ marginLeft: 20 }}
      >
        <div className='row' style={{ margin: '10px 20px' }}>
          <div className='col-md-4  col-sm-12'>
            <Form.Item label={locale.labels.name} name='name'>
              <Input disabled={true} variant='borderless' />
            </Form.Item>
          </div>
          <div className='col-md-6  col-sm-12'>
            <Form.Item label={locale.labels.permissions}>
              <Search
                className={'permissions-search'}
                placeholder={locale.labels.inputSearchText}
                onSearch={onSearch}
                onChange={getData}
              />
              <List
                size='large'
                style={{
                  overflow: 'auto',
                  maxHeight: 300,
                  marginTop: 10
                }}
                bordered
                dataSource={permissionsData}
                renderItem={(item) => <List.Item>{item.title}</List.Item>}
              />
            </Form.Item>
          </div>
          <div className='col-md-2  col-sm-12'>
            <Space style={{ marginTop: 30 }}>{children}</Space>
          </div>
        </div>
      </Form>
    </Spin>
  );
};
