import './ServiceDetails.scss';

import { Col, Form, Row, Spin, Typography } from 'antd';
import { useDeleteService } from 'api/hooks/services/useDeleteService';
import { useServiceDetails } from 'api/hooks/services/useServiceDetails';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import useServiceId from 'hooks/useServiceId';
import { ServiceItemForm } from 'layout/modals/addService/ServiceItemForm';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const ServiceDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const serviceId = useServiceId();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const { data, isPending } = useServiceDetails(clinicId, serviceId);
  const deleteService = useDeleteService(clinicId);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true });
  }, [values]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    form.setFieldValue('stock', data?.stock);
    setShowCancelModal(false);
    setEditMode(false);
  };

  const onFieldsChange = (e): void => {
    if (e.length < 2 && e.length !== 0 && !editMode) {
      setEditMode(true);
    }
  };

  const onConfirmDeleteClick = (): void => {
    deleteService.mutate(serviceId);
    navigate(`/clinic/${clinicId}/services`);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>{data?.name}</Title>
        </Col>
      </Row>
      <Spin spinning={isPending} className='services-details-page-spin-container'>
        {data && (
          <ServiceItemForm
            form={form}
            setEditMode={setEditMode}
            onFieldsChange={onFieldsChange}
            initialValues={data}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.services}
              editMode={editMode}
              onEditClick={() => setEditMode(true)}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  setEditMode(false);
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </ServiceItemForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
        <ConfirmDeleteModal
          type={'device'}
          open={showDeleteModal}
          onOk={onConfirmDeleteClick}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Spin>
    </>
  );
};

export default ServiceDetails;
