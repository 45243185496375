import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IVaccinationsList } from 'api/interfaces/Vaccination';

import { useTableData } from '../useTableData';

export const useVaccinationsList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.VACCINATIONS(clinicId);
  const result = useTableData<IVaccinationsList>(endpoint, defaultParams, ['vaccinationsList']);

  return {
    ...result,
    vaccinations: result.data.data
  };
};
