import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillsList } from 'api/interfaces/Bills';

import { useTableData } from '../useTableData';

export const useBillsList = (clinicId: number, defaultParams: Record<string, string | number>) => {
  const endpoint = API_ENDPOINTS.BILLS(clinicId);
  const result = useTableData<IBillsList>(endpoint, defaultParams, ['billsList', clinicId]);

  return {
    ...result,
    bills: result.data.data
  };
};
