import { Card, Col, Row } from 'antd';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IClientDetails } from 'api/interfaces/Client';
import { TextEditorView } from 'components/TextEditorView';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getTime } from 'utils/get-time';
import { getParticipants, getServices } from 'utils/string-from-entities-list';

type Props = {
  appointment: IAppointmentDetails;
  appointmentClient: IClientDetails;
  editAppointmentBtn: JSX.Element;
  editClientBtn: JSX.Element;
};

export const AppointmentCard = ({
  appointment,
  appointmentClient,
  editAppointmentBtn,
  editClientBtn
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.appointments.event');

  const clientAddress = useMemo((): JSX.Element => {
    const address =
      appointmentClient &&
      ['country', 'region', 'city', 'address']
        .map((k) => appointmentClient[k])
        .filter((value) => value && value !== '')
        .join(', ');
    return (
      address && (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.address}: </span>
          <span id='client-address'>{address}</span>
        </>
      )
    );
  }, [appointmentClient]);

  return (
    <Row className='cards-container'>
      <Col span={24} md={14}>
        <Card className='ant-card-bordered personal-info-card with-shadow'>
          <div className='card-content'>
            <Col>{appointment?.start_time && getTime(appointment)}</Col>
            <Col>
              {appointment?.services?.length > 0 && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.services}: </span>
                  <span id='appointment-services'>{getServices(appointment?.services)}</span>
                </>
              )}
            </Col>
            <Col>
              {appointment?.doctor?.first_name && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.doctor}: </span>
                  <Link
                    id='doctor-name'
                    className={'custom-links'}
                    to={`/clinic/${clinicId}/administration/employees/${appointment?.doctor?.id}`}
                  >{`${appointment?.doctor?.first_name} ${appointment?.doctor?.last_name} `}</Link>
                  (
                  <Link
                    id='doctor-phone'
                    to={`tel:${appointment?.doctor?.country_code}${appointment?.doctor?.phone_number}`}
                    className='d-flex align-baseline nowrap'
                  >
                    {appointment?.doctor?.phone_number
                      ? `+${appointment?.doctor?.country_code}${appointment?.doctor?.phone_number}`
                      : '-'}
                  </Link>
                  )
                </>
              )}
            </Col>
            <Col>
              {appointment?.participants.length !== 0 && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.otherParticipants}: </span>
                  <span id='participants'>{getParticipants(appointment?.participants)}</span>
                </>
              )}
            </Col>
            <Col>
              {appointment?.location?.details && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.location}: </span>
                  <span id='location'>
                    {appointment?.location?.details ? appointment?.location?.details : '-'}
                  </span>
                </>
              )}
            </Col>
            <Col>
              {appointment?.notes && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                  <TextEditorView value={appointment?.notes} id='notes' />
                </>
              )}
            </Col>
            {editAppointmentBtn}
          </div>
        </Card>
      </Col>
      <Col span={24} md={10}>
        <Card className='ant-card-bordered personal-info-card with-shadow client-container'>
          <div className='card-content'>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
                {!!appointmentClient && (
                  <Link
                    id='client-name'
                    className={'custom-links'}
                    to={`/clinic/${clinicId}/clients/${appointmentClient?.id}`}
                  >
                    {appointmentClient?.first_name + ' '}
                    {appointmentClient?.last_name !== null && appointmentClient?.last_name}
                  </Link>
                )}
                {blacklistedStatus(appointmentClient)}
              </>
            </Col>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.phoneNumber}: </span>
                {appointmentClient?.phone_number ? (
                    <Link
                      id='client-phone'
                      to={`tel:${appointmentClient?.country_code}${appointmentClient?.phone_number}`}
                      className='d-flex align-baseline nowrap'
                    >
                      {`+${appointmentClient.country_code}${appointmentClient.phone_number}`}
                    </Link>
                  )
                  : <span>{locale.labels.notSpecified}</span>}
              </>
            </Col>
            <Col style={{ flexWrap: 'nowrap', display: 'flex' }}>
              {appointmentClient?.email && (
                <>
                  <span style={{ fontWeight: 600, minWidth: 55 }}>E-mail: </span>
                  <Link
                    id='client-email'
                    to={`mailto:${appointmentClient?.email}`}
                    className='custom-links d-flex align-baseline nowrap ellipsis-overflow-text'
                  >
                    {appointmentClient?.email}
                  </Link>
                </>
              )}
            </Col>
            <Col>{clientAddress}</Col>
            {editClientBtn}
          </div>
        </Card>
      </Col>
    </Row>
  );
};
