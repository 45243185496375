import '../stock/scss/StockList.scss';
import './InventoryInfo.scss';

import { Card, Col, Pagination, Row, Spin, Statistic, Table, TableColumnProps } from 'antd';
import { useStockList } from 'api/hooks/stock/useStockList';
import { useStockStats } from 'api/hooks/stock/useStockStats';
import { IStock } from 'api/interfaces/Stock';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { scrollToTop } from 'helpers/list-helpers';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { stockStatuses } from 'i18n/stockStatuses';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import getStatus from '../stock/helpers/TagSelector';
import { usePlural } from '../stock/utils/usePlural';

const defaultParams = {
  page: 1,
  items: 10,
  'q[status][]': ['out_of_stock', 'critical_level'],
  sort_key: 'created_at',
  sort_order: 'desc'
};

const InventoryInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const userAccess = useAccess();
  const navigate = useNavigate();

  const statuses = useI18n(stockStatuses);
  const { unitFormat } = usePlural();

  const locale = useLocale('private.inventory.stock');

  const {
    stocks,
    isLoading: stockLoading,
    params,
    data,
    updateParams,
    updateSorting
  } = useStockList(clinicId, defaultParams);
  const { data: stats, isPending: statsLoading } = useStockStats(clinicId);

  const redirectToStockDetails = (record: IStock) =>
    userAccess.accesses.stock.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/stock/${record.id}`);
      }
    };

  const getUsageMeasurementLabel = (stock: IStock): string => {
    if (!stock.quantity) return '-';

    return `${stock.quantity} (${stock.remaining_capacity} ${unitFormat(
      stock.remaining_capacity,
      stock.unit_of_measurement
    )})`;
  };

  const getStatusLabel = (e: IStock): '-' | JSX.Element => {
    if (!e?.status) return '-';

    return getStatus({ value: e.status, label: statuses[e.status] });
  };

  const columns = useMemo(
    (): TableColumnProps<IStock>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        onCell: redirectToStockDetails,
        render: (e) => (e.name ? ListFieldWithTooltip(e.name, 15) : '-')
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        onCell: redirectToStockDetails,
        render: (e) => (e.manufacturer ? ListFieldWithTooltip(e.manufacturer, 15) : '-')
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: (a, b) => (a.status > b.status ? 1 : -1),
        onCell: redirectToStockDetails,
        render: getStatusLabel
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1),
        onCell: redirectToStockDetails,
        render: getUsageMeasurementLabel
      }
    ],
    [stocks]
  );

  return (
    <Spin spinning={statsLoading}>
      <Row gutter={[20, 0]} className='inventory-info-page'>
        <Col span={24} sm={24} md={8} lg={7} xl={6}>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <div className='inventory-info-page__statistics-box'>
              <Statistic
                style={{ display: 'flex', flexDirection: 'column' }}
                title={locale.labels.totalStockProductsCount}
                valueStyle={{
                  color: '#cf1322',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stats?.available_stock_count}
              />
              <Statistic
                style={{ display: 'flex', flexDirection: 'column' }}
                title={locale.labels.runningOutOfStock}
                valueStyle={{
                  color: '#3f8600',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stats?.low_stock_count}
                precision={0}
              />
              <Statistic
                style={{ display: 'flex', flexDirection: 'column' }}
                title={locale.labels.ranOutOfStock}
                valueStyle={{
                  color: '#cf1322',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stats?.out_of_stock_count}
                precision={0}
              />
            </div>
          </Card>
        </Col>
        <Col span={24} sm={24} md={16} lg={17} xl={18}>
          <Table
            pagination={false}
            className='accent-header stock-list'
            rowKey='id'
            loading={stockLoading}
            dataSource={stocks}
            columns={columns}
            onChange={updateSorting}
          />
          <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
            <Pagination
              current={+params.page}
              total={data?.metadata.total || 0}
              pageSize={20}
              onChange={(page) => {
                updateParams({ page });
                scrollToTop();
              }}
            />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default InventoryInfo;
