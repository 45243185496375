import './scss/Avatar.scss';

import { Button, Modal, Slider, Spin } from 'antd';
import React, { ChangeEvent, useMemo, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

import ConfirmCancelModal from '../components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from '../components/ViewDetailsButtonsGroup';
import { useLocale } from '../hooks/useLocale';

interface Props {
  src: string;
  loading?: boolean;
  editAccess?: boolean;
  onImageChange?: (file, cb) => void;
  changeAvatarModalVisible?: boolean;
  setChangeAvatarModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  avatarTitle?: string;
}

export const AvatarComponent = ({
  src,
  loading,
  editAccess,
  onImageChange,
  changeAvatarModalVisible,
  setChangeAvatarModalVisible,
  avatarTitle
}: Props): JSX.Element => {
  const locale = useLocale('private.image-loader-component');
  let editor: any = '';

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: ''
  });

  const clinicAvatar = useMemo(
    (): JSX.Element => (
      <img
        onClick={() => {
          if (editAccess) {
            setChangeAvatarModalVisible(true);
          }
        }}
        alt=''
        className='clinic-avatar'
        src={src}
      />
    ),
    [editAccess, src]
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const url = URL.createObjectURL(e.target.files[0]);

    setPicture({
      ...picture,
      img: url,
      cropperOpen: true
    });
  };

  const handleSlider = (value: number): void => {
    setPicture({
      ...picture,
      zoom: value
    });
  };

  const setEditorRef = (ed): void => {
    editor = ed;
  };

  const handleSave = (): void => {
    if (picture.img) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      convertToFile(croppedImg);
    }
  };

  const convertToFile = async (dataUrl: string): Promise<void> => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const name = (Math.random() + 1).toString(36).substring(7);
    const newFile = new File([blob], name, { type: 'image/jpeg' });
    onImageChange(newFile, () =>
      setPicture({ img: null, croppedImg: '', cropperOpen: false, zoom: 2 })
    );
  };

  const onConfirmCancelClick = (): void => {
    if (picture.img) {
      editor.getImageScaledToCanvas().toDataURL();
      setPicture({ img: null, croppedImg: '', cropperOpen: false, zoom: 2 });
    }
    setChangeAvatarModalVisible(false);
    setShowCancelModal(false);
  };

  const onCancelClick = (): void => {
    if (picture.img) {
      setShowCancelModal(true);
    } else {
      setChangeAvatarModalVisible(false);
    }
  };

  return (
    <>
      {clinicAvatar}
      <Modal width={800} open={changeAvatarModalVisible} onCancel={onCancelClick} footer={null}>
        <Spin spinning={loading}>
          <h5>{avatarTitle || locale.labels.addAvatar}</h5>
          <Button type='primary'>
            <input type='file' accept='image/*' onChange={handleFileChange} />
          </Button>
          {picture.cropperOpen && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                  marginBottom: 20
                }}
              >
                <AvatarEditor
                  ref={setEditorRef}
                  image={picture.img}
                  height={300}
                  width={300}
                  borderRadius={150}
                  color={[255, 255, 255, 0.6]}
                  rotate={0}
                  disableBoundaryChecks
                  scale={picture.zoom}
                />
              </div>
              <Slider
                value={picture.zoom}
                min={0.1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              ></Slider>
            </div>
          )}
          <div>
            <div className='avatar-modal-btns-container'>
              <ViewDetailsButtonsGroup
                fixed
                showCreateBtn
                onSaveModalClick={handleSave}
                accessGroup={editAccess}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={onCancelClick}
              />
            </div>
          </div>
        </Spin>
      </Modal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
