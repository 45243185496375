import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const getMailView = (listData: string): JSX.Element[] | string => {
  if (!listData || listData?.length === 0) return '-';
  const parsedListData = JSON.parse(listData);
  if (!parsedListData.filter(el => el).length) return '-';

  return parsedListData.map((el) => {
    const email = typeof el === 'object' ? el?.email : el;
    return <div key={uuidv4()}>{email ? <a href={`mailto:${email}`}>{email}</a> : '-'}</div>;
  });
};

export const getPhoneView = (listData: string): JSX.Element[] | string => {
  if (!listData) return '-';
  const parsedListData = JSON.parse(listData)?.filter((item) => !!item?.phone_number);
  if (!parsedListData.length) return '-';

  return parsedListData.map((el) => (
    <div key={el.phone_number}>
      <Link
        to={`tel:${el.country_code}${el.phone_number}`}
        className='d-flex align-baseline nowrap'
      >
        {`+${el.country_code}${el.phone_number}`}
      </Link>
    </div>
  ));
};
