import 'moment/locale/uk';
import './AppointmentsList.scss';

import { Button, Pagination, Popover, Row, Table, TableColumnProps, Tooltip } from 'antd';
import { useAppointmentsList } from 'api/hooks/appointments/useAppointmentsList';
import { useChangeAppointmentStatus } from 'api/hooks/appointments/useChangeAppointmentStatus';
import { IAppointment, IPatient } from 'api/interfaces/Appointment';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import { IncompleteHealthRecordMarker } from 'components/IncompleteHealthRecordMarker';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import {
  appointmentListStatuses,
  appointmentListStatusesFreemium,
  appointmentStatuses,
  paymentStatuses,
} from 'constants/dictionary/default/selectOptions';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { AddAppointment } from 'layout/modals/addAppointment/AddAppointment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getTime } from 'utils/get-time';

import { renderAppointmentStatus, renderPaymentStatus } from './AppointmentsBaseHelper';
import { CancellationForm } from './components/CancellationForm';
import { getAppointmentTooltip } from './utils/get-appointment-tooltip';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

const AppointmentsList = (): JSX.Element => {
  const { isOpened, open } = useModal();
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const { localeCode } = useLocaleCode();
  const appModules = useAccess().availableModules;
  const locale = useLocale('private.appointments.event');
  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const localizedAppointmentListStatuses = useLocalizedList(
    appModules.finances ? appointmentListStatuses : appointmentListStatusesFreemium
  );

  const { data, isLoading, appointments, params, updateParams, updateSorting } =
    useAppointmentsList(clinicId, defaultParams);
  const changeAppointmentStatus = useChangeAppointmentStatus();

  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);

  useEffect((): void => {
    if (localeCode === 'ukUA') {
      moment.locale('uk');
    } else {
      moment.locale('en');
    }
  }, [localeCode]);

  const redirectToAppointment = ({ id }: { id: number }): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/appointments/${id}`);
    }
  });

  const redirectToClient = (record: IAppointment): string =>
    `/clinic/${clinicId}/clients/${record?.client?.id}`;

  const redirectToPatient = (record: IPatient): string =>
    `/clinic/${clinicId}/patients/${record?.id}`;

  const onStatusChange = (status: string, appointment: IAppointment): void => {
    changeAppointmentStatus.mutate({ appointmentId: appointment.id, clinicId, status });
  };

  const getPatientView = (e: IAppointment): JSX.Element[] | string => {
    if (!e.patients?.length) return '-';

    return e.patients.map((el) => (
      <div key={el.id}>
        <Link className={'custom-links'} to={redirectToPatient(el)}>
          {el.name}
        </Link>
      </div>
    ));
  };

  const StatusTooltip = (appointment: IAppointment): JSX.Element => {
    const title = appointment?.cancellation?.cancelled_by?.title;
    return (
      <div>
        {!!appointment?.cancellation?.reason && (
          <>
            <p style={{ marginBottom: '2px' }}>
              {locale.labels.cancelledBy}: {appointment?.cancellation?.cancelled_by?.name}{' '}
              {title ? `(${title})` : ''}
            </p>
            <p style={{ marginBottom: '2px' }}>
              {locale.labels.reason}: {`${appointment.cancellation.reason}`}
            </p>
          </>
        )}
      </div>
    );
  };

  function canCancel(appointment: IAppointment): boolean {
    return ['future', 'in_progress'].includes(appointment.status);
  }

  function defineStatus(appointment: IAppointment): JSX.Element {
    if (!appointment.status) return null;

    if (appointment.status === 'cancelled') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap'
          }}
        >
          {renderAppointmentStatus(appointment.status, localizedStatuses)}
          <Popover
            placement='topLeft'
            title={locale.labels.appointmentCancelled}
            content={StatusTooltip(appointment)}
          >
            <span
              className='icofont-info-circle'
              style={{ fontSize: '15px', marginLeft: 6, alignSelf: 'baseline' }}
            />
          </Popover>
        </div>
      );
    }

    if (appointment.status === 'finished' && appModules.finances) {
      return renderPaymentStatus(appointment.payment_status, localizedPaymentStatuses);
    }

    return renderAppointmentStatus(appointment.status, localizedStatuses);
  }

  const columns = useMemo(
    (): TableColumnProps<IAppointment>[] => [
      {
        key: 'title',
        dataIndex: 'title',
        title: locale.labels.visitReason,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (title) => title,
        width: '15%'
      },
      {
        key: 'created_at',
        title: locale.labels.time,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: getTime
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        render: (e) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: 6 }}>{defineStatus(e)}</span>
            <IncompleteHealthRecordMarker appointment={e} locale={locale} />
          </div>
        )
      },
      {
        key: 'patients',
        title: locale.labels.patient,
        render: getPatientView
      },
      {
        key: 'client_phone',
        title: locale.labels.client,
        sorter: () => null,
        render: (e) => (
          <>
            <Link className={'custom-links'} to={redirectToClient(e)}>
              {ListFieldWithTooltip(e.client?.name, 15)}{' '}
            </Link>
            {!!e.client?.phone_number && (
              <Link
                to={`tel:${e.client?.country_code}${e.client?.phone_number}`}
                className='d-flex align-baseline nowrap'
              >
                (<span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
                {`+${e.client?.country_code}${e.client?.phone_number}`})
              </Link>
            )}
            {blacklistedStatus(e.client)}
          </>
        )
      },
      {
        key: 'doctor_name',
        dataIndex: 'doctor',
        title: locale.labels.doctor,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (doctor) => ListFieldWithTooltip(doctor?.name)
      },
      {
        key: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location?.details) : '-')
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <div className='buttons-list nowrap'>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={getAppointmentTooltip(e, locale)}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!!getAppointmentTooltip(e, locale)}
                onClick={() => onStatusChange('in_progress', e)}
                shape='circle'
                type='primary'
              >
                <span className='icofont-play-alt-2' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canCancel(e) ? locale.messages.appointmentCanNotBeCancelledList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canCancel(e)}
                onClick={() => {
                  setCurrentAppointmentId(e.id);
                  open(MODAL.cancellationForm);
                }}
                shape='circle'
                danger
              >
                <span className='icofont-ui-block' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW
        onFilterChange={updateParams}
        selectOptions={localizedAppointmentListStatuses}
        selectPlaceholder={locale.labels.filterByStatus}
        dateRangeEnabled
      />

      <Table
        pagination={false}
        className='accent-header appointments-list-table'
        rowKey='id'
        loading={isLoading || changeAppointmentStatus.isPending}
        onChange={updateSorting}
        dataSource={appointments}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
      </Row>
      {isOpened(MODAL.cancellationForm) && (
        <CancellationForm clinicId={clinicId} appointmentId={currentAppointmentId} />
      )}

      {isOpened(MODAL.addAppointment) && <AddAppointment />}
      <StickyButton
        offsetBottom={10}
        onClick={() => open(MODAL.addAppointment)}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addAppointment}
      />
    </>
  );
};

export default AppointmentsList;
