import './AddFinancialAccount.scss';

import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { useCreateFinancialAccount } from 'api/hooks/finances/useCreateFinancialAccount';
import { useEditFinancialAccount } from 'api/hooks/finances/useEditFinancialAccount';
import { IAccountPayload, IBankAccount } from 'api/interfaces/Finances';
import { useModal } from 'api/store/modalStore';
import { TextEditor } from 'components/TextEditor';
import {
  financialAccountsTypesOptions,
  financialAcountsOptions
} from 'constants/dictionary/default/selectOptions';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues?: IBankAccount;
  locale: ILocale;
}>;

export const FinancialAccountForm = ({
  form,
  initialValues,
  children,
  locale
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal } = useModal();

  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(
    initialValues?.account_type
  );

  const editAccount = useEditFinancialAccount(clinicId, initialValues?.id, closeModal);
  const createAccount = useCreateFinancialAccount(clinicId, closeModal);

  const onSubmit = (data: IAccountPayload): void => {
    const payload = {
      ...data,
      clinic_id: clinicId,
      account_number: data.account_number || null
    };

    if (initialValues?.id) {
      editAccount.mutate(payload);
    } else {
      createAccount.mutate(payload);
    }
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.name} name={'name'} rules={[requiredRule()]}>
            <Input />
          </Form.Item>
          <Form.Item label={locale.labels.accountType} rules={[requiredRule()]} name='account_type'>
            <Select
              value={selectedAccountType}
              onChange={setSelectedAccountType}
              options={useLocalizedList(financialAccountsTypesOptions)}
            />
          </Form.Item>
          {selectedAccountType === 'bank' && (
            <Form.Item label={locale.labels.number} rules={[requiredRule()]} name='account_number'>
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name='status'
            rules={[requiredRule()]}
            label={locale.labels.status}
            initialValue={initialValues?.status ? initialValues?.status : 'active'}
          >
            <Select filterOption={false} options={useLocalizedList(financialAcountsOptions)} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <TextEditor label={locale.labels.description} name={['description']} rows={2} />
          <TextEditor label={locale.labels.notes} name={['notes']} rows={2} />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
