import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IFinancialStats } from 'api/interfaces/Stats';
import { prepareStats } from 'api/utils/prepareStats';
import { useLocale } from 'hooks/useLocale';

import { useTableData } from '../useTableData';

export const useFinancialStats = (clinicId: number, lineDatePeriod: string) => {
  const { uah } = useLocale('private.currencies').labels;
  const endpoint = API_ENDPOINTS.FINANCIAL_STATS(clinicId);
  const result = useTableData<IFinancialStats>(endpoint, null, ['financeStats', clinicId]);
  return {
    ...result,
    stats: prepareStats(result.data as IFinancialStats, uah, lineDatePeriod) as IFinancialStats
  };
};
