import 'assets/scss/select.scss';
import './UserProfile.scss';

import { Col, Divider, Row, Select, Typography } from 'antd';
import { useUploadUserAvatar } from 'api/hooks/avatar.ts/useUploadAvatar';
import { useEditUser } from 'api/hooks/user/useEditUser';
import { IUserProfile } from 'api/interfaces/User';
import useUserStore from 'api/store/userStore';
import userAvatar from 'assets/img/user-avatar.svg';
import { AvatarComponent } from 'components/Avatar';
import MultiSkillsSelect from 'components/MultiSkillsSelect';
import { locales } from 'constants/locales';
import { useLocale } from 'hooks/useLocale';
import ChangeUserEmail from 'layout/modals/ChangeUserEmail';
import ChangeUserPassword from 'layout/modals/ChangeUserPassword';
import { ChangeUserPhoneNumber } from 'layout/modals/ChangeUserPhoneNumber';
import EditUserData from 'layout/modals/EditUserData';
import moment from 'moment';
import React, { useState } from 'react';

const { Title } = Typography;

const UserProfile = (): JSX.Element => {
  const locale = useLocale('private.user.user-profile');
  const user = useUserStore(({ user }) => user);

  const [selectedItems, setSelectedItems] = useState([]);
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);

  const uploadAvatar = useUploadUserAvatar();
  const editUser = useEditUser();

  const handleSetUserAvatar = (data, callbackFn?: () => void): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    uploadAvatar.mutate(avatarData);
    callbackFn();
    setChangeAvatarModalVisible(false);
  };

  const localesList = locales.map((locale) => ({
    value: locale.key,
    label: locale.label
  }));

  function onLocaleSelect(value) {
    const values: Partial<IUserProfile> = {
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      birth_date: user.birth_date,
      locale: value,
      country: user.country,
      region: user.region,
      city: user.city,
      address: user.address
    };
    // TODO CHECK IF COULD BE SIMPLIFIED TO SEND { ...user, locale: newLocale }
    editUser.mutate(values);
  }

  return (
    <div className='stack' style={{ margin: '0 7%' }}>
      <AvatarComponent
        loading={uploadAvatar.isPending}
        editAccess={true}
        onImageChange={handleSetUserAvatar}
        src={user?.avatar ? user.avatar : userAvatar}
        setChangeAvatarModalVisible={setChangeAvatarModalVisible}
        changeAvatarModalVisible={changeAvatarModalVisible}
      />
      <Divider />
      <Row>
        <Col span={24} md={16}>
          <Title level={5}>{locale.labels.firstName}</Title>
          {user.first_name}
          {user.middle_name && (
            <>
              <Title level={5}>{locale.labels.middleName}</Title>
              {user.middle_name}
            </>
          )}
          <Title level={5}>{locale.labels.lastName}</Title>
          {user.last_name}
          <Title level={5}>{locale.labels.dateOfBirth}</Title>
          {moment(user.birth_date).format('DD.MM.YYYY')}
          <Title level={5}>{locale.labels.address}</Title>
          {user.country}, {user.region}, {user.city}, {user.address}
          <Title level={5}>{locale.labels.skills}</Title>
          <MultiSkillsSelect
            user={user}
            editMode={false}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
          <Title level={5}>{locale.labels.language}</Title>
          <Select
            className={'locale-dropdown'}
            defaultValue={localesList.find((l) => l.value === user.locale).label}
            options={localesList}
            onSelect={onLocaleSelect}
          />
        </Col>
        <Col span={24} md={8}>
          <EditUserData />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} md={16}>
          <Title level={5}>{locale.labels.email}</Title>
          {user.email}
        </Col>
        <Col span={24} md={8}>
          <ChangeUserEmail />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} md={16}>
          <Title level={5}>{locale.labels.phoneNumber}</Title>+{user.country_code}
          {user.phone_number}
        </Col>
        <Col span={24} md={8}>
          <ChangeUserPhoneNumber />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} md={16} />
        <Col span={24} md={8}>
          <ChangeUserPassword />
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
