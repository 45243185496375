import '../Finances.scss';
import './ShiftsList.scss';

import { Pagination, Table, TableColumnProps, Tag } from 'antd';
import { useShiftsList } from 'api/hooks/shifts/useShiftsList';
import { IShift } from 'api/interfaces/Shift';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { currentEarnings, getShiftDuration, userData } from './ShiftsBaseHelper';

const defaultParams = {
  page: 1,
  sort_key: 'opened_at',
  sort_order: 'desc'
};

const ShiftsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  const { data, shifts, isLoading, params, updateParams, updateSorting } = useShiftsList(
    clinicId,
    defaultParams
  );

  const redirectToDetails = (record: IShift): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/shifts/${record.id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IShift>[] => [
      {
        key: 'opened_at',
        dataIndex: 'opened_at',
        title: locale.labels.openedAt,
        sorter: () => null,
        render: (opened_at, e) => dayjs(opened_at).local().format('DD.MM.YYYY HH:mm')
      },
      {
        key: 'opened_by_name',
        title: locale.labels.openedBy,
        sorter: () => null,
        render: (e) => userData(e.opened_by)
      },
      {
        key: 'open',
        dataIndex: 'open',
        title: locale.labels.status,
        sorter: () => null,
        render: (open) =>
          open ? (
            <Tag color='green' style={{ fontWeight: 600 }}>
              {locale.labels.opened}
            </Tag>
          ) : (
            <Tag color='red' style={{ fontWeight: 600 }}>
              {locale.labels.closed}
            </Tag>
          )
      },
      {
        key: 'closed_at',
        dataIndex: 'closed_at',
        title: locale.labels.closedAt,
        sorter: () => null,
        render: (closed_at, e) =>
          closed_at ? dayjs(closed_at).local().format('DD.MM.YYYY HH:mm') : '-'
      },
      {
        key: 'closed_by_name',
        title: locale.labels.closedBy,
        sorter: () => null,
        render: (e) => userData(e.closed_by)
      },
      {
        key: 'total_diff',
        dataIndex: 'total_diff',
        title: locale.labels.totalDiff,
        sorter: () => null,
        render: (total_diff) => currentEarnings(total_diff || 0, currencies)
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        title: locale.labels.duration,
        render: (duration, e) => getShiftDuration(e, locale)
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Table
        pagination={false}
        className='accent-header'
        rowKey='id'
        loading={isLoading}
        onChange={updateSorting}
        dataSource={shifts}
        columns={columns}
        onRow={redirectToDetails}
      />
      <Pagination
        current={+params.page}
        total={data.metadata.total || 0}
        showSizeChanger={false}
        pageSize={20}
        onChange={(page) => updateParams({ page })}
      />
    </>
  );
};

export default ShiftsList;
