import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvoicePurchasesList } from 'api/interfaces/Invoice';

import { useTableData } from '../useTableData';

export const useInvoiceStockPurchases = (
  clinicId: number,
  invoiceId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = invoiceId ? `${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}/items` : null;
  const queryKeys = ['invoiceStockPurchases', clinicId, invoiceId];

  const result = useTableData<IInvoicePurchasesList>(
    endpoint,
    defaultParams,
    queryKeys,
    searchDisabled
  );

  return {
    ...result,
    invoicePurchases: result.data.data || []
  };
};
