import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClientsList } from 'api/interfaces/Client';

const defaultParams = {
  page: 0,
  sort_key: 'name',
  sort_order: 'asc'
};

export const useClientSearch = (clinicId: number, searchParams: { id: number; name: string }) => {
  const { id, name = '' } = searchParams || {};
  const params = id ? { 'q[id]': id } : { 'q[composite][]': name.trim().split(/\s+/) };

  const queryFn = async () => {
    const { data } = await axiosClient.get(API_ENDPOINTS.CLIENTS(clinicId), {
      params: { ...defaultParams, ...params }
    });
    return data;
  };

  return useQuery<IClientsList>({
    queryKey: ['clientSearch', clinicId, searchParams],
    queryFn,
    enabled: !!searchParams
  });
};
