import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ITransactionsList } from 'api/interfaces/Finances';

import { useTableData } from '../useTableData';

export const useBankAccountTransactions = (
  clinicId: number,
  accountId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = `${API_ENDPOINTS.ACCOUNTS(clinicId)}/${accountId}/payments`;

  const result = useTableData<ITransactionsList>(
    endpoint,
    defaultParams,
    ['bankAccountsTransactions', clinicId, accountId],
    searchDisabled
  );

  return {
    ...result,
    accountPayments: result.data.data || []
  };
};
