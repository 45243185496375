import { Form } from 'antd';
import { ICreatePatientFormData } from 'api/interfaces/Patient';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { PatientForm } from './PatientForm';

type Props = {
  initialValues?: ICreatePatientFormData;
};

export const AddPatientModal = ({ initialValues }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const { closeModal } = useModal();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useAccess();
  const locale = useLocale('private.patients.patient-page');

  return (
    <>
      <VLXModal
        title={locale.labels.createNewPatient}
        centered
        open
        form={form}
        onModalClose={closeModal}
        width={800}
      >
        <PatientForm form={form} initialValues={initialValues} closeModal={closeModal}>
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.patients}
            fixed
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </PatientForm>

        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
