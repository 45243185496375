import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvitationsList } from 'api/interfaces/Invitation';

import { useTableData } from '../useTableData';

export const useInvitationsList = (clinicId: number) => {
  const endpoint = API_ENDPOINTS.INVITATIONS(clinicId);
  const result = useTableData<IInvitationsList>(endpoint, null, ['invitationLists', clinicId]);

  return {
    ...result,
    invitations: result.data.data || []
  };
};
