export const packagesLocale = {
  enGB: {
    box: {
      one: 'box',
      many: 'boxes'
    },
    packet: {
      one: 'packet',
      many: 'packets'
    },
    tin: {
      one: 'tin',
      many: 'tins'
    },
    can: {
      one: 'can',
      many: 'cans'
    },
    bag: {
      one: 'bag',
      many: 'bags'
    },
    bottle: {
      one: 'bottle',
      many: 'bottles'
    },
    vial: {
      one: 'vial',
      many: 'vials'
    },
    tube: {
      one: 'tube',
      many: 'tubes'
    },
    aerosol: {
      one: 'aerosol',
      many: 'aerosols'
    },
    spray: {
      one: 'spray',
      many: 'sprays'
    },
    blisterPack: {
      one: 'blister pack',
      many: 'blister packs'
    },
    ampoule: {
      one: 'ampoule',
      many: 'ampoules'
    },
    container: {
      one: 'container',
      many: 'containers'
    },
    sachet: {
      one: 'sachet',
      many: 'sachets'
    },
    jar: {
      one: 'jar',
      many: 'jars'
    },
    tray: {
      one: 'tray',
      many: 'trays'
    },
    tub: {
      one: 'tub',
      many: 'tubes'
    },
    canister: {
      one: 'canister',
      many: 'canisters'
    },
    pallet: {
      one: 'pallet',
      many: 'pallets'
    },
    pouch: {
      one: 'pouch',
      many: 'pouches'
    }
  },
  ukUA: {
    box: {
      one: 'коробка',
      few: 'коробки',
      many: 'коробок'
    },
    packet: {
      one: 'пачка',
      few: 'пачки',
      many: 'пачок'
    },
    tin: {
      one: 'консерва',
      few: 'консерви',
      many: 'консерв'
    },
    can: {
      one: 'бляшанка',
      few: 'бляшанки',
      many: 'бляшанок'
    },
    bag: {
      one: 'мішок',
      few: 'мішки',
      many: 'мішків'
    },
    bottle: {
      one: 'пляшка',
      few: 'пляшки',
      many: 'пляшок'
    },
    vial: {
      one: 'флакон',
      few: 'флакони',
      many: 'флаконів'
    },
    tube: {
      one: 'тюбик',
      few: 'тюбики',
      many: 'тюбиків'
    },
    aerosol: {
      one: 'аерозоль',
      few: 'аерозолі',
      many: 'аерозолів'
    },
    spray: {
      one: 'спрей',
      few: 'спреї',
      many: 'спреїв'
    },
    blisterPack: {
      one: 'блістер',
      few: 'блістери',
      many: 'блістерів'
    },
    ampoule: {
      one: 'ампула',
      few: 'ампули',
      many: 'ампул'
    },
    container: {
      one: 'контейнер',
      few: 'контейнери',
      many: 'контейнерів'
    },
    sachet: {
      one: 'саше',
      few: 'саше',
      many: 'саше'
    },
    jar: {
      one: 'банка',
      few: 'банки',
      many: 'банок'
    },
    tray: {
      one: 'лоток',
      few: 'лотки',
      many: 'лотків'
    },
    tub: {
      one: 'відро',
      few: 'відра',
      many: 'відер'
    },
    canister: {
      one: 'каністра',
      few: 'каністри',
      many: 'каністр'
    },
    pallet: {
      one: 'палета',
      few: 'палети',
      many: 'палет'
    },
    pouch: {
      one: 'упаковка',
      few: 'упаковки',
      many: 'упаковок'
    }
  }
};
