import { Col, Form, Input, Row, Select } from 'antd';
import { useEditEmployee } from 'api/hooks/employees/useUpdateEmployee';
import {
  IEmployeeDetails,
  IEmployeeFormValues,
  IEmployeeUpdatePayload
} from 'api/interfaces/Employee';
import { IRole } from 'api/interfaces/Role';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import MultiSkillsSelect from 'components/MultiSkillsSelect';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import * as config from 'config/config-manager';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useState } from 'react';

const FormItem = Form.Item;
const { TextArea } = Input;

interface Props {
  employee: IEmployeeDetails;
  roles: IRole[];
  clinicId: number;
  employeeId: number;
  locale: ILocale;
}

const EmployeeProfileForm = ({
  employee,
  roles,
  clinicId,
  employeeId,
  locale
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [initialSelectedItemsData, setInitialSelectedItemsData] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [skillsWasChanged, setSkillsWasChanged] = useState<boolean>(false);

  const editEmployee = useEditEmployee(clinicId, employeeId, () => setEditMode(false));

  const userAccess = useAccess();

  const location = (): string => {
    const loc = employee.location;
    return loc
      ? ['country', 'region', 'city', 'address']
          .map((k) => loc[k])
          .filter((value) => value && value !== '')
          .join(', ')
      : '-';
  };

  useEffect(() => {
    form.resetFields();
  }, [employee]);

  const onSaveClick = (values: IEmployeeFormValues) => {
    const payload: IEmployeeUpdatePayload = {
      first_name: employee.first_name,
      last_name: employee.last_name,
      birth_date: employee.birth_date,
      country: employee.location.country,
      region: employee.location.region,
      city: employee.location.city,
      address: employee.location.address,
      role_id: form.getFieldValue('role_id') || employee.role.id,
      skills: selectedItems,
      title: values.title
    };

    editEmployee.mutate(payload);
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const onConfirmCancelClick = () => {
    form.resetFields();
    setEditMode(false);
    setShowCancelModal(false);
    setSelectedItems(initialSelectedItemsData);
  };

  const onChangeFieldValue = () => {
    if (!editMode) {
      setEditMode(true);
    }
    if (!skillsWasChanged) {
      setSkillsWasChanged(true);
    }
  };

  return (
    employee.role && (
      <Form
        layout='vertical'
        onFinish={onSaveClick}
        form={form}
        initialValues={employee}
        onFieldsChange={onFieldsChange}
        className='profile-form'
      >
        <FormItem label={locale.labels.firstName} name='first_name'>
          <Input disabled placeholder={locale.labels.firstName} />
        </FormItem>

        <FormItem label={locale.labels.middleName} name='middle_name'>
          <Input disabled placeholder={locale.labels.middleName} />
        </FormItem>

        <FormItem label={locale.labels.lastName} name='last_name'>
          <Input disabled placeholder={locale.labels.lastName} />
        </FormItem>

        <Row>
          <Col span={24} md={11}>
            <FormItem label={locale.labels.title} name='title'>
              <Input placeholder={locale.labels.title} />
            </FormItem>
          </Col>
          <Col span={24} offset={2} md={11}>
            <FormItem label={locale.labels.role} hidden={config.disabledForProd} name='role_id'>
              <Select
                showSearch
                placeholder={locale.placeholders.searchToSelect}
                options={roles.map((role) => ({
                  value: role.id,
                  label: role.name
                }))}
                defaultValue={{
                  value: employee.role.id,
                  label: employee.role.name
                }}
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
              />
            </FormItem>
          </Col>
        </Row>

        <FormItem label={locale.labels.address} name='address' initialValue={location()}>
          <TextArea
            style={{ maxWidth: '500px' }}
            disabled
            rows={1}
            placeholder={locale.labels.address}
          />
        </FormItem>

        <FormItem label={locale.labels.skills} name='skills'>
          <MultiSkillsSelect
            user={employee as IEmployeeDetails}
            onChangeFieldValue={onChangeFieldValue}
            setInitialSelectedItemsData={setInitialSelectedItemsData}
            editMode
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </FormItem>

        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.employees}
          editMode={editMode}
          showCreateBtn
          onEditClick={() => setEditMode(true)}
          onCancelClick={() => {
            if (form.isFieldsTouched() || skillsWasChanged) {
              setShowCancelModal(true);
            } else {
              setEditMode(false);
            }
          }}
          hideDeleteBtn
        />
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </Form>
    )
  );
};

export default EmployeeProfileForm;
