import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientDetails } from 'api/interfaces/Patient';

export const useAppointmentPatients = (clinicId: number, appointmentId: number) => {
  const endpoint = API_ENDPOINTS.APPOINTMENT_PATIENTS(clinicId, appointmentId);
  const queryKey = ['appointmentPatients', clinicId, appointmentId];

  return useQuery<IPatientDetails[]>({
    queryFn: async () => {
      const { data } = await axiosClient.get(endpoint);
      return data.data;
    },
    queryKey,
    enabled: !!appointmentId
  });
};
