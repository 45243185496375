import './ClientItemForm.scss';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Switch
} from 'antd';
import { useCreateClient } from 'api/hooks/clients/useCreateClient';
import { useEditClient } from 'api/hooks/clients/useEditClient';
import { IClientDetails, IClientFormData } from 'api/interfaces/Client';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { getDate } from 'utils/get-time';
import { trimSocialLinks } from 'utils/trimSocialLinks';

const { TextArea } = Input;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues?: IClientDetails;
  hideDefaultBtn?: boolean;
  locale: ILocale;
  createClientCallback?: (data) => void;
  onSuccess?: (data) => void;
}>;

export const ClientItemForm = ({
  form,
  locale,
  initialValues,
  onSuccess,
  children
}: Props): JSX.Element => {
  const clinicId = useClinicId();

  const [showBlacklistedFields, setShowBlacklistedFields] = useState(false);

  const createClient = useCreateClient(clinicId, onSuccess);
  const editClient = useEditClient(clinicId, initialValues?.id, onSuccess);

  useEffect(() => {
    form.resetFields();
    if (showBlacklistedFields !== initialValues?.blacklisted?.status)
      setShowBlacklistedFields(initialValues?.blacklisted?.status);
  }, [initialValues]);

  const onSubmit = (data: IClientFormData): void => {
    const apiData = {
      ...data,
      phone_number: data.phone_number === '' ? null : data.phone_number,
      social_media_links: trimSocialLinks(data.social_media_links),
      date_of_birth: getDate(data.date_of_birth)
    };

    if (initialValues) {
      editClient.mutate(apiData);
    } else {
      createClient.mutate(apiData);
    }
  };

  const remapInitialValues = useCallback(
    () =>
      initialValues && {
        ...initialValues,
        social_media_links: JSON.parse(initialValues?.social_media_links),
        date_of_birth: initialValues?.date_of_birth && dayjs(initialValues?.date_of_birth)
      },
    [initialValues]
  );

  const socialMediaList = (): JSX.Element[] =>
    socialMediaLinks.map((link, index) => (
      <Option key={index} value={link.value}>
        {link.value}
        {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
      </Option>
    ));

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={remapInitialValues()}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.firstName}
            name={['first_name']}
            rules={[
              requiredRule(locale.messages.nameEmpty),
              patternRule(PATTERNS.NAME, locale.messages.nameNotValid)
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.middleName}
            name={['middle_name']}
            rules={[patternRule(PATTERNS.NAME, locale.messages.nameNotValid)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.lastName}
            name={['last_name']}
            rules={[patternRule(PATTERNS.NAME, locale.messages.lastNameNotValid)]}
          >
            <Input />
          </Form.Item>
          <PhoneFieldFormItem />
          <Form.Item
            label={locale.labels.email}
            name={['email']}
            rules={[patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)]}
          >
            <Input />
          </Form.Item>
          <TextEditor
            label={locale.labels.specialNotes}
            name={['private_notes']}
            help={locale.messages.specialNotesVisibility}
            rows={4}
          />
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.dateOfBirth} name='date_of_birth'>
            <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label={locale.labels.homeAddress} style={{ marginBottom: 0 }}>
            <Form.Item
              name={['country']}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginRight: '16px'
              }}
            >
              <Input placeholder={locale.placeholders.country} />
            </Form.Item>
            <Form.Item
              name={['region']}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)'
              }}
            >
              <Input placeholder={locale.placeholders.region} />
            </Form.Item>
          </Form.Item>
          <Form.Item name={['city']}>
            <Input placeholder={locale.placeholders.city} />
          </Form.Item>
          <Form.Item name={['address']}>
            <Input placeholder={locale.placeholders.address} />
          </Form.Item>
          <Form.Item
            className={'horizontal-label-form-item blacklisted-container'}
            name={['blacklisted', 'status']}
            valuePropName={'checked'}
            label={locale.labels.blacklisted}
          >
            <Switch onChange={(value) => setShowBlacklistedFields(value)} />
          </Form.Item>
          {showBlacklistedFields && (
            <>
              <Form.Item
                label={locale.labels.blacklistReason}
                name={['blacklisted', 'reason']}
                rules={[requiredRule(locale.messages.blacklistReason)]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      <Form.List name='social_media_links'>
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }) => (
              <Row key={name} gutter={[20, 10]}>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    rules={[requiredRule(locale.messages.selectSocialNetwork)]}
                  >
                    <Select
                      placeholder={locale.placeholders.selectSocialMedia}
                      filterOption={(input, option) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {socialMediaList()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      requiredRule(locale.messages.specifyLink),
                      patternRule(PATTERNS.SOCIAL_LINK, locale.messages.invalidSocialLink)
                    ]}
                  >
                    <Input placeholder={locale.placeholders.linkToProfile} />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    remove(name);
                  }}
                  danger
                  type='primary'
                  shape={'circle'}
                  className={'inventory-update-buttons'}
                >
                  <span className='icofont icofont-trash' />
                </Button>
              </Row>
            ))}
            <Row>
              <Form.Item>
                <Button
                  className='icofont icofont-plus'
                  type={'primary'}
                  style={{
                    borderRadius: 30,
                    fontWeight: 'bold'
                  }}
                  onClick={() => add()}
                >
                  {fields.length ? locale.buttons.addMore : locale.buttons.addSocialLinks}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};
