import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPatientPayload } from 'api/interfaces/Patient';
import { useClientId } from 'hooks/useId';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditPatient = (clinicId: number, patientId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IPatientPayload) =>
    axiosClient.put(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}`, payload);
  const clientId = useClientId();

  const queryKeys = [
    'patientsList',
    ['patientDetails', clinicId, patientId],
    ['patientDetails', clinicId, patientId],
    ['appointmentPatients', clinicId],
    ['clientPatients', clinicId, clientId]
  ];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
