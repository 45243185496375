import 'quill/dist/quill.core.css';
import './App.scss';

import { ConfigProvider, ConfigProviderProps } from 'antd';
import enGB from 'antd/locale/en_GB';
import ukUA from 'antd/locale/uk_UA';
import { useUserProfile } from 'api/hooks/user/useUserProfile';
import { useLocaleCode } from 'api/store/localeContext';
import useUserStore from 'api/store/userStore';
import dayjs from 'dayjs';
import * as ls from 'local-storage';
import { ProviderDetails } from 'pages/inventory/providers/ProviderDetails';
import { ProvidersList } from 'pages/inventory/providers/ProvidersList';
import React, { useEffect, useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';

import Loader from './components/Loader';
import * as config from './config/config-manager';
import BaseLayout from './layout/base/BaseLayout';
import AppointmentDetails from './pages/appointments/AppointmentDetails';
import AppointmentsList from './pages/appointments/AppointmentsList';
import EventsCalendar from './pages/calendar/EventsCalendar';
import UserCalendar from './pages/calendar/UserCalendar';
import ClientDetails from './pages/clients/ClientDetails';
import ClientsList from './pages/clients/ClientsList';
import ClinicActions from './pages/clinic/ClinicActions';
import ClinicDictionaries from './pages/clinic/ClinicDictionaries';
import ClinicDocuments from './pages/clinic/ClinicDocuments';
import ClinicInfo from './pages/clinic/ClinicInfo';
import EmployeeProfilePage from './pages/clinic/employees/EmployeeProfilePage';
import EmployeesList from './pages/clinic/employees/EmployeesList';
import { GeneralSettings } from './pages/clinic/GeneralSettings';
import InvitationsList from './pages/clinic/InvitationsList';
import { PrintConfiguration } from './pages/clinic/PrintConfiguration';
import RolesList from './pages/clinic/RolesList';
import RoomsList from './pages/clinic/RoomsList';
import ClinicRegistration from './pages/clinicRegistration/ClinicRegistration';
import DashboardPage from './pages/dashboard/DashboardPage';
import BillsDetails from './pages/finances/bills/BillsDetails';
import BillsList from './pages/finances/bills/BillsList';
import FinancesInfo from './pages/finances/dashboard/FinancesInfo';
import FinancesActions from './pages/finances/FinancesActions';
import FinancialAccountDetails from './pages/finances/financialAccounts/FinancialAccountDetails';
import FinancialAccountsList from './pages/finances/financialAccounts/FinancialAccountsList';
import PaymentsDetails from './pages/finances/payments/PaymentsDetails';
import PaymentsList from './pages/finances/payments/PaymentsList';
import ShiftDetails from './pages/finances/shifts/ShiftsDetails';
import ShiftsList from './pages/finances/shifts/ShiftsList';
import HomeLanding from './pages/HomeLanding';
import AuditDetails from './pages/inventory/audit/AuditDetails/AuditDetails';
import AuditList from './pages/inventory/audit/AuditList/AuditList';
import InventoryInfo from './pages/inventory/dashboard/InventoryInfo';
import { EquipmentDetails } from './pages/inventory/equipment/EquipmentDetails';
import { EquipmentList } from './pages/inventory/equipment/EquipmentList';
import InventoryActions from './pages/inventory/InventoryActions';
import InvoiceDetails from './pages/inventory/invoices/InvoiceDetails/InvoiceDetails';
import InvoicesList from './pages/inventory/invoices/InvoicesList';
import StockDetails from './pages/inventory/stock/StockDetails/StockDetails';
import StockList from './pages/inventory/stock/StockList';
import { ToolDetails } from './pages/inventory/tools/ToolDetails';
import { ToolsList } from './pages/inventory/tools/ToolsList';
import PatientDetails from './pages/patients/PatientDetatils';
import { PatientsList } from './pages/patients/PatientsList';
import { Pricing } from './pages/Pricing/Pricing';
import { Privacy } from './pages/Privacy';
import { SaleDetails } from './pages/sales/SaleDetails';
import SalesList from './pages/sales/SalesList';
import ServiceDetails from './pages/services/ServiceDetails';
import ServicesList from './pages/services/ServicesList';
import { Forbidden } from './pages/sessions/403';
import { NotFound } from './pages/sessions/404';
import AcceptInvitation from './pages/sessions/AcceptInvitation';
import EmailConfirmation from './pages/sessions/EmailConfirmation';
import SetNewPassword from './pages/sessions/SetNewPassword';
import { TermsAndConditions } from './pages/TermsAndConditions';
import UserProfile from './pages/UserProfile';

type Locale = ConfigProviderProps['locale'];

const antdLocaleList: { [key: string]: Locale } = {
  enGB,
  ukUA
};

const locales: Record<string, () => Promise<any>> = {
  ukUA: async () => import('dayjs/locale/uk'),
  enGB: async () => import('dayjs/locale/en')
};

const loadLocale = async (language: string): Promise<void> => {
  locales[language]?.().then(() => dayjs.locale(language));
};

const App = (): JSX.Element => {
  const getUser = useUserProfile();

  const { localeCode, setLocale } = useLocaleCode();
  const antdLocale = antdLocaleList[localeCode];

  const userId = useUserStore(({ user }) => user.id);
  const clearUser = useUserStore(({ clearUser }) => clearUser);

  const token: string | undefined = ls.get('auth_token');

  useEffect(() => {
    const checkForToken = (): void => {
      if (!ls.get('token')) {
        ls.clear();
        clearUser();
      }
    };

    ls.on('token', checkForToken);
    return () => ls.off('token', checkForToken);
  }, []);

  useEffect(() => {
    if (token && !userId) {
      getUser.mutate();
    }
  }, [token]);

  useEffect(() => {
    loadLocale(localeCode);
    setLocale(localeCode);
    document.documentElement.lang = localeCode === 'ukUA' ? 'uk' : 'en';
  }, [localeCode]);

  const routes = (
    <Route path='/' element={<BaseLayout />}>
      <Route index element={userId ? <Navigate to='/dashboard' replace /> : <HomeLanding />} />
      <Route path='/confirm-email/:token' element={<EmailConfirmation />} />
      <Route path='/dashboard' element={<DashboardPage />} />
      {!config.disabledForProd && (
        <Route path='/clinic-registration' element={<ClinicRegistration />} />
      )}
      <Route path='/my-calendar' element={<UserCalendar />} />
      <Route path='/privacy-policy' element={<Privacy />} />
      <Route path='/set-new-password/:token' element={<SetNewPassword />} />
      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
      {!config.disabledForProd && <Route path='/pricing' element={<Pricing />} />}
      <Route path='/user-profile' element={<UserProfile />} />
      <Route path='/accept-invitation/:token' element={<AcceptInvitation />} />

      <Route path='/clinic/:clinicId'>
        <Route element={<ClinicActions />}>
          <Route
            path='/clinic/:clinicId/administration/dictionaries'
            element={<ClinicDictionaries />}
          />
          <Route path='/clinic/:clinicId/administration/documents' element={<ClinicDocuments />} />
          <Route path='/clinic/:clinicId/administration/employees' element={<EmployeesList />} />
          {!config.disabledForProd && (
            <Route
              path='/clinic/:clinicId/administration/invitations'
              element={<InvitationsList />}
            />
          )}
          <Route path='/clinic/:clinicId/administration/info' index element={<ClinicInfo />} />
          <Route path='/clinic/:clinicId/administration/print' element={<PrintConfiguration />} />
          {!config.disabledForProd && (
            <Route path='/clinic/:clinicId/administration/roles' element={<RolesList />} />
          )}
          <Route path='/clinic/:clinicId/administration/rooms' element={<RoomsList />} />
        </Route>
        <Route
          path='/clinic/:clinicId/administration/employees/:employeeId'
          element={<EmployeeProfilePage />}
        />
        <Route path='/clinic/:clinicId/appointments' element={<AppointmentsList />} />
        <Route
          path='/clinic/:clinicId/appointments/:appointmentId'
          element={<AppointmentDetails />}
        />

        <Route path='/clinic/:clinicId/clients' element={<ClientsList />} />
        <Route path='/clinic/:clinicId/clients/:clientId' element={<ClientDetails />} />

        <Route path='/clinic/:clinicId/events-calendar' element={<EventsCalendar />} />

        {/*Finance module*/}
        <Route element={<FinancesActions />}>
          <Route path='/clinic/:clinicId/finances/bills' index element={<BillsList />} />
          <Route
            path='/clinic/:clinicId/finances/financial-accounts'
            index
            element={<FinancialAccountsList />}
          />
          <Route path='/clinic/:clinicId/finances/info' index element={<FinancesInfo />} />
          <Route path='/clinic/:clinicId/finances/payments' index element={<PaymentsList />} />
          <Route path='/clinic/:clinicId/finances/shifts' index element={<ShiftsList />} />
        </Route>
        <Route path='/clinic/:clinicId/finances/bills/:billId' index element={<BillsDetails />} />
        <Route
          path='/clinic/:clinicId/finances/financial-accounts/:financialAccountId'
          index
          element={<FinancialAccountDetails />}
        />
        <Route
          path='/clinic/:clinicId/finances/payments/:paymentId'
          index
          element={<PaymentsDetails />}
        />
        <Route path='/clinic/:clinicId/finances/shifts/:shiftId' index element={<ShiftDetails />} />
        {/*end of finance module*/}

        {/*Inventory module*/}
        <Route element={<InventoryActions />}>
          <Route path='/clinic/:clinicId/inventory/audit' index element={<AuditList />} />
          <Route path='/clinic/:clinicId/inventory/equipment' index element={<EquipmentList />} />
          <Route path='/clinic/:clinicId/inventory/generalSettings' element={<GeneralSettings />} />
          <Route path='/clinic/:clinicId/inventory/info' index element={<InventoryInfo />} />
          <Route path='/clinic/:clinicId/inventory/invoices' index element={<InvoicesList />} />
          <Route path='/clinic/:clinicId/inventory/providers' element={<ProvidersList />} />
          <Route path='/clinic/:clinicId/inventory/stock' element={<StockList />} />
          <Route path='/clinic/:clinicId/inventory/tools' element={<ToolsList />} />
        </Route>
        <Route path='/clinic/:clinicId/inventory/audit/:auditId' element={<AuditDetails />} />
        <Route
          path='/clinic/:clinicId/inventory/equipment/:equipmentId'
          index
          element={<EquipmentDetails />}
        />
        <Route
          path='/clinic/:clinicId/inventory/invoices/:invoiceId'
          index
          element={<InvoiceDetails />}
        />
        <Route
          path='/clinic/:clinicId/inventory/providers/:providerId'
          element={<ProviderDetails />}
        />
        <Route path='/clinic/:clinicId/inventory/stock/:stockId' index element={<StockDetails />} />
        <Route path='/clinic/:clinicId/inventory/tools/:toolId' index element={<ToolDetails />} />
        {/*end of inventory module*/}

        <Route path='/clinic/:clinicId/patients' element={<PatientsList />} />
        <Route path='/clinic/:clinicId/patients/:patientId' element={<PatientDetails />} />

        <Route path='/clinic/:clinicId/services' element={<ServicesList />} />
        <Route path='/clinic/:clinicId/services/:serviceId' element={<ServiceDetails />} />

        <Route path='/clinic/:clinicId/shop-farmacy' element={<SalesList />} />
        <Route path='/clinic/:clinicId/shop-farmacy/new' element={<SaleDetails />} />
        <Route path='/clinic/:clinicId/shop-farmacy/:shopFarmacyId' element={<SaleDetails />} />
      </Route>

      <Route path='/403' element={<Forbidden />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  );

  const router = useMemo(() => createBrowserRouter(createRoutesFromElements(routes)), [userId]);

  if (token && !userId) return <Loader />;

  return (
    <ConfigProvider locale={antdLocale}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
