import '../scss/StockDetails.scss';

import { Form, notification } from 'antd';
import { useEditStock } from 'api/hooks/stock/useEditStock';
import { IStock, IStockPayload } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { StockItemForm } from '../forms/StockItemForm';
import { getStockItemPayload } from '../utils/getStockItemPayload';

dayjs.extend(customParseFormat);

type Props = {
  locale: ILocale;
  stock: IStock;
};

export const EditStockItemModal = ({ locale, stock }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const onSuccess = (): void => {
    closeModal();
    notification.success({ message: locale.messages.dataEditedSuccessfully });
  };
  const editStock = useEditStock(clinicId, stock?.id, onSuccess);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSubmit = (formData: IStockPayload): void => {
    const payload = getStockItemPayload(formData);
    editStock.mutate(payload);
  };

  return (
    <>
      <VLXModal title={locale.labels.editStock} centered open form={form} onModalClose={closeModal}>
        <StockItemForm onSubmit={onSubmit} form={form} initialValues={stock}>
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.stock}
            fixed
            hideDeleteBtn
            editMode
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
          />
        </StockItemForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
