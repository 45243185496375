import { create } from 'zustand';

interface SidebarState {
  sidebarOpened: boolean;
  sidebarToggle: () => void;
}

export const useSidebar = create<SidebarState>((set) => ({
  sidebarOpened: false,
  sidebarToggle: () => set((state) => ({ sidebarOpened: !state.sidebarOpened }))
}));
