import './scss/BillPrint.scss';

import { useLocaleCode } from 'api/store/localeContext';
import { TextEditorView } from 'components/TextEditorView';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import { getAnimalAge } from 'pages/patients/utils/PatientHelper';
import React, { ReactNode } from 'react';
import { localizedPetData } from 'utils/localized-pet-data';
import { parseDiagnosis } from 'utils/string-from-entities-list';

interface Props {
  patient: any;
  printAnamnesis?: boolean;
}

const EMPTY_CONTENT = '<p><br></p>';

export const PatientPrintInfo = ({ patient, printAnamnesis }: Props): JSX.Element => {
  const eventLocale = useLocale('private.appointments.event');
  const locale = useLocale('private.appointments.appointment-bill')?.labels;
  const patientLocale = useLocale('private.patients.patient-page');
  const { localeCode } = useLocaleCode();

  const getPatientSex = (sex: string): '' | '♂' | '♀' => {
    if (sex === 'male') return '♂';
    if (sex === 'female') return '♀';
    return '';
  };

  const getPatientDetails = (): ReactNode => {
    const { name, sex, birth_date } = patient;
    const patName = name;
    const { species, breed } = localizedPetData(patient, localeCode);
    const patSpecies = species ? `, ${species}` : '';
    const patBreed = breed ?? '';
    const patAge = birth_date ? getAnimalAge(patientLocale, birth_date) : '';
    const additionalInfo = [patBreed, getPatientSex(sex), patAge].filter((el) => !!el && el !== '');

    return `${patName}${patSpecies} ${
      additionalInfo.length > 0 ? `(${additionalInfo.join(', ')})` : ''
    }`;
  };

  return (
    <div className='patient-print-info'>
      <div style={{ marginTop: 20 }}>
        <span style={{ fontWeight: '600' }}>{`${locale.patient}: `}</span>
        {getPatientDetails()}
      </div>
      <table>
        <tbody>
          <tr>
            <th>{eventLocale.labels.diagnosis}</th>
            <td>
              {patient?.health_record.diagnosis === '[]' ||
              patient?.health_record.diagnosis === null
                ? eventLocale.labels.noDiagnosisRequired
                : parseDiagnosis(patient?.health_record.diagnosis)}
            </td>
          </tr>
          {patient?.health_record?.provisional_diagnosis !== '[]' &&
            patient?.health_record?.provisional_diagnosis !== null && (
              <tr>
                <th>{eventLocale.labels.preliminaryDiagnosis}</th>
                <td>{parseDiagnosis(patient.health_record.provisional_diagnosis)}</td>
              </tr>
            )}
          <tr>
            <th>{eventLocale.labels.recommendations}</th>
            <td>
              <div style={{ whiteSpace: 'break-spaces' }}>
                {patient?.health_record?.recommendations ? (
                  <TextEditorView
                    value={patient?.health_record?.recommendations}
                    borderless
                    showAll
                  />
                ) : (
                  '-'
                )}
              </div>
            </td>
          </tr>
          {printAnamnesis &&
            patient?.health_record?.anamnesis &&
            patient?.health_record?.anamnesis !== EMPTY_CONTENT && (
              <tr>
                <th>{eventLocale.labels.anamnesis}</th>
                <td>
                  <TextEditorView value={patient?.health_record?.anamnesis} borderless showAll />
                </td>
              </tr>
            )}
          <tr>
            <th>{eventLocale.labels.returnVisitRecomended}</th>
            <td>
              {patient.health_record.date_of_next_visit
                ? dayjs(patient.health_record.date_of_next_visit).format('DD.MM.YYYY')
                : eventLocale.labels.notSet}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
