import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IVaccinationsList } from 'api/interfaces/Vaccination';

import { useTableData } from '../useTableData';

const defaultParams = {
  page: 0
};

export const usePatientVaccinationsList = (
  clinicId: number,
  patientId: number,
  params?: Record<string, number>
) => {
  const endpoint = API_ENDPOINTS.PATIENT_VACCINATIONS(clinicId, patientId);
  const queryKeys = ['patientVaccinationsList', clinicId, patientId];

  const result = useTableData<IVaccinationsList>(
    endpoint,
    params || defaultParams,
    queryKeys,
    true
  );

  return {
    ...result,
    vaccinations: result.data.data
  };
};
