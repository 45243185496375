import './DashboardPage.scss';

import { Menu, MenuProps, Typography } from 'antd';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import React, { useEffect, useState } from 'react';

import { DashboardPageAppointmentsList } from './DashboardPageAppointmentsList';

const DashboardPage = (): JSX.Element => {
  const locale = useLocale('private.dashboard.dashboard');
  const [currentTab, setCurrentTab] = useState(useLinkParams('tab'));

  const activeTabSearchParam = useLinkParams('tab');
  const items: MenuProps['items'] = [
    {
      label: locale.tabs.upcomingEvents,
      key: 'upcomingEvents'
    },
    {
      label: locale.tabs.pendingAction,
      key: 'pendingAction'
    }
  ];

  const pushHistoryState = (tab): void => {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?tab=${tab}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };

  const changeTab: MenuProps['onClick'] = (e): void => {
    if (e.key === currentTab) return;
    pushHistoryState(e.key);
    setCurrentTab(e.key);
  };

  useEffect((): void => {
    if (activeTabSearchParam) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  useEffect((): void => {
    if (!currentTab && !activeTabSearchParam) {
      setCurrentTab('upcomingEvents');
    }
  }, [currentTab, activeTabSearchParam]);

  return (
    <div className='dashboard-page'>
      <Typography.Title style={{ fontSize: 22, marginBottom: 10 }}>
        {locale.labels.title}
      </Typography.Title>
      <Menu
        onClick={changeTab}
        selectedKeys={[currentTab]}
        mode='horizontal'
        items={items}
        style={{ marginBottom: 20 }}
      />
      {currentTab === 'upcomingEvents' && (
        <DashboardPageAppointmentsList
          filters={{
            notStatus: 'cancelled',
            startTimeFrom: dayjs().tz('utc').format('YYYY-MM-DDTHH:mm:ss')
          }}
        />
      )}
      {currentTab === 'pendingAction' && (
        <DashboardPageAppointmentsList
          filters={{
            actionRequired: 1,
            startTimeTo: dayjs().tz('utc').format('YYYY-MM-DDTHH:mm:ss')
          }}
        />
      )}
    </div>
  );
};

export default DashboardPage;
