import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { useModal } from 'api/store/modalStore';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const getQuery = ({ timeFrom, timeTo }): string =>
  `${timeTo ? `&q[time_to]=${timeTo}` : ''}${timeFrom ? `&q[time_from]=${timeFrom}` : ''}`;

export const useGenerateVaccinationReport = (clinicId: number) => {
  const { closeModal } = useModal();

  const mutationFn = async (payload) => {
    const response = await axiosClient.get(
      `${API_ENDPOINTS.CLINICS(clinicId)}/vaccination_report?${getQuery(payload)}`,
      { responseType: 'blob' }
    );
    return response.data;
  };

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      saveAs(data, `Vaccination_Report(${dayjs().format('DD-MM-YYYY HH:mm:ss')}).xlsx`);
      closeModal();
    },
    onError: () => {
      notification.error({ message: 'Failed to generate vaccination report.' });
    }
  });
};
