import './scss/ToolsList.scss';

import { Form, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useToolsList } from 'api/hooks/tools/useToolsList';
import { ITool } from 'api/interfaces/Tool';
import { MODAL, useModal } from 'api/store/modalStore';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import StickyButton from 'components/StickyButton';
import { toolStatuses, toolTypes } from 'constants/dictionary/default/inventoryOptions';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateToolModal } from './CreateToolModal';
import { conditionStatus } from './helpers/TagSelector';

function getSearchItems(
  placeholders: Record<string, string>,
  labels: Record<string, string>
): { name: string; placeholder: string }[] {
  return [
    {
      name: 'name',
      placeholder: labels.name
    },
    {
      name: 'condition',
      placeholder: labels.toolCondition
    },
    {
      name: 'location',
      placeholder: labels.location
    },
    {
      name: 'first_name',
      placeholder: placeholders.responsibleFirstName
    },
    {
      name: 'last_name',
      placeholder: placeholders.responsibleLastName
    },
    {
      name: 'tool_type',
      placeholder: labels.toolType
    },
    {
      name: 'manufacturer',
      placeholder: labels.manufacturer
    }
  ];
}

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const ToolsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const userAccess = useAccess();
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.tools');
  const [form] = Form.useForm();
  const { isOpened, open } = useModal();

  const searchItems = useMemo(() => getSearchItems(locale.labels, locale.placeholders), [locale]);

  const { tools, data, isLoading, params, updateParams, updateSorting } = useToolsList(
    clinicId,
    defaultParams,
    false
  );

  const localizedToolStatuses = useLocalizedList(toolStatuses);
  const localizedToolTypes = useLocalizedList(toolTypes);

  useEffect(() => updateParams(defaultParams), []);

  const getToolStatus = (e: ITool): JSX.Element | '' => {
    const res = localizedToolStatuses.find((el) => el?.value === e);
    return res ? conditionStatus(res) : '';
  };

  const onFinish = (): void => {
    const object = form.getFieldsValue();
    const searchObject = {};
    Object.entries(object).forEach(([key, value]) => {
      if (value && value !== '') {
        searchObject[key] = value;
      }
    });
    updateParams(searchObject);
  };

  const redirectToToolDetails = (record: ITool) =>
    userAccess.accesses.tools.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/tools/${record.id}`);
      }
    };

  const getToolType = (e: ITool): string => {
    const res = localizedToolTypes.find((el) => el?.value === e);
    return res?.label ?? '-';
  };

  const columns = useMemo(
    (): TableColumnProps<ITool>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        onCell: redirectToToolDetails,
        render: (name, record) => name
      },
      {
        key: 'condition',
        dataIndex: 'condition',
        title: locale.labels.toolCondition,
        sorter: () => null,
        onCell: redirectToToolDetails,
        render: (condition) => getToolStatus(condition)
      },
      {
        key: 'uses_left',
        dataIndex: 'uses_left',
        title: locale.labels.usesLeft,
        onCell: redirectToToolDetails,
        render: (uses_left) => uses_left
      },
      {
        key: 'tool_type',
        dataIndex: 'tool_type',
        title: locale.labels.toolType,
        sorter: () => null,
        onCell: redirectToToolDetails,
        render: (tool_type) => getToolType(tool_type)
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: redirectToToolDetails,
        render: (location) => location || '-'
      }
    ],
    [tools]
  );

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Table
        pagination={false}
        className='accent-header tools-list'
        rowKey='id'
        loading={isLoading}
        dataSource={tools}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onChange={updateSorting}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          className={'equipment-pagination'}
          current={+params.page}
          pageSize={20}
          total={data.metadata.total || 0}
          onChange={(page) => updateParams({ page })}
        />
        {userAccess.accesses.tools.create && (
          <StickyButton
            offsetBottom={10}
            onClick={() => open(MODAL.addToolModal)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
        )}
        {userAccess.accesses.tools.create && isOpened(MODAL.addToolModal) && (
          <CreateToolModal clinicId={clinicId} />
        )}
      </Row>
    </>
  );
};
