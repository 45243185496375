import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ITool, IToolsList } from 'api/interfaces/Tool';

import { useTableData } from '../useTableData';

export const useToolsList = (
  clinicId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = API_ENDPOINTS.TOOLS(clinicId);

  const result = useTableData<IToolsList>(endpoint, defaultParams, ['toolsList', clinicId], searchDisabled);

  return {
    ...result,
    tools: result.data.data as ITool[] || []
  };
};
