import './scss/PrintPricesForm.scss';

import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { IStock } from 'api/interfaces/Stock';
import { printPricesConfigurations } from 'constants/dictionary/default/selectOptions';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { requiredRule } from 'utils/form-rules';

import { PricesComponent } from './PricesComponent';
import { PricesListItem } from './PricesListItem';
import { PrintMultipleStocksFormItem } from './PrintMultipleStocksFormItem';
import { IPriceComponent } from './types';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  stockData: IStock[];
  showChooseNewStockBtn?: boolean;
}>;

export const PrintMultipleStocksForm = ({
  children,
  form,
  locale,
  stockData,
  showChooseNewStockBtn
}: Props): JSX.Element => {
  const localizedPrintPricesConfigurations = useLocalizedList(printPricesConfigurations);
  const contentToPrint = useRef(null);

  const [pricesToPrint, setPricesToPrint] = useState(null);
  const [stock, setStock] = useState(stockData);

  useEffect(() => {
    setStock(stockData);

    form.setFieldsValue({
      stock: getUniqueStock(stockData),
      printConfig: ['name', 'barcode', 'price', 'sku']
    });
  }, [stockData]);

  useEffect((): (() => void) => {
    return () => setPricesToPrint(null);
  }, []);

  useEffect(() => {
    if (pricesToPrint) handlePrint(null, () => contentToPrint.current);
  }, [pricesToPrint]);

  const handlePrint = useReactToPrint({
    documentTitle: '',
    removeAfterPrint: true
  });

  const getPrintComponent = (
    stockData: IStock,
    priceData: IPriceComponent,
    printConfig: Array<string>
  ) => {
    const selectedStock = stock.find((el) => el.id === stockData?.id);
    const selectedPrice = selectedStock.prices.find((el) => el.id === priceData.priceId);
    return (
      <PricesComponent
        item={priceData}
        printConfig={printConfig}
        locale={locale}
        price={selectedPrice}
        stock={selectedStock}
      />
    );
  };

  const onSubmit = (data): void => {
    const res = [];
    data.stock.forEach((item) => {
      item.pricesToPrint.forEach((el) => {
        for (let i = 0; i < el.printQuantity; i++) {
          res.push(getPrintComponent(item, el, data?.printConfig));
        }
      });
    });
    setPricesToPrint(res);
  };

  const getUniqueStock = (stockList: IStock[]): Array<{ pricesToPrint } & IStock> => {
    const uniqueStocksMap = new Map<number, { pricesToPrint } & IStock>();

    stockList.forEach((el) => {
      if (!uniqueStocksMap.has(el.id)) {
        uniqueStocksMap.set(el.id, {
          ...el,
          pricesToPrint: el.prices
            .filter((price) => price.type === 'default')
            .map((price) => ({ ...price, priceId: price.id }))
        });
      }
    });

    return Array.from(uniqueStocksMap.values());
  };

  const selectNewStock = (newStock: IStock) => {
    if (newStock) {
      setStock([...stock, newStock]);
    }
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      className='print-prices-form'
      layout='vertical'
      form={form}
    >
      <Col lg={12} xl={10} md={15}>
        <Form.Item
          label={locale.labels.content}
          name='printConfig'
          rules={[requiredRule(locale.errors.mandatoryField)]}
        >
          <Select
            filterOption={false}
            mode='multiple'
            options={localizedPrintPricesConfigurations}
          />
        </Form.Item>
      </Col>

      <Form.List name='stock'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={`stock-row-${key}`}>
                <Row className='stock-name-box'>
                  <PrintMultipleStocksFormItem
                    locale={locale}
                    stock={form.getFieldValue(['stock', name])}
                    name={name}
                    form={form}
                    selectNewStock={selectNewStock}
                  />
                  <div className='stock-name-box__divider'></div>
                  <Form.Item label={' '}>
                    <Button
                      onClick={() => {
                        remove(name);
                      }}
                      danger
                      type='primary'
                      shape={'circle'}
                      className='remove-btn'
                      style={{ marginLeft: 20 }}
                    >
                      <span className='icofont icofont-trash mr-2' />
                    </Button>
                  </Form.Item>
                </Row>
                <Form.List name={[name, 'pricesToPrint']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name: priceName }) => (
                        <PricesListItem
                          key={`price-row-${key}`}
                          locale={locale}
                          id={priceName}
                          remove={remove}
                          prices={form.getFieldValue(['stock', name, 'prices'])}
                          stock={form.getFieldValue(['stock', name])}
                          price={form.getFieldValue(['stock', name, 'pricesToPrint', priceName])}
                        />
                      ))}
                      <Form.Item>
                        <Button type='primary' onClick={() => add()} style={{ borderRadius: 30 }}>
                          <span
                            className='icofont icofont-plus mr-2'
                            style={{ fontSize: '1.3em' }}
                          />
                          <span>{locale.buttons.add}</span>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            ))}
            {showChooseNewStockBtn && (
              <Col style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
                <Form.Item style={{ marginBottom: 0, marginRight: 20 }}>
                  <Button type='primary' onClick={() => add()} style={{ borderRadius: 30 }}>
                    <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
                    <span>{locale.buttons.addStock}</span>
                  </Button>
                </Form.Item>
                <div className='stock-name-box__divider'></div>
              </Col>
            )}
          </>
        )}
      </Form.List>
      {children}
      <div className='prices-print-content-wrapper'>
        {
          <div className='prices-print-wrapper'>
            <div className='prices-print-content' ref={contentToPrint}>
              {pricesToPrint}
            </div>
          </div>
        }
      </div>
    </Form>
  );
};
