export const breadcrubmsLocale = {
  enGB: {
    'user-profile': 'My profile',
    inventory: 'Inventory',
    employees: 'Employees',
    details: 'Details',
    tools: 'Tools',
    stock: 'Stock',
    equipment: 'Equipment',
    invitations: 'Invitations',
    rooms: 'Rooms',
    roles: 'Roles',
    appointments: 'Appointments',
    patients: 'Patients',
    services: 'Services',
    events: 'Calendar',
    clients: 'Clients',
    invoices: 'Invoices',
    'financial-accounts': 'Financial account',
    bills: 'Bills',
    shifts: 'Working shifts',
    payments: 'Payments',
    documents: 'Documents',
    dictionaries: 'Dictionaries',
    audit: 'Audit',
    'shop-farmacy': 'Store/Pharmacy',
    'general-settings': 'General settings',
    providers: 'Providers'
  },
  ukUA: {
    'user-profile': 'Мій профіль',
    inventory: 'Склад',
    employees: 'Працівники',
    details: 'Деталі',
    tools: 'Інструменти',
    stock: 'Склад',
    equipment: 'Обладнання',
    invitations: 'Запрошення',
    rooms: 'Приміщення',
    roles: 'Ролі',
    appointments: 'Візити',
    patients: 'Пацієнти',
    services: 'Послуги',
    events: 'Календар',
    clients: 'Клієнти',
    invoices: 'Накладні',
    'financial-accounts': 'Рахунки',
    bills: 'Квитанції',
    shifts: 'Робочі зміни',
    payments: 'Оплати',
    documents: 'Документи',
    dictionaries: 'Словники',
    audit: 'Інвентаризація',
    'shop-farmacy': 'Магазин/Аптека',
    'general-settings': 'Загальні налаштування',
    providers: 'Постачальники'
  }
};
