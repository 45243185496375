import '../scss/ViewEditInvoice.scss';

import { Spin } from 'antd';
import { useBillDetails } from 'api/hooks/bills/useBillDetails';
import { useDeteleInvoice } from 'api/hooks/invoices/useDeteleInvoice';
import { useInvoiceDetails } from 'api/hooks/invoices/useInvoiceDetails';
import { MODAL, useModal } from 'api/store/modalStore';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { StatusTag } from 'components/StatusTag';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useInvoiceId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

import { AddItemsToStockModal } from '../../stock/modals/AddItemsToStockModal';
import { EditInvoiceModal } from '../EditInvoiceModal';
import { InvoicePurchasesTable } from '../InvoicePurchasesTable';
import { InvoiceDetailsHeader } from './InvoiceDetailsHeader';

dayjs.extend(customParseFormat);

const InvoiceDetails = (): JSX.Element => {
  const { isOpened, closeModal } = useModal();
  const clinicId = useClinicId();
  const invoiceId = useInvoiceId();
  const userAccess = useAccess();
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.inventory.invoices');

  const deleteInvoice = useDeteleInvoice(clinicId, invoiceId);
  const { data: invoice, isPending } = useInvoiceDetails(clinicId, invoiceId);
  const { data: bill, isPending: billLoading } = useBillDetails(clinicId, invoice?.bill_id);

  const editingDisabled = ['partially_paid', 'paid'].includes(bill?.payment_status);

  const onConfirmDeleteClick = (): void => {
    deleteInvoice.mutate(null);
    closeModal();
  };

  return (
    <Spin spinning={isPending || billLoading} className='invoice-details-page-spin-container'>
      {!!invoice && !!bill && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='invoice-number'>
              {invoice.invoice_number}
            </h4>
            {!!invoice?.payment_status && <StatusTag status={invoice?.payment_status} />}
          </div>
          <div className='invoice-row-container__form' style={{ marginBottom: 10 }}>
            <InvoiceDetailsHeader
              invoice={invoice}
              clinicId={clinicId}
              currency={currencies.uah}
              locale={locale}
              editingDisabled={editingDisabled}
              bill={bill}
            />
          </div>
          <div className='invoice-row-container__table'>
            <InvoicePurchasesTable editingDisabled={editingDisabled} invoice={invoice} />
          </div>
          {isOpened(MODAL.editInvoiceModal) && (
            <EditInvoiceModal locale={locale} invoice={invoice} />
          )}
          {isOpened(MODAL.confirmDeleteModal) && (
            <ConfirmDeleteModal
              type={'device'}
              open
              onOk={onConfirmDeleteClick}
              onCancel={closeModal}
            />
          )}
          {isOpened(MODAL.addItemsToStockModal) && (
            <AddItemsToStockModal
              accessGroup={userAccess.accesses.invoices}
              invoice={invoice}
              locale={locale}
            />
          )}
        </div>
      )}
    </Spin>
  );
};

export default InvoiceDetails;
