import '../Finances.scss';

import { Button, Col, Pagination, Row, Spin, Table, TableColumnProps, Tooltip } from 'antd';
import { useBankAccountsList } from 'api/hooks/finances/useBankAccountsList';
import { IBankAccount } from 'api/interfaces/Finances';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { financialAccountsTypesOptions, financialAcountsOptions } from 'constants/dictionary/default/selectOptions';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { AddFinancialAccountModal } from 'layout/modals/finances/addFinancialAccount/AddFinancialAccountModal';
import { TransferAccountsModal } from 'layout/modals/finances/transferAccounts/TransferAccountsModal';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import valueFromList from 'utils/value-from-list';

const defaultParams = {
  page: 1,
  sort_key: 'name',
  sort_order: 'desc'
};

const FinancialAccountsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();

  const locale = useLocale('private.finances.financial-accounts');
  const currencies = useLocale('private.currencies').labels;
  const { open, isOpened } = useModal();
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const { bankAccounts, data, isPending, params, updateParams, updateSorting } =
    useBankAccountsList(clinicId, defaultParams, false);

  const redirectToAccountDetails = (record: IBankAccount): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/financial-accounts/${record.id}`);
    }
  });

  const localizedFinancialAcountsOptions = useLocalizedList(financialAcountsOptions);
  const localizedFinancialAcountsTypesOptions = useLocalizedList(financialAccountsTypesOptions);

  const getStatus = (e: IBankAccount): string => {
    const res = localizedFinancialAcountsOptions.find((el) => el.value === e);
    return res ? res.label : '';
  };

  const columns = useMemo(
    (): TableColumnProps<IBankAccount>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (name) => name ?? '-',
        onCell: redirectToAccountDetails
      },
      {
        key: 'account_type',
        dataIndex: 'account_type',
        title: locale.labels.accountType,
        sorter: (a, b) => (a.account_type > b.account_type ? 1 : -1),
        render: (account_type) =>
          valueFromList(localizedFinancialAcountsTypesOptions, account_type),
        onCell: redirectToAccountDetails
      },
      {
        key: 'account_number',
        dataIndex: 'account_number',
        title: locale.labels.number,
        sorter: (a, b) => (a.account_number > b.account_number ? 1 : -1),
        render: (account_number) => account_number ?? '-',
        onCell: redirectToAccountDetails
      },
      {
        key: 'current_balance',
        dataIndex: 'current_balance',
        title: locale.labels.currentBalance,
        sorter: (a, b) => (a.current_balance > b.current_balance ? 1 : -1),
        render: (current_balance, e) => `${money(current_balance)} ${currencies.uah}`,
        onCell: redirectToAccountDetails
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: locale.labels.status,
        sorter: (a, b) => (a.status > b.status ? 1 : -1),
        render: getStatus,
        onCell: redirectToAccountDetails
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <Tooltip title={!shiftOpened ? locale.labels.transferBlockedClosedShift : null}>
            <Col style={{ width: 'min-content' }}>
              <Button
                className={'icofont icofont-loop open-modal-button transfer-button-table-view'}
                type={'primary'}
                disabled={!shiftOpened}
                onClick={() => {
                  setSelectedAccountId(e.id);
                  open(MODAL.transferAccountsModal);
                }}
              ></Button>
            </Col>
          </Tooltip>
        )
      }
    ],
    [data, shiftOpened]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Spin spinning={isPending} className='financial-accounts-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header financial-accounts-list-table'
          rowKey='id'
          dataSource={bankAccounts}
          columns={columns}
          onChange={updateSorting}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            current={+params.page}
            total={data.metadata.total || 0}
            showSizeChanger={false}
            pageSize={20}
            onChange={(page) => updateParams({ page })}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => open(MODAL.addFinancialAccountModal)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addAccount}
          />
        </Row>
      </Spin>
      {isOpened(MODAL.addFinancialAccountModal) && <AddFinancialAccountModal />}
      {isOpened(MODAL.transferAccountsModal) && (
        <TransferAccountsModal currentAccountId={selectedAccountId} />
      )}
    </>
  );
};

export default FinancialAccountsList;
