import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClientHistoryList } from 'api/interfaces/Client';

import { useTableData } from '../useTableData';

const params = {
  page: 1,
  sort_key: 'event_time',
  sort_order: 'desc'
};

export const useClientsHistory = (clinicId: number, clientId: number) => {
  const endpoint = `${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}/history`;
  const queryKey = ['clientHistory', clinicId, clientId];

  const result = useTableData<IClientHistoryList>(endpoint, params, queryKey, true);

  return {
    ...result,
    clientHistory: result.data.data
  };
};
