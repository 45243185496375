import { Form, Tooltip } from 'antd';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IHealthRecord } from 'api/interfaces/Patient';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { AppointmentDetailsPatientTabForm } from './AppointmentDetailsPatientTabForm';
import { AppointmentDetailsPatientTabServicesForm } from './AppointmentDetailsPatientTabServicesForm';

type Props = {
  patientId: number;
  onlyServices?: boolean;
  initialValues: IHealthRecord;
  locale: ILocale;
  appointment: IAppointmentDetails;
  disabled: boolean;
};

export const AppointmentDetailsPatientTab = ({
  patientId,
  onlyServices,
  initialValues,
  locale,
  appointment,
  disabled
}: Props): JSX.Element => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { accesses } = useAccess();
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setShowCancelModal(false);
    setEditMode(false);
  };

  const onFieldsChange = (): void => {
    if (form.isFieldsTouched() && !editMode) {
      setEditMode(true);
    }
  };

  const buttons = (): JSX.Element => (
    <ViewDetailsButtonsGroup
      fixed
      editMode={editMode}
      onEditClick={() => setEditMode(true)}
      accessGroup={accesses.appointments}
      hideEditBtn
      hideDeleteBtn
      onCancelClick={() => {
        if (form.isFieldsTouched()) {
          setShowCancelModal(true);
        } else {
          setEditMode(false);
        }
      }}
      onDeleteClick={() => setShowCancelModal(false)}
    />
  );

  const getTooltipText = () => {
    if (!disabled) return null;
    if (disabled && appointment?.status === 'finished') {
      return locale.messages.dataCannonBeModified;
    } else {
      return locale.messages.startAppointmentFirst;
    }
  };

  return (
    <>
      <Tooltip
        trigger='hover'
        placement='top'
        title={getTooltipText()}
      >
        {onlyServices && (
          <AppointmentDetailsPatientTabServicesForm
            appointment={appointment}
            form={form}
            initialValues={initialValues}
            onFieldsChange={onFieldsChange}
            setEditMode={setEditMode}
            locale={locale}
            patientId={patientId}
            disabled={disabled}
          >
            {buttons()}
          </AppointmentDetailsPatientTabServicesForm>
        )}
        {!onlyServices && (
          <AppointmentDetailsPatientTabForm
            appointment={appointment}
            form={form}
            initialValues={initialValues}
            setEditMode={setEditMode}
            onFieldsChange={onFieldsChange}
            locale={locale}
            patientId={patientId}
            disabled={disabled}
          >
            {buttons()}
          </AppointmentDetailsPatientTabForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </Tooltip>
    </>
  );
};
