import '../scss/StockDetails.scss';

import { Button, Col, Form, FormInstance, notification, Row, Select } from 'antd';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { useReassignLocations } from 'api/hooks/stock/useReassignLocations';
import { IRoom } from 'api/interfaces/Room';
import { ILocation, IReassignedLocation, IStock } from 'api/interfaces/Stock';
import { useModal } from 'api/store/modalStore';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useCallback, useMemo, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

import { getLocationReassignPayload } from '../utils/getLocationReassignPayload';
import { useGetResidual } from '../utils/useGetResidual';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  stock: IStock;
  form: FormInstance;
  locale: ILocale;
}>;

export const ReassignLocationForm = ({ stock, children, form, locale }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal } = useModal();

  const onSuccess = () => {
    notification.success({ message: locale.messages.locationReassignedSuccessfully });
    closeModal();
  };

  const { rooms } = useRoomsList(clinicId);
  const reassignLocations = useReassignLocations(clinicId, stock?.id, onSuccess);

  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stock);

  const [locationFrom, setLocationFrom] = useState<ILocation>(null);

  const roomsList = useMemo(
    (): IRoom[] => rooms.filter((room) => room.id !== locationFrom?.room_id && !room.deleted),
    [rooms, locationFrom]
  );

  const onSubmit = async ({ list }: { list: IReassignedLocation[] }): Promise<void> => {
    if (form.getFieldError(['location_from'])[0]) return;

    const payload = getLocationReassignPayload(list, roomsList, locationsList);

    if (locationFrom.name !== 'unassigned') {
      let baseResidual = getBaseResidual(list);

      payload.push({
        room_id: locationFrom.room_id,
        quantity_packages: Math.floor(baseResidual / stock.quantity_per_package),
        quantity_units: baseResidual % stock.quantity_per_package
      });
    }

    reassignLocations.mutate({ locations: payload });
  };
  const locationsList = useMemo(
    () =>
      stock?.locations.filter((el) => !el.deleted).sort((a) => (a.name === 'unassigned' ? 1 : -1)),
    [stock]
  );

  const locationSelectOptions = (locations: ILocation[]): JSX.Element[] =>
    locations.map(
      (item): JSX.Element => (
        <Option value={item.id} key={item.id}>
          {`${item.name === 'unassigned' ? locale.labels.unassigned : item.name}: ${getResidual(
            item.residual || 0
          )}`}
        </Option>
      )
    );

  const roomSelectOptions = (): JSX.Element[] => {
    const list = roomsList.map((item): JSX.Element => {
      const room = locationsList.find((location) => location.room_id === item.id);

      return (
        <Option value={item.id} key={item.id}>
          {`${item.name}: ${getResidual(room?.residual || 0)}`}
        </Option>
      );
    });

    const unassignedLocation = stock.locations.find((item) => item.name === 'unassigned');

    return list.concat([
      <Option value={'unassigned'} key={'unassigned'}>
        {`${locale.labels.unassigned}: ${getResidual(unassignedLocation?.residual || 0)}`}
      </Option>
    ]);
  };

  const getBaseResidual = (list): number => {
    let residual = locationFrom.residual;

    list.forEach((item) => {
      if (!item) return;
      residual -= (item.quantity_packages || 0) * stock.quantity_per_package;
      residual -= item.quantity_units || 0;
    });

    return residual;
  };

  const residualValidate = (): void => {
    let residual = getBaseResidual(form.getFieldsValue().list);

    form.setFields([
      {
        name: 'location_from',
        errors: [residual < 0 ? locale.errors.notEnoughInLocation : null]
      }
    ]);
  };

  const RoomSelector = useCallback(() => {
    const onSelectFrom = (locationId: number): void => {
      const location = stock.locations.find((item) => item.id === locationId);
      form.setFieldValue(['list'], [undefined]);
      setLocationFrom(location);
    };

    return (
      <Select placeholder={locale.labels.pickLocation} onChange={onSelectFrom}>
        {locationSelectOptions(locationsList.filter((item) => item.residual))}
      </Select>
    );
  }, [stock]);

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item label={locale.labels.from} name='location_from'>
        <RoomSelector />
      </Form.Item>
      <Form.List name='list'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <Row key={key} gutter={16} className='invitation-creation-form-row'>
                <Col lg={8} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={locale.labels.to}
                    name={[name, 'room_id']}
                    rules={[requiredRule()]}
                  >
                    <Select
                      disabled={!locationFrom?.id}
                      placeholder={locale.labels.pickLocation}
                      className='room-select'
                    >
                      {roomSelectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                {!noPackageFormat && (
                  <Col lg={7} md={12} sm={12} xs={24}>
                    <Form.Item
                      label={`${locale.labels.quantity} (${packagingFormat}):`}
                      name={[name, 'quantity_packages']}
                    >
                      <VLXInputNumber disabled={!locationFrom?.id} onChange={residualValidate} />
                    </Form.Item>
                  </Col>
                )}
                <Col lg={7} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={`${locale.labels.quantity} (${unitOfMeasurement}):`}
                    name={[name, 'quantity_units']}
                  >
                    <VLXInputNumber disabled={!locationFrom?.id} onChange={residualValidate} />
                  </Form.Item>
                </Col>
                <Col lg={2} md={8} sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      remove(name);
                      residualValidate();
                    }}
                    danger
                    type='primary'
                    shape={'circle'}
                    className='locations-remove-btn'
                  >
                    <span className='icofont icofont-trash mr-2' />
                  </Button>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                disabled={!locationFrom?.id}
                type='primary'
                onClick={() => add()}
                style={{ borderRadius: 30 }}
              >
                <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
                <span>{locale.buttons.add}</span>
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};
