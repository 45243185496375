import '../scss/StockForm.scss';

import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { useClinicInfo } from 'api/hooks/clinic/useClinicInfo';
import { IStock, IStockPayload } from 'api/interfaces/Stock';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
// import { AddDictionaryModal } from 'layout/modals/addDictionary/AddDictionaryModal';
import { FORMATS } from 'pages/sales/utils/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { generateUniqueBarcode } from 'utils/generate-unique-barcode';

import { PLURAL, usePlural } from '../utils/usePlural';

const { Option } = Select;

type StockInputProps = {
  name: string;
  label: string;
  requiredMsg?: string;
  max?: number;
};

type Props = React.PropsWithChildren<{
  onSubmit: (data: IStockPayload) => void;
  form: FormInstance;
  initialValues?: IStock;
  createFromInvoice?: boolean;
}>;

export const StockItemForm = ({
  onSubmit,
  form,
  initialValues,
  children,
  createFromInvoice = false
}: Props): JSX.Element => {
  const locale = useLocale('private.inventory.stock');
  const { unitFormat, packageFormat } = usePlural();
  const packages = useI18n(packagesLocale);
  const measurements = useI18n(measurementsLocale);
  const clinicId = useClinicId();
  // const [dictionarySearchValue, _setDictionarySearchValue] = useState('');
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasurement] = useState(null);
  const [selectedPackagingFormat, setSelectedPackagingFormat] = useState(
    initialValues?.packaging_format
  );
  const [selectedPackagingType, setSelectedPackagingType] = useState(
    initialValues?.packaging_format === 'none' ? FORMATS.unit : FORMATS.package
  );
  // const [dictionaryModalType, _setDictionaryModalType] = useState(null);
  const noPackaging = selectedPackagingType === FORMATS.unit;

  const { data: clinic } = useClinicInfo(clinicId);
  // const { dictionary: stockTypes } = useDictionary(clinicId, DICTIONARY.stockTypeId);
  // const { dictionary: stockCategories } = useDictionary(clinicId, DICTIONARY.stockCategoryId);
  // const { dictionary: stockSubCategories } = useDictionary(clinicId, DICTIONARY.stockSubCategoryId);

  const packagingFormat = packageFormat(1, selectedPackagingFormat);
  const unitOfMeasurement = unitFormat(1, selectedUnitOfMeasurement);
  const packageAndUnitSelected = packagingFormat && unitOfMeasurement;

  const mapInitialValues = () =>
    !!initialValues && {
      ...initialValues,
      packaging_format:
        initialValues?.packaging_format === 'none' ? null : initialValues?.packaging_format
    };

  useEffect(() => {
    if (!initialValues && clinic) {
      form.setFieldsValue({
        markup_package: clinic?.markup_package,
        markup_unit: clinic?.markup_unit
      });
    }
  }, [clinic, initialValues]);

  // const generateOptions = (options): JSX.Element[] =>
  //   options.map((item) => (
  //     <Option key={item.id + item.name} title={item.name} value={item.id}>
  //       {item.name}
  //     </Option>
  //   ));

  // const filterStockOption = (input: string, option): boolean => option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  const localizedUsageUnits = Object.keys(measurements).map((key) => (
    <Option key={`status_${key}`} value={key}>
      {measurements[key][PLURAL.one]}
    </Option>
  ));

  const localizedPackages = Object.keys(packages).map((key) => (
    <Option key={`status_${key}`} value={key}>
      {packages[key][PLURAL.one]}
    </Option>
  ));

  const selectPackagingFormat = (value: string): void => {
    if (value === 'none') {
      form.setFieldValue('quantity_per_package', 1);
    }
    setSelectedPackagingFormat(value);
  };

  // const StockSelect = useCallback(
  //   ({ label, type, options }): JSX.Element => (
  //     <Form.Item name={type} label={label}>
  //       <Select
  //         showSearch
  //         onSearch={(val) => {
  //           setDictionarySearchValue(val);
  //           setDictionaryModalType(type);
  //         }}
  //         filterOption={filterStockOption}
  //         notFoundContent={
  //           <div
  //             onClick={() => {
  //               open(MODAL.addDictionary);
  //               setDictionaryModalType(type);
  //             }}
  //             id='addNewServiceGroupBtn'
  //           >
  //             + {locale.labels.addNewValue}
  //           </div>
  //         }
  //       >
  //         {generateOptions(options)}
  //       </Select>
  //     </Form.Item>
  //   ),
  //   []
  // );

  const StockInput = useCallback(
    ({ name, label, requiredMsg, max }: StockInputProps): JSX.Element => {
      const required = requiredMsg ? [requiredRule(requiredMsg)] : [];
      const rules = [maxRule(max || 50, locale.errors[`length_rule_${max || 50}`]), ...required];

      return (
        <Form.Item name={name} label={label} rules={rules}>
          <Input />
        </Form.Item>
      );
    },
    []
  );

  const setGeneratedBarcode = (): void => {
    const barcode = generateUniqueBarcode();
    form.setFieldValue('barcode', barcode);
  };

  return (
    <Form
      key={'StockForm'}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={mapInitialValues()}
    >
      <Row gutter={[40, 0]} className={'form-row'}>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.details}</h5>}>
            <StockInput
              name='name'
              label={locale.labels.name}
              requiredMsg={locale.errors.enterStockName}
              max={100}
            />
            <StockInput
              name='manufacturer'
              label={locale.labels.manufacturer}
              requiredMsg={locale.errors.enterManufacturer}
            />
            <Row>
              <Col flex='auto'>
                <StockInput name='barcode' label={locale.labels.barcode} />
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={setGeneratedBarcode}
                  style={{ borderRadius: 30, width: 44, marginLeft: '6px', marginTop: '29px' }}
                >
                  <span className='icofont icofont-loop' />
                </Button>
              </Col>
            </Row>
            <StockInput name='sku' label={locale.labels.sku} />
            {/*<StockSelect*/}
            {/*  type='stock_type_id'*/}
            {/*  label={locale.labels.stockType}*/}
            {/*  options={stockTypes}*/}
            {/*/>*/}
            {/*<StockSelect*/}
            {/*  type='stock_category_id'*/}
            {/*  label={locale.labels.category}*/}
            {/*  options={stockCategories}*/}
            {/*/>*/}
            {/*<StockSelect*/}
            {/*  type='stock_sub_category_id'*/}
            {/*  label={locale.labels.subCategory}*/}
            {/*  options={stockSubCategories}*/}
            {/*/>*/}
            <StockInput name='unique_code' label={locale.labels.uniqueCode} />
            <Form.Item label={<h5>{locale.labels.additionalDetails}</h5>}>
              <TextEditor label={locale.labels.description} name={['description']} rows={3} />
              <TextEditor label={locale.labels.instructions} name={['instructions']} rows={3} />
              <TextEditor label={locale.labels.ingredients} name={['ingredients']} rows={3} />
              <TextEditor label={locale.labels.notes} name={['notes']} rows={3} />
            </Form.Item>
          </Form.Item>
        </Col>

        {/* SECOND COLUMN */}

        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.defaultMarkups}</h5>}>
            {!noPackaging && (
              <Form.Item
                label={locale.labels.perPackage}
                name='markup_package'
                rules={[requiredRule(locale.errors.canNotBeEmpty)]}
              >
                <VLXInputNumber />
              </Form.Item>
            )}
            <Form.Item
              label={locale.labels.perUnit}
              name='markup_unit'
              rules={[requiredRule(locale.errors.canNotBeEmpty)]}
            >
              <VLXInputNumber />
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.packing}</h5>}>
            {!initialValues && (
              <Form.Item label={`${locale.labels.packagingType}`}>
                <p className='packaging-type-hint'>
                  <span className='icofont icofont-info-circle' style={{ paddingRight: 5 }}></span>
                  {locale.messages.packagingTypeHint}
                </p>
                <Button
                  className={`packaging-type-btn left ${noPackaging ? 'active' : ''}`}
                  onClick={() => setSelectedPackagingType(FORMATS.unit)}
                >
                  {locale.labels.pieces}
                </Button>
                <Button
                  className={`packaging-type-btn right ${!noPackaging ? 'active' : ''}`}
                  onClick={() => setSelectedPackagingType(FORMATS.package)}
                >
                  {locale.labels.packaging}
                </Button>
                <p className='packaging-type-hint'>
                  {selectedPackagingType === FORMATS.unit
                    ? locale.messages.unitFormatHint
                    : locale.messages.packagingFormatHint}
                </p>
              </Form.Item>
            )}
            {!noPackaging && (
              <>
                <Form.Item
                  name='packaging_format'
                  label={`${locale.labels.packagingFormat}`}
                  rules={[
                    requiredRule(locale.errors.enterPackagingFormat),
                    maxRule(50, locale.errors.enterMaxFieldValueLength_1_50)
                  ]}
                >
                  <Select onChange={selectPackagingFormat} filterOption={false}>
                    {localizedPackages}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='unit_of_measurement'
                  label={locale.labels.usageMeasurement}
                  rules={[requiredRule(locale.errors.enterUsageMeasurement)]}
                >
                  <Select
                    onChange={(value) => setSelectedUnitOfMeasurement(value)}
                    filterOption={false}
                  >
                    {localizedUsageUnits}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='quantity_per_package'
                  label={`${locale.labels.quantityPerPackage} (${locale.labels.inMesUn})`}
                  rules={[
                    requiredRule(locale.errors.enterQuantityPerPackage),
                    patternRule(PATTERNS.DIGITS_MAX_10, locale.errors.onlyNumberAllowedMax10Chars)
                  ]}
                >
                  <Input
                    disabled={!!initialValues}
                    onKeyPress={(event) => {
                      const fieldValue = form.getFieldValue('usage_step');
                      const regExp = /[.,]/.test(fieldValue) ? /[0-9]/ : /[0-9.,]/;
                      if (!regExp.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name='reorder_point'
              label={`${locale.labels.reorderPoint} ${
                noPackaging ? '' : `(${locale.labels.inMesUn})`
              }`}
              rules={[
                patternRule(PATTERNS.DIGITS_MAX_10, locale.errors.onlyNumberAllowedMax10Chars)
              ]}
            >
              <Input
                onKeyPress={(event) => {
                  const fieldValue = form.getFieldValue('usage_step');
                  const regExp = /[.,]/.test(fieldValue) ? /[0-9]/ : /[0-9.,]/;
                  if (!regExp.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Form.Item>
          {!initialValues && !createFromInvoice && (
            <Form.Item label={<h5>{locale.labels.initialPricesAndResidual}</h5>}>
              {!noPackaging && (
                <Form.Item
                  name='price_per_package'
                  label={`${locale.labels.price} (${packagingFormat || locale.labels.package})`}
                >
                  <VLXInputNumber disabled={!packageAndUnitSelected} />
                </Form.Item>
              )}
              <Form.Item
                name='price_per_unit'
                label={`${locale.labels.price} ${
                  noPackaging ? '' : `(${unitOfMeasurement || locale.labels.units})`
                }`}
              >
                <VLXInputNumber disabled={!noPackaging && !packageAndUnitSelected} />
              </Form.Item>
              {!noPackaging && (
                <Form.Item
                  name='quantity_in_packages'
                  label={`${locale.labels.quantity} (${packagingFormat || locale.labels.packages})`}
                >
                  <VLXInputNumber disabled={!packageAndUnitSelected} />
                </Form.Item>
              )}
              <Form.Item
                name='quantity_in_units'
                label={`${locale.labels.quantity} ${
                  noPackaging
                    ? ''
                    : `(${unitOfMeasurement || locale.labels.units}, ${
                        locale.labels.notIncludedInPackage
                      })`
                }`}
              >
                <VLXInputNumber disabled={!noPackaging && !packageAndUnitSelected} />
              </Form.Item>
            </Form.Item>
          )}
        </Col>
      </Row>
      {children}
      {/* <AddDictionaryModal
        defaultDictionaryNameValue={dictionarySearchValue}
        title={locale.labels.addNewValue}
        dictionaryKey={dictionaryModalType}
        onSuccess={(id: number) => {
          form.setFieldValue(dictionaryModalType, id);
        }}
      /> */}
    </Form>
  );
};
