import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillPaymentPayload } from 'api/interfaces/Bills';
import { useInvoiceId, useSaleId } from 'hooks/useId';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateBillPayment = (
  clinicId: number,
  billId: number,
  appointmentId: number,
  callbackFn?: (data) => void
) => {
  const saleId = useSaleId();
  const invoiceId = useInvoiceId();

  const queryKeys = [
    ['billTransactionsList', clinicId, billId],
    ['billDetails', clinicId, billId],
    ...(saleId ? ['saleDetails', clinicId, saleId] : []),
    ...(invoiceId ? ['invoiceDetails', clinicId, invoiceId] : []),
    ...(appointmentId ? ['appointmentBill', clinicId, appointmentId] : [])
  ];

  const mutationFn = (payload: IBillPaymentPayload) =>
    axiosClient.post(`${API_ENDPOINTS.BILLS(clinicId)}/${billId}/payments`, payload);

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
