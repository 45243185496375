import { Pagination, Row, Table, TableColumnProps, Tag } from 'antd';
import { useInvitationsList } from 'api/hooks/invintations/useInvitationsList';
import { IInvitation } from 'api/interfaces/Invitation';
import { MODAL, useModal } from 'api/store/modalStore';
import StickyButton from 'components/StickyButton';
import { statusColor } from 'constants/invitationStatuses';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { AddInvitationModal } from 'layout/modals/invitation/AddInvitationModal';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const InvitationsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const { open, isOpened } = useModal();
  const { invitations, isLoading, data, params, updateParams } = useInvitationsList(clinicId);

  useEffect(() => {
    updateParams({});
  }, []);

  const columns = useMemo(
    (): TableColumnProps<IInvitation>[] => [
      {
        key: 'phone_number',
        dataIndex: 'phone_number',
        title: 'Phone number',
        sorter: (a, b) =>
          `${a.country_code}${a.phone_number}` > `${a.country_code}${b.phone_number}` ? 1 : -1,
        render: (e, record) => (
          <Link
            to={`tel:${record.country_code ?? ''}${record.phone_number}`}
            className='custom-links d-flex align-baseline nowrap'
          >
            <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />+
            {record.country_code}
            {record.phone_number}
          </Link>
        )
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'E-mail',
        sorter: (a, b) => (a.email > b.email ? 1 : -1),
        render: (email) => (
          <Link to={`mailto:${email}`} className='custom-links d-flex align-baseline nowrap'>
            <span className='icofont icofont-email mr-1' style={{ fontSize: 16 }} />
            {email}
          </Link>
        )
      },
      {
        key: 'role',
        dataIndex: 'role',
        title: 'Role',
        sorter: (a, b) => (a.role > b.role ? 1 : -1),
        render: (role) => (
          <span className='nowrap' style={{ color: '#a5a5a5' }}>
            {role}
          </span>
        )
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status) => (
          <Tag style={{ borderRadius: 20 }} color={statusColor[status]}>
            {status}
          </Tag>
        )
      }
    ],
    [invitations]
  );

  return (
    <>
      <Table
        pagination={false}
        className='accent-header'
        rowKey='id'
        dataSource={invitations}
        loading={isLoading}
        columns={columns}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <Pagination
          current={+params.page}
          total={data?.metadata.total || 0}
          pageSize={20}
          onChange={(page) => {
            updateParams({ page });
            scrollToTop();
          }}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addInvitationModal)}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={'Invite'}
        />
        {isOpened(MODAL.addInvitationModal) && <AddInvitationModal />}
      </Row>
    </>
  );
};

export default InvitationsList;
