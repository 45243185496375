import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAccountPayload } from 'api/interfaces/Finances';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateFinancialAccount = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IAccountPayload) => axiosClient.post(`${API_ENDPOINTS.ACCOUNTS(clinicId)}`, payload),
    ['bankAccountsList', clinicId],
    callbackFn
  );
};
