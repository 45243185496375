import '../assets/scss/select.scss';

import { Select } from 'antd';
import { useAddSkill } from 'api/hooks/skills/useAddSkill';
import { useSkills } from 'api/hooks/skills/useSkills';
import { IEmployeeDetails } from 'api/interfaces/Employee';
import { IUserProfile } from 'api/interfaces/User';
import { useLocale } from 'hooks/useLocale';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';

interface Props {
  user: IUserProfile | IEmployeeDetails;
  editMode: boolean;
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  onChangeFieldValue?: () => void;
  setInitialSelectedItemsData?: (items: string[]) => void;
}

const MultiSkillsSelect = ({
  user,
  editMode,
  selectedItems,
  setSelectedItems,
  onChangeFieldValue,
  setInitialSelectedItemsData
}: Props): JSX.Element => {
  const locale = useLocale('private.user.user-profile');

  const [searchTerm, setSearchTerm] = React.useState('');
  const { data: skills = [], refetch: refetchSkills } = useSkills({ name: searchTerm });
  const { mutate: addSkill } = useAddSkill(() => {
    refetchSkills();
  });

  useEffect(() => {
    const initialSkills = user.skills.map((skill) => skill.name);
    setSelectedItems(initialSkills);
    setInitialSelectedItemsData?.(initialSkills);
  }, [user, setSelectedItems, setInitialSelectedItemsData]);

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
    if (value.length > 0) refetchSkills();
  }, 500);

  const handleSelect = (value: string) => {
    const exists = skills.some((skill) => skill.name === value);
    if (!exists) {
      addSkill({ name: value });
    }
  };

  const handleChange = (items: string[]) => {
    setSelectedItems(items);
    onChangeFieldValue?.();
  };

  return (
    <Select
      className='skills-tags-select'
      mode='tags'
      placeholder={locale.labels.skills}
      value={selectedItems}
      onSelect={handleSelect}
      disabled={!editMode}
      onSearch={handleSearch}
      onChange={handleChange}
      tokenSeparators={[',']}
      options={skills
        .filter((skill) => !selectedItems.includes(skill.name))
        .map((skill) => ({
          value: skill.name,
          label: skill.name
        }))}
    />
  );
};

export default MultiSkillsSelect;
