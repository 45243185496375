import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvoice, IInvoicePurchaseItem } from 'api/interfaces/Invoice';

import { useCustomMutation } from '../useCustomMutation';

export const useDeleteInvoicePurchase = (
  clinicId: number,
  invoice: IInvoice,
  purchasedItem: IInvoicePurchaseItem,
  callbackFn?: () => void
) => {
  const mutationFn = () =>
    axiosClient.delete(
      `${API_ENDPOINTS.STOCK(clinicId)}/${purchasedItem?.stock.id}/items/${purchasedItem?.id}`
    );

  return useCustomMutation(
    mutationFn,
    [
      ['billDetails', clinicId, invoice?.bill_id],
      ['invoiceDetails', clinicId, invoice?.id],
      ['invoiceStockPurchases', clinicId, invoice?.id]
    ],
    callbackFn
  );
};
