import { notification } from 'antd';

// import { Base64 } from 'js-base64';
// import { disconnectShiftsChannel } from 'lib/ShiftsConsumer';
// import { disconnectSocketConnection } from 'lib/SocketConnection';
// import * as localStorage from 'local-storage';
import baseClient from './baseClient';

export const authUrl: string = '/v1/auth';

// export const registration = async (data: any) =>
//   baseClient.post(`${authUrl}/register`, data).then((response: any) => {
//     return response.data;
//   });

export const confirmEmail = async (data: any, resend = false, locale = null) =>
  baseClient
    .post(`${authUrl}${resend ? '/confirmation/resend' : '/confirmation'}`, { token: data })
    .then((response: any) => {
      if (resend) {
        notification.success({
          message: locale.messages.hasBeenResent,
          description: locale.messages.pleaseRecheck
        });
      }
      return response;
    })
    .catch((error: any) => {
      return error?.response;
    });

// export const login = async (data: any) => {
//   const token = `Basic ${Base64.encode(`${data.email}:${data.password}`)}`;
//   return baseClient
//     .post(`${authUrl}/login`, null, {
//       headers: authHeader(token)
//     })
//     .then((response: any) => {
//       if (response.data?.email_confirmed === false) {
//         return response.data;
//       } else {
//         localStorage.set('auth_token', `${response.data.auth_token}`);
//         if (data.remember) {
//           localStorage.set('token', token);
//         }
//         return response.data;
//       }
//     })
//     .catch(async (error: any) => {
//       notification.error({
//         message: error.response.statusText,
//         description: error.response.data?.message
//       });
//       return Promise.reject(error);
//     });
// };

// export const logout = async () =>
//   baseClient.get(`${authUrl}/logout`).then(() => {
//     // Drop websocket connection
//     disconnectShiftsChannel();
//     disconnectSocketConnection();

//     // Clear local storage
//     localStorage.remove('auth_token');
//     localStorage.remove('token');
//     localStorage.remove('eventCalendarFilters');
//     localStorage.remove('appointmentListFilters');
//   });

export const resetPassword = async (data: any) =>
  baseClient.post(`${authUrl}/password/reset`, data).then((response: any) => {
    return response.data;
  });

// export const forgotPassword = async (data: any) =>
//   baseClient.post(`${authUrl}/password/forgot`, data).then((response: any) => {
//     return response.data;
//   });
