import 'assets/sass/components/_table.scss';

import { Col, Row } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

import Dictionary from './components/Dictionary';

const colStyles = {
  minWidth: '350px',
  maxWidth: '400px',
  marginBottom: '10px',
  marginLeft: '10px'
};

const ClinicDictionaries = (): JSX.Element => {
  const locale = useLocale('private.administration.dictionaries');

  return (
    <>
      <Row style={{ marginTop: '20px' }}>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='diagnosis'
            dictionaryName={locale.labels.diagnoses}
            dictionaryKey={DICTIONARY.diagnoses}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='bill-payment-types'
            dictionaryName={locale.labels.billPaymentTypes}
            dictionaryKey={DICTIONARY.billCategory}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='service-groups'
            dictionaryName={locale.labels.serviceGroups}
            dictionaryKey={DICTIONARY.serviceGroups}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-types'
            dictionaryName={locale.labels.stockTypes}
            dictionaryKey={DICTIONARY.stockTypeId}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-categories'
            dictionaryName={locale.labels.stockCategories}
            dictionaryKey={DICTIONARY.stockCategoryId}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-sub-categories'
            dictionaryName={locale.labels.stockSubCategories}
            dictionaryKey={DICTIONARY.stockSubCategoryId}
          />
        </Col>
      </Row>
    </>
  );
};

export default ClinicDictionaries;
