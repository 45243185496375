import './ClientDetails.scss';

import { Button, Card, Col, Collapse, Row, Tag, Tooltip, Typography } from 'antd';
import { useUploadClientAvatar } from 'api/hooks/avatar.ts/useUploadAvatar';
import { IClientDetails } from 'api/interfaces/Client';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import userAvatar from 'assets/img/user-avatar.svg';
import { AvatarComponent } from 'components/Avatar';
import { DebtStatus } from 'components/DebtStatus';
import { TextEditorView } from 'components/TextEditorView';
import { getSocialMediaData } from 'constants/dictionary/default/socialMediaOptions';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { ClientItemModal } from 'layout/modals/addClient/ClientItemModal';
import { ReplenishmentPopup } from 'layout/modals/finances/replenishmentPopup/ReplenishmentPopup';
import React, { useState } from 'react';

const EMPTY_CONTENT = '<p><br></p>';

type Props = {
  clinicId: number;
  clientId: number;
  data: IClientDetails;
  locale: ILocale;
};

export const ClientDetailsOverview = ({ clinicId, clientId, data, locale }: Props): JSX.Element => {
  const { isOpened, open } = useModal();
  const currencies = useLocale('private.currencies').labels;
  const { availableModules, accesses } = useAccess();
  const shiftOpened = useShiftStore(({ shift }) => shift?.open);

  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);

  const uploadAvatar = useUploadClientAvatar(clinicId, clientId);

  const handleSetAvatar = (data: Blob, callbackFn): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    uploadAvatar.mutate(avatarData);
    setChangeAvatarModalVisible(false);
    callbackFn();
  };

  const getClientName = (): string => {
    const { first_name, middle_name, last_name } = data || {};
    return [first_name, middle_name, last_name].filter((str) => str).join(' ');
  };

  return (
    <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container'>
      <Row justify='center' style={{ marginTop: 10 }}>
        <AvatarComponent
          loading={uploadAvatar.isPending}
          editAccess={accesses.clinics.edit}
          src={data?.avatar ? data?.avatar : userAvatar}
          onImageChange={handleSetAvatar}
          setChangeAvatarModalVisible={setChangeAvatarModalVisible}
          changeAvatarModalVisible={changeAvatarModalVisible}
        />
      </Row>
      <Col>
        <Row justify='center' style={{ alignItems: 'center', marginBottom: '10px' }}>
          <Typography.Title
            level={4}
            id='client-name'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {getClientName()}
            {blacklistedStatus(data, 20)}
          </Typography.Title>
        </Row>
        {availableModules.finances && (
          <>
            <Row justify='center' style={{ marginBottom: '10px' }}>
              <DebtStatus debt={data?.billed_payed_amounts?.diff} />
            </Row>
            <Row justify='center' style={{ marginBottom: '10px', gap: 10 }}>
              <Tag color={'green'}>
                {locale.labels.balance}: {money(data?.balance)} {currencies.uah}
              </Tag>
              <Tooltip
                trigger={'hover'}
                placement='topLeft'
                title={!shiftOpened ? locale.labels.paymentBlockedClosedShift : null}
              >
                <Row>
                  <Button
                    type='primary'
                    disabled={!shiftOpened}
                    onClick={() => open(MODAL.replenishmentPopup)}
                    className='icofont icofont-plus'
                    style={{ borderRadius: 18, width: '35px', height: '35px' }}
                  ></Button>
                </Row>
              </Tooltip>
            </Row>
          </>
        )}
        <Row
          justify='center'
          style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column' }}
        >
          {data?.private_notes && data?.private_notes !== EMPTY_CONTENT && (
            <Collapse
              size={'small'}
              style={{ backgroundColor: 'white' }}
              items={[
                {
                  key: 1,
                  label: (
                    <>
                      <span style={{ marginRight: '5px' }}>{locale.labels.specialNotes}</span>
                      <span
                        className='icofont-warning'
                        style={{ color: 'orange', fontSize: '16px' }}
                      ></span>
                    </>
                  ),
                  children: <TextEditorView value={data?.private_notes} id='notes' borderless />
                }
              ]}
            ></Collapse>
          )}
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-birthday-cake' />
          </Col>
          <Col id='client-birthdate' style={{ fontSize: 16 }}>
            {data?.date_of_birth ? dayjs(data?.date_of_birth).format('DD.MM.YYYY') : '-'}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-ui-touch-phone info-icon' />
          </Col>
          <Col id='client-phone'>
            {data?.phone_number ? (
              <a
                href={`tel:${data?.country_code}${data?.phone_number}`}
                className='custom-links d-flex align-baseline nowrap'
                style={{ fontSize: 16 }}
              >
                {data?.phone_number ? `+${data?.country_code}${data?.phone_number}` : '-'}
              </a>
            ) : (
              '-'
            )}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10 client-email-row'>
          <Col className='icon-style'>
            <span className='icofont-ui-message info-icon' />
          </Col>
          <Col id='client-email'>
            {data?.email ? (
              <a
                href={`mailto:${data?.email}`}
                className='custom-links d-flex align-baseline nowrap'
                style={{ fontSize: 16 }}
              >
                {data?.email}
              </a>
            ) : (
              '-'
            )}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-location-pin info-icon' />
          </Col>
          {data && (
            <Col id='client-address'>
              {['country', 'region', 'city', 'address']
                .map((k) => data[k])
                .filter((value) => value !== null && value !== '')
                .join(', ') || '-'}
            </Col>
          )}
        </Row>

        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-ui-calendar' />
          </Col>
          <Col id='client-last_visit_date' style={{ fontSize: 16 }}>
            {data?.last_visit_date ? dayjs(data.last_visit_date).format('DD.MM.YYYY HH:mm') : '-'}
          </Col>
        </Row>

        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-sale-discount' />
          </Col>
          <Col style={{ fontSize: 16 }}>
            <div>
              <span id='discount-value-stock'>{locale.labels.stock}:</span>{' '}
              <span id='discount-value-stock-amount'>{data?.discount_stock || 0}</span>%
            </div>
            <div style={{ marginTop: 3 }} id='discount-value-services'>
              <span>{locale.labels.services}:</span>{' '}
              <span id='discount-value-services-amount'>{data?.discount_services || 0}</span>%
            </div>
          </Col>
        </Row>

        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-pay' />
          </Col>
          <Col id='client-total-paid' style={{ fontSize: 16 }}>
            {data?.billed_payed_amounts?.total_payed ? (
              <>
                <span id='client-total-paid-amount'>
                  {money(data.billed_payed_amounts.total_payed)}
                </span>{' '}
                {currencies.uah}
              </>
            ) : (
              '-'
            )}
          </Col>
        </Row>

        {data?.social_media_links &&
          JSON.parse(data?.social_media_links)?.map((link, index) => {
            const media = getSocialMediaData(link.type);
            return (
              <Row key={index} align='middle'>
                <Col className='icon-style'>{media?.icon}</Col>
                <Col id='client-email'>
                  <a href={`${media?.baseUrl}${link?.description}`} style={{ fontSize: 15 }}>
                    {link?.description}
                  </a>
                </Col>
              </Row>
            );
          })}
        <Row style={{ justifyContent: 'center' }}>
          <Button
            id='edit-client'
            className='icofont icofont-pencil-alt-2'
            style={{
              borderRadius: 30,
              marginTop: 20,
              fontWeight: 'bold'
            }}
            onClick={() => open(MODAL.clientItemModal)}
            type='primary'
          >
            {locale.buttons.editClient}
          </Button>
        </Row>
      </Col>
      {isOpened(MODAL.clientItemModal) && <ClientItemModal initialValues={data} />}
      {isOpened(MODAL.replenishmentPopup) && <ReplenishmentPopup />}
    </Card>
  );
};
