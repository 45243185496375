import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import useShiftStore from 'api/store/shiftStore';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCloseShift = (clinicId: number) => {
  const { setOpenedShift } = useShiftStore();
  const mutationFn = (shiftId: number) =>
    axiosClient.put(`${API_ENDPOINTS.SHIFTS(clinicId)}/${shiftId}`);

  return useCustomMutation(mutationFn, ['shiftsList', clinicId], ({ data }) =>
    setOpenedShift(data)
  );
};
