import { Form, Spin } from 'antd';
import { useDeletePatient } from 'api/hooks/patients/useDeletePatient';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientForm } from './PatientForm';

type Props = {
  title: string;
  initialValues: any;
};

export const EditPatientModal = ({ initialValues, title }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const clinicId = useClinicId();
  const userAccess = useAccess();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSuccess = (): void => {
    navigate(`/clinic/${clinicId}/patients`);
    closeModal();
  };
  const deletePatient = useDeletePatient(clinicId, onSuccess);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setShowCancelModal(false);
    closeModal();
  };

  return (
    <>
      <Spin spinning={deletePatient.isPending}>
        <VLXModal title={title} centered form={form} open onModalClose={closeModal} width={800}>
          <PatientForm form={form} initialValues={initialValues} editMode closeModal={closeModal}>
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.patients}
              editMode
              fixed
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowDeleteModal(false);
                  setShowCancelModal(true);
                } else {
                  closeModal();
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </PatientForm>
        </VLXModal>
      </Spin>
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={() => deletePatient.mutate(initialValues.id)}
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
