import { Pagination, Row, Table, TableColumnProps, Tag } from 'antd';
import { useEditRoom } from 'api/hooks/rooms/useEditRoom';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { IRoom, ROOM_STATUSES } from 'api/interfaces/Room';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { roomStatuses } from 'constants/dictionary/default/selectOptions';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { RoomStatusSelect } from 'layout/components/statusSelect/StatusSelect';
import { AddRoom } from 'layout/modals/addRoom/AddRoom';
import React, { useState } from 'react';

const defaultParams = {
  page: 1,
  sort_key: 'name',
  sort_order: 'asc',
  'q[deleted]': 'false'
};

const RoomsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.rooms');

  const { rooms, data, isLoading, params, updateParams, updateSorting } = useRoomsList(
    clinicId,
    defaultParams,
    false
  );

  const updateRoomStatus = useEditRoom(clinicId);

  const [showRoomModal, setShowRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<IRoom>();

  const openRoomDetails = (room: IRoom): { onClick: () => void } => ({
    onClick: () => {
      setSelectedRoom(room);
      setShowRoomModal(true);
    }
  });

  const columns: TableColumnProps<IRoom>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: locale.labels.name,
      onCell: openRoomDetails,
      sorter: () => null,
      render: (name) => name
    },
    {
      key: 'status',
      title: locale.labels.status,
      sorter: () => null,
      render: (room: IRoom) => (
        <div style={{ minWidth: '162px' }}>
          <RoomStatusSelect
            status={room.status}
            room={room}
            statusesList={roomStatuses}
            onStatusChange={(newStatus: ROOM_STATUSES) => {
              updateRoomStatus.mutate({ payload: { ...room, status: newStatus }, id: room.id });
            }}
          />
        </div>
      )
    },
    {
      key: 'not_for_appointment',
      title: locale.labels.forAppointments,
      sorter: () => null,
      render: (e) =>
        e.not_for_appointment ? (
          <Tag color='red'>{locale.labels.no}</Tag>
        ) : (
          <Tag color='green'>{locale.labels.yes}</Tag>
        )
    },
    {
      key: 'location',
      title: locale.labels.location,
      sorter: () => null,
      onCell: openRoomDetails,
      render: (e) => (e?.location ? ListFieldWithTooltip(e.location, 25) : '-')
    },
    {
      key: 'equipment',
      title: locale.labels.equipment,
      onCell: openRoomDetails,
      render: (e) => e?.equipment?.join(', ') || '-'
    }
  ];

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Table
        dataSource={rooms}
        className='accent-header rooms-table'
        columns={columns}
        loading={isLoading}
        rowKey='id'
        pagination={false}
        onChange={updateSorting}
      />
      <Row justify='space-between' style={{ marginTop: 10 }}>
        <Pagination
          current={+params.page}
          total={data?.metadata.total || 0}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
      </Row>
      {showRoomModal && (
        <AddRoom
          clinicId={clinicId}
          rooms={data?.data}
          selectedRoom={selectedRoom}
          closeModal={() => {
            setShowRoomModal(false);
            setSelectedRoom(null);
          }}
        />
      )}
      <StickyButton
        offsetBottom={10}
        onClick={() => setShowRoomModal(true)}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addRoom}
      />
    </>
  );
};

export default RoomsList;
