import 'moment/locale/uk';
import '../appointments/AppointmentsList.scss';

import { Button, Pagination, Popover, Row, Table, TableColumnProps, Tooltip } from 'antd';
import { useDashboardAppointmentsList } from 'api/hooks/appointments/useAppointmentsList';
import { useChangeAppointmentStatus } from 'api/hooks/appointments/useChangeAppointmentStatus';
import { IAppointment } from 'api/interfaces/Appointment';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { appointmentStatuses, paymentStatuses } from 'constants/dictionary/default/selectOptions';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getTime } from 'utils/get-time';

import { IncompleteHealthRecordMarker } from '../../components/IncompleteHealthRecordMarker';
import { renderAppointmentStatus, renderPaymentStatus } from '../appointments/AppointmentsBaseHelper';
import { CancellationForm } from '../appointments/components/CancellationForm';
import { getAppointmentTooltip } from '../appointments/utils/get-appointment-tooltip';

type Props = {
  filters: Record<string, string | number>;
};

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

export const DashboardPageAppointmentsList = ({ filters }: Props): JSX.Element => {
  const { isOpened, open } = useModal();
  const navigate = useNavigate();
  const locale = useLocale('private.appointments.event');
  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);

  const { localeCode } = useLocaleCode();

  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const [currentClinicId, setCurrentClinicId] = useState(null);

  const { data, appointments, isLoading, params, updateParams, updateSorting } =
    useDashboardAppointmentsList({
      ...defaultParams,
      ...filters
    });
  const changeAppointmentStatus = useChangeAppointmentStatus();

  useEffect(() => {
    updateParams();
  }, []);

  useEffect((): void => {
    if (localeCode === 'ukUA') {
      moment.locale('uk');
    } else {
      moment.locale('en');
    }
  }, [localeCode]);

  const redirectToAppointment = (record: IAppointment): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${record.clinic_id}/appointments/${record.id}`);
    }
  });

  const onStatusChange = (status: string, appointment: IAppointment): void => {
    const { id, clinic_id: clinicId } = appointment;
    changeAppointmentStatus.mutate({ appointmentId: id, clinicId, status });
  };

  const getPatientView = (e: IAppointment): JSX.Element[] | '-' => {
    if (!e.patients?.length) return '-';

    return e.patients.map((el) => (
      <div key={el.id}>
        <Link className={'custom-links'} to={`/clinic/${e.clinic_id}/patients/${el.id}`}>
          {ListFieldWithTooltip(el.name)}
        </Link>
      </div>
    ));
  };

  const canCancel = (appointment: IAppointment): boolean =>
    appointment.status === 'future' || appointment.status === 'in_progress';

  const Content = (appointment: IAppointment): JSX.Element => (
    <div>
      <p style={{ marginBottom: '2px' }}>
        {locale.labels.cancelledBy}:{' '}
        {`${appointment?.cancellation?.cancelled_by?.name} (${appointment?.cancellation?.cancelled_by?.title})`}
      </p>
      <p style={{ marginBottom: '2px' }}>
        {locale.labels.reason}: {`${appointment?.cancellation?.reason}`}
      </p>
    </div>
  );

  const defineStatus = (appointment: IAppointment): JSX.Element => {
    if (!appointment.status) {
      return null;
    }

    if (appointment.status === 'cancelled') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          {renderAppointmentStatus(appointment.status, localizedStatuses)}
          <Popover
            // onOpenChange={onOpenChange}
            placement='topLeft'
            title={locale.labels.appointmentCancelled}
            content={Content(appointment)}
          >
            <span className='icofont-info-circle' style={{ fontSize: '15px' }} />
          </Popover>
        </div>
      );
    }
    if (appointment.status === 'finished') {
      return renderPaymentStatus(appointment.payment_status, localizedPaymentStatuses);
    } else {
      return renderAppointmentStatus(appointment.status, localizedStatuses);
    }
  };

  const columns = useMemo(
    (): TableColumnProps<IAppointment>[] => [
      {
        key: 'clinic_name',
        dataIndex: 'clinic_name',
        title: locale.labels.clinicName,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (clinic_name) => <>{clinic_name}</>,
        width: '10%'
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: locale.labels.visitReason,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (title) => title,
        width: '15%'
      },
      {
        key: 'even_start_time',
        title: locale.labels.time,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: getTime
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        render: (e) => (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ marginRight: 6 }}>{defineStatus(e)}</span>
            <IncompleteHealthRecordMarker appointment={e} locale={locale} />
          </div>
        )
      },
      {
        key: 'patient',
        title: locale.labels.patient,
        render: (e) => getPatientView(e)
      },
      {
        key: 'client_phone',
        title: locale.labels.client,
        sorter: () => null,
        render: (e: IAppointment) => (
          <>
            {
              <Link
                to={`/clinic/${e?.clinic_id}/clients/${e?.client?.id}`}
                className={'custom-links'}
              >
                {ListFieldWithTooltip(e.client?.name, 15)}{' '}
              </Link>
            }
            {!!e.client?.phone_number && (
              <Link
                to={`tel:${e.client?.country_code}${e.client?.phone_number}`}
                className='d-flex align-baseline nowrap'
              >
                (<span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
                {`+${e.client?.country_code}${e.client?.phone_number}`})
              </Link>
            )}
            {blacklistedStatus(e.client)}
          </>
        )
      },
      {
        key: 'doctor_name',
        dataIndex: 'doctor',
        title: locale.labels.doctor,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (doctor) => ListFieldWithTooltip(doctor?.name)
      },
      {
        key: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: redirectToAppointment,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location?.details) : '-')
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <div className='buttons-list nowrap'>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={getAppointmentTooltip(e, locale)}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!!getAppointmentTooltip(e, locale)}
                onClick={() => onStatusChange('in_progress', e)}
                shape='circle'
                type='primary'
              >
                <span className='icofont-play-alt-2' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!canCancel(e) ? locale.messages.appointmentCanNotBeCancelledList : null}
            >
              <Button
                className='change-appointment-status-button'
                disabled={!canCancel(e)}
                onClick={() => {
                  setCurrentAppointmentId(e.id);
                  setCurrentClinicId(e.clinic_id);
                  open(MODAL.cancellationForm);
                }}
                shape='circle'
                danger
              >
                <span className='icofont-ui-block' style={{ fontSize: '17px' }} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <Table
        pagination={false}
        loading={isLoading}
        onChange={updateSorting}
        className='accent-header appointments-list-table'
        rowKey='id'
        dataSource={appointments}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          showSizeChanger={false}
          current={+params.page}
          showLessItems
          total={data.metadata.total || 0}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
      </Row>
      {isOpened(MODAL.cancellationForm) && (
        <CancellationForm clinicId={currentClinicId} appointmentId={currentAppointmentId} />
      )}
    </>
  );
};
