import './PatientForm.scss';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Typography
} from 'antd';
import { useCreatePatient } from 'api/hooks/patients/useCreatePatient';
import { useEditPatient } from 'api/hooks/patients/useEditPatient';
import { ICreatePatientFormData } from 'api/interfaces/Patient';
import { useLocaleCode } from 'api/store/localeContext';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import animalsList from 'constants/dictionary/animals';
import {
  animalSex,
  patientAggressiveness,
  yesNoUnknownOptions
} from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { textEditor } from 'i18n/textEditor';
import React, { useEffect, useState } from 'react';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { getDate } from 'utils/get-time';
import { localizeList } from 'utils/localized-pet-data';

import { ClientsSelect } from '../../components/clientsSelect/ClientsSelect';

const { TextArea } = Input;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: ICreatePatientFormData;
  editMode?: boolean;
  closeModal: () => void;
}>;

export const PatientForm = ({
  form,
  initialValues,
  editMode,
  closeModal,
  children
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const errorMessages = useI18n<typeof textEditor['ukUA']>(textEditor);
  const locale = useLocale('private.patients.patient-page');
  const { localeCode } = useLocaleCode();

  const createPatient = useCreatePatient(clinicId, closeModal);
  const editPatient = useEditPatient(clinicId, initialValues?.id, closeModal);

  const [ownerExists, setOnwerExists] = useState(true);
  const [showDeceasedFields, setShowDeceasedFields] = useState(false);
  const [selectedPetBreedsList, setSelectedPetBreedsList] = useState([]);
  const [selectedPetColorsList, setSelectedPetColorsList] = useState([]);
  const [selectedPetCoatsList, setSelectedPetCoatsList] = useState([]);
  const [selectedBreedLabel, setSelectedBreedLabel] = useState(locale.labels.breed);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const [shouldResetForm, setShouldResetForm] = useState(true);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (shouldResetForm) {
      if (editMode) {
        form.resetFields();
        setShouldResetForm(false);
      }
    }

    if (initialValues) {
      setSelectedOwnerId(initialValues.primary_contact_id ?? initialValues?.owner);

      if (!!initialValues.species) {
        const breeds = animalsList.find((el) => el.value === initialValues.species).breeds;
        const colors = animalsList.find((el) => el.value === initialValues.species).colors;
        const coats = animalsList.find((el) => el.value === initialValues.species).coats;

        setSelectedPetBreedsList(breeds || []);
        setSelectedPetColorsList(colors || []);
        setSelectedPetCoatsList(coats || []);
      }

      if (showDeceasedFields !== initialValues.deceased) {
        setShowDeceasedFields(initialValues.deceased);
      }
    }
  }, [initialValues]);

  const getWeight = (weight): string =>
    weight ? Number(weight.toString().replace(',', '.')).toFixed(3) : '0';

  const onSubmit = (data: ICreatePatientFormData): void => {
    const payload = {
      ...data,
      vaccination_notification: true,
      appointments_notification: true,
      weight: getWeight(data.weight),
      birth_date: getDate(data.birth_date),
      death_date: getDate(data.death_date),
      additional_contacts:
        data.additional_contacts?.length > 0 ? JSON.stringify(data.additional_contacts) : null
    };

    if (editMode) {
      editPatient.mutate(payload);
    } else {
      createPatient.mutate(payload);
    }
  };

  const remapInitialValues = (): ICreatePatientFormData => {
    if (!initialValues?.additional_contacts) return initialValues;

    return {
      ...initialValues,
      additional_contacts: JSON.parse(initialValues?.additional_contacts as string)
    };
  };

  return (
    <Spin spinning={editPatient.isPending || createPatient.isPending}>
      <Form
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={remapInitialValues()}
      >
        <Form.Item name='clinic_id' initialValue={clinicId} hidden>
          <Input />
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.name}
              name={['name']}
              rules={[
                requiredRule(locale.messages.requiredField),
                patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.species}
              name={['species']}
              className='add-new-patient__owner-select'
              rules={[requiredRule(locale.messages.inputSpecies)]}
            >
              <Select
                filterOption={false}
                onChange={(val) => {
                  const breeds = animalsList.find((el) => el.value === val).breeds;
                  const colors = animalsList.find((el) => el.value === val).colors;
                  const coats = animalsList.find((el) => el.value === val).coats;
                  form.setFieldValue('color', null);
                  form.setFieldValue('breed', null);
                  form.setFieldValue('fur', null);
                  setSelectedBreedLabel(
                    val === 'exotic' || val === 'rodent'
                      ? locale.labels.subtype
                      : locale.labels.breed
                  );
                  setSelectedPetBreedsList(breeds || []);
                  setSelectedPetColorsList(colors || []);
                  setSelectedPetCoatsList(coats || []);
                }}
                options={localizeList(animalsList, localeCode)}
              />
            </Form.Item>
            <Form.Item
              label={selectedBreedLabel}
              name={['breed']}
              className='add-new-patient__owner-select'
            >
              <Select
                disabled={selectedPetBreedsList.length === 0}
                filterOption={(input, option) => {
                  return option.label.toString().toLowerCase().includes(input.toLowerCase());
                }}
                showSearch
                options={localizeList(selectedPetBreedsList, localeCode)}
              />
            </Form.Item>
            <Form.Item label={locale.labels.chip} name={['chip_number']}>
              <Input
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item label={locale.labels.dateOfBirth} name='birth_date'>
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item name='sex' label={locale.labels.sex}>
              <Select filterOption={false} options={localizeList(animalSex, localeCode)} />
            </Form.Item>
            <Form.Item label={locale.labels.weight} name={['weight']}>
              <VLXInputNumber precision={3} />
            </Form.Item>
            <Form.Item label={locale.labels.color} name='color'>
              <Select
                disabled={selectedPetColorsList.length === 0}
                filterOption={(input, option) => {
                  return option.label.toString().toLowerCase().includes(input.toLowerCase());
                }}
                showSearch
                options={localizeList(selectedPetColorsList, localeCode)}
              />
            </Form.Item>
            <Form.Item name='fur' label={locale.labels.coat}>
              <Select
                filterOption={(input, option) => {
                  return option.label.toString().toLowerCase().includes(input.toLowerCase());
                }}
                showSearch
                disabled={selectedPetCoatsList.length === 0}
                options={localizeList(selectedPetCoatsList, localeCode)}
              />
            </Form.Item>
            <Form.Item
              name='blood_type'
              label={locale.labels.bloodType}
              rules={[maxRule(15, locale.messages.max15Chars)]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name='neutering' label={locale.labels.neutering}>
              <Select
                filterOption={false}
                options={localizeList(yesNoUnknownOptions, localeCode)}
              />
            </Form.Item>
            <Form.Item name='aggressiveness' label={locale.labels.aggressiveness}>
              <Select
                filterOption={false}
                options={localizeList(patientAggressiveness, localeCode)}
              />
            </Form.Item>
            <Form.Item name='lives_outdoor' label={locale.labels.livesOutdoor}>
              <Radio.Group>
                <Radio value={true}>{locale.buttons.yes}</Radio>
                <Radio value={false}>{locale.buttons.no}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name='tattoo' label={locale.labels.tattoo}>
              <Input />
            </Form.Item>
            <TextEditor label={locale.labels.warnings} name={['warnings']} rows={3} />
            <TextEditor label={locale.labels.lifeAnamnesis} name={['life_anamnesis']} rows={6} />
            <TextEditor label={locale.labels.notes} name={['notes']} rows={3} />
            <Form.Item
              help={locale.messages.specialNotesVisibility}
              label={locale.labels.specialNotes}
              rules={[maxRule(20000, errorMessages.length_rule_20000)]}
              name={['private_notes']}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name='deceased' label={locale.labels.deceased}>
              <Radio.Group
                onChange={({ target: { value } }: RadioChangeEvent) => {
                  setShowDeceasedFields(value);
                  if (value) {
                    form.setFieldValue('death_date', dayjs(new Date()));
                  }
                }}
              >
                <Radio value={true}>{locale.buttons.yes}</Radio>
                <Radio value={false}>{locale.buttons.no}</Radio>
              </Radio.Group>
            </Form.Item>
            {showDeceasedFields && (
              <>
                <Form.Item
                  label={locale.labels.dateOfDeath}
                  name='death_date'
                  rules={[requiredRule()]}
                >
                  <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
                </Form.Item>
                <Form.Item label={locale.labels.reasonOfDeath} name={['deceaded_reason']}>
                  <TextArea rows={5} />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12} style={{ borderTop: '1px solid grey' }}>
            <Typography.Title level={5}>{locale.labels.owner}</Typography.Title>
            {ownerExists && (
              <Row>
                <Col
                  md={14}
                  style={{
                    marginRight: 10,
                    minWidth: '100%'
                  }}
                >
                  <Form.Item name={'owner'} rules={[requiredRule(locale.messages.inputOwner)]}>
                    <ClientsSelect
                      selectedClientId={selectedOwnerId}
                      disabled={!!initialValues?.owner}
                      onChange={setSelectedOwnerId}
                    />
                  </Form.Item>
                </Col>
                {!initialValues?.owner && (
                  <Button
                    id='add-new-owner'
                    type={'primary'}
                    style={{ borderRadius: 30 }}
                    onClick={() => {
                      setOnwerExists(false);
                      form.setFieldValue('owner', null);
                    }}
                  >
                    {locale.buttons.addNew}
                  </Button>
                )}
              </Row>
            )}
            {!ownerExists && (
              <div style={{ marginRight: 10 }}>
                <Form.Item
                  label={locale.labels.firstName}
                  name={['new_client', 'first_name']}
                  rules={[requiredRule(locale.messages.inputFirstName)]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={locale.labels.lastName} name={['new_client', 'last_name']}>
                  <Input />
                </Form.Item>
                <PhoneFieldFormItem
                  formItemPhonePrefix={['new_client', 'country_code']}
                  formItemPhoneNumber={['new_client', 'phone_number']}
                />
                <Form.Item label={locale.labels.address} style={{ marginBottom: 0 }}>
                  <Form.Item
                    name={['new_client', 'country']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                      marginRight: '16px'
                    }}
                  >
                    <Input placeholder={locale.placeholders.country} />
                  </Form.Item>
                  <Form.Item
                    name={['new_client', 'region']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)'
                    }}
                  >
                    <Input placeholder={locale.placeholders.region} />
                  </Form.Item>
                </Form.Item>
                <Form.Item name={['new_client', 'city']}>
                  <Input placeholder={locale.placeholders.city} />
                </Form.Item>
                <Form.Item name={['new_client', 'address']}>
                  <Input placeholder={locale.placeholders.address} />
                </Form.Item>
                <Form.Item name={['new_client', 'email']} label={locale.labels.email}>
                  <Input />
                </Form.Item>
                <Button
                  type={'primary'}
                  style={{ borderRadius: 30 }}
                  onClick={() => {
                    setOnwerExists(true);
                    form.setFieldValue('owner', null);
                  }}
                >
                  {locale.buttons.chooseExisting}
                </Button>
              </div>
            )}
          </Col>
          <Col span={24} md={12} style={{ borderTop: '1px solid grey' }}></Col>
        </Row>
        <Divider />
        <Form.List name='additional_contacts'>
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={[20, 10]}>
                  <Col span={22} md={11}>
                    <PhoneFieldFormItem
                      formItemPhonePrefix={[name, 'country_code']}
                      formItemPhoneNumber={[name, 'phone_number']}
                      hideLabel
                    />
                  </Col>
                  <Col span={22} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      rules={[requiredRule(locale.messages.specifyDescription)]}
                    >
                      <Input placeholder={locale.placeholders.description} />
                    </Form.Item>
                  </Col>
                  <Button
                    onClick={() => {
                      remove(name);
                    }}
                    danger
                    type='primary'
                    shape={'circle'}
                    className={'inventory-update-buttons'}
                  >
                    <span className='icofont icofont-trash' />
                  </Button>
                </Row>
              ))}
              <Row>
                <Form.Item>
                  <Button
                    className='icofont icofont-plus'
                    type={'primary'}
                    style={{
                      borderRadius: 30,
                      fontWeight: 'bold'
                    }}
                    onClick={() => add()}
                  >
                    {fields.length
                      ? locale.buttons.addAnother
                      : locale.buttons.addAdditionalContacts}
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
        {children}
      </Form>
    </Spin>
  );
};
