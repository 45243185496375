import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ISaleDetails } from 'api/interfaces/Sale';

export const useSaleDetails = (clinicId: number, saleId: number) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(`${API_ENDPOINTS.SALES(clinicId)}/${saleId}`);
    return data;
  };

  return useQuery<ISaleDetails>({
    queryFn,
    queryKey: ['saleDetails', clinicId, saleId],
    enabled: !!saleId
  });
};
