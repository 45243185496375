import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ICancelApointmentPayload } from 'api/interfaces/Appointment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

type MutationProps = {
  appointmentId: number;
  payload: ICancelApointmentPayload;
};

export const useCancelAppointment = (clinicId: number, onSuccess?: () => void) => {
  const mutationFn = ({ appointmentId, payload }: MutationProps): Promise<void> =>
    axiosClient.post(`${API_ENDPOINTS.APPOINTMENTS(clinicId)}/${appointmentId}`, payload);

  return useCustomMutation(mutationFn, ['appointmentsList', ['appointment', clinicId]], onSuccess);
};
