import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { PrintConfigurationForm } from '../../layout/modals/printConfiguration/PrintConfigurationForm';

export const PrintConfiguration = (): JSX.Element => {
  const userAccess = useAccess();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [form] = Form.useForm();
  const locale = useLocale('public.modals.printConfiguration');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
    setEditMode(false);
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && e.length !== 0 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <h3>{locale.labels.printConfiguration}</h3>
      <PrintConfigurationForm form={form} setEditMode={setEditMode} onFieldsChange={onFieldsChange}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.services}
          editMode={editMode}
          onEditClick={() => setEditMode(true)}
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              setEditMode(false);
            }
          }}
        />
      </PrintConfigurationForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />
    </>
  );
};
