import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockPayload } from 'api/interfaces/Stock';

import { useCustomMutation } from '../useCustomMutation';

export const useStockPurchase = (clinicId: number, callbackFn?: (data) => void) => {
  const mutationFn = ({ payload, id }: { payload: IStockPayload; id: number }) =>
    axiosClient.post(`${API_ENDPOINTS.STOCK(clinicId)}/${id}/items`, payload);

  return useCustomMutation(mutationFn, ['stockDetails', clinicId], callbackFn);
};
