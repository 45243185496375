import { Form } from 'antd';
import { IPayment } from 'api/interfaces/Payment';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { AddPaymentForm } from './AddPaymentForm';

type Props = {
  initialValues: IPayment;
  locale: ILocale;
};
export const EditPaymentModal = ({ initialValues, locale }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();

  const userAccess = useAccess();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [formIsTouched, setFormIsTouched] = useState(false);

  return (
    <>
      <VLXModal
        formIsTouched={formIsTouched}
        title={locale.labels.editPayment}
        centered
        form={form}
        open
        onModalClose={closeModal}
        width={800}
      >
        <AddPaymentForm
          locale={locale}
          form={form}
          initialValues={initialValues}
          setFormIsTouched={setFormIsTouched}
        >
          <ViewDetailsButtonsGroup
            fixed
            hideDeleteBtn
            accessGroup={userAccess.accesses.services}
            editMode
            onCancelClick={() => {
              if (form.isFieldsTouched() || formIsTouched) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
          />
        </AddPaymentForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
