import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClientsList } from 'api/interfaces/Client';

import { useTableData } from '../useTableData';

export const useClientsList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.CLIENTS(clinicId);
  const result = useTableData<IClientsList>(endpoint, defaultParams, ['clientsList', clinicId]);

  return {
    ...result,
    clients: result.data.data
  };
};
