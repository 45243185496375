import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEmployee, IEmployeeList } from 'api/interfaces/Employee';
import { getColoredItems } from 'pages/calendar/utils/get-colored-items';

import { useTableData } from '../useTableData';

export const useEmployeesColoredList = (clinicId: number) => {
  const endpoint = API_ENDPOINTS.EMPLOYEES(clinicId);

  const result = useTableData<IEmployeeList>(
    endpoint,
    { page: 0 },
    ['employeesList', 'clinicId'],
    true
  );

  return {
    ...result,
    employees: getColoredItems<IEmployee>(result.data.data || [])
  };
};
