import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { IEmployeeDetails } from '../../interfaces/Employee';

export const useEmployeeDetails = (clinicId: number, employeeId: number) => {
  const endpoint = `${API_ENDPOINTS.EMPLOYEES(clinicId)}/${employeeId}`;

  const fetchEmployeeDetails = async (): Promise<IEmployeeDetails> => {
    const { data } = await axiosClient.get<IEmployeeDetails>(endpoint);
    return data;
  };

  return useQuery<IEmployeeDetails>({
    queryKey: ['employeeDetails', clinicId, employeeId],
    queryFn: fetchEmployeeDetails,
    enabled: !!clinicId && !!employeeId,
    staleTime: 3000
  });
};
