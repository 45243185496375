import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

type MutationProps = {
  appointmentId: number;
  clinicId: number;
  status: string;
};

const statusPayload = (value: string) => ({
  properties: [{ name: 'status', value }]
});

export const useChangeAppointmentStatus = () => {
  const mutationFn = ({ appointmentId, clinicId, status }: MutationProps): Promise<void> =>
    axiosClient.patch(
      `${API_ENDPOINTS.APPOINTMENTS(clinicId)}/${appointmentId}`,
      statusPayload(status)
    );

  return useCustomMutation(mutationFn, ['appointmentsList', 'appointment']);
};
