import { Button, Form, Input, Modal, Spin } from 'antd';
import { useChangePassword } from 'api/hooks/user/useChangePassword';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import MessageModal from '../components/messageModal/MessageModal';

const ChangeUserPassword = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const locale = useLocale('private.user.user-profile');
  const [form] = Form.useForm();

  const onSuccess = (): void => {
    MessageModal('success', locale.messages.passwordChanged);
    setVisible(false);
  };

  const onError = (): void => {
    form.setFields([{ name: 'current_password', errors: [locale.messages.wrongPassword] }]);
  };

  const changePassword = useChangePassword(onSuccess, onError);

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changePassword}
      </Button>
      <Modal
        title={locale.labels.changePasswordTitle}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
      >
        <Spin spinning={changePassword.isPending}>
          <Form form={form} onFinish={changePassword.mutate} autoComplete='off' layout='vertical'>
            <Form.Item
              label={locale.labels.oldPassword}
              name='current_password'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.newPassword}
              name='new_password'
              rules={[
                requiredRule(locale.messages.emptyField),
                patternRule(PATTERNS.PASSWORD, locale.messages.inputPassword)
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.repeatNewPassword}
              name='new_password_confirmation'
              dependencies={['new_password']}
              hasFeedback
              rules={[
                requiredRule(locale.messages.emptyField),
                ({ getFieldValue }) => ({
                  async validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return await Promise.resolve();
                    }
                    return await Promise.reject(new Error(locale.messages.passwordDidNotMatch));
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeUserPassword;
