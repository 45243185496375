import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { DICTIONARY } from 'api/constants/dictionary';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteDictionary = (
  clinicId: number,
  key: DICTIONARY,
  callbackFn?: (id) => void
) => {
  return useCustomMutation(
    (id: number) => axiosClient.delete(`${API_ENDPOINTS.DICTIONARIES(clinicId)}/${key}/${id}`),
    [key, clinicId],
    (data) => callbackFn(data?.data.id)
  );
};
