import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { useLogin } from 'api/hooks/user/useLogin';
import { ILoginPayload, ILoginResponse } from 'api/interfaces/User';
import * as config from 'config/config-manager';
import { useLocale } from 'hooks/useLocale';
import * as ls from 'local-storage';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { requiredRule } from 'utils/form-rules';

import { LinkButton } from '../linkButton/LinkButton';

type Props = {
  onNavigate: (path) => void;
  changeVisible: (value) => void;
};

const Login = ({ onNavigate, changeVisible }: Props): JSX.Element => {
  const navigate = useNavigate();
  const locale = useLocale('public.modals.login');
  const [form] = Form.useForm();

  const onLogin = (data: ILoginResponse): void => {
    if (data.email_confirmed === false) {
      changeVisible(false);
      navigate(`/confirm-email/${data.mail_token}?locale=${ls.get('locale')}`);
    } else {
      navigate('/dashboard');
    }
  };

  const onError = (error): void => {
    form.setFields([{ name: 'password', errors: [error.response.data.message] }]);
  };

  const login = useLogin(onLogin, onError);

  const onSubmit = (payload: ILoginPayload) => {
    login.mutate(payload);
  };

  return (
    <Spin spinning={login.isPending}>
      <Form
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        form={form}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label={locale.labels.emailPhone}
          name='email'
          rules={[requiredRule(locale.messages.loginEmpty)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={locale.labels.password}
          name='password'
          rules={[requiredRule(locale.messages.passwordEmpty)]}
        >
          <Input.Password autoComplete='on' />
        </Form.Item>
        <Form.Item name='remember' valuePropName='checked'>
          <Checkbox>{locale.labels.rememberMe}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {locale.buttons.login}
          </Button>
        </Form.Item>
      </Form>
      <LinkButton onClick={() => onNavigate('forgotPassword')}>
        {locale.links.forgotPassword}
      </LinkButton>
      {!config.disabledForProd && (
        <>
          <br />
          {locale.labels.dontHaveAccount}{' '}
          <LinkButton onClick={() => onNavigate('userRegistration')}>
            {locale.links.signUp}
          </LinkButton>
        </>
      )}
    </Spin>
  );
};

export default Login;
