import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEventPayload } from 'api/interfaces/CalendarEvent';

import { useCustomMutation } from '../useCustomMutation';

export const useCreateEvent = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation<IEventPayload, void>(
    (payload: IEventPayload) =>
      axiosClient.post(`${API_ENDPOINTS.CALENDAR_EVENTS(clinicId)}`, payload),
    ['calendar-events'],
    callbackFn
  );
};
