import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRole } from 'api/interfaces/Role';

export const useRoles = (clinicId: number, rolesAccess: boolean = true) => {
  const fetchRoles = async (): Promise<IRole[]> => {
    const { data } = await axiosClient.get<IRole[]>(API_ENDPOINTS.ROLES(clinicId));
    return data;
  };

  return useQuery<IRole[]>({
    queryKey: ['rolesList', clinicId],
    queryFn: fetchRoles,
    enabled: !!clinicId && rolesAccess,
    staleTime: 3000
  });
};
