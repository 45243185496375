import { IUserAccesses } from 'api/store/userAccesses';
import useUserStore from 'api/store/userStore';
import * as ls from 'local-storage';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Generate dynamic routeMap from matched clinic's accesses
const getRouteMap = ({ accesses, availableModules }: IUserAccesses) => ({
  '/clinic/:id/administration/info': accesses.clinics?.showItem,
  '/clinic/:id/administration/employees': accesses.employees?.showList,
  '/clinic/:id/administration/employees/:employeeId': accesses.employees?.showItem,
  '/clinic/:id/administration/invitations': accesses.invitations?.showList,
  '/clinic/:id/administration/rooms': accesses.rooms?.showList,
  '/clinic/:id/administration/roles': availableModules?.roles && accesses.roles?.showList,
  '/clinic/:id/appointments': accesses.appointments?.showList,
  '/clinic/:id/appointments/:appointmentId': accesses.appointments?.showItem,
  '/clinic/:id/clients': accesses.clients?.showList,
  '/clinic/:id/clients/:clientId': accesses.clients?.showItem,
  '/clinic/:id/events-calendar': accesses.events?.showList,
  '/clinic/:id/finances/info': availableModules?.finances,
  '/clinic/:id/finances/bills': availableModules?.finances,
  '/clinic/:id/finances/shifts': availableModules?.finances,
  '/clinic/:id/finances/payments': availableModules?.finances,
  '/clinic/:id/finances/financial-accounts': availableModules?.finances,
  '/clinic/:id/finances/shifts/:shiftId': availableModules?.finances,
  '/clinic/:id/finances/financial-accounts/:financialAccountId': availableModules?.finances,
  '/clinic/:id/finances/bills/:billId': availableModules?.finances,
  '/clinic/:id/finances/payments/:paymentId': availableModules?.finances,
  '/clinic/:id/inventory/info': availableModules?.inventory && accesses.clinics?.showItem,
  '/clinic/:id/inventory/equipment': availableModules?.inventory && accesses.equipment?.showList,
  '/clinic/:id/inventory/tools': availableModules?.inventory && accesses.tools?.showList,
  '/clinic/:id/inventory/stock': availableModules?.inventory && accesses.stock?.showList,
  '/clinic/:id/inventory/audit': availableModules?.inventory && accesses.audit?.showList,
  '/clinic/:id/inventory/providers': availableModules?.inventory && accesses.audit?.showList,
  '/clinic/:id/inventory/invoices': availableModules?.inventory,
  '/clinic/:id/inventory/generalSettings': availableModules?.inventory,
  '/clinic/:id/inventory/invoices/:invoiceId':
    availableModules?.inventory && accesses.invoices?.showItem,
  '/clinic/:id/inventory/audit/:auditId': availableModules?.inventory && accesses.audit?.showItem,
  '/clinic/:id/inventory/equipment/:equipmentId':
    availableModules?.inventory && accesses.equipment?.showItem,
  '/clinic/:id/inventory/tools/:toolId': availableModules?.inventory && accesses.tools?.showItem,
  '/clinic/:id/inventory/stock/:stockId': availableModules?.inventory && accesses.stock?.showItem,
  '/clinic/:id/inventory/providers/:providerId':
    availableModules?.inventory && accesses.providers?.showItem,
  '/clinic/:id/patients': accesses.patients?.showList,
  '/clinic/:id/patients/:patientId': accesses.patients?.showItem,
  '/clinic/:id/services': accesses.services?.showList,
  '/clinic/:id/services/:serviceId': accesses.services?.showItem,
  '/clinic/:id/shop-farmacy': availableModules?.shopPharmacy && accesses.clients?.showList,
  '/clinic/:id/shop-farmacy/new': availableModules?.shopPharmacy && accesses.clients?.showItem,
  '/clinic/:id/shop-farmacy/:shopFarmacyId':
    availableModules?.shopPharmacy && accesses.clients?.showItem
});

export const useAccess = () => {
  const { userAccesses, id: userId } = useUserStore(({ user }) => user);
  const location = useLocation();
  const routeParts = location.pathname.split('/');
  const routeClinicId = Number(routeParts[2]) || ls.get('selectedClinicId');

  // We define clinic from routeId, if not we take first clinic.
  const clinicAccess = useMemo(() => {
    const access = userAccesses.find((c) => c.clinicId === routeClinicId) || userAccesses[0];

    if (access) {
      ls.set('selectedClinicId', access.clinicId);
      return access;
    }
  }, [userAccesses, routeClinicId]);

  const routeMap = useMemo(() => (clinicAccess ? getRouteMap(clinicAccess) : {}), [clinicAccess]);

  const hasAccess = useMemo(() => {
    const userRoutes = ['/dashboard', '/my-calendar', '/user-profile'];
    // if (!userId && location.pathname !== '/') return false;
    // If route is not clinic route we check if it's either authorized user or public route
    if (routeParts.length < 3 || routeParts[1] !== 'clinic') {
      return userRoutes.includes(location.pathname) ? !!userId : true;
    }

    for (const pattern in routeMap) {
      const patternParts = pattern.split('/');

      if (routeParts.length !== patternParts.length) continue;

      const isMatch = patternParts.every(
        (part, index) =>
          (part === ':id' && !isNaN(Number(routeParts[index]))) || part === routeParts[index]
      );

      if (isMatch) return routeMap[pattern];
    }

    return true;
  }, [location.pathname, userAccesses, userId]);

  return {
    hasAccess,
    selectedClinicId: clinicAccess?.clinicId,
    selectedClinicName: clinicAccess?.clinicName,
    accesses: clinicAccess?.accesses,
    availableModules: clinicAccess?.availableModules
  };
};
