import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClient, IClientPayload } from 'api/interfaces/Client';
import { AxiosResponse } from 'axios';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateClient = (
  clinicId: number,
  callbackFn?: (data: AxiosResponse<IClient>) => void
) => {
  return useCustomMutation(
    (payload: IClientPayload) => axiosClient.post(`${API_ENDPOINTS.CLIENTS(clinicId)}`, payload),
    ['clientsList'],
    callbackFn
  );
};
