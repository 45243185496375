import { Checkbox, Col, Row } from 'antd';
import { IPrintConfigurationParams } from 'api/interfaces/Print';
import { useModal } from 'api/store/modalStore';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React from 'react';

type Props = React.PropsWithChildren<{
  onPrintClick: () => void;
  isSaleBill: boolean;
  localPrintConfig: IPrintConfigurationParams;
  setLocalPrintConfig: (data: IPrintConfigurationParams) => void;
  setPrintingStarted: (value: boolean) => void;
}>;

export const BeforePrintConfigurationModal = ({
  onPrintClick,
  isSaleBill,
  localPrintConfig,
  setLocalPrintConfig,
  children,
  setPrintingStarted
}: Props): JSX.Element => {
  const userAccess = useAccess();
  const locale = useLocale('public.modals.printConfiguration');
  const { closeModal } = useModal();

  return (
    <>
      <VLXModal
        centered
        open
        onModalClose={() => {
          setPrintingStarted(false);
          closeModal();
        }}
        width={'80%'}
      >
        <div>
          <label style={{ fontSize: 18, fontWeight: 800, marginBottom: 20 }}>
            {isSaleBill
              ? locale.labels.printSaleBillDetails
              : locale.labels.printAppointmentBillDetails}
          </label>
          <Row>
            <Col span={24} md={12} lg={9} xl={7}>
              <div style={{ marginTop: '40px' }}>
                <label style={{ paddingRight: 14, fontSize: 16 }}>
                  {locale.labels.printAnamnesis}
                </label>
                <Checkbox
                  checked={localPrintConfig?.print_anamnesis}
                  onChange={(e) =>
                    setLocalPrintConfig({ ...localPrintConfig, print_anamnesis: e.target.checked })
                  }
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <label style={{ paddingRight: 14, fontSize: 16 }}>
                  {locale.labels.printItemsWith0Price}
                </label>
                <Checkbox
                  checked={localPrintConfig?.print_free_items}
                  onChange={(e) =>
                    setLocalPrintConfig({ ...localPrintConfig, print_free_items: e.target.checked })
                  }
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <label style={{ paddingRight: 14, fontSize: 16 }}>
                  {locale.labels.doNotItemizeMedicines}
                </label>
                <Checkbox
                  checked={localPrintConfig?.not_print_stock_details}
                  onChange={(e) =>
                    setLocalPrintConfig({ ...localPrintConfig, not_print_stock_details: e.target.checked })
                  }
                />
              </div>
            </Col>
            <Col span={24} md={12} lg={15} xl={17}>
              <label style={{ fontWeight: 800, fontSize: 16 }}>
                {locale.labels.documentPreview}:
              </label>
              {children}
            </Col>
          </Row>
          <ViewDetailsButtonsGroup
            fixed
            submitButtonIcon={'icofont-print'}
            accessGroup={userAccess.accesses.invitations}
            onSaveModalClick={onPrintClick}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              setPrintingStarted(false);
              closeModal();
            }}
          />
        </div>
      </VLXModal>
    </>
  );
};
