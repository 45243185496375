import '../FinancesInfo.scss';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Divider, Popover, Row, Statistic, Tag } from 'antd';
import { IShiftDetails } from 'api/interfaces/Shift';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

type Props = {
  shift: IShiftDetails;
  showInfo?: boolean;
};

const ShiftStatusCard = ({ shift, showInfo = true }: Props): JSX.Element => {
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  const [currentShiftDuration, setCurrentShiftDuration] = useState(null);

  useEffect(() => {
    setCurrentShiftDuration(getShiftDuration());
  }, []);

  function currentEarnings() {
    const totalDiff = shift?.total_diff;
    if (totalDiff > 0) {
      return currentEarningsStats('#3f8600', <ArrowUpOutlined />);
    } else if (totalDiff < 0) {
      return currentEarningsStats('#cf1322', <ArrowDownOutlined />);
    } else {
      return currentEarningsStats('#595959', '');
    }
  }

  function currentEarningsStats(color, prefix) {
    if (!shift) return null;

    return (
      <Statistic
        title={locale.labels.currentIncome}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        value={shift?.total_diff}
        precision={2}
        valueStyle={{ color, fontSize: '20px' }}
        prefix={prefix}
        suffix={currencies.uah}
      />
    );
  }

  function toDate(date) {
    return dayjs(date).local().format('DD.MM.YYYY HH:mm');
  }

  function getShiftDuration() {
    const endTime = shift?.closed_at ? dayjs(shift?.closed_at).local() : dayjs().local();
    const seconds = endTime.diff(dayjs(shift?.opened_at).local(), 'second');
    const totalHours = Math.floor(seconds / (60 * 60)); // How many hours?
    const totalSeconds = seconds - totalHours * 60 * 60; // Pull those hours out of totalSeconds
    const totalMinutes = Math.floor(totalSeconds / 60);
    return `${totalHours}${locale.labels.hours} ${totalMinutes}${locale.labels.minutes}`;
  }

  const text = <span>{locale.labels.shift}</span>;

  const content = (
    <div>
      <p style={{ marginBottom: '2px' }}>{`${locale.labels.opened}: ${toDate(
        shift?.opened_at
      )}`}</p>
      <p>
        {`${locale.labels.openedBy}: ${shift?.opened_by?.name}`}
        {shift?.opened_by?.title ? ` (${shift?.opened_by?.title})` : ''}
      </p>
      {!shift?.open ? (
        <>
          <p style={{ marginBottom: '2px' }}>{`${locale.labels.closed}: ${toDate(
            shift?.closed_at
          )}`}</p>
          <p>
            {`${locale.labels.closedBy}: ${shift?.closed_by?.name}`}
            {shift?.closed_by?.title ? ` (${shift?.closed_by?.title})` : ''}
          </p>
        </>
      ) : null}
      <p>{`${locale.labels.duration}: ${currentShiftDuration}`}</p>
    </div>
  );

  const onOpenChange = (visible): void => {
    if (visible) setCurrentShiftDuration(getShiftDuration());
  };

  return (
    <>
      <Row justify={'start'} id='shift-status'>
        {shift?.open ? (
          <Tag color='green' id='shift-opened' style={{ fontWeight: 600 }}>
            {locale.labels.shiftOpened}
          </Tag>
        ) : (
          <Tag color='red' id='shift-closed' style={{ fontWeight: 600 }}>
            {locale.labels.shiftClosed}
          </Tag>
        )}
        {showInfo && (
          <Popover onOpenChange={onOpenChange} placement='topLeft' title={text} content={content}>
            <span className='icofont-info-circle' style={{ fontSize: '15px' }} />
          </Popover>
        )}
      </Row>
      <Row justify={'space-evenly'}>
        <Col
          span={8}
          xs={24}
          md={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <span id='current-earnings'>{currentEarnings()}</span>
        </Col>
        <Col span={16}>
          <Row justify={'space-evenly'}>
            <div id='total-income'>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                title={locale.labels.totalIncome}
                valueStyle={{ color: '#3f8600', fontSize: '20px' }}
                value={shift?.total_income_amount}
                precision={2}
                suffix={currencies.uah}
              />
            </div>
            <div id='total-outcome'>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                title={locale.labels.totalOutcome}
                valueStyle={{ color: '#cf1322', fontSize: '20px' }}
                value={shift?.total_outcome_amount}
                precision={2}
                suffix={currencies.uah}
              />
            </div>
          </Row>
          <Divider />
          <Row justify={'space-evenly'}>
            <div id='total-income-operations'>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                title={locale.labels.incomeOperations}
                valueStyle={{ color: '#3f8600', fontSize: '20px' }}
                value={shift?.total_income_operations}
                precision={0}
              />
            </div>
            <div id='total-outcome-operations'>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                title={locale.labels.outcomeOperations}
                valueStyle={{ color: '#cf1322', fontSize: '20px' }}
                value={shift?.total_outcome_operations}
                precision={0}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ShiftStatusCard;
