import './AppointmentDetails.scss';

import { Col, Form, FormInstance, Row, Select } from 'antd';
import { useAddAppointmentHealthRecord } from 'api/hooks/appointments/useAddAppointmentHealthRecord';
import { useUpdateAppointmentHealthRecord } from 'api/hooks/appointments/useUpdateAppointmentHealthRecord';
import { useServicesList } from 'api/hooks/services/useServicesList';
import { IAppointmentDetails } from 'api/interfaces/Appointment';
import { IHealthRecord } from 'api/interfaces/Patient';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useMemo } from 'react';
import { requiredRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';
import { getDate } from 'utils/get-time';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IHealthRecord;
  locale: ILocale;
  patientId: number;
  onFieldsChange: () => void;
  appointment: IAppointmentDetails;
  disabled: boolean;
  setEditMode: (value: boolean) => void;
}>;

export const AppointmentDetailsPatientTabServicesForm = ({
  form,
  initialValues,
  children,
  locale,
  patientId,
  onFieldsChange,
  appointment,
  disabled,
  setEditMode
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { services } = useServicesList(clinicId);

  const addHealthRecord = useAddAppointmentHealthRecord(clinicId, appointment.id, patientId, () =>
    setEditMode(false)
  );
  const updateHealthRecord = useUpdateAppointmentHealthRecord(
    clinicId,
    patientId,
    initialValues?.id,
    () => setEditMode(false)
  );

  useEffect(() => {
    form.resetFields();
    setEditMode(!initialValues?.services?.length);
  }, [initialValues, patientId]);

  const onSubmit = (data: IHealthRecord): void => {
    const payload: IHealthRecord = {
      ...data,
      anamnesis: initialValues?.anamnesis,
      diagnosis: initialValues?.diagnosis,
      complaints: initialValues?.complaints,
      provisional_diagnosis: initialValues?.provisional_diagnosis,
      no_diagnosis_required: initialValues ? initialValues.no_diagnosis_required : true,
      patient_state: initialValues?.patient_state,
      recommendations: initialValues?.recommendations,
      notes: initialValues?.notes,
      date_of_next_visit: getDate(initialValues?.date_of_next_visit),
      clinic_id: clinicId
    };

    if (initialValues?.id) {
      updateHealthRecord.mutate(payload);
    } else {
      addHealthRecord.mutate(payload);
    }
  };

  const servicesList = useMemo(
    () =>
      getOptions(
        services.filter((s) => !s.deleted || initialValues?.services.some((ser) => ser.id === s.id))
      ),
    [services]
  );

  const mapFormValues = (): number[] => {
    if (!initialValues?.services?.length && appointment?.services) {
      return appointment?.services.map(({ id }) => id);
    } else if (initialValues?.services?.length) {
      return initialValues.services.map(({ id }) => id);
    }
  };

  return (
    <Form
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={{ services_ids: mapFormValues() || [] }}
      disabled={disabled}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            className='add-appointment-form__multi-select-field'
            label={locale.labels.servicesProvided}
            rules={[requiredRule()]}
            name='services_ids'
            extra={!initialValues?.services?.length ? locale.labels.servicesProvidedHint : null}
          >
            <Select
              mode='multiple'
              filterOption={(input, option) =>
                option?.title.toString().toLowerCase().includes(input?.toLowerCase())
              }
              showSearch
            >
              {servicesList}
            </Select>
          </Form.Item>
        </Col>
        <Col className={'appointment-services-buttons'} span={4} md={4}>
          {children}
        </Col>
      </Row>
    </Form>
  );
};
