import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAppointmentList } from 'api/interfaces/Appointment';

import { useTableData } from '../useTableData';

export const useAppointmentsList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.APPOINTMENTS(clinicId);
  const result = useTableData<IAppointmentList>(endpoint, defaultParams, [
    'appointmentsList',
    clinicId
  ]);

  return {
    ...result,
    appointments: result.data.data
  };
};

export const useDashboardAppointmentsList = (defaultParams: Record<string, string | number>) => {
  const endpoint = API_ENDPOINTS.DASHBOARD_APPOINTMENTS;
  const result = useTableData<IAppointmentList>(endpoint, defaultParams, ['appointmentsList']);

  return {
    ...result,
    appointments: result.data.data
  };
};
