import { notification } from 'antd';
import userStore from 'api/store/userStore';
import axios, { AxiosResponse } from 'axios';
import * as ls from 'local-storage';

import * as config from '../config/config-manager';

// import { clearUser, getUser } from '../redux/user/actions';

const authUrl: string = '/v1/auth';
const baseClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    APIKEY: config.apiKey
  }
});

export const interceptor = (store) => {
  async function buildErrorObject(error) {
    if (!error?.response?.data?.errors) return;
    const errorArray = [];
    const errors = error.response.data.errors;
    const errorKeys = Object.keys(errors);
    errorKeys.forEach((key) => {
      const errorKey = `[${key}: ${errors[key]}]`;
      errorArray.push(errorKey);
    });
    return errorArray;
  }

  async function showError(error) {
    const errorArray = await buildErrorObject(error);
    const description = errorArray || error.response.data?.message;
    notification.error({
      message: error.response.statusText,
      description
    });
  }

  function reauthenticate(basicToken) {
    baseClient
      .post(`${authUrl}/login`, null, {
        headers: authHeader(basicToken)
      })
      .then((response: AxiosResponse<any>) => {
        localStorage.set('auth_token', `${response.data.auth_token}`);
      })
      .catch(async (error) => {
        if (error.response?.status === 401) {
          userStore.getState().clearUser();
          localStorage.clear();
        }
      });
  }

  baseClient.interceptors.request.use((request) => {
    const token = ls.get('auth_token');
    request.headers.set(requestLocale());
    if (token != null && request.headers.get('Authorization') == null) {
      request.headers.set(authHeader(`Bearer ${token}`));
    }
    return request;
  });

  baseClient.interceptors.response.use((response) => response);

  baseClient.interceptors.response.use(undefined, async (error) => {
    // TODO: read expected login failed message from the localization file, so it will be localized
    const locale: string = ls.get('locale') || 'enGB';
    const loginFailedMessage: object = {
      enGB: 'Invalid Credentials',
      ukUA: 'Неправильний логін чи пароль.'
    };
    const ipBlockedMessage: object = {
      enGB: 'Ip Address is locked',
      ukUA: 'IP-адреса заблокована'
    };
    const wrongPasswordMessage: string = 'Current password is not match';

    // If we have network error there will be no error response. This condition prevents user from seeing app crashes.
    if (!error?.response) {
      return Promise.resolve({});
    }
    const loginFailed: boolean = error.response.data.message.includes(loginFailedMessage[locale]);
    const loginLocked: boolean = error.response.data.message.includes(ipBlockedMessage[locale]);
    const passwordFailed: boolean = error.response.data.message.includes(wrongPasswordMessage);
    console.warn('ERROR Flow', error);
    if (error.response.status === 401 && !loginFailed && !loginLocked) {
      const basicToken = ls.get('token');
      if (basicToken) {
        console.warn('ERROR Flow 2', basicToken);
        reauthenticate(basicToken);
      } else {
        ls.clear();
        showError(error);
        return Promise.reject(error);
      }
    } else {
      if (!loginFailed && !loginLocked && !passwordFailed) showError(error);
      return Promise.reject(error);
    }
  });
};

export const authHeader = (token: string) => {
  return { Authorization: token };
};

export function requestLocale() {
  const locale: string = ls.get('locale') || 'enGB';
  return { 'Accept-Language': locale };
}

export default baseClient;
