import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { DICTIONARY } from 'api/constants/dictionary';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditDictionary = (clinicId: number, key: DICTIONARY, callbackFn?: () => void) => {
  return useCustomMutation(
    ({ payload, id }: { payload; id: number }) =>
      axiosClient.put(`${API_ENDPOINTS.DICTIONARIES(clinicId)}/${key}/${id}`, payload),
    [key, clinicId],
    callbackFn
  );
};
