import { Column } from '@ant-design/charts';
import { IShiftProfitLossByCategory } from 'api/interfaces/Stats';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  shift_loss_by_category?: IShiftProfitLossByCategory[];
  locale: ILocale;
};

export const ShiftOutcomeByCategoryColumnChart = ({
  shift_loss_by_category,
  locale
}: Props): JSX.Element => {
  return (
    <Column
      title={locale.labels.outcomeByCategory}
      data={shift_loss_by_category || []}
      isStack={false}
      xField='category'
      yField='amount'
      colorField='category'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
