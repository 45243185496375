import './StatusSelect.scss';

import { Select } from 'antd';
import { IRoom } from 'api/interfaces/Room';
import useLocalizedList from 'hooks/useLocalizedList';
import React from 'react';

interface Props {
  status?: string;
  onStatusChange?: (newStatus) => void;
  room?: IRoom;
  disabled?: boolean;
  statusesList?: Record<string, string>[];
}

const { Option } = Select;

export const RoomStatusSelect = ({
  status,
  onStatusChange,
  room,
  disabled,
  statusesList
}: Props) => {
  const list = useLocalizedList(statusesList).map(
    (item: { value: string; label: string }, index: number) => (
      <Option key={`status_${index}`} value={item.value}>
        {item.label}
      </Option>
    )
  );

  return (
    <div>
      <Select disabled={disabled} onChange={onStatusChange} value={status}>
        {list}
      </Select>
    </div>
  );
};
