import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteInvoiceDocument = (clinicId: number, invoiceId: number) =>
  useDeleteDocument(`${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}/documents`, [
    'invoiceDocuments',
    clinicId,
    invoiceId
  ]);

export const useDeleteClientDocument = (clinicId: number, clientId: number) =>
  useDeleteDocument(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}/documents`, [
    'clientDocuments',
    clinicId,
    clientId
  ]);

export const useDeletePatientDocument = (clinicId: number, patientId: number) =>
  useDeleteDocument(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}/documents`, [
    'patientDocuments',
    clinicId,
    patientId
  ]);

export const useDeleteClinicDocument = (clinicId: number) =>
  useDeleteDocument(`${API_ENDPOINTS.CLINICS(clinicId)}/documents`, ['clinicDocuments', clinicId]);

export const useDeleteDocument = (endpoint, queryKey: (number | string)[]) => {
  const mutationFn = (documentId: string): Promise<void> =>
    axiosClient.delete(`${endpoint}/${documentId}`);

  return useCustomMutation(mutationFn, queryKey);
};
