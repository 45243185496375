import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import { IEmployeeList } from '../../interfaces/Employee';
import { useTableData } from '../useTableData';

export const useEmployeesList = (
  clinicId: number,
  params: Record<string, string | number> = {}
) => {
  const endpoint = API_ENDPOINTS.EMPLOYEES(clinicId);

  const result = useTableData<IEmployeeList>(endpoint, params, ['employeesList', clinicId], true);

  return {
    ...result,
    employees: result.data.data || []
  };
};
