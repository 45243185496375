import './ClinicInfo.scss';

import { CopyOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin, Tooltip, Typography } from 'antd';
import { useUploadClinicAvatar } from 'api/hooks/avatar.ts/useUploadAvatar';
import { useClinicInfo } from 'api/hooks/clinic/useClinicInfo';
import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import profileBackground from 'assets/img/profile-bg.jpg';
import { AvatarComponent } from 'components/Avatar';
import { TextEditorView } from 'components/TextEditorView';
import { getSocialMediaData, parseSocialLink } from 'constants/dictionary/default/socialMediaOptions';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { companyTypes } from 'i18n/companyTypesSelect';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ClinicInfoForm } from './ClinicInfoForm';

const ClinicInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.general');
  const editAccess = useAccess().accesses.clinics.edit;
  const companyTypesOptions = useI18n(companyTypes);

  const { data: clinic, isPending } = useClinicInfo(clinicId);

  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getCompanyTypeAndName = (type: string, name: string): string => {
    const companyType = companyTypesOptions[type];
    return companyType ? `${companyType} "${name}"` : name;
  };

  const uploadAvatar = useUploadClinicAvatar(clinicId);

  const handleSetAvatar = (data, callbackFn): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    uploadAvatar.mutate(avatarData);
    setChangeAvatarModalVisible(false);
    callbackFn();
  };

  const copyUserData = async (): Promise<boolean | void> => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
    const copyText = `${clinic.name}\n${locale.labels.phoneNumber}: +${clinic.country_code}${clinic.phone_number}\n${locale.labels.eMail}: ${clinic.email}\n${locale.labels.address}: ${clinic.location}`;

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(copyText);
    } else {
      return document.execCommand('copy', true, copyText);
    }
  };

  return (
    <Spin spinning={isPending} style={{ height: '100vh' }}>
      {!!clinic && (
        <div className='stack clinic-info-page'>
          <Row style={{ paddingTop: '20px' }}>
            <Col span={24} lg={24} style={{ padding: '0 24px' }}>
              <Col span={24}>
                <Card
                  className='ant-card-bordered personal-info-card with-shadow'
                  cover={<img alt='cover' src={profileBackground} />}
                >
                  <Row justify='center' style={{ marginTop: '-50px' }}>
                    <AvatarComponent
                      loading={uploadAvatar.isPending}
                      editAccess={editAccess}
                      src={clinic.avatar || clinicDefaultLogo}
                      onImageChange={handleSetAvatar}
                      avatarTitle={locale.labels.addLogo}
                      setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                      changeAvatarModalVisible={changeAvatarModalVisible}
                    />
                  </Row>
                  <Row align='middle' justify='space-between' style={{ marginBottom: 15 }}>
                    <Typography.Title level={4}>
                      {getCompanyTypeAndName(clinic.company_type, clinic.name)}
                    </Typography.Title>
                    <Row>
                      <ClinicInfoForm clinic={clinic} loading={isPending} />
                      {
                        <Tooltip
                          open={tooltipOpen}
                          mouseLeaveDelay={3}
                          title={locale.placeholders.copied}
                        >
                          <CopyOutlined onClick={copyUserData} className='copy-clinic-icon' />
                        </Tooltip>
                      }
                    </Row>
                  </Row>
                  <Row>
                    <TextEditorView borderless value={clinic.description} />
                  </Row>
                  <br />
                  <Row justify='space-between' style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Col span={8} className='min-width-300'>
                      <Row align='middle' justify='space-between' style={{ marginBottom: 15 }}>
                        <Typography.Title style={{ margin: 0 }} level={5}>
                          {locale.labels.contacts}
                        </Typography.Title>
                      </Row>
                      <Row align='middle' style={{ marginBottom: '10px' }}>
                        <Col style={{ width: '50px' }}>
                          <span className='icofont-ui-touch-phone info-icon' />
                        </Col>
                        <Col>
                          <Link to={`tel:+${clinic.country_code}${clinic.phone_number}`}>
                            <div>{locale.labels.phone}</div>+{clinic.country_code}
                            {clinic.phone_number}
                          </Link>
                        </Col>
                      </Row>
                      <Row align='middle' style={{ marginBottom: '10px' }}>
                        <Col style={{ width: '50px' }}>
                          <span className='icofont-ui-message info-icon' />
                        </Col>
                        <Col>{clinic.email}</Col>
                      </Row>
                      <Row align='middle' style={{ marginBottom: '10px' }}>
                        <Col style={{ width: '50px' }}>
                          <span className='icofont-location-pin info-icon' />
                        </Col>
                        <Col>{clinic.location}</Col>
                      </Row>
                      <Typography.Title style={{ marginTop: '10px' }} level={5}>
                        {locale.labels.workingHours}
                      </Typography.Title>
                      <Row align='middle' style={{ marginBottom: '10px' }}>
                        <Col style={{ width: '50px' }}>
                          <span className='icofont-stopwatch info-icon' />
                        </Col>
                        <Col>
                          <TextEditorView value={clinic.working_hours} borderless />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} className='min-width-300'>
                      {clinic.social_media_links !== '[]' && clinic.social_media_links !== null && (
                        <Typography.Title style={{ marginTop: '10px' }} level={5}>
                          {locale.labels.socialMedia}
                        </Typography.Title>
                      )}
                      {clinic.social_media_links !== '[]' && clinic.social_media_links !== null && 
                        JSON.parse(clinic.social_media_links)?.map((link, index) => {
                          const media = getSocialMediaData(link.type);
                          const username = parseSocialLink(link.description);

                          return (
                            <Row key={index} align='middle'>
                              <Col className='clinic-info-icon-style'>{media?.icon}</Col>
                              <Col id='client-email' className='clinic-media-link-container'>
                                <Link
                                  to={`${media?.baseUrl}${username}`}
                                  style={{ fontSize: 15 }}
                                  className='clinic-media-link'
                                >
                                  {username}
                                </Link>
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                    <Col span={12} className='min-width-300'>
                      {clinic.additional_contacts !== '[]' && clinic.additional_contacts !== null && (
                        <Typography.Title style={{ marginTop: '10px' }} level={5}>
                          {locale.labels.additionalContacts}
                        </Typography.Title>
                      )}
                      {clinic.additional_contacts !== '[]' && clinic.additional_contacts !== null &&
                        JSON.parse(clinic.additional_contacts)?.map((contact, index) => (
                          <Row
                            gutter={[24, 0]}
                            key={index}
                            align='middle'
                            style={{ paddingBottom: '10px' }}
                          >
                            <Col className='icon-style' span={2} style={{ marginRight: 10 }}>
                              <span className='icofont-ui-touch-phone info-icon' />
                            </Col>
                            <Col span={10}>
                              <Link
                                to={`tel:+${contact?.country_code}${contact?.phone_number}`}
                                style={{ fontSize: 15 }}
                              >
                                +{contact?.country_code}
                                {contact?.phone_number}
                              </Link>
                            </Col>
                            <Col span={10}>
                              <span style={{ fontSize: 15 }}>{contact?.description}</span>
                            </Col>
                          </Row>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Col>
          </Row>
        </div>
      )}
    </Spin>
  );
};

export default ClinicInfo;
