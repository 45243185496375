import './scss/HealthRecordsHistory.scss';

import { Collapse, Spin, Timeline } from 'antd';
import { usePatientHealthRecords } from 'api/hooks/patients/usePatientHealthRecords';
import calendar from 'assets/img/calendarWhite.svg';
import { useLocale } from 'hooks/useLocale';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { getServices } from 'utils/string-from-entities-list';

import { TextEditorView } from './TextEditorView';

type Props = {
  clinicId: number;
  patientId: number;
};

export const HealthRecordsHistory = ({ clinicId, patientId }: Props): JSX.Element => {
  const locale = useLocale('private.appointments.event');

  const { data: history, isLoading } = usePatientHealthRecords(clinicId, patientId);

  const getParsedJsonValue = (jsonStr: string | null): string => {
    try {
      const parsedStr = JSON.parse(jsonStr);
      return parsedStr?.join(', ');
    } catch {
      return jsonStr;
    }
  };

  return (
    <Spin spinning={isLoading}>
      {history && !history?.length && (
        <div className='history-container__no-data'>
          <p>{locale.labels.noData}</p>
        </div>
      )}

      <div className='history-container'>
        <Timeline
          items={history?.map((el) => {
            return {
              dot: (
                <div className='history-container__icon-container'>
                  {' '}
                  <img src={calendar} />
                </div>
              ),
              children: (
                <>
                  {el?.title && (
                    <p className='history-container__title'>
                      <Link
                        to={`/clinic/${clinicId}/appointments/${el?.appointment_id}?patientId=${el?.patient_id}&tab=details`}
                        className='history-container__services-title custom-links d-flex align-baseline'
                      >
                        <strong>{el?.title}</strong>
                      </Link>
                    </p>
                  )}
                  {el?.complaints && (
                    <p>
                      <strong>{locale.labels.complaints}: </strong>
                      {el.complaints}
                    </p>
                  )}
                  <p className='history-container__time'>
                    {moment(el?.created_at).format('HH:mm DD MMM, YYYY')}
                  </p>
                  <Collapse
                    size={'small'}
                    className='history-container__collapse-item'
                    items={[
                      {
                        key: `details${el.id}`,
                        label: locale.labels.details,
                        children: (
                          <>
                            {clinicId === el?.clinic_id && (
                              <p>
                                <strong>{locale.labels.services}: </strong>
                                {/* todo API remove any after services api change */}
                                {getServices(el?.services as any)}
                              </p>
                            )}
                            {
                              <p>
                                <strong>{locale.labels.diagnosis}: </strong>
                                {!el?.no_diagnosis_required && el.diagnosis !== '[]'
                                  ? getParsedJsonValue(el?.diagnosis as string)
                                  : locale.labels.noDiagnosisRequired}
                              </p>
                            }
                            {!el?.no_diagnosis_required &&
                              el?.provisional_diagnosis &&
                              el?.provisional_diagnosis !== '[]' && (
                                <p>
                                  <strong>{locale.labels.preliminaryDiagnosis}: </strong>
                                  {getParsedJsonValue(el?.provisional_diagnosis as string)}
                                </p>
                              )}
                            <div>
                              <strong>{locale.labels.recommendations}: </strong>
                              {el?.recommendations ? (
                                <TextEditorView value={el?.recommendations} id='recommendations' />
                              ) : (
                                locale.labels.noRecommendations
                              )}
                            </div>
                            {el?.anamnesis && (
                              <div>
                                <strong>{locale.labels.anamnesis}: </strong>
                                <TextEditorView value={el?.anamnesis} id='anamnesis' />
                              </div>
                            )}
                            {el?.date_of_next_visit && (
                              <p>
                                <strong>{locale.labels.nextVisitDate}: </strong>
                                {moment(el.date_of_next_visit).format('DD MMM, YYYY')}
                              </p>
                            )}
                            {el?.notes && (
                              <div>
                                <strong>{locale.labels.notes}: </strong>
                                <TextEditorView value={el?.notes} id='notes' />
                              </div>
                            )}
                          </>
                        )
                      }
                    ]}
                  ></Collapse>
                </>
              )
            };
          })}
        />
      </div>
    </Spin>
  );
};
