import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IInvoicesList } from 'api/interfaces/Invoice';

import { useTableData } from '../useTableData';

export const useInvoicesList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.INVOICES(clinicId);
  const result = useTableData<IInvoicesList>(endpoint, defaultParams, ['invoicesList', clinicId]);

  return {
    ...result,
    invoices: result.data.data
  };
};
