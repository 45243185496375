import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { useProvidersList } from 'api/hooks/providers/useProvidersList';
import { IInvoice } from 'api/interfaces/Invoice';
import { MODAL, useModal } from 'api/store/modalStore';
import { TextEditor } from 'components/TextEditor';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddProviderModal } from 'pages/inventory/providers/modal/AddProviderModal';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';

type Props = React.PropsWithChildren<{
  onFieldsChange?: (e) => void;
  onFinish: (payload) => void;
  form: FormInstance;
  initialValues?: IInvoice & { provider_id: number };
}>;

export const InvoiceForm = ({
  onFieldsChange,
  onFinish,
  form,
  initialValues,
  children
}: Props): JSX.Element => {
  const locale = useLocale('private.inventory.invoices');
  const clinicId = useClinicId();
  const { open, isOpened } = useModal();

  const { providers } = useProvidersList(clinicId);
  const [providerSearchValue, setProviderSearchValue] = useState('');

  const providersList = getOptions(providers);

  const filterOptions = (input: string, option) =>
    option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  return (
    <>
      <Form
        key={'InvoiceForm'}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={initialValues}
      >
        <Row gutter={[40, 0]} className={'form-row'}>
          <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
            <Form.Item
              name='invoice_number'
              label={locale.labels.invoiceNumber}
              rules={[requiredRule(locale.messages.invoiceNumberEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='provider_id'
              className='long-value-form-item'
              label={locale.labels.provider}
              rules={[requiredRule(locale.messages.providerEmpty)]}
            >
              <Select
                showSearch
                filterOption={filterOptions}
                onSearch={(val) => setProviderSearchValue(val)}
                notFoundContent={
                  <div onClick={() => open(MODAL.addProviderModal)} id='addNewServiceGroupBtn'>
                    + {locale.labels.addProvider}
                  </div>
                }
              >
                {providersList}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
            <Form.Item
              name='purchase_date'
              label={`${locale.labels.date}`}
              initialValue={initialValues?.purchase_date || dayjs()}
              rules={[requiredRule(locale.messages.dateEmpty)]}
            >
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <TextEditor label={locale.labels.notes} name={['notes']} />
          </Col>
        </Row>
        {children}
      </Form>
      {isOpened(MODAL.addProviderModal) && (
        <AddProviderModal
          callBackFunction={(data) => {
            form.setFieldValue('provider_id', data?.id);
          }}
          defaultProviderName={providerSearchValue}
        />
      )}
    </>
  );
};
