import 'dayjs/locale/uk';
import './FinancesInfo.scss';

import { Button, Card, Col, DatePicker, Row, Select, Spin } from 'antd';
import { useCloseShift } from 'api/hooks/shifts/useCloseShift';
import { useOpenedShift } from 'api/hooks/shifts/useOpenedShift';
import { useOpenShift } from 'api/hooks/shifts/useOpenShift';
import { useShiftsList } from 'api/hooks/shifts/useShiftsList';
import { useFinancialStats } from 'api/hooks/stats/useFinancialStats';
import { IShift } from 'api/interfaces/Shift';
import { useLocaleCode } from 'api/store/localeContext';
import useShiftStore from 'api/store/shiftStore';
import { DATE_TIME_FORMAT } from 'constants/common';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

import { AccountsBalancesPieChart } from './components/AccountsBalancesPieChart';
import { ALLShiftsInOutcomeLineChart } from './components/AllShiftsInOutcomeLineChart';
import { BilledOverPaidColumnChart } from './components/BilledOverPaidColumnChart';
import { ShiftBilledAmountByEmployeeColumnChart } from './components/ShiftBilledAmountByEmployeeColumnChart';
import { ShiftIncomeByCategoryColumnChart } from './components/ShiftIncomeByCategoryColumnChart';
import { ShiftInOutcomeByAccountsColumnChart } from './components/ShiftInOutcomeByAccountsColumnChart';
import { ShiftOutcomeByCategoryColumnChart } from './components/ShiftOutcomeByCategoryColumnChart';
import ShiftStatusCard from './components/ShiftStatusCard';
import { TotalDebtsColumnChart } from './components/TotalDebtsColumnChart';
import { WorkingWeekPicker } from './components/WeekPicker';
import {
  allBallancesColumnConfig,
  chartsColumnConfig,
  shiftMainStatsColumnConfig
} from './utils/configs';

dayjs.extend(weekOfYear);
dayjs.locale('uk');

const { RangePicker } = DatePicker;

interface ILocalizedSelectOption {
  value: string;
  ukUA: string;
  enGB: string;
}

const filterTypeOptions: ILocalizedSelectOption[] = [
  {
    value: 'by_date_range',
    ukUA: 'За період',
    enGB: 'By date range'
  },
  {
    value: 'by_specific_shift',
    ukUA: 'За зміну',
    enGB: 'By shift'
  }
];

const FinancesInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const { localeCode } = useLocaleCode();
  const locale = useLocale('private.finances.shifts');

  const { shift } = useShiftStore();

  const [timeFrom, setTimeFrom] = useState<string>(null);
  const [timeTo, setTimeTo] = useState<string>(null);
  const [filterType, setFilterType] = useState('by_date_range');
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>([null, null]);
  const [weekRange, setWeekRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [lineDatePeriod, setLineDatePeriod] = useState('year');

  const { shifts, updateParams: updateShiftsParams } = useShiftsList(clinicId);
  const { stats, isLoading, updateParams } = useFinancialStats(clinicId, lineDatePeriod);

  const getOpenedShift = useOpenedShift();
  const openShift = useOpenShift(clinicId);
  const closeShift = useCloseShift(clinicId);

  useEffect(() => {
    getOpenedShift.mutate({ clinicId });
  }, []);

  useEffect((): void => {
    if (shift) {
      const timeFromUTC = dayjs(shift?.opened_at).utc().format(DATE_TIME_FORMAT);
      const timeToUTC = dayjs(shift?.closed_at).utc().format(DATE_TIME_FORMAT);
      const timeFromLocal = dayjs(shift?.opened_at).local();
      const timeToLocal = dayjs(shift?.closed_at).local();
      setTimeFrom(timeFromUTC);
      if (shift?.closed_at) setTimeTo(timeToUTC);
      setDateRange([timeFromLocal, shift?.closed_at ? timeToLocal : null]);
      onShiftsRangeChange([timeFromLocal, shift?.closed_at ? timeToLocal : null]);
    }
  }, [shift]);

  useEffect(() => {
    if (!!timeFrom || !!timeTo) {
      updateParams({ timeFrom, timeTo, lineDatePeriod });
    }
  }, [timeFrom, timeTo, lineDatePeriod]);

  useEffect(() => {
    if (weekRange) onShiftsRangeChange(weekRange);
  }, [weekRange, timeFrom]);

  const onShiftsRangeChange = (weekRange: [dayjs.Dayjs, dayjs.Dayjs]) => {
    const timeFrom: string = weekRange[0]?.utc().format(DATE_TIME_FORMAT);
    const timeTo: string = weekRange[1]?.utc().format(DATE_TIME_FORMAT);
    updateShiftsParams({ timeFrom, timeTo, lineDatePeriod });
  };

  const onDateRangeChange = (dates: [dayjs.Dayjs, dayjs.Dayjs] | null) => {
    const timeFromUTC = dates[0]?.utc().format(DATE_TIME_FORMAT);
    const timeToUTC = dates[1]?.utc().format(DATE_TIME_FORMAT);
    setDateRange(dates);
    setTimeFrom(timeFromUTC);
    setTimeTo(timeToUTC);
  };

  const onShiftSelect = (value: number): void => {
    const shift = shifts.find((shift: IShift): boolean => shift.id === value);
    const timeFromLocal: dayjs.Dayjs = dayjs(shift.opened_at).local();
    const timeToLocal: dayjs.Dayjs = shift.closed_at ? dayjs(shift.closed_at).local() : null;

    onDateRangeChange([timeFromLocal, timeToLocal]);
  };

  const convertShiftSelectLabel = (item: IShift): string => {
    const displayFormat: string = 'dddd HH:mm';
    const dateTimeFrom: string = dayjs(item.opened_at).local().format(displayFormat);
    const dateTimeTo: string = item.closed_at
      ? dayjs(item.closed_at).local().format(displayFormat)
      : '';
    return `${dateTimeFrom} - ${dateTimeTo}`;
  };

  return (
    <>
      <Row
        id='finances-dashboard'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Col {...shiftMainStatsColumnConfig}>
          <Card className='ant-card-bordered shift-details-card with-shadow'>
            <ShiftStatusCard shift={shift} />
            <Row id='change-status' justify={'end'} style={{ marginTop: '20px' }}>
              {shift?.open ? (
                <Button
                  type={'primary'}
                  id='close-shift'
                  onClick={() => closeShift.mutate(shift.id)}
                >
                  {locale.labels.closeShit}
                </Button>
              ) : (
                <Button type={'primary'} id='open-shift' onClick={openShift.mutate}>
                  {locale.labels.openShift}
                </Button>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <Row>
          <Col {...allBallancesColumnConfig(stats?.accounts_balances?.data, 'account_name')}>
            <AccountsBalancesPieChart accountsBalances={stats?.accounts_balances} locale={locale} />
          </Col>
          <Col span={12} xs={24} sm={24} md={24} xl={24} xxl={8}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Col>
                <span
                  onClick={() => setLineDatePeriod('week')}
                  className='ant-btn ant-btn-primary ant-btn-sm'
                  style={{ width: '60px' }}
                >
                  {locale.labels.week}
                </span>
                <span
                  onClick={() => setLineDatePeriod('month')}
                  className='ant-btn ant-btn-primary ant-btn-sm'
                  style={{ width: '60px', marginLeft: '2px' }}
                >
                  {locale.labels.month}
                </span>
                <span
                  onClick={() => setLineDatePeriod('year')}
                  className='ant-btn ant-btn-primary ant-btn-sm'
                  style={{ width: '60px', marginLeft: '2px' }}
                >
                  {locale.labels.year}
                </span>
              </Col>
              <Col style={{ width: '100%' }}>
                <ALLShiftsInOutcomeLineChart
                  all_shifts_profit={stats?.all_shifts_profit}
                  locale={locale}
                />
              </Col>
            </Row>
          </Col>
          {stats?.total_debts?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.total_debts, 'type')}>
              <TotalDebtsColumnChart total_debts={stats?.total_debts} locale={locale} />
            </Col>
          )}
        </Row>
        <Row style={{ display: 'flex', flexDirection: 'row' }}>
          <Col span={6} xs={24} sm={24} md={6} xl={6} xxl={4} className={'inputs_col_with_margins'}>
            <Select
              value={filterType}
              onSelect={setFilterType}
              options={filterTypeOptions.map((item: ILocalizedSelectOption) => ({
                value: item.value,
                label: item[localeCode]
              }))}
            />
          </Col>
          {filterType === 'by_specific_shift' && (
            <>
              <Col
                span={8}
                xs={24}
                sm={24}
                md={8}
                xl={8}
                xxl={8}
                className={'inputs_col_with_margins'}
              >
                <WorkingWeekPicker setWeekRange={setWeekRange} locale={locale} />
              </Col>
              <Col
                span={8}
                xs={24}
                sm={24}
                md={8}
                xl={8}
                xxl={8}
                className={'inputs_col_with_margins'}
              >
                <Select
                  placeholder={locale.placeholders.selectWorkingShift}
                  onSelect={onShiftSelect}
                  options={shifts.map((item) => ({
                    value: item.id,
                    label: convertShiftSelectLabel(item)
                  }))}
                />
              </Col>
            </>
          )}
          {filterType === 'by_date_range' && (
            <Col
              span={12}
              xs={24}
              sm={24}
              md={12}
              xl={10}
              xxl={8}
              className={'inputs_col_with_margins'}
            >
              <RangePicker value={dateRange} showTime onChange={onDateRangeChange} />
            </Col>
          )}
        </Row>
        <Row>
          {stats?.shift_profit_by_accounts?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.shift_profit_by_accounts, 'account_name')}>
              <ShiftInOutcomeByAccountsColumnChart
                shift_profit_by_accounts={stats?.shift_profit_by_accounts}
                locale={locale}
              />
            </Col>
          )}
          {stats?.shift_profit_by_category?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.shift_profit_by_category, 'category')}>
              <ShiftIncomeByCategoryColumnChart
                shift_profit_by_category={stats?.shift_profit_by_category}
                locale={locale}
              />
            </Col>
          )}
          {stats?.shift_loss_by_category?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.shift_loss_by_category, 'category')}>
              <ShiftOutcomeByCategoryColumnChart
                shift_loss_by_category={stats?.shift_loss_by_category}
                locale={locale}
              />
            </Col>
          )}
          {stats?.employees_profit?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.employees_profit, 'user_name')}>
              <ShiftBilledAmountByEmployeeColumnChart
                employees_profit={stats?.employees_profit}
                locale={locale}
              />
            </Col>
          )}
          {stats?.billed_over_paid?.length > 0 && (
            <Col {...chartsColumnConfig(stats?.billed_over_paid, 'name')}>
              <BilledOverPaidColumnChart
                billed_over_paid={stats?.billed_over_paid}
                locale={locale}
              />
            </Col>
          )}
        </Row>
      </Spin>
    </>
  );
};

export default FinancesInfo;
