import { Divider, Form, FormInstance, Input, Spin, Transfer } from 'antd';
import { useCreateRole } from 'api/hooks/roles/useCreateRole';
import { usePermissions } from 'api/hooks/roles/usePermissions';
import { IPermission } from 'api/interfaces/Role';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { Key, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = React.PropsWithChildren<{
  form: FormInstance;
}>;

export const AddRoleForm = ({ form, children }: Props): JSX.Element => {
  const clinicId = useClinicId();

  const { data: permissions, isPending } = usePermissions(clinicId);
  const createRole = useCreateRole(clinicId);
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const locale = useLocale('private.administration.roles');

  const onSubmit = (data: IPermission): void => {
    const payload = {
      name: data.name,
      permissions_ids: targetKeys.map((key) => parseInt(key as string))
    };
    createRole.mutate(payload);
  };

  const data = permissions.map((item) => ({
    key: item.id,
    title: item.name,
    description: '',
    chosen: false
  }));

  return (
    <Spin spinning={isPending}>
      <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
        <div className='row'>
          <div className='col-md-4 col-sm-12'>
            <Form.Item
              label={locale.labels.name}
              name='name'
              rules={[requiredRule(locale.messages.nameEmpty)]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className='col-md-8 col-sm-12'>
            <Transfer
              className={'transfer'}
              dataSource={data}
              listStyle={{
                maxWidth: '50%',
                minWidth: '30%',
                width: 400,
                height: 500
              }}
              showSearch
              oneWay={true}
              targetKeys={targetKeys}
              onChange={(newTargetKeys) => {
                setTargetKeys(newTargetKeys);
              }}
              render={(item) => item.title}
              pagination
            />
          </div>
        </div>
        <Divider />
        {children}
      </Form>
    </Spin>
  );
};
