import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ILoginPayload, ILoginResponse, ILoginResult } from 'api/interfaces/User';
import queryClient from 'api/queryClient';
import { useLocaleCode } from 'api/store/localeContext';
import useUserStore from 'api/store/userStore';
import * as ls from 'local-storage';

import { fetchUserProfile } from './useUserProfile';
import { AxiosResponse } from 'axios';

const loginApi = async ({ email, password, remember }: ILoginPayload): Promise<ILoginResult> => {
  const authHeader = `Basic ${btoa(`${email}:${password}`)}`;

  const response = await axiosClient.post<ILoginResponse>(`${API_ENDPOINTS.AUTH}/login`, null, {
    headers: { Authorization: authHeader }
  });

  return { ...response, authHeader, remember };
};

export const useLogin = (onLogin: (data: ILoginResponse) => void, onError: (error: Promise<AxiosResponse>) => void) => {
  const { setLocale } = useLocaleCode();
  const setUser = useUserStore.getState().setUser;

  return useMutation({
    mutationFn: loginApi,
    onSuccess: async ({ data, authHeader, remember }: ILoginResult) => {
      ls.set('auth_token', data.auth_token);

      if (remember) {
        ls.set('token', authHeader);
      }

      const userProfile = await queryClient.fetchQuery({
        queryKey: ['userProfile'],
        queryFn: fetchUserProfile
      });

      // We set results to store and context
      setLocale(userProfile.locale);
      setUser(userProfile);

      // Callback to close the modal and redirect user
      onLogin(data);
    },
    onError
  });
};
