import { createHashHistory } from 'history';
import { combineReducers } from 'redux';

// import { appointmentsReducer } from './appointments/reducer';
// import { clientsReducer } from './clients/reducer';
// import { clinicReducer } from './clinic/reducer';
// import { commonReducer } from './common/reducer';
// import { billPaymentTypesReducer } from './dictionaries/bill-payment-types/reducer';
// import { diagnosesReducer } from './dictionaries/diagnoses/reducer';
// import { dictionaryReducer } from './dictionaries/dictionary/reducer';
// import { serviceGroupsReducer } from './dictionaries/service-groups/reducer';
// import { stockCategoriesReducer } from './dictionaries/stock-categories/reducer';
// import { stockSubCategoriesReducer } from './dictionaries/stock-sub-categories/reducer';
// import { stockTypesReducer } from './dictionaries/stock-types/reducer';
// import { employeesReducer } from './employees/reducer';
// import { eventsReducer } from './events/reducer';
// import { billsReducer } from './finances/bills/reducer';
// import { financialAccountReducer } from './finances/financialAccounts/reducer';
// import { paymentsReducer } from './finances/payments/reducer';
import { auditReducer } from './inventory/audit/reducer';
// import { equipmentReducer } from './inventory/equipment/reducer';
// import { invoiceReducer } from './inventory/invoice/reducer';
// import { providerReducer } from './inventory/providers/reducer';
import { stockReducer } from './inventory/stock/reducer';

// import { toolsReducer } from './inventory/tools/reducer';
// import { invitationsReducer } from './invitations/reducer';
// import { localeCodeReducer } from './localeCode/reducer';

// import { pageDataReducer } from './page-data/reducer';
// import { patientsReducer } from './patients/reducer';
// import { permissionsReducer } from './permissions/reducer';
// import { rolesReducer } from './roles/reducer';
// import { roomsReducer } from './rooms/reducer';
// import { salesReducer } from './sales/reducer';
// import { servicesReducer } from './services/reducer';
// import { settingsReducer } from './settings/reducer';

// import { shiftsReducer } from './shifts/reducer';
// import { skillsReducer } from './skills/reducer';

// import { speciesReducer } from './species/reducer';

// import { userReducer } from './user/reducer';

export const history = createHashHistory();

const appReducer = combineReducers({
  // appointments: appointmentsReducer,
  audit: auditReducer,
  // bills: billsReducer,
  // billPaymentTypes: billPaymentTypesReducer,
  // clients: clientsReducer,
  // clinic: clinicReducer,
  // common: commonReducer,
  // diagnoses: diagnosesReducer,
  // dictionary: dictionaryReducer,
  // employees: employeesReducer,
  // equipment: equipmentReducer,
  // events: eventsReducer,
  // financialAccounts: financialAccountReducer,
  // invitations: invitationsReducer,
  // invoices: invoiceReducer,
  // localeCode: localeCodeReducer,
  // pageData: pageDataReducer,
  // patients: patientsReducer,
  // permissions: permissionsReducer,
  // payments: paymentsReducer,
  // rooms: roomsReducer,
  // roles: rolesReducer,
  stock: stockReducer
  // shifts: shiftsReducer,
  // speciesList: speciesReducer,
  // sales: salesReducer,
  // skills: skillsReducer,
  // serviceGroups: serviceGroupsReducer,
  // stockTypes: stockTypesReducer,
  // stockCategories: stockCategoriesReducer,
  // stockSubCategories: stockSubCategoriesReducer,
  // settings: settingsReducer
  // services: servicesReducer,
  // tools: toolsReducer
  // user: userReducer,
  // providers: providerReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;
