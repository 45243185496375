import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRolePayload } from 'api/interfaces/Role';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateRole = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    (payload: IRolePayload) => axiosClient.post(`${API_ENDPOINTS.ROLES(clinicId)}`, payload),
    [['rolesList', clinicId]],
    callbackFn
  );
};
