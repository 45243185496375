import './scss/HealthRecordsHistory.scss';

import { Pagination, Spin, Timeline } from 'antd';
import { useClientsHistory } from 'api/hooks/clients/useClientHistory';
import { IClientHistory } from 'api/interfaces/Client';
import calendar from 'assets/img/calendarWhite.svg';
import pharmacy from 'assets/img/pharmacyWhite.svg';
import { appointmentStatuses } from 'constants/dictionary/default/selectOptions';
import { money, scrollToTop } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import moment from 'moment';
import { renderAppointmentStatus } from 'pages/appointments/AppointmentsBaseHelper';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  clinicId: number;
  clientId?: number;
};

export const ClientHistory = ({ clinicId, clientId }: Props): JSX.Element => {
  const locale = useLocale('private.appointments.event');
  const currencies = useLocale('private.currencies').labels;
  const localizedStatuses = useLocalizedList(appointmentStatuses);

  const { clientHistory, isLoading, data, params, updateParams } = useClientsHistory(
    clinicId,
    clientId
  );

  const getItemIcon = (type: string): ReactNode => {
    switch (type) {
      case 'payment':
        return <i style={{ color: 'white' }} className='icofont-money'></i>;
      case 'sale':
        return <img src={pharmacy} />;
      default:
        return <img src={calendar} />;
    }
  };

  const renderHistoryLink = (el: IClientHistory): string => {
    switch (el.type) {
      case 'payment':
        return `/clinic/${clinicId}/finances/payments/${el?.entity_id}`;
      case 'sale':
        return `/clinic/${clinicId}/shop-farmacy/${el?.entity_id}`;
      default:
        return `/clinic/${clinicId}/appointments/${el?.entity_id}`;
    }
  };

  const renderHistoryType = (historyItem: IClientHistory): JSX.Element => {
    switch (historyItem.type) {
      case 'payment':
        return <span>{locale.labels.payment}</span>;
      case 'sale':
        return <span>{locale.labels.sale}</span>;
      default:
        return (
          <>
            <span style={{ marginRight: 8 }}>{locale.labels.appointment}</span>
            {renderAppointmentStatus(historyItem.status, localizedStatuses)}
          </>
        );
    }
  };

  return (
    <Spin spinning={isLoading}>
      {!clientHistory?.length && (
        <div className='history-container__no-data'>
          <p>{locale.labels.noData}</p>
        </div>
      )}
      <div className='history-container'>
        <Timeline
          items={clientHistory?.map((el: IClientHistory) => ({
            dot: <div className='history-container__icon-container'>{getItemIcon(el.type)}</div>,
            children: (
              <>
                <Link
                  style={{ paddingTop: 4, display: 'block' }}
                  to={renderHistoryLink(el)}
                  className='history-container__services-title custom-links d-flex align-baseline'
                >
                  {renderHistoryType(el)}
                </Link>
                <p className='history-container__time'>
                  {moment(el?.event_time).format('HH:mm DD MMM, YYYY')}
                </p>
                {el.type !== 'payment' && (
                  <p>
                    <strong>{locale.labels.sum}: </strong>
                    {`${money(el.total_billed_amount)} ${currencies.uah}`}
                  </p>
                )}
                <p>
                  <strong>{locale.labels.payed}: </strong>
                  {`${money(el.total_paid_amount)} ${currencies.uah}`}
                </p>
              </>
            )
          }))}
        />
      </div>
      <Pagination
        current={+params.page}
        total={data.metadata.total || 0}
        showSizeChanger={false}
        pageSize={20}
        onChange={(page) => {
          scrollToTop();
          updateParams({ page });
        }}
      />
    </Spin>
  );
};
