import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { AxiosProgressEvent } from 'axios';

import { useCustomMutation } from '../useCustomMutation';

type MutationProps = {
  formData: FormData;
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
};

const headers = { 'Content-Type': 'multipart/form-data' };

export const useUploadInvoiceDocument = (clinicId: number, invoiceId: number) =>
  useUploadDocument(`${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}/documents`, [
    'invoiceDocuments',
    clinicId,
    invoiceId
  ]);

export const useUploadClientDocument = (clinicId: number, clientId: number) =>
  useUploadDocument(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}/documents`, [
    'clientDocuments',
    clinicId,
    clientId
  ]);

export const useUploadPatientDocument = (clinicId: number, patientId: number) =>
  useUploadDocument(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}/documents`, [
    'patientDocuments',
    clinicId,
    patientId
  ]);

export const useUploadClinicDocument = (clinicId: number) =>
  useUploadDocument(`${API_ENDPOINTS.CLINICS(clinicId)}/documents`, ['clinicDocuments', clinicId]);

export const useUploadDocument = (endpoint: string, queryKey: (number | string)[]) => {
  const mutationFn = ({ formData, onUploadProgress }: MutationProps) =>
    axiosClient.post(endpoint, formData, {
      headers,
      onUploadProgress
    });

  return useCustomMutation(mutationFn, queryKey);
};
