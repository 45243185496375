export const API_ENDPOINTS = {
  ACCOUNTS: (id: number) => `/v1/clinics/${id}/accounts`,
  APPOINTMENTS: (id: number) => `/v1/clinics/${id}/appointments`,
  APPOINTMENT_PATIENTS: (id: number, appointmentId: number) =>
    `v1/clinics/${id}/appointments/${appointmentId}/patients`,
  BILLS: (id: number) => `/v1/clinics/${id}/bills`,
  CALENDAR_EVENTS: (id: number) => `/v1/clinics/${id}/events`,
  CANCEL_EVENT: (eventId: number) => `v1/events/${eventId}/cancel`,
  CLINICS: (id: number) => `/v1/clinics/${id}`,
  CLIENTS: (id: number) => `/v1/clinics/${id}/clients`,
  CLIENT_PATIENTS: (id: number, clientId: number) =>
    `v1/clinics/${id}/clients/${clientId}/patients`,
  DICTIONARIES: (id: number) => `/v1/clinics/${id}/dictionaries`,
  EMPLOYEES: (id: number) => `/v1/clinics/${id}/employees`,
  EQUIPMENT: (id: number) => `/v1/clinics/${id}/equipment`,
  INVITATIONS: (id: number) => `/v1/clinics/${id}/invitations`,
  INVOICES: (id: number) => `/v1/clinics/${id}/invoices`,
  PATIENTS: (id: number) => `v1/clinics/${id}/patients`,
  PATIENT_VACCINATIONS: (id: number, patientId: number) =>
    `/v1/clinics/${id}/patients/${patientId}/vaccinations`,
  PAYMENTS: (id: number) => `v1/clinics/${id}/payments`,
  PERMISSIONS: (id: number) => `v1/clinics/${id}/permissions`,
  PROVIDERS: (id: number) => `v1/clinics/${id}/providers`,
  ROOMS: (id: number) => `/v1/clinics/${id}/rooms`,
  ROLES: (id: number) => `/v1/clinics/${id}/roles`,
  PRINT: (id: number) => `/v1/clinics/${id}/print_configs`,
  SALES: (id: number) => `/v1/clinics/${id}/sales`,
  SERVICES: (id: number) => `/v1/clinics/${id}/services`,
  SHIFTS: (id: number) => `/v1/clinics/${id}/shifts`,
  FINANCIAL_STATS: (id: number) => `/v1/clinics/${id}/stats/financial`,
  STOCK: (id: number) => `/v1/clinics/${id}/stock`,
  STOCK_STATS: (id: number) => `/v1/clinics/${id}/stock_stats`,
  TOOLS: (id: number) => `/v1/clinics/${id}/tools`,
  VACCINATIONS: (id: number) => `/v1/clinics/${id}/vaccinations`,
  AUTH: 'v1/auth/',
  DASHBOARD_APPOINTMENTS: 'v1/appointments',
  EVENTS: 'v1/events',
  ORGANISATION_REQ: 'v1/registration_requests',
  PHONE_CHANGE: 'v1/users/phone/change',
  SKILLS: '/v1/skills',
  USER: 'v1/users'
};
