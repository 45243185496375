import './ClientDetails.scss';

import { Avatar as AvatarView, Button, Col, List, Row, Select, Tooltip, Typography } from 'antd';
import { useDeleteAppointmentPatient } from 'api/hooks/appointments/useDeleteAppointmentPatient';
import { IPatient, IPatientDetails } from 'api/interfaces/Patient';
import { useLocaleCode } from 'api/store/localeContext';
import checkedIcon from 'assets/img/checked.svg';
import closeIcon from 'assets/img/close.svg';
import tombIcon from 'assets/img/tomb.svg';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo, useState } from 'react';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge, getDefaultAvatar } from '../patients/utils/PatientHelper';

const { Option } = Select;

type Props = {
  clientPatients: IPatientDetails[] | IPatient[];
  setSelectedPatient: (val) => void;
  selectedPatientId: number;
  disabled?: boolean;
  lgRowWidth?: number;
  appointmentId?: number;
  removeCallbackFn?: (patient) => void;
  addNewPetAction?: () => void;
};

export const ClientPatientsList = ({
  appointmentId,
  removeCallbackFn,
  disabled,
  clientPatients,
  setSelectedPatient,
  selectedPatientId,
  lgRowWidth,
  addNewPetAction
}: Props): JSX.Element => {
  const showDeletePatientIcon = appointmentId && clientPatients?.length > 1;
  const clinicId = useClinicId();
  const { localeCode } = useLocaleCode();
  const locale = useLocale('private.clients.client');
  const patientLocale = useLocale('private.patients.patient-page');

  const [showDeletePatientModal, setShowDeletePatientModal] = useState<boolean>(false);
  const [patientToDelete, setPatientToDelete] = useState(null);

  const onSuccess = (): void => {
    removeCallbackFn(patientToDelete);
    triggerDeleteModal(null);
  };

  const deletePatient = useDeleteAppointmentPatient(clinicId, appointmentId, onSuccess);

  const getPatientCheckedIcon = (patient: IPatientDetails): boolean => {
    if (!patient?.health_record) return;
    const appDetailsFilled = patient?.health_record?.completed;

    const stockDetailsFilled = !(patient?.health_record?.services || []).some(
      ({ stock_saved }) => stock_saved === false
    );

    return appDetailsFilled && stockDetailsFilled;
  };

  const triggerDeleteModal = (id: any): void => {
    setPatientToDelete(id);
    setShowDeletePatientModal(!!id);
  };

  const onConfirmDeletePatientClick = (): void => {
    deletePatient.mutate(patientToDelete);
  };

  const patientsList = useMemo(
    (): JSX.Element[] =>
      clientPatients.map((item) => (
        <Option key={`patient_${item.id}`} value={item.id}>
          <div className='patient-select-mobile-list-option'>
            <div className='patient-select-mobile-list-option__name'>{item.name}</div>
            <div className='patient-select-mobile-list-option__icons'>
              {getPatientCheckedIcon(item) && (
                <img src={checkedIcon} className='patient-select-mobile-list-option__mark-icon' />
              )}
              {showDeletePatientIcon && !disabled && (
                <img
                  className='patient-select-mobile-list-option__delete-icon delete-patient-icon'
                  src={closeIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    triggerDeleteModal(item.id);
                  }}
                />
              )}
            </div>
          </div>
        </Option>
      )),
    [clientPatients]
  );

  return (
    <>
      <Col className='client-details__mobile-pets-list'>
        <Select
          className='patient-select-mobile-list'
          value={selectedPatientId}
          filterOption={false}
          onChange={setSelectedPatient}
          disabled={clientPatients.length <= 1}
        >
          {patientsList}
        </Select>
        <Button
          type='primary'
          onClick={addNewPetAction}
          className='icofont icofont-plus'
          style={{ borderRadius: 30, width: 40, marginLeft: 10, left: 5, right: 10 }}
        ></Button>
      </Col>
      <Col
        span={24}
        lg={lgRowWidth || 10}
        className='client-details__list-container'
        id='client-pets-list'
      >
        <List className='client-details__list'>
          {clientPatients.map((el: any) => (
            <List.Item
              style={{ backgroundColor: el.id === selectedPatientId ? '#ececec' : 'transparent' }}
              onClick={() => setSelectedPatient(el.id)}
              key={el.id}
              className='client-details__list-item'
            >
              <Row>
                <AvatarView
                  size={44}
                  style={{ marginRight: 15 }}
                  src={el?.avatar ? el?.avatar : getDefaultAvatar(el?.species)}
                />
                <Col>
                  <Typography id='list-pet-name' style={{ fontSize: 16, fontWeight: 'bold' }}>
                    {el?.name}
                  </Typography>
                  <Row>
                    {el?.species && (
                      <Typography id='list-pet-species'>
                        {localizedPetData(el, localeCode, true).species}
                      </Typography>
                    )}
                    <Typography>{el?.species && el?.birth_date && ',\u00A0'}</Typography>
                    {el?.date_of_death ? (
                      <Typography id='patient-death-icon'>
                        <Tooltip
                          trigger={'hover'}
                          placement='topLeft'
                          title={getAnimalAge(patientLocale, el?.birth_date, el?.date_of_death)}
                        >
                          {'\u00A0'}
                          <img src={tombIcon} style={{ width: 22, height: 22 }} />
                        </Tooltip>
                      </Typography>
                    ) : (
                      <Typography id='list-pet-age'>
                        {getAnimalAge(locale, el?.birth_date)}
                      </Typography>
                    )}
                  </Row>
                </Col>
              </Row>
              {getPatientCheckedIcon(el) && (
                <img src={checkedIcon} style={{ width: 32, height: 32, paddingRight: 10 }} />
              )}
              {showDeletePatientIcon && !disabled && (
                <img
                  className='delete-patient-icon'
                  src={closeIcon}
                  onClick={() => triggerDeleteModal(el.id)}
                />
              )}
            </List.Item>
          ))}
        </List>
        <Row className='client-details__list__add-pet-btn'>
          {!disabled && (
            <Button
              id='add-pet'
              type='primary'
              onClick={addNewPetAction}
              className='icofont icofont-plus'
              style={{ borderRadius: 30, fontWeight: 'bold' }}
            >
              {locale.buttons.addNewPet}
            </Button>
          )}
        </Row>
        <ConfirmDeleteModal
          type={'device'}
          open={showDeletePatientModal}
          onOk={onConfirmDeletePatientClick}
          customTitle={locale.labels.confirmUnassignPatientFromAppointment}
          onCancel={() => {
            triggerDeleteModal(null);
          }}
        />
      </Col>
    </>
  );
};
