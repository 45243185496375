import './scss/StockList.scss';

import { Button, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useStockList } from 'api/hooks/stock/useStockList';
import { useStockPurchases } from 'api/hooks/stock/useStockPurchases';
import { IStock, IStockPurchase } from 'api/interfaces/Stock';
import { MODAL, useModal } from 'api/store/modalStore';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { StatusTag } from 'components/StatusTag';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { stockStatuses as stockStatusesWithFilters } from 'constants/dictionary/default/selectOptions';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { stockStatuses } from 'i18n/stockStatuses';
import { PrintPricesModal } from 'layout/modals/printPrices/PrintPricesModal';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getClinicStock } from 'services/clinic/inventory/stockService';

import getExpirationStatus from './helpers/ExpirationTagSelector';
import { CreateStockItemModal } from './modals/CreateStockItemModal';
import { StockListPriceItem } from './StockListPriceItem';
import { usePlural } from './utils/usePlural';

const getExpirationDate = (item: IStock): '-' | JSX.Element =>
  item.expiration_date ? getExpirationStatus(item.expiration_date) : '-';

const defaultParams = {
  page: 1,
  sort_key: 'name',
  sort_order: 'asc'
};

const StockList = (): JSX.Element => {
  const clinicId = useClinicId();
  const { accesses } = useAccess();
  const navigate = useNavigate();

  const { open, isOpened } = useModal();
  const locale = useLocale('private.inventory.stock');
  const currencies = useLocale('private.currencies').labels;
  const { unitFormat } = usePlural();
  const statuses = useI18n(stockStatuses);
  const localizedStockStatuses = useLocalizedList(stockStatusesWithFilters);
  const [searchParams] = useSearchParams();
  const filterParams = searchParams.get('filter');
  const statusesParams = searchParams.get('statuses');

  const [allStockItems, setAllStockItems] = useState([]);

  const [expandedId, setExpandedId] = useState<number>(null);
  const { stockItems: expandedData, isLoading: expandedLoading } = useStockPurchases(
    clinicId,
    expandedId
  );
  const { data, stocks, isLoading, params, updateParams, updateSorting } = useStockList(
    clinicId,
    defaultParams
  );

  const redirectToStockDetails = (record: IStock): { onClick: () => void } =>
    accesses.stock.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/stock/${record.id}`);
      }
    };

  const stockQuantity = (e: IStock): string => {
    const unitsResidual = `${e.remaining_capacity} ${unitFormat(
      e.remaining_capacity,
      e.unit_of_measurement
    )}`;
    if (e.packaging_format === 'none') return e.remaining_capacity ? unitsResidual : '-';
    return e.remaining_capacity ? `${e.quantity} (${unitsResidual})` : '-';
  };

  const stockPrices = (e: IStock): '-' | JSX.Element => {
    if (!e?.price_per_unit) return '-';
    return <StockListPriceItem item={e} />;
  };

  const columns = useMemo(
    (): TableColumnProps<IStock>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.name || '-'
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.manufacturer ? ListFieldWithTooltip(e.manufacturer, 15) : '-')
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => <StatusTag status={e.status} />
      },
      {
        key: 'sku',
        title: locale.labels.sku,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.sku || '-'
      },
      {
        key: 'barcode',
        title: locale.labels.barcode,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.barcode || '-'
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: stockQuantity
      },
      {
        key: 'prices',
        title: locale.labels.priceFor,
        sorter: () => null,
        render: stockPrices
      }
      // {
      //   key: 'type',
      //   title: locale.labels.stockType,
      //   sorter: () => null,
      //   onCell: redirectToStockDetails,
      //   render: (e) => (e.type ? ListFieldWithTooltip(e.type, 15) : '-')
      // },
      // {
      //   key: 'category',
      //   title: locale.labels.category,
      //   sorter: () => null,
      //   onCell: redirectToStockDetails,
      //   render: (e) => (e.category ? ListFieldWithTooltip(e.category, 15) : '-')
      // },
      // {
      //   key: 'sub_category',
      //   title: locale.labels.subCategory,
      //   sorter: () => null,
      //   onCell: redirectToStockDetails,
      //   render: (e) => (e.sub_category ? ListFieldWithTooltip(e.sub_category, 15) : '-')
      // }
    ],
    [data]
  );

  const expandedColumns = useMemo(
    (): TableColumnProps<IStockPurchase>[] => [
      {
        key: 'invoice_number',
        title: locale.labels.invoiceNumber,
        render: (e) =>
          e.invoice_id
            ? ListFieldWithTooltip(
                <Link
                  className={'custom-links'}
                  to={`/clinic/${clinicId}/inventory/invoices/${e.invoice_id}`}
                >
                  {e.invoice_number}
                </Link>
              )
            : '-'
      },
      {
        key: 'purchase_date',
        title: locale.labels.purchaseDate,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series || '-'
      },
      {
        key: 'expiration_date',
        title: locale.labels.expirationDate,
        render: getExpirationDate
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        render: (e) => e.quantity || '-'
      },
      {
        key: 'purchase_price',
        title: locale.labels.purchasePrice,
        render: (e) =>
          `${e.price_per_unit ? `${Number(e.price_per_unit).toFixed(2)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.price,
        render: (e) =>
          `${e.selling_price ? `${Number(e.selling_price).toFixed(2)} ${currencies.uah}` : '-'}`
      }
    ],
    [expandedData]
  );

  const expandedRowRender = (): JSX.Element => (
    <Table
      loading={expandedLoading}
      columns={expandedColumns}
      dataSource={expandedData}
      pagination={false}
      size='small'
      className='stock-items-table'
      bordered
    />
  );

  const printPricesClick = (): void => {
    open(MODAL.printPricesModal);
    if (filterParams || statusesParams) {
      const searchArray = [];
      if (filterParams !== null) {
        const criteria = filterParams.split(' ');
        criteria
          .filter((c) => c !== '')
          .forEach((c) => {
            if (c && c !== '') {
              searchArray.push({
                name: 'composite',
                value: c,
                isArrayValue: true
              });
            }
          });
      }
      if (statuses) {
        const statusesList = statusesParams.split(',');
        statusesList?.forEach((criteria) => {
          if (criteria) {
            searchArray.push({
              name: 'status',
              value: criteria,
              isArrayValue: true
            });
          }
        });
      }

      getClinicStock(clinicId, 0, searchArray).then((res) => {
        const filteredData = res.data.filter((el) => el?.prices?.length > 0);
        setAllStockItems(filteredData);
      });
    }
  };

  return (
    <>
      <VLXSearchNEW
        onFilterChange={updateParams}
        selectOptions={localizedStockStatuses}
        selectPlaceholder={locale.labels.filterByStatus}
      >
        <Button
          type='primary'
          style={{ borderRadius: 20, fontWeight: 600 }}
          onClick={printPricesClick}
        >
          <span
            className='icofont icofont-print mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
          {locale.buttons.printPrices}
        </Button>
      </VLXSearchNEW>
      <Table
        pagination={false}
        className='accent-header stock-list'
        rowKey='id'
        loading={isLoading}
        dataSource={stocks}
        onChange={updateSorting}
        columns={columns}
        expandable={{
          onExpand: (expanded: boolean, record: IStock) => {
            setExpandedId(expanded ? record.id : null);
          },
          expandedRowKeys: [expandedId],
          expandedRowRender
        }}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        {accesses.stock.create && (
          <Row className='stock-list-buttons-container'>
            <StickyButton
              offsetBottom={10}
              onClick={() => open(MODAL.createStockItemModal)}
              iconElement={
                // TODO API REPLACE
                <span
                  className='icofont icofont-plus mr-2 sticky-btn-icon'
                  style={{ fontSize: '1.3em' }}
                />
              }
              textElement={locale.buttons.create}
            />
          </Row>
        )}
      </Row>
      {isOpened(MODAL.createStockItemModal) && <CreateStockItemModal />}
      {isOpened(MODAL.printPricesModal) && (
        <PrintPricesModal stock={allStockItems} multipleStocksMode />
      )}
    </>
  );
};

export default StockList;
