import { IStock } from 'api/interfaces/Stock';
import { useCallback } from 'react';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { usePlural } from './usePlural';

type IResidual = {
  getResidual: (residual) => string;
  unitOfMeasurement: string;
  packagingFormat: string;
  noPackageFormat: boolean;
};

export const useGetResidual = (selectedStock: IStock | ISelectedAuditItem): IResidual => {
  const { unitFormat, packageFormat } = usePlural(selectedStock);
  const noPackageFormat = selectedStock?.packaging_format === 'none';

  const getResidual = useCallback(
    (residual: number): string => {
      const { quantity_per_package } = selectedStock || {};

      const packagesResidual = Math.floor(residual / quantity_per_package) || 0;
      const unitsResidual = (residual % quantity_per_package || 0).toFixed(2);

      if (noPackageFormat) {
        return `${residual} ${unitFormat()}`;
      }

      if ((unitsResidual && !packagesResidual) || !residual) {
        return `${unitsResidual} ${unitFormat(parseFloat(unitsResidual))}`;
      }

      if (packagesResidual && !unitsResidual) {
        return `${packagesResidual} ${packageFormat(packagesResidual)}`;
      }

      return `${packagesResidual} ${packageFormat(packagesResidual)} ${unitsResidual} ${unitFormat(
        parseFloat(unitsResidual)
      )}`;
    },
    [selectedStock]
  );

  return {
    getResidual,
    unitOfMeasurement: unitFormat(),
    packagingFormat: packageFormat(),
    noPackageFormat
  };
};
