import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAppointmentDetails } from 'api/interfaces/Appointment';

export const useAppointmentDetails = (appointment: {
  appointment_id: number;
  clinic_id: number;
}) => {
  const { appointment_id, clinic_id } = appointment || {};
  const endpoint = `${API_ENDPOINTS.APPOINTMENTS(clinic_id)}/${appointment_id}`;

  const queryFn = async () => {
    const response = await axiosClient.get<IAppointmentDetails>(endpoint);
    return response.data;
  };

  return useQuery<IAppointmentDetails>({
    queryFn,
    queryKey: ['appointment', clinic_id, appointment_id],
    enabled: !!clinic_id && !!appointment_id
  });
};
