import { Form } from 'antd';
import { IHealthRecordService, IStock } from 'api/interfaces/Patient';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import React, { useEffect, useState } from 'react';

import { AppointmentServicesFormContainer } from './AppointmentServicesFormContainer';

type Props = {
  healthRecordId: number;
  disabled: boolean;
  serviceData: IHealthRecordService;
  servicesStockStatus: { id: number; status: boolean }[];
  setServicesStockStatus: (statuses) => void;
};

export const AppointmentServicesContainer = ({
  healthRecordId,
  disabled,
  serviceData,
  servicesStockStatus,
  setServicesStockStatus
}: Props): JSX.Element => {
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [formTouched, setFormTouched] = useState<boolean>(!serviceData?.stock_saved);
  const [addedItems, setAddedItems] = useState<number[]>(
    serviceData?.stock?.length ? serviceData?.stock.map((_, index) => index) : []
  );
  const [initialServices, setInitialServices] = useState<IStock[]>(serviceData?.stock);

  useEffect((): void => {
    const newStatusesArray = servicesStockStatus.map((el) =>
      el.id === serviceData?.id
        ? {
            id: el.id,
            status: serviceData?.stock_saved && !formTouched
          }
        : el
    );

    setServicesStockStatus(newStatusesArray);
    setEditMode(formTouched);
  }, [formTouched]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setShowCancelModal(false);
    setFormTouched(false);
    setEditMode(false);
    setInitialServices(serviceData?.stock);
    setAddedItems(serviceData?.stock?.length ? serviceData?.stock.map((_, index) => index) : []);
    form.setFieldsValue({ stock: serviceData?.stock });
  };

  const onFieldsChange = (): void => {
    setFormTouched(true);
    setEditMode(true);
  };

  const onSaveModalClick = (): void => {
    if (!form.getFieldsError().some((el) => el.errors.length > 0)) {
      setEditMode(false);
      setFormTouched(false);
    }
  };

  return (
    <>
      <AppointmentServicesFormContainer
        form={form}
        disabled={disabled}
        serviceData={serviceData}
        healthRecordId={healthRecordId}
        onFieldsChange={onFieldsChange}
        setFormTouched={setFormTouched}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        initialServices={initialServices}
        setInitialServices={setInitialServices}
      >
        {!disabled && (
          <ViewDetailsButtonsGroup
            key={serviceData?.id}
            fixed
            hideDeleteBtn
            onSaveModalClick={onSaveModalClick}
            editMode={editMode}
            onEditClick={() => setEditMode(true)}
            accessGroup={userAccess.accesses.appointments}
            onCancelClick={() => {
              if (formTouched) {
                setShowCancelModal(true);
              } else {
                setEditMode(false);
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        )}
      </AppointmentServicesFormContainer>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
