import { Form } from 'antd';
import { IProvider } from 'api/interfaces/Provider';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddProviderForm } from './AddProviderForm';

interface Props {
  callBackFunction?: (data: IProvider) => void;
  defaultProviderName?: string;
}

export const AddProviderModal = ({ callBackFunction, defaultProviderName }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const locale = useLocale('private.inventory.providers');
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.createProvider}
        centered
        form={form}
        open
        onModalClose={closeModal}
        width={800}
      >
        <AddProviderForm
          form={form}
          defaultProviderName={defaultProviderName}
          callBackFunction={callBackFunction}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.providers}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </AddProviderForm>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={closeModal}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
