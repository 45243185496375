import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRoomPayload } from 'api/interfaces/Room';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditRoom = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation(
    ({ payload, id }: { payload: IRoomPayload; id: number }) =>
      axiosClient.put(`${API_ENDPOINTS.ROOMS(clinicId)}/${id}`, payload),
    ['roomsList'],
    callbackFn
  );
};
