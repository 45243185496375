import './AddBill.scss';

import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { DICTIONARY } from 'api/constants/dictionary';
import { useCreateBill } from 'api/hooks/bills/useCreateBill';
import { useEditBill } from 'api/hooks/bills/useEditBill';
import { useDictionary } from 'api/hooks/dictionaries/useDictionary';
import { IBillDetails } from 'api/interfaces/Bills';
import { MODAL, useModal } from 'api/store/modalStore';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import { billType, yesNoBooleanOptions } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';
import { requiredRule, validatorRule } from 'utils/form-rules';
import { getOptions } from 'utils/get-options';

import { AddDictionaryModal } from '../../addDictionary/AddDictionaryModal';
import { DATE_TIME_VIEW_FORMAT } from 'constants/common.tsx';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues?: IBillDetails;
  formEditMode?: boolean;
  locale: ILocale;
  setFormIsTouched?: (touched) => void;
}>;

export const BillItemForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  locale,
  setFormIsTouched
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal, open } = useModal();
  const yesNoBooleanLocalizedOptions = useLocalizedList(yesNoBooleanOptions);

  const { dictionary: billPaymentTypes } = useDictionary(clinicId, DICTIONARY.billCategory);
  const billCategories = getOptions(billPaymentTypes);

  const editBill = useEditBill(clinicId, initialValues?.id, closeModal);
  const createBill = useCreateBill(clinicId, closeModal);

  const [billPrice, setBillPrice] = useState(initialValues?.amount || null);
  const [billCategorySearchValue, setBillCategoryearchValue] = useState('');

  const onDiscountPercantageChange = (value: string): void => {
    form.setFields([{ name: ['stock_discount', 'amount'], errors: [] }]);

    if (value === null) {
      form.setFieldValue(['stock_discount', 'amount'], null);
      form.setFieldValue(['final_amount'], form.getFieldValue('amount'));
      return;
    }

    const summ = Number(form.getFieldValue('amount'));
    const res = summ * Number(value) * 0.01;
    form.setFieldValue(['stock_discount', 'amount'], res.toFixed(2));
    form.setFieldValue(['final_amount'], (summ - res).toFixed(2));
  };

  const onDiscountAmountChange = (value: number): void => {
    form.setFields([{ name: ['stock_discount', 'percentage'], errors: [] }]);

    if (value === null) {
      form.setFieldValue(['stock_discount', 'percentage'], null);
      form.setFieldValue(['final_amount'], form.getFieldValue('amount'));
      return;
    }

    const summ = Number(form.getFieldValue('amount'));
    const res = (value / summ) * 100;
    form.setFieldValue(['stock_discount', 'percentage'], res.toFixed(0));
    form.setFieldValue(['final_amount'], (summ - value).toFixed(2));
  };

  const onAmountChange = (value: string): void => {
    form.setFieldValue(['stock_discount', 'amount'], null);
    form.setFieldValue(['stock_discount', 'percentage'], null);
    form.setFieldValue(['final_amount'], Number(value).toFixed(2));
    form.setFields([
      { name: ['stock_discount', 'amount'], errors: [] },
      { name: ['stock_discount', 'percentage'], errors: [] }
    ]);
  };

  const onSubmit = (data: IBillDetails): void => {
    const payload = {
      ...data,
      clinic_id: clinicId,
      services_discount: {
        amount: null,
        percentage: null
      },
      stock_discount: {
        amount: Number(data.stock_discount.amount || 0).toFixed(2),
        percentage: data.stock_discount.percentage || 0
      },
      amount: Number(data.final_amount || 0).toFixed(2),
      client_id: initialValues?.client?.id
    };

    if (data.date) {
      payload.date = dayjs(data.date).format('DD.MM.YYYYThh:mm:ss');
    }

    if (formEditMode) {
      editBill.mutate(payload);
    } else {
      createBill.mutate(payload);
    }
  };

  const filterBillCategoryOption = (input: string, option) =>
    option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={{
        ...initialValues,
        date: dayjs(initialValues?.date || undefined)
      }}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.name} name={'name'} rules={[requiredRule()]}>
            <Input />
          </Form.Item>
          <Form.Item label={locale.labels.amount} rules={[requiredRule()]} name='amount'>
            <VLXInputNumber
              disabled={formEditMode}
              onChange={(value) => {
                form.setFieldsValue({ amount: value });
                setBillPrice(value);
                onAmountChange(value);
              }}
            />
          </Form.Item>
          <Form.Item rules={[requiredRule()]} name='vat_included' label={locale.labels.vatIncluded}>
            <Select filterOption={false} options={useLocalizedList(yesNoBooleanLocalizedOptions)} />
          </Form.Item>
          <Form.Item
            name='bill_type'
            hidden={formEditMode}
            rules={[requiredRule()]}
            label={locale.labels.billType}
          >
            <Select
              disabled={formEditMode}
              filterOption={false}
              options={useLocalizedList(billType)}
            />
          </Form.Item>
          <Form.Item name='bill_payment_type_id' label={locale.labels.category}>
            <Select
              showSearch
              onSearch={(val) => setBillCategoryearchValue(val)}
              filterOption={filterBillCategoryOption}
              notFoundContent={
                <div onClick={() => open(MODAL.addDictionary)} id='addNewServiceGroupBtn'>
                  + {locale.labels.addBillCategory}
                </div>
              }
            >
              {billCategories}
            </Select>
          </Form.Item>
          {/* <Form.Item
            className={'fiscal-checkbox-form-item'}
            name='fiscal'
            label={locale.labels.fiscal}
            valuePropName='checked'
            initialValue={initialValues?.fiscal || false}
          >
            <Checkbox />
          </Form.Item> */}
        </Col>
        <Col span={24} md={12}>
          <Row>
            <Col span={24} md={12} style={{ padding: '0 8px' }}>
              <Form.Item
                label={locale.labels.percentage}
                name={['stock_discount', 'percentage']}
                rules={[validatorRule((value) => value <= 100, locale.errors.percentageMax100)]}
              >
                <VLXInputNumber
                  disabled={
                    !billPrice ||
                    initialValues?.stock_discount?.percentage === 100 ||
                    ['paid', 'partially_paid'].includes(initialValues?.payment_status)
                  }
                  max={100}
                  precision={0}
                  onChange={onDiscountPercantageChange}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12} style={{ padding: '0 8px' }}>
              <Form.Item
                label={locale.labels.discount}
                name={['stock_discount', 'amount']}
                rules={[
                  validatorRule(
                    (value) => Number(value) <= Number(billPrice),
                    `${locale.errors.discountCannotBeMoreThan} (${billPrice})`
                  )
                ]}
              >
                <VLXInputNumber
                  disabled={
                    !billPrice ||
                    initialValues?.stock_discount?.percentage === 100 ||
                    ['paid', 'partially_paid'].includes(initialValues?.payment_status)
                  }
                  max={Number(billPrice)}
                  onChange={onDiscountAmountChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={'date'} label={locale.labels.date}>
            <DatePicker showTime format={DATE_TIME_VIEW_FORMAT} />
          </Form.Item>
          <Form.Item label={locale.labels.finalAmount} name={'final_amount'}>
            <VLXInputNumber disabled />
          </Form.Item>
          <TextEditor label={locale.labels.description} name={['description']} rows={2} />
        </Col>
      </Row>
      {children}
      <AddDictionaryModal
        defaultDictionaryNameValue={billCategorySearchValue}
        title={locale.labels.addBillCategory}
        dictionaryKey={DICTIONARY.billCategory}
        onSuccess={(id: number) => {
          form.setFieldValue('bill_payment_type_id', id);
          if (formEditMode) {
            setFormIsTouched(true);
          }
        }}
      />
    </Form>
  );
};
