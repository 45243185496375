import { Form, Modal } from 'antd';
import { useCreateSale } from 'api/hooks/sales/useCreateSale';
import { useEditSale } from 'api/hooks/sales/useEditSale';
import { IClient } from 'api/interfaces/Client';
import { ISaleDetails, ISaleStock } from 'api/interfaces/Sale';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formPayloadItems } from '../utils/formPayloadItems';
import { SaleItemForm } from './SaleItemForm';

type Props = {
  locale: ILocale;
  itemToEdit?: { item: ISaleStock; index: number };
  saleDetails: ISaleDetails;
  selectedClient: IClient;
  selectedEmployee?: number;
  setFieldsTouched: (val: boolean) => void;
};
export const SaleItemModal = ({
  locale,
  itemToEdit,
  saleDetails,
  selectedClient,
  setFieldsTouched,
  selectedEmployee
}: Props): JSX.Element => {
  const clinicId = useClinicId();

  const [form] = Form.useForm();
  const { closeModal } = useModal();

  const navigate = useNavigate();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onCreate = ({ data }: { data: ISaleDetails }): void => {
    if (data?.bill_id) {
      navigate(`/clinic/${clinicId}/shop-farmacy/${data.id}`);
    }
    closeModal();
  };

  const createSale = useCreateSale(clinicId, onCreate);
  const editSale = useEditSale(clinicId, saleDetails, closeModal);

  useEffect(() => {
    form.resetFields();
  }, [saleDetails]);

  const onSubmit = (data: ISaleStock): void => {
    const items = (saleDetails?.stock || []).map((item, index: number) =>
      formPayloadItems(index === itemToEdit?.index ? data : item)
    );

    const payload = {
      client_id: selectedClient?.id || null,
      employee_id: selectedEmployee || null,
      vat_included: false,
      items: itemToEdit ? items : items.concat(formPayloadItems(data)),
      fiscal: true,
      discount_percentage: saleDetails?.stock_discount.percentage || 0
    };

    if (saleDetails?.id) {
      editSale.mutate(payload);
    } else {
      createSale.mutate(payload);
    }
    form.resetFields();
  };

  const onCancel = (): void => {
    if (form.isFieldsTouched()) {
      setShowCancelModal(true);
    } else {
      closeModal();
    }
  };

  const FormContent = useCallback(
    () => (
      <Form
        key={'SaleItemForm'}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={itemToEdit?.item}
        className='sale-form'
      >
        <SaleItemForm
          locale={locale}
          form={form}
          itemToEdit={itemToEdit?.item}
          onCancel={onCancel}
          setFieldsTouched={setFieldsTouched}
        ></SaleItemForm>
      </Form>
    ),
    [saleDetails, selectedClient, selectedEmployee]
  );

  const ModalWithForm = useCallback(
    () => (
      <Modal
        forceRender
        zIndex={1000}
        title={locale.labels.editStock}
        centered
        open
        footer={null}
        onCancel={onCancel}
        width={800}
        destroyOnClose
      >
        <FormContent />
      </Modal>
    ),
    [saleDetails, selectedClient, selectedEmployee]
  );

  return (
    <>
      {itemToEdit ? (
        <ModalWithForm />
      ) : (
        <>
          <h5 style={{ fontWeight: 600, fontSize: 16 }}>{locale.labels.addStock}</h5>
          <FormContent />
        </>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={() => {
          closeModal();
          setShowCancelModal(false);
          form.resetFields();
        }}
        cancelText={itemToEdit ? null : locale.messages.clearSalesForm}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
