import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IHealthRecord } from 'api/interfaces/Patient';

export const usePatientHealthRecords = (clinicId: number, patientId: number) => {
  return useQuery({
    queryKey: ['patientHealthRecords', clinicId, patientId],
    queryFn: async (): Promise<IHealthRecord[]> => {
      const { data } = await axiosClient.get(
        `${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}/health_records?sort_key=created_at&sort_order=desc`
      );
      return data.data;
    }
  });
};
