import { IClinic } from 'api/interfaces/Clinic';
import { IEmployee } from 'api/interfaces/Employee';
import { IUserAccesses } from 'api/store/userAccesses';

export const resourcesFilter = (
  filteredEmployees: IEmployee[],
  selectedEmployees: number[],
  selectedRooms: number[],
  userId: number
): IEmployee[] => {
  const uniqueResources = filteredEmployees.reduce((acc, employee) => {
    const { id } = employee;
    if (
      !acc[id] &&
      (!selectedEmployees.length || selectedEmployees.find((selectedIds) => selectedIds === id))
    ) {
      acc[id] = employee;
    }
    return acc;
  }, {});

  const resourcesArray = Object.values(uniqueResources) as IEmployee[];

  return resourcesArray.sort((a) => (a.id > userId ? 1 : -1));
};

export const clinicResourcesFilter = (
  filteredClinics: (IUserAccesses & {
    color: string;
  })[],
  selectedClinics: number[]
): IClinic[] => {
  const uniqueResources = filteredClinics.reduce((acc, clinic) => {
    const { clinicId } = clinic;
    if (!acc[clinicId] && selectedClinics.find((selectedIds) => selectedIds === clinicId)) {
      acc[clinicId] = { ...clinic, id: clinicId };
    }
    return acc;
  }, {});

  return Object.values(uniqueResources) as IClinic[];
};
