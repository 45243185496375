import './scss/ViewEditInvoice.scss';

import { Form } from 'antd';
import { useEditInvoice } from 'api/hooks/invoices/useEditInvoice';
import { IInvoice, IInvoicePayload } from 'api/interfaces/Invoice';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { InvoiceForm } from './components/InvoiceForm';

dayjs.extend(customParseFormat);

type Props = React.PropsWithChildren<{
  locale: ILocale;
  invoice: IInvoice;
}>;

export const EditInvoiceModal = ({ locale, invoice }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { closeModal } = useModal();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const editInvoice = useEditInvoice(clinicId, invoice?.id, closeModal);

  const [editMode, setEditMode] = useState<boolean>(true);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSubmit = (payload: IInvoicePayload): void => {
    payload.purchase_date = dayjs(payload.purchase_date || undefined).format('DD.MM.YYYY');

    if (form.isFieldsTouched()) {
      editInvoice.mutate(payload);
    }
  };

  const onFieldsChange = (e): void => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editInvoice}
        form={form}
        centered
        open
        onModalClose={closeModal}
        width={800}
      >
        <InvoiceForm
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
          form={form}
          initialValues={{
            ...invoice,
            purchase_date: dayjs(invoice?.purchase_date) as dayjs.Dayjs,
            provider_id: invoice?.provider?.id
          }}
        >
          <ViewDetailsButtonsGroup
            fixed
            hideDeleteBtn
            accessGroup={userAccess.accesses.invoices}
            editMode={editMode}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onEditClick={() => setEditMode(true)}
          />
        </InvoiceForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => {
          setShowCancelModal(false);
          setEditMode(true);
        }}
      />
    </>
  );
};
