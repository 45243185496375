import { Line } from '@ant-design/charts';
import { IAllShiftsProfit } from 'api/interfaces/Stats';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  all_shifts_profit?: IAllShiftsProfit[];
  locale: ILocale;
};

export const ALLShiftsInOutcomeLineChart = ({ all_shifts_profit, locale }: Props): JSX.Element => {
  const sortedData: IAllShiftsProfit[] = (all_shifts_profit || []).sort(
    (a: IAllShiftsProfit, b: IAllShiftsProfit): number => {
      if (a.payment_type === 'income' && b.payment_type !== 'income') {
        return -1;
      }
      if (a.payment_type !== 'income' && b.payment_type === 'income') {
        return 1;
      }
      return 0;
    }
  );

  const colorRange = (): string[] => {
    const hasIncome = all_shifts_profit?.some(
      (item: IAllShiftsProfit): boolean => item.payment_type === 'income'
    );
    const hasOutcome = all_shifts_profit?.some(
      (item: IAllShiftsProfit): boolean => item.payment_type === 'outcome'
    );

    if (hasIncome && hasOutcome) {
      return ['#8ccc90', '#d66145'];
    } else if (hasIncome) {
      return ['#8ccc90'];
    } else if (hasOutcome) {
      return ['#d66145'];
    }
    return [];
  };

  return (
    <Line
      data={sortedData || []}
      xField='shift_opened_at'
      yField='amount'
      sizeField='amount'
      legend={{ size: false }}
      colorField='type'
      scale={{ color: { range: colorRange() } }}
      label={{
        text: 'label',
        style: {
          fontSize: 12,
          fontWeight: 'bold'
        }
      }}
    />
  );
};
