import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteRoom = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = ({ roomId }: { roomId: number }) =>
    axiosClient.delete(`${API_ENDPOINTS.ROOMS(clinicId)}/${roomId}`);

  return useCustomMutation(mutationFn, ['roomsList'], callbackFn);
};
