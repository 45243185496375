import { ISalePayloadItem, ISaleStock } from 'api/interfaces/Sale';

export const formPayloadItems = (item: ISaleStock): ISalePayloadItem => ({
  stock_id: item.id,
  location_id: item.stock_location.id,
  price_id: item.stock_price.id,
  quantity_in_packages: item.quantity_in_packages || 0,
  quantity_in_units: item.quantity_in_units || 0,
  price: Number(item.price).toFixed(2)
});
