import './Bills.scss';

import { Form, Row } from 'antd';
import { useBillDetails } from 'api/hooks/bills/useBillDetails';
import { useDeleteBill } from 'api/hooks/bills/useDeleteBill';
import { IBillDetails } from 'api/interfaces/Bills';
import { MODAL, useModal } from 'api/store/modalStore';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { PageInitialLoader } from 'components/PageInitialLoader';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useBillId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import { AppointmentBill } from 'layout/modals/appointmentBill/AppointmentBill';
import { EditBillModal } from 'layout/modals/finances/addBill/EditBillModal';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import { BillInvoicePurchasesTable } from 'pages/inventory/invoices/BillInvoicePurchasesTable';
import React, { ReactNode, useState } from 'react';

import { BillTransactions } from './BillTransactions';
import { BillDetailsHeader } from './header/BillDetailsHeader';
import { BillPrintButton } from './print/BillPrintButton';
import BillsDetailsSalesSection from './print/BillsDetailsSalesSection';

const BillsDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const billId = useBillId();
  const userAccess = useAccess();

  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;
  const { open, isOpened } = useModal();

  const { data: bill, isPending } = useBillDetails(clinicId, billId);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteBill = useDeleteBill(clinicId, billId, () => setShowDeleteModal(false));

  const [editBillForm] = Form.useForm();
  const [viewBillForm] = Form.useForm();

  const renderBillDetailsByBillType = ({
    appointment,
    sale,
    invoice,
    client
  }: IBillDetails): ReactNode => {
    if (appointment) {
      return (
        <div className='bill-appointment-info-block'>
          <AppointmentBill form={viewBillForm} billDetailsPage bill={bill} />
        </div>
      );
    }
    if (sale) {
      return <BillsDetailsSalesSection form={editBillForm} initialValues={bill} client={client} />;
    }
    if (invoice) {
      return (
        <>
          <h4>{locale.labels.stock}</h4>
          <BillInvoicePurchasesTable data={bill.invoice.stock} />
        </>
      );
    }
  };

  return (
    <>
      <PageInitialLoader loading={isPending} />
      {!!bill && <BillDetailsHeader bill={bill} locale={locale} currency={currencies.uah} />}

      {!bill?.deleted && (
        <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div
            style={{
              visibility: !isOpened(MODAL.editBillModal) ? 'visible' : 'hidden',
              padding: 10
            }}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.services}
              hideCancelBtn
              fixed
              editMode={false}
              onEditClick={() => open(MODAL.editBillModal)}
              hideDeleteBtn={!bill?.can_be_destroyed}
              onDeleteClick={() => setShowDeleteModal(true)}
            />
          </div>
          <BillPrintButton bill={bill} />
        </Row>
      )}

      {!!bill && renderBillDetailsByBillType(bill)}

      <BillTransactions locale={locale} currency={currencies.uah}>
        {bill?.payment_status !== 'paid' && !bill?.deleted && <AddBillPayment bill={bill} />}
      </BillTransactions>

      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={deleteBill.mutate}
        onCancel={() => setShowDeleteModal(false)}
      />
      {isOpened(MODAL.editBillModal) && (
        <EditBillModal form={editBillForm} locale={locale} initialValues={bill} />
      )}
    </>
  );
};

export default BillsDetails;
