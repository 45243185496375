import './PatientDatails.scss';

import { Card, Col, Collapse, Menu, MenuProps, Row, Spin, Typography } from 'antd';
import { useUploadPatientAvatar } from 'api/hooks/avatar.ts/useUploadAvatar';
import { useClientDetails } from 'api/hooks/clients/useClientDetails';
import { usePatientDocuments } from 'api/hooks/documents/useDocuments';
import { useUploadPatientDocument } from 'api/hooks/documents/useUploadDocument';
import { usePatientDetails } from 'api/hooks/patients/usePatientDetails';
import { useLocaleCode } from 'api/store/localeContext';
import { AvatarComponent } from 'components/Avatar';
import DocumentsTab from 'components/DocumentsTab';
import { HealthRecordsHistory } from 'components/HealthRecordsHistory';
import { animalIdUrl } from 'constants/common';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import usePatientId from 'hooks/usePatientId';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { localizedPetData } from 'utils/localized-pet-data';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';
import { ClientDetailsPetsTab } from '../clients/ClientDetailsPetsTab';
import { aggressiveness } from '../clients/helpers/TagSelector';
import { PatientVaccinationDetails } from './PatientVaccinationDetails';
import { getAnimalAge, getDefaultAvatar } from './utils/PatientHelper';

const PatientDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const patientId = usePatientId();
  const { localeCode } = useLocaleCode();
  const locale = useLocale('private.patients.patient-page');
  const { accesses, availableModules } = useAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabSearchParam = searchParams.get('tab');

  const { data, isPending } = usePatientDetails(clinicId, patientId);
  const { data: client } = useClientDetails(clinicId, data?.primary_contact_id);

  const { data: patientDocuments } = usePatientDocuments(clinicId, patientId);
  const uploadDocument = useUploadPatientDocument(clinicId, patientId);
  const uploadAvatar = useUploadPatientAvatar(clinicId, patientId);
  
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);

  useEffect(() => { if (!activeTabSearchParam) setSearchParams({ tab: 'general' }); }, [activeTabSearchParam]);

  const changeTab: MenuProps['onClick'] = ({ key }: { key: string }): void => {
    setSearchParams({ tab: key });
  };

  const handleSetAvatar = (data, callbackFn): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    uploadAvatar.mutate(avatarData);
    setChangeAvatarModalVisible(false);
    callbackFn();
  };

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.general,
      key: 'general'
    },
    {
      label: locale.tabs.vaccinations,
      key: 'vaccinations'
    },
    {
      label: (
        <PremiumSubscriptionTooltip display={!availableModules.documents}>
          {locale.tabs.documents}
        </PremiumSubscriptionTooltip>
      ),
      key: 'documents',
      disabled: !availableModules.documents
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const BreedAndSpecies = useMemo((): JSX.Element => {
    if (!data?.species) return;

    const { species, breed } = localizedPetData(data, localeCode);
    return (
      <Row align='middle' className='patient-details__species-container'>
        <Typography.Text
          id='patient-species'
          className='patient-details__species-field'
          style={{ fontSize: 16 }}
        >
          {species}
        </Typography.Text>
        {!!breed && (
          <Typography.Text id='patient-breed' style={{ fontSize: 16 }}>
            ({breed})
          </Typography.Text>
        )}
      </Row>
    );
  }, [data]);

  return (
    <Spin spinning={isPending}>
      {!!data && (
        <Row className='patient-details'>
          <Col span={24} md={10} lg={7} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container'>
              <Row justify='center' style={{ marginTop: 10 }}>
                <AvatarComponent
                  loading={uploadAvatar.isPending}
                  editAccess={accesses.patients.edit}
                  src={data.avatar ? data.avatar : getDefaultAvatar(data.species)}
                  onImageChange={handleSetAvatar}
                  avatarTitle={locale.labels.addLogo}
                  setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                  changeAvatarModalVisible={changeAvatarModalVisible}
                />
              </Row>
              <Col>
                <Row align='middle' className='patient-details__name-container'>
                  <Typography id='patient-name' style={{ fontWeight: 'bold' }}>
                    {data.name}
                  </Typography>
                </Row>
                <Row align='middle' className='patient-details__species-container'>
                  <Typography.Text
                    id='patient-age'
                    className='patient-details__species-field'
                    style={{ fontSize: 16 }}
                  >
                    {getAnimalAge(locale, data.birth_date, data.death_date)}
                  </Typography.Text>
                </Row>
                {BreedAndSpecies}
                <Row
                  id='patient-aggressiveness'
                  align='middle'
                  className='patient-details__species-container'
                >
                  {!!data.aggressiveness && aggressiveness(data.aggressiveness, localeCode)}
                </Row>
                {!!data.chip_number && (
                  <Row align='middle' className='patient-details__species-container'>
                    <Typography.Text
                      id='patient-chip'
                      style={{
                        fontSize: 16,
                        marginTop: 7
                      }}
                    >
                      {locale.labels.chip}:{' '}
                      <Link className={'custom-links'} to={animalIdUrl + data.chip_number}>
                        {data.chip_number}
                      </Link>
                    </Typography.Text>
                  </Row>
                )}
                {data.private_notes && (
                  <Collapse
                    size={'small'}
                    style={{ backgroundColor: 'white', marginBottom: '10px' }}
                    items={[
                      {
                        key: 1,
                        label: (
                          <>
                            <span style={{ marginRight: '5px' }}>{locale.labels.specialNotes}</span>
                            <span
                              className='icofont-warning'
                              style={{
                                color: 'orange',
                                fontSize: '16px'
                              }}
                            ></span>
                          </>
                        ),
                        children: <p>{data?.private_notes}</p>
                      }
                    ]}
                  ></Collapse>
                )}
                <Row justify='center'>
                  <Typography.Title level={4}>{locale.labels.owner}</Typography.Title>
                </Row>
                <Row align='middle' className='m-b-10'>
                  <Col className='icon-style'>
                    <span className='icofont-user info-icon' />
                  </Col>
                  <Col>
                    <Row justify='center' style={{ alignItems: 'center' }}>
                      <Link
                        id='owner-name'
                        className={'custom-links'}
                        to={`/clinic/${clinicId}/clients/${data.primary_contact_id}`}
                        style={{ fontSize: 16 }}
                      >
                        {data.owner_name}
                      </Link>
                      {blacklistedStatus(client, 20)}
                    </Row>
                  </Col>
                </Row>

                <Row align='middle' className='m-b-10'>
                  <Col className='icon-style'>
                    <span className='icofont-ui-touch-phone info-icon' />
                  </Col>
                  <Col>
                    <Link
                      id='owner-phone'
                      to={`tel:${data.owner_country_code}${data.owner_phone_number}`}
                      className='d-flex align-baseline nowrap'
                      style={{ fontSize: 16 }}
                    >
                      {data.owner_phone_number
                        ? `+${data.owner_country_code}${data.owner_phone_number}`
                        : '-'}
                    </Link>
                  </Col>
                </Row>
                <Row align='middle' className='m-b-10 client-email-row'>
                  <Col className='icon-style'>
                    <span className='icofont-ui-message info-icon' />
                  </Col>
                  <Col id='client-email'>
                    {data.owner_email ? (
                      <Link
                        id='owner-email'
                        to={`mailto:${data.owner_email}`}
                        className='custom-links d-flex align-baseline nowrap'
                        style={{ fontSize: 16 }}
                      >
                        {data.owner_email}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                {data.additional_contacts?.length > 0 && (
                  <>
                    <Row justify='center'>
                      <Typography.Title level={4}>
                        {locale.labels.additionalContacts}
                      </Typography.Title>
                    </Row>
                    {JSON.parse(data.additional_contacts)?.map((contact, index) => (
                      <Row gutter={24} key={index} align='middle' style={{ paddingBottom: '10px' }}>
                        <Col span={4} className='icon-style'>
                          <span className='icofont-ui-touch-phone info-icon'></span>
                        </Col>
                        <Col span={20}>
                          <div style={{ fontSize: 15 }}>{contact?.description}</div>
                          <Link
                            id='additional_contacts_number'
                            to={`tel:${data?.owner_country_code}${contact?.phone_number}`}
                            style={{ fontSize: 15 }}
                          >
                            +{data?.owner_country_code}
                            {contact?.phone_number}
                          </Link>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Col>
            </Card>
          </Col>
          <Col span={24} md={14} lg={17} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow'>
              <Menu
                onClick={changeTab}
                selectedKeys={[activeTabSearchParam]}
                mode='horizontal'
                items={items}
                style={{ marginBottom: 20 }}
              />
              {activeTabSearchParam === 'general' && !!data && (
                <ClientDetailsPetsTab
                  accessGroup={accesses.patients.edit}
                  data={data}
                  hidePatientTopSection
                />
              )}
              {activeTabSearchParam === 'vaccinations' && (
                <PatientVaccinationDetails
                  locale={locale}
                  patientId={patientId}
                  clientId={data?.primary_contact_id}
                />
              )}
              {activeTabSearchParam === 'documents' && (
                <DocumentsTab uploadDocument={uploadDocument} data={patientDocuments} />
              )}
              {activeTabSearchParam === 'history' && (
                <HealthRecordsHistory clinicId={clinicId} patientId={data.id} />
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default PatientDetails;
