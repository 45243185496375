import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { ISkill, ISkillsQueryParams } from 'api/interfaces/Skills';

export const useSkills = (params: ISkillsQueryParams) => {
  const fetchSkills = async (): Promise<ISkill[]> => {
    const { data } = await axiosClient.get<ISkill[]>(API_ENDPOINTS.SKILLS, {
      params: {
        page: params.page || 1,
        ...(params.name ? { 'q[name]': params.name } : {})
      }
    });
    return data;
  };

  return useQuery<ISkill[]>({
    queryKey: ['skills', params],
    queryFn: fetchSkills,
    staleTime: 3000
  });
};
