import { Select } from 'antd';

const { Option } = Select;

interface ListItem {
  id: string | number;
  name: string;
}

export const getOptions = <T extends ListItem>(list: T[]): JSX.Element[] =>
  list.map((item) => (
    <Option key={item.id + item.name} title={item.name} value={item.id}>
      {item.name}
    </Option>
  ));
