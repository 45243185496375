import './SaleDetails.scss';

import { Card, Col, Row } from 'antd';
import { useSaleDetails } from 'api/hooks/sales/useSaleDetails';
import { IClient } from 'api/interfaces/Client';
import { ISaleStock } from 'api/interfaces/Sale';
import { MODAL, useModal } from 'api/store/modalStore';
import useUserStore from 'api/store/userStore';
import { PageInitialLoader } from 'components/PageInitialLoader';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useSaleId } from 'hooks/useId';
import { sales } from 'i18n/pages/sales';
import React, { useState } from 'react';

import { SaleDetailsOverview } from './components/SaleDetailsOverview';
import { SaleItemModal } from './components/SaleItemModal';
import { SaleItemsPreviewTable } from './components/SaleItemsPreviewTable';

export const SaleDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const saleId = useSaleId();
  const { isOpened, open } = useModal();
  const locale = useI18n<typeof sales['ukUA']>(sales);

  const { data: saleDetails, isLoading } = useSaleDetails(clinicId, saleId);

  const userId = useUserStore(({ user }) => user.id);

  const [saleEditable, setSaleEditable] = useState<boolean>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [fieldsTouched, setFieldsTouched] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<{ item: ISaleStock; index: number }>();
  const [selectedEmployee, setSelectedEmployee] = useState<number>(!saleId ? userId : null);

  const editItem = (item: ISaleStock, index: number): void => {
    setItemToEdit({ item, index });
    open(MODAL.saleItemModal);
  };

  return (
    <>
      <PageInitialLoader loading={!!saleId && isLoading} />
      <Row className='sale-form__container'>
        <Col span={24} xl={8} className='sale-form__container__left-side'>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <SaleDetailsOverview
              saleDetails={saleDetails}
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              locale={locale}
              setSaleEditable={setSaleEditable}
              saleEditable={saleEditable}
              setFieldsTouched={setFieldsTouched}
              fieldsTouched={fieldsTouched}
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
            />
          </Card>
        </Col>
        <Col span={24} xl={16} className='sale-form__container__right-side'>
          {saleDetails && (
            <SaleItemsPreviewTable
              saleDetails={saleDetails}
              editItem={editItem}
              locale={locale}
              saleEditable={saleEditable}
            />
          )}
          {(saleEditable || !saleDetails) && (
            <SaleItemModal
              locale={locale}
              selectedClient={selectedClient}
              selectedEmployee={selectedEmployee}
              saleDetails={saleDetails}
              setFieldsTouched={setFieldsTouched}
            />
          )}
        </Col>
      </Row>
      {isOpened(MODAL.saleItemModal) && (
        <SaleItemModal
          locale={locale}
          selectedClient={selectedClient}
          itemToEdit={itemToEdit}
          saleDetails={saleDetails}
          setFieldsTouched={setFieldsTouched}
        />
      )}
    </>
  );
};
