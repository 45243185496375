import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

type Key = string | number | (string | number)[];

export const useCustomMutation = <TVariables, TData>(
  mutationFn: (payload?: TVariables) => Promise<TData>,
  queryKeys: Key[] = [],
  onSuccess?: (data) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      queryKeys?.forEach((key: Key) => {
        const queryKey = typeof key === 'string' ? [key] : key;
        queryClient.invalidateQueries({ queryKey });
      });

      onSuccess?.(data);
    },
    onError: (error: AxiosError<{ message: string }>) => {
      notification.error({
        message: error.response.statusText,
        description: error.response.data.message
      });
    }
  });
};
