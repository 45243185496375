import { ISaleStock } from 'api/interfaces/Sale';
import { IStock } from 'api/interfaces/Stock';
import { LC, useLocaleCode } from 'api/store/localeContext';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { useCallback } from 'react';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

export enum PLURAL {
  'one' = 'one',
  'few' = 'few',
  'many' = 'many'
}

export const getPluralCategoryUA = (count: number): PLURAL => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (count === 1) return PLURAL.one;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) return PLURAL.many;
  if (lastDigit >= 2 && lastDigit <= 4) return PLURAL.few;
  return PLURAL.many;
};

export const getPluralCategoryEN = (count: number): PLURAL => {
  return count === 1 ? PLURAL.one : PLURAL.many;
};

export const usePlural = (selectedStock?: IStock | ISaleStock | ISelectedAuditItem) => {
  const { localeCode } = useLocaleCode();

  const formatFn = useCallback(
    (locale: typeof measurementsLocale | typeof packagesLocale, presetUnit?: string) =>
      (amount?: number, unit?: string): string => {
        const plural = localeCode === LC.en ? getPluralCategoryEN : getPluralCategoryUA;

        return (
          locale[localeCode]?.[presetUnit || unit]?.[plural(amount === 0 ? amount : amount || 1)] ||
          ''
        );
      },
    [localeCode]
  );

  return {
    unitFormat: formatFn(measurementsLocale, selectedStock?.unit_of_measurement),
    packageFormat: formatFn(packagesLocale, selectedStock?.packaging_format)
  };
};
