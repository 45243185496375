import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAudit, showCreateModal } from 'redux/inventory/audit/actions';
import { CreateAuditRequest } from 'services/interfaces/clinics/inventory/audit/CreateAuditRequest';

import AuditForm from '../AuditForms/AuditForm';

const AddAuditModal = ({
  clinicId,
  callbackFn
}: {
  clinicId: number;
  callbackFn: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useAccess();
  const [createAuditForm] = Form.useForm();
  const locale = useLocale('private.inventory.audit');

  const showModal = useSelector(({ audit }: IAppState) => audit.showCreateModal);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSubmit = (data: CreateAuditRequest): void => {
    dispatch(createAudit(clinicId, data, callbackFn));
  };

  const onCancel = (): void => {
    dispatch(showCreateModal(false));
  };

  const onConfirmCancelClick = (): void => {
    createAuditForm.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={() => dispatch(showCreateModal(true))}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.createAudit}
      />
      {showModal && (
        <VLXModal
          form={createAuditForm}
          title={locale.labels.createNewAudit}
          open={showModal}
          onModalClose={onCancel}
          width={800}
        >
          <AuditForm
            onSubmit={onSubmit}
            form={createAuditForm}
            initialValues={{
              date: dayjs()
            }}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.audit}
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (createAuditForm.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showCreateModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AuditForm>
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </VLXModal>
      )}
    </>
  );
};

export default AddAuditModal;
