import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IClinicPayload } from 'api/interfaces/Clinic';

import { useCustomMutation } from '../useCustomMutation';

export const useEditClinic = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IClinicPayload): Promise<IClinicPayload> =>
    axiosClient.put(API_ENDPOINTS.CLINICS(clinicId), payload);

  return useCustomMutation(mutationFn, ['clinicInfo', clinicId], callbackFn);
};
