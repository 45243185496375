import { Col, Row, Spin, Typography } from 'antd';
import { useBankAccountDetails } from 'api/hooks/finances/useBankAccountDetails';
import { MODAL, useModal } from 'api/store/modalStore';
import { TextEditorView } from 'components/TextEditorView';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import {
  financialAccountsTypesOptions,
  financialAcountsOptions
} from 'constants/dictionary/default/selectOptions';
import { money } from 'helpers/list-helpers';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { EditFinancialAccountModal } from 'layout/modals/finances/addFinancialAccount/EditFinancialAccountModal';
import React from 'react';
import { useParams } from 'react-router-dom';
import valueFromList from 'utils/value-from-list';

import { BankAccountTransactions } from './BankAccountTransactions';

const { Title } = Typography;

const FinancialAccountDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const { isOpened, open } = useModal();
  const financialAccountId = +useParams().financialAccountId;
  const userAccess = useAccess();

  const localizedFinancialAcountsOptions = useLocalizedList(financialAcountsOptions);
  const localizedFinancialAcountsTypesOptions = useLocalizedList(financialAccountsTypesOptions);
  const locale = useLocale('private.finances.financial-accounts');
  const currencies = useLocale('private.currencies').labels;

  const { data: account, isPending } = useBankAccountDetails(clinicId, financialAccountId);

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>{account?.name}</Title>
        </Col>
      </Row>
      <Spin spinning={isPending} className='payments-details-page-spin-container'>
        <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
          <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
            <Row>
              <Col span={24} md={12} className='appointment-details-top-container__left-column'>
                <Col>
                  {account?.account_number && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.number}: </span>
                      <span id='account_number'>{account?.account_number}</span>
                    </>
                  )}
                </Col>
                <Col>
                  {account?.account_type && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.accountType}: </span>
                      <span id='account_type'>
                        {valueFromList(
                          localizedFinancialAcountsTypesOptions,
                          account?.account_type
                        )}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.currentBalance}: </span>
                  <span id='current_balance'>
                    {`${money(account?.current_balance)} ${currencies.uah}`}
                  </span>
                </Col>
              </Col>
              <Col span={24} md={12} className='appointment-details-top-container__right-column'>
                <Col>
                  {account?.status && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.status}: </span>
                      <span id='status'>
                        {valueFromList(localizedFinancialAcountsOptions, account?.status)}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.description}: </span>
                  <TextEditorView value={account?.description} id='description' />
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                  <TextEditorView value={account?.notes} id='notes' />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
        {!isOpened(MODAL.editFinancialAccountModal) && (
          <div style={{ padding: 10 }}>
            <ViewDetailsButtonsGroup
              id={1}
              hideCancelBtn
              fixed
              accessGroup={userAccess.accesses.services}
              editMode={false}
              onEditClick={() => open(MODAL.editFinancialAccountModal)}
              hideDeleteBtn
            />
          </div>
        )}
        {!!account && (
          <BankAccountTransactions
            currentAccountId={account?.id}
            financialAccountId={financialAccountId}
            currency={currencies.uah}
          />
        )}
      </Spin>
      {isOpened(MODAL.editFinancialAccountModal) && (
        <EditFinancialAccountModal locale={locale} initialValues={account} />
      )}
    </>
  );
};

export default FinancialAccountDetails;
