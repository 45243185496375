import { Form } from 'antd';
import { IBillDetails } from 'api/interfaces/Bills';
import { MODAL, useModal } from 'api/store/modalStore';
import useShiftStore from 'api/store/shiftStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddBillPaymentForm } from './AddBillPaymentForm';

type Props = {
  bill: IBillDetails;
  hideAddButton?: boolean;
};

export const AddBillPayment = ({ bill, hideAddButton }: Props): JSX.Element => {
  const { accesses } = useAccess();
  const locale = useLocale('private.finances.bills');
  const [form] = Form.useForm();

  const shiftOpened = useShiftStore(({ shift }) => shift.open);

  const { isOpened, open, closeModal } = useModal();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      {!hideAddButton && (
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addBillPayment)}
          disabled={!shiftOpened}
          tooltip={!shiftOpened ? locale.labels.paymentBlockedClosedShift : null}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPayment}
        />
      )}
      {isOpened(MODAL.addBillPayment) && (
        <VLXModal
          title={locale.labels.createPayment}
          centered
          form={form}
          open={isOpened(MODAL.addBillPayment)}
          onModalClose={closeModal}
          width={800}
        >
          <AddBillPaymentForm locale={locale} form={form} bill={bill}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={accesses.bills}
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  closeModal();
                }
              }}
              onDeleteClick={() => setShowCancelModal(false)}
            />
          </AddBillPaymentForm>

          <ConfirmCancelModal open={showCancelModal} onOk={() => { closeModal(); setShowCancelModal(false); }} onCancel={() => setShowCancelModal(false) } />
        </VLXModal>
      )}
    </>
  );
};
