import { Button, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useProvidersList } from 'api/hooks/providers/useProvidersList';
import { IProvider } from 'api/interfaces/Provider';
import { MODAL, useModal } from 'api/store/modalStore';
import { DebtStatus } from 'components/DebtStatus';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddProviderModal } from './modal/AddProviderModal';
import { EditProviderModal } from './modal/EditProviderModal';
import { getMailView, getPhoneView } from './utils/utils';

const defaultParams = {
  page: 1,
  sort_key: 'name',
  sort_order: 'asc'
};

export const ProvidersList = (): JSX.Element => {
  const { open, isOpened } = useModal();
  const clinicId = useClinicId();
  const userAccess = useAccess();
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.providers');
  const { availableModules } = useAccess();

  const [selectedProvider, setSelectedProvider] = useState<IProvider>(null);

  const { data, providers, isLoading, params, updateParams, updateSorting } = useProvidersList(
    clinicId,
    defaultParams,
    false
  );

  const redirectToProviderDetails = ({ id }: { id: number }): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/inventory/providers/${id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IProvider>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        onCell: redirectToProviderDetails,
        sorter: () => null,
        render: (e) => ListFieldWithTooltip(e.name ?? '-', 30)
      },
      {
        key: 'phone_numbers',
        title: locale.labels.contactPhone,
        sorter: () => null,
        render: (e) => getPhoneView(e.phone_numbers)
      },
      {
        key: 'debt',
        title: locale.labels.paymentsStatus,
        hidden: !availableModules.finances,
        sorter: (a, b) => null,
        render: (e) => <DebtStatus debt={e.debt} />
      },
      {
        key: 'emails',
        title: locale.labels.emails,
        sorter: () => null,
        render: (e) => getMailView(e.emails)
      },
      {
        key: 'address',
        title: locale.labels.address,
        onCell: redirectToProviderDetails,
        sorter: () => null,
        render: (e) => e.address ?? '-'
      },
      {
        key: 'actions',
        title: locale.labels.actions,
        render: (e) => (
          <Button
            className='change-appointment-status-button'
            disabled={!userAccess.accesses.providers.edit}
            onClick={() => {
              setSelectedProvider(e);
              open(MODAL.editProviderModal);
            }}
            shape='circle'
            type='primary'
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        )
      }
    ],
    [providers]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Table
        pagination={false}
        className='accent-header audit-list'
        rowKey='id'
        loading={isLoading}
        onChange={updateSorting}
        dataSource={providers}
        columns={columns}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        {userAccess.accesses.providers.create && (
          <StickyButton
            offsetBottom={10}
            onClick={() => open(MODAL.addProviderModal)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
        )}
      </Row>
      {isOpened(MODAL.addProviderModal) && <AddProviderModal />}
      {isOpened(MODAL.editProviderModal) && <EditProviderModal initialValues={selectedProvider} />}
    </>
  );
};
