import { useLocaleCode } from 'api/store/localeContext';

export function useLocale(namespace: string, customLocale = null): any {
  const { localeCode } = useLocaleCode();
  const path = namespace.replaceAll('.', '/');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require(`../../public/data/localization/${
    customLocale === null ? localeCode : customLocale
  }/${path}.json`);
}
