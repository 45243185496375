import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IServicePayload } from 'api/interfaces/Service';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditService = (clinicId: number, serviceId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IServicePayload) =>
    axiosClient.put(`${API_ENDPOINTS.SERVICES(clinicId)}/${serviceId}`, payload);

  return useCustomMutation(mutationFn, ['servicesList', clinicId], callbackFn);
};
