export const measurementsLocale = {
  enGB: {
    pcs: {
      one: 'pcs',
      many: 'pcs'
    },
    tablet: {
      one: 'tablet',
      many: 'tablets'
    },
    capsule: {
      one: 'capsule',
      many: 'capsules'
    },
    ampoule: {
      one: 'ampoule',
      many: 'ampoules'
    },
    dose: {
      one: 'dose',
      many: 'doses'
    },
    pair: {
      one: 'pair',
      many: 'pairs'
    },
    ml: {
      one: 'ml',
      many: 'ml'
    },
    liter: {
      one: 'liter',
      many: 'liters'
    },
    milligram: {
      one: 'milligram',
      many: 'milligrams'
    },
    gram: {
      one: 'gram',
      many: 'grams'
    },
    kg: {
      one: 'kg',
      many: 'kg'
    },
    vial: {
      one: 'vial',
      many: 'vials'
    },
    pipette: {
      one: 'pipette',
      many: 'pipettes'
    },
    cm: {
      one: 'cm',
      many: 'cm'
    },
    m: {
      one: 'm',
      many: 'm'
    },
    fluidOunce: {
      one: 'fluid ounce',
      many: 'fluid ounces'
    },
    ounce: {
      one: 'ounce',
      many: 'ounces'
    },
    pound: {
      one: 'pound',
      many: 'pounds'
    },
    gallon: {
      one: 'gallon',
      many: 'gallons'
    }
  },
  ukUA: {
    pcs: {
      one: 'шт',
      few: 'шт',
      many: 'шт'
    },
    tablet: {
      one: 'таблетка',
      few: 'таблетки',
      many: 'таблеток'
    },
    capsule: {
      one: 'капсула',
      few: 'капсули',
      many: 'капсул'
    },
    ampoule: {
      one: 'ампула',
      few: 'ампули',
      many: 'ампул'
    },
    dose: {
      one: 'доза',
      few: 'дози',
      many: 'доз'
    },
    pair: {
      one: 'пара',
      few: 'пари',
      many: 'пар'
    },
    ml: {
      one: 'мл',
      few: 'мл',
      many: 'мл'
    },
    liter: {
      one: 'літр',
      few: 'літри',
      many: 'літрів'
    },
    milligram: {
      one: 'міліграм',
      few: 'міліграми',
      many: 'міліграмів'
    },
    gram: {
      one: 'грам',
      few: 'грами',
      many: 'грамів'
    },
    kg: {
      one: 'кг',
      few: 'кг',
      many: 'кг'
    },
    vial: {
      one: 'флакон',
      few: 'флакони',
      many: 'флаконів'
    },
    pipette: {
      one: 'піпетка',
      few: 'піпетки',
      many: 'піпеток'
    },
    cm: {
      one: 'см',
      few: 'см',
      many: 'см'
    },
    m: {
      one: 'м',
      few: 'м',
      many: 'м'
    },
    fluidOunce: {
      one: 'рідка унція',
      few: 'рідкі унції',
      many: 'рідких унцій'
    },
    ounce: {
      one: 'унція',
      few: 'унції',
      many: 'унцій'
    },
    pound: {
      one: 'фунт',
      few: 'фунти',
      many: 'фунтів'
    },
    gallon: {
      one: 'галон',
      few: 'галони',
      many: 'галонів'
    }
  }
};
