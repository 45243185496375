import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillPayload } from 'api/interfaces/Bills';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditBill = (
  clinicId: number,
  billId: number,
  callbackFn?: () => void,
  appointmentId?: number
) => {
  const mutationFn = (payload: IBillPayload) =>
    axiosClient.put(`${API_ENDPOINTS.BILLS(clinicId)}/${billId}`, payload);

  const queryKeys = [
    ['billsList', clinicId],
    ['billDetails', clinicId, billId],
    ...[appointmentId ? ['appointmentBill', clinicId, appointmentId] : []]
  ];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};
