import './ClientDetails.scss';

import { Avatar as AvatarView, Button, Col, Row, Spin, Tooltip, Typography } from 'antd';
import { usePatientVaccinationsList } from 'api/hooks/vaccinations/usePatientVaccinations';
import { IPatientDetails } from 'api/interfaces/Patient';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import syringeIcon from 'assets/img/syringe.svg';
import tomb from 'assets/img/tomb.svg';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { TextEditorView } from 'components/TextEditorView';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { animalIdUrl } from 'constants/common';
import {
  animalSex,
  yesNoBooleanOptions,
  yesNoBooleanOptionsWithUnknown
} from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { weight } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { EditPatientModal } from 'layout/modals/patient/EditPatientModal';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getDate } from 'utils/get-time';
import { getLocalisedLabel, localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge, getDefaultAvatar } from '../patients/utils/PatientHelper';
import { aggressiveness } from './helpers/TagSelector';

type Props = {
  data: IPatientDetails;
  accessGroup: boolean;
  hidePatientTopSection?: boolean;
};

const EMPTY_CONTENT = '<p><br></p>';

export const ClientDetailsPetsTab = ({
  data,
  accessGroup,
  hidePatientTopSection
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const { open, isOpened } = useModal();
  const locale = useLocale('private.clients.client');
  const patientLocale = useLocale('private.patients.patient-page');
  const { localeCode } = useLocaleCode();

  const { vaccinations } = usePatientVaccinationsList(clinicId, data.id);

  const localizedPetInfo = useMemo(
    (): Record<string, string> => localizedPetData(data, localeCode),
    [data]
  );

  const [petSex, petNeutering, petLivesOutdoor, petDeciased] = useMemo(
    () => [
      getLocalisedLabel(animalSex, data.sex, localeCode),
      getLocalisedLabel(yesNoBooleanOptionsWithUnknown, data.neutering, localeCode),
      getLocalisedLabel(yesNoBooleanOptions, data.lives_outdoor, localeCode),
      getLocalisedLabel(yesNoBooleanOptions, data.deceased, localeCode)
    ],
    [data]
  );

  const PatientDetailsTopSections = useMemo((): JSX.Element => {
    const name = data.name ?? '';
    const birth_date = data.birth_date ? getAnimalAge(locale, data.birth_date) : '';
    const species = data.species ?? '';

    const getDot = (str1: string, str2: string): ', ' | '' => (str1 && str2 ? ', ' : '');

    return (
      <>
        <Row>
          <Typography
            id='patient-name-age'
            style={{ fontSize: 18, fontWeight: 'bold', marginRight: 4 }}
          >
            {name}
            {getDot(name, birth_date)}
          </Typography>
          {data.date_of_death ? (
            <Typography id='patient-death-icon'>
              <Tooltip
                trigger={'hover'}
                placement='topLeft'
                title={getAnimalAge(patientLocale, data.birth_date, data.date_of_death)}
              >
                <img src={tomb} style={{ width: 22, height: 22 }} />
              </Tooltip>
            </Typography>
          ) : (
            <Typography id='patient-birth-date' style={{ fontSize: 18 }}>
              {birth_date}
            </Typography>
          )}
          {!!data.aggressiveness && aggressiveness(data.aggressiveness, localeCode)}
        </Row>
        <Row>
          <Typography
            id='patient-species'
            style={{ fontSize: 16 }}
            className='client-details__patient-details__top-section__right-side__species'
          >
            {localizedPetInfo?.species ?? '-'}
            {getDot(species, localizedPetInfo?.breed)}
          </Typography>
          {!!localizedPetInfo?.breed && (
            <Typography id='patient-breed' style={{ fontSize: 16 }}>
              {localizedPetInfo.breed}
            </Typography>
          )}
        </Row>
      </>
    );
  }, [data]);

  const VaccinationsData = useMemo((): JSX.Element => {
    const res = vaccinations?.filter((el) => dayjs().isBefore(dayjs(el.expiration_date)));

    if (!res?.length) {
      return (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span>{' '}
          {locale.labels.vaccinationNoData}
        </>
      );
    }

    const getManufacturer = (value): string => (value ? `(${value})` : '');

    if (res.length === 1) {
      return (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span> {res[0].name}
          {getManufacturer(res[0].manufacturer)} {locale.labels.due}{' '}
          {getDate(res[0].expiration_date)}
        </>
      );
    }

    let vaccsToShow = [];
    let vaccsInTooltip = [];

    if (res?.length > 4) {
      vaccsToShow = res
        .slice(0, 4)
        .map((el: { name: string; manufacturer: string; expiration_date: string }) => (
          <div key={el.name}>
            {ListFieldWithTooltip(`${el.name} ${getManufacturer(el.manufacturer)}`, 45)}{' '}
            {locale.labels.due} {getDate(el.expiration_date)}
          </div>
        ));
      vaccsInTooltip = res.slice(4, res.length).map((el) => (
        <div key={el.name}>
          {el.name} {getManufacturer(el.manufacturer)} {locale.labels.due}{' '}
          {getDate(el.expiration_date)}
        </div>
      ));
    } else {
      vaccsToShow = res.map((el) => (
        <div key={el.name}>
          {ListFieldWithTooltip(`${el.name} ${getManufacturer(el.manufacturer)}`, 45)}{' '}
          {locale.labels.due} {getDate(el.expiration_date)}
        </div>
      ));
    }

    const moreVaccsToShow =
      res?.length > 4 ? (
        <Tooltip overlayStyle={{ maxWidth: '500px' }} title={vaccsInTooltip}>
          <span style={{ color: 'grey', cursor: 'pointer' }}>...+{res.length - 4}</span>
        </Tooltip>
      ) : (
        ''
      );

    return (
      <>
        <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span> {vaccsToShow}
        {moreVaccsToShow}
      </>
    );
  }, [vaccinations]);

  return (
    <>
      {!hidePatientTopSection && (
        <Row className='client-details__patient-details__top-section'>
          <Col style={{ width: '100%' }}>
            <Row>
              <AvatarView
                size={100}
                style={{ marginRight: 15 }}
                src={data.avatar ? data.avatar : getDefaultAvatar(data.species)}
              />
              <Row className='client-details__patient-details__top-section__right-side'>
                <Col>
                  {PatientDetailsTopSections}
                  {data.chip_number && (
                    <Typography id='patient-chip' style={{ fontSize: 16 }}>
                      <span>{locale.labels.chip}: </span>
                      <Link className={'custom-links'} to={animalIdUrl + data.chip_number}>
                        {data.chip_number}
                      </Link>
                    </Typography>
                  )}
                </Col>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    id='patient-page-redirect'
                    type='primary'
                    onClick={() => {
                      navigate(`/clinic/${clinicId}/patients/${data.id}`);
                    }}
                    style={{ borderRadius: 30, whiteSpace: 'normal' }}
                  >
                    {locale.buttons.viewPatient}
                    <span className='icofont icofont-external-link icon-after-btn' />
                  </Button>
                </div>
              </Row>
            </Row>
          </Col>
        </Row>
      )}
      {data.warnings && data.warnings !== EMPTY_CONTENT && (
        <Row align='middle' className='client-details__patient-details__alergies-container'>
          <Col className='icon-style'>
            <span className='icofont-warning info-icon' />
          </Col>
          <Col className='text'>
            <TextEditorView value={data.warnings} id='patient-warnings' borderless />
          </Col>
        </Row>
      )}
      <Row align='middle' className='client-details__patient-details__vacination-container'>
        <Col className='icon-style'>
          <img src={syringeIcon} style={{ width: 27, marginTop: 10, marginRight: 10 }} />
        </Col>
        <Spin spinning={!vaccinations}>
          <Col id='patient-vaccination' className='text'>
            {VaccinationsData}
          </Col>
        </Spin>
      </Row>

      <div className='client-details__patient-details__info-container grid-container'>
        <div className='left-column'>
          <Col>
            <Row>
              <span className='labels'>{patientLocale.labels.dateOfBirth}: </span>
              <span id='patient-birth-date'>
                {data.birth_date ? moment(data.birth_date).format('DD.MM.YYYY') : '-'}
              </span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.sex}: </span>
              <span id='patient-sex'>{petSex || '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.weight}: </span>
              <span id='patient-weight'>{data.weight ? weight(data.weight) : '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.neutering}: </span>
              <span id='patient-neutering'>{petNeutering || '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.bloodType}: </span>
              <span id='patient-blood-type'>{data.blood_type ?? '-'}</span>
            </Row>
          </Col>
        </div>

        <div className='right-column'>
          <Col>
            <Row>
              <span className='labels'>{patientLocale.labels.tattoo}: </span>
              <span id='patient-tattoo'>{data.tattoo ?? '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.livesOutdoor}: </span>
              <span id='patient-lives-outdoor'>{petLivesOutdoor || '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.color}: </span>
              <span id='patient-color'>{localizedPetInfo?.color || '-'}</span>
            </Row>
            <Row>
              <span className='labels'>{patientLocale.labels.coat}: </span>
              <span id='patient-coat'>{localizedPetInfo?.coat || '-'}</span>
            </Row>
          </Col>
        </div>
      </div>
      <Row style={{ marginBottom: 12 }}>
        {data.deceased && (
          <Col span={24}>
            <div className='client-details__patient-details__info-container__divider' />
            <span style={{ fontWeight: 600 }}>{patientLocale.labels.deceased}: </span>
            <span id='patient-deceased'>
              {petDeciased + '\u00A0'}({getDate(data.death_date)})
            </span>
          </Col>
        )}
      </Row>

      <Row style={{ marginLeft: 10 }}>
        <div className='labels'>{patientLocale.labels.lifeAnamnesis}: </div>
        <TextEditorView value={data.life_anamnesis} id='patient-life-anamnesis' />
      </Row>
      <Row style={{ marginLeft: 10, marginBottom: 16 }}>
        <span className='labels'>{patientLocale.labels.notes}: </span>
        <TextEditorView value={data.notes} id='patient-notes' />
      </Row>

      {isOpened(MODAL.editPatientModal) && (
        <EditPatientModal
          title={locale.labels.editPatient}
          initialValues={{
            ...data,
            owner: data.primary_contact_id,
            birth_date: data.birth_date && dayjs(data.birth_date),
            death_date: data.death_date && dayjs(data.death_date)
          }}
        />
      )}
      <div
        style={{
          visibility: !isOpened(MODAL.editPatientModal) ? 'visible' : 'hidden',
          padding: '0 0 10px'
        }}
      >
        <ViewDetailsButtonsGroup
          hideCancelBtn
          fixed
          accessGroup={accessGroup}
          editMode={false}
          onEditClick={() => open(MODAL.editPatientModal)}
          hideDeleteBtn
        />
      </div>
    </>
  );
};
