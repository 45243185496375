import '../invoices/scss/InvoicesList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import { useInvoicesList } from 'api/hooks/invoices/useInvoicesList';
import { useInvoiceStockPurchases } from 'api/hooks/invoices/useInvoiceStockPurchases';
import { IInvoice, IInvoicePurchaseItem } from 'api/interfaces/Invoice';
import { MODAL, useModal } from 'api/store/modalStore';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { StatusTag } from 'components/StatusTag';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { money } from 'helpers/list-helpers';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AddInvoiceModal } from './AddInvoiceModal';

const defaultParams = {
  page: 1,
  sort_key: 'created_at',
  sort_order: 'desc'
};

const InvoicesList = (): JSX.Element => {
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const userAccess = useAccess();
  const { open, isOpened } = useModal();

  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;

  const [expandedId, setExpandedId] = useState<number>(null);
  const { invoicePurchases, isLoading: expandedLoading } = useInvoiceStockPurchases(
    clinicId,
    expandedId
  );

  const { data, invoices, isLoading, params, updateParams, updateSorting } = useInvoicesList(
    clinicId,
    defaultParams
  );

  const redirectToInvoiceDetails = (record: IInvoice) =>
    userAccess.accesses.invoices.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/invoices/${record.id}`);
      }
    };

  const getProviderName = (e): JSX.Element | '-' =>
    e.provider?.name ? (
      <Link
        className={'custom-links'}
        to={`/clinic/${clinicId}/inventory/providers/${e?.provider?.id}`}
      >
        {e.provider.name}
      </Link>
    ) : (
      '-'
    );

  const columns = useMemo(
    (): TableColumnProps<IInvoice>[] => [
      {
        key: 'purchase_date',
        title: locale.labels.date,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'invoice_number',
        title: locale.labels.invoiceNumber,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => `${e.invoice_number ? e.invoice_number : '-'}`
      },
      {
        key: 'payment_status',
        sorter: () => null,
        title: locale.labels.paymentStatus,
        render: (e) => <StatusTag status={e.payment_status} />,
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'total_price',
        title: locale.labels.totalPrice,
        render: (e) => (e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-'),
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'remaining_amount',
        sorter: () => null,
        title: locale.labels.remainingAmount,
        render: (e) =>
          e.remaining_amount ? `${money(e.remaining_amount)} ${currencies.uah}` : '-',
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'provider_name',
        title: locale.labels.provider,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => getProviderName(e)
      }
    ],
    [data]
  );

  const expandedColumns = useMemo(
    (): TableColumnProps<IInvoicePurchaseItem>[] => [
      {
        key: 'stock_name',
        title: locale.labels.name,
        render: (e) =>
          ListFieldWithTooltip(
            <Link
              className={'custom-links'}
              to={`/clinic/${clinicId}/inventory/stock/${e.stock.id}`}
            >
              {e.stock.name}
            </Link>
          )
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series || '-'
      },
      {
        key: 'quantity',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.quantity || '-'
      },
      {
        key: 'price_per_unit',
        title: locale.labels.purchasePrice,
        render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.sellingPrice,
        render: (e) => `${e.selling_price ? `${money(e.selling_price)} ${currencies.uah}` : '-'}`
      }
    ],
    [invoicePurchases, expandedId]
  );

  const expandedRowRender = useCallback(
    (): JSX.Element =>
      invoicePurchases ? (
        <Table
          rowKey='id'
          columns={expandedColumns}
          loading={expandedLoading}
          dataSource={invoicePurchases}
          pagination={false}
          size='small'
          className='invoice-items-table'
          bordered
          rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        />
      ) : (
        <Spin
          style={{
            display: 'block',
            padding: 8
          }}
        />
      ),
    [invoicePurchases, expandedId]
  );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Table
        pagination={false}
        className='accent-header invoices-list'
        rowKey='id'
        dataSource={invoices}
        loading={isLoading}
        onChange={updateSorting}
        columns={columns}
        expandable={{
          onExpand: (expanded, record: IInvoice) => {
            setExpandedId(expanded ? record.id : null);
          },
          expandedRowKeys: [expandedId],
          expandedRowRender
        }}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <StickyButton
          offsetBottom={10}
          onClick={() => open(MODAL.addInvoiceModal)}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.createInvoice}
        />
        {isOpened(MODAL.addInvoiceModal) && <AddInvoiceModal clinicId={clinicId} />}
      </Row>
    </>
  );
};

export default InvoicesList;
