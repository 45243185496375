import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteStock = (clinicId: number, stockId: number, callbackFn?: () => void) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.STOCK(clinicId)}/${stockId}`);

  return useCustomMutation(
    mutationFn,
    [
      ['stockDetails', clinicId, stockId],
      ['stockList', clinicId]
    ],
    callbackFn
  );
};
