import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAddAppointmentPatients } from 'api/interfaces/Appointment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useAddPatientToAppointment = (
  clinicId: number,
  appointmentId: number,
  onSuccess?: () => void
) => {
  const mutationFn = (payload: IAddAppointmentPatients): Promise<void> =>
    axiosClient.post(
      `${API_ENDPOINTS.APPOINTMENTS(clinicId)}/${appointmentId}/patients_batch`,
      payload
    );

  return useCustomMutation(
    mutationFn,
    [
      ['appointment', clinicId, appointmentId],
      ['appointmentPatients', clinicId, appointmentId]
    ],
    onSuccess
  );
};
