import baseClient from './baseClient';

// TODO API, add this functionality
export interface InvitationEmployeeRegisterRequest {
  invitation_token: string;
  user_data: {
    first_name: string;
    last_name: string;
    birth_date: string;
    password: string;
    country: string;
    region: string;
    city: string;
    address: string;
  };
}

export interface CreateInvitationRequest {
  clinic_id: number;
  invitation_list: [
    {
      email: string;
      country_code: string;
      phone_number: string;
      role_id: number;
    }
  ];
}

export interface ConfirmInvitationRequest {
  invitation_token: string;
  confirmation_code: string;
}

export interface AcceptInvitationRequest {
  token: string;
}

const invitationsUrl: string = '/v1/invitations';

export const createInvitation = async (invitationData: CreateInvitationRequest) =>
  baseClient.post(`${invitationsUrl}/`, invitationData).then((response: any) => {
    return response.data;
  });

export const acceptInvitation = async (acceptData: AcceptInvitationRequest) =>
  baseClient.post(`${invitationsUrl}/accept`, acceptData).then((response: any) => {
    return response.data;
  });

export const confirmInvitation = async (confirmData: ConfirmInvitationRequest) =>
  baseClient.post(`${invitationsUrl}/confirm`, confirmData).then((response: any) => {
    return response.data;
  });

export const registerUserByInvitation = async (
  registrationData: InvitationEmployeeRegisterRequest
) =>
  baseClient.post(`${invitationsUrl}/employee/register`, registrationData).then((response: any) => {
    return response.data;
  });
