import { Params, useLocation, useParams } from 'react-router-dom';

export function useId(key: string, id: string | null): number | null {
  const location = useLocation();

  if (!location.pathname.includes(key) && !id) {
    return null;
  }

  return Number(id) || Number(location.pathname.split('/')[4]);
}

export function useBillId(): number | null {
  const { billId }: Readonly<Params<string>> = useParams();
  return useId('bills', billId);
}

export function useClientId(): number | null {
  const { clientId }: Readonly<Params<string>> = useParams();
  return useId('clients', clientId);
}

export function useServiceId(): number | null {
  const { serviceId }: Readonly<Params<string>> = useParams();

  return useId('services', serviceId);
}

export function useAppointmentId(): number | null {
  const { appointmentId }: Readonly<Params<string>> = useParams();
  return useId('appointmens', appointmentId);
}

export function useSaleId(): number | null {
  const { shopFarmacyId }: Readonly<Params<string>> = useParams();
  return useId('shop-farmacy', shopFarmacyId);
}

export function useStockId(): number | null {
  const { stockId }: Readonly<Params<string>> = useParams();
  return useId('stock', stockId);
}

export function useInvoiceId(): number | null {
  const { invoiceId }: Readonly<Params<string>> = useParams();
  return useId('invoices', invoiceId);
}

export function useProviderId(): number | null {
  const { providerId }: Readonly<Params<string>> = useParams();
  return useId('providers', providerId);
}

export function useEquipmentId(): number | null {
  const { equipmentId }: Readonly<Params> = useParams();
  return useId('equipment', equipmentId);
}
