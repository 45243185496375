import { Subscription } from '@rails/actioncable';

import { getSocketConnection } from './SocketConnection';

let shiftsChannel: Subscription = null;

export const consumeShiftsChannel = (
  clinicId: number,
  token: string,
  setShiftState: Function
): void => {
  shiftsChannel = getSocketConnection().subscriptions.create(
    { channel: `ShiftsChannel`, clinic_id: clinicId, token },
    {
      initialized(): void {
        console.log('ShiftsChannel initialized');
      },

      received(data): void {
        setShiftState({ open: data.opened });
      },

      connected(): void {
        console.log('ShiftsChannel connected');
      }
    }
  );
};

export const disconnectShiftsChannel = (): void => {
  if (shiftsChannel) {
    shiftsChannel.unsubscribe();
    shiftsChannel = null;
    console.log('ShiftsChannel disconnected');
  }
};
