import 'assets/scss/transfer.scss';

import { Form, Input, Space, Spin, Transfer } from 'antd';
import { useEditRole } from 'api/hooks/roles/useEditRole';
import { usePermissions } from 'api/hooks/roles/usePermissions';
import { useRoleDetails } from 'api/hooks/roles/useRoleDetails';
import { IPermission } from 'api/interfaces/Role';
import { useLocale } from 'hooks/useLocale';
import React, { Key, useEffect, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = React.PropsWithChildren<{
  clinicId: number;
  selectedRoleId: number;
}>;

export const EditRoleForm = ({ clinicId, children, selectedRoleId }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.roles');

  const { data: permissions, isPending } = usePermissions(clinicId);
  const { data: selectedRole } = useRoleDetails(clinicId, selectedRoleId);

  const editRole = useEditRole(clinicId, selectedRoleId);

  const [targetKeys, setTargetKeys] = useState<Key[]>([]);

  const data = permissions?.map((item) => ({
    key: item.id,
    title: item.name,
    description: '',
    chosen: false
  }));

  const getData = (): void => {
    const target = selectedRole?.permissions?.map((item) => item.id);
    setTargetKeys(target);
  };

  useEffect(() => {
    getData();
    form.setFieldsValue(selectedRole);
  }, [selectedRole]);

  const onFinish = (values: { name: string; permissions: IPermission[] }): void => {
    const payload = {
      name: values.name,
      permissions_ids: values.permissions.map((item) => item.id)
    };

    editRole.mutate(payload);
  };

  return (
    <Spin spinning={isPending}>
      <Form autoComplete='off' form={form} layout={'vertical'} onFinish={onFinish}>
        <div className='row' style={{ margin: '10px 20px' }}>
          <div className='col-md-3 col-sm-12'>
            <Form.Item
              label={locale.labels.name}
              name='name'
              rules={[requiredRule(locale.messages.nameEmpty)]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <Form.Item label={locale.labels.permissions} name='permissions' required={true}>
              <Transfer
                className={'transfer'}
                dataSource={data}
                titles={[locale.labels.availablePermissions, locale.labels.currentRolePermissions]}
                listStyle={{
                  maxWidth: '50%',
                  minWidth: '30%',
                  width: 400,
                  height: 500
                }}
                oneWay={true}
                showSearch
                targetKeys={targetKeys}
                onChange={setTargetKeys}
                render={(item) => item.title}
                pagination
              />
            </Form.Item>
          </div>
          <div className='col-md-3 col-sm-12'>
            <Space style={{ marginTop: 30 }}>{children}</Space>
          </div>
        </div>
      </Form>
    </Spin>
  );
};
