import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IAppointmentPatientSericePayload } from 'api/interfaces/Patient';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useAddPatientInventoryHealthRecord = (
  clinicId: number,
  appointmentId: number,
  healthRecordsId: number,
  onSuccess?: () => void
) => {
  const mutationFn = (payload: IAppointmentPatientSericePayload): Promise<void> =>
    axiosClient.post(
      `${API_ENDPOINTS.APPOINTMENTS(
        clinicId
      )}/${appointmentId}/health_records/${healthRecordsId}/inventory`,
      payload
    );

  return useCustomMutation(
    mutationFn,
    [
      ['appointmentPatients', clinicId, appointmentId],
      ['appointmentBill', clinicId, appointmentId]
    ],
    onSuccess
  );
};
