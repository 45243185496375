import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, notification, Upload } from 'antd';
import { useCreatePrintConfiguration } from 'api/hooks/print/useCreatePrintConfiguration';
import { useEditPrintConfiguration } from 'api/hooks/print/useEditPrintConfiguration';
import { usePrintConfiguration } from 'api/hooks/print/usePrintConfiguration';
import { IPrintConfigurationParams } from 'api/interfaces/Print';
import { TextEditor } from 'components/TextEditor';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  setEditMode: (value: boolean) => void;
  onFieldsChange: (e) => void;
}>;

export const PrintConfigurationForm = ({
  form,
  children,
  setEditMode,
  onFieldsChange
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('public.modals.printConfiguration');

  const [avatar, setAvatar] = useState(null);

  const { data: printConfiguration } = usePrintConfiguration(clinicId);
  const editClinic = useEditPrintConfiguration(clinicId, printConfiguration?.id, () => {
    notification.success({ message: locale.buttons.configurationSaved });
    setEditMode(false);
  });
  const createClinic = useCreatePrintConfiguration(clinicId, () => {
    notification.success({ message: locale.buttons.configurationSaved });
    setEditMode(false);
  });

  useEffect(() => {
    form.resetFields();
    if (printConfiguration?.logo) {
      setAvatar(printConfiguration.logo);
    }
  }, [printConfiguration]);

  const onSubmit = (data: IPrintConfigurationParams) => {
    const requestData = new FormData();

    if (data?.logo?.file?.originFileObj) {
      requestData.append('logo', data.logo.file.originFileObj);
    }
    requestData.append(
      'additional_parameters',
      JSON.stringify({
        footer: data.footer,
        header: data.header,
        print_anamnesis: data.print_anamnesis,
        not_print_stock_details: data.not_print_stock_details,
        print_free_items: data.print_free_items
      })
    );

    if (printConfiguration?.id) {
      editClinic.mutate(requestData);
    } else {
      createClinic.mutate(requestData);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={printConfiguration}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item name='logo' label={locale.labels.logo}>
        <Upload
          name='avatar'
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          action=''
          onChange={(info) => {
            getBase64(info.file.originFileObj, (url) => setAvatar(url));
          }}
        >
          {avatar ? (
            <img src={avatar} alt='avatar' style={{ overflow: 'hidden', maxHeight: 150 }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
      <TextEditor label={locale.labels.header} name={['header']} />
      <TextEditor label={locale.labels.footer} name={['footer']} />
      <Form.Item
        name='print_anamnesis'
        label={locale.labels.printAnamnesis}
        valuePropName='checked'
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name='print_free_items'
        label={locale.labels.printItemsWith0Price}
        valuePropName='checked'
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name='not_print_stock_details'
        label={locale.labels.doNotItemizeMedicines}
        valuePropName='checked'
      >
        <Checkbox />
      </Form.Item>
      {children}
    </Form>
  );
};
