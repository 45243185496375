import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPermission } from 'api/interfaces/Role';

export const usePermissions = (clinicId: number) => {
  const fetchRoles = async (): Promise<IPermission[]> => {
    const { data } = await axiosClient.get<IPermission[]>(API_ENDPOINTS.PERMISSIONS(clinicId));
    return data;
  };

  return useQuery<IPermission[]>({
    queryKey: ['permissionsList', clinicId],
    queryFn: fetchRoles,
    staleTime: 3000
  });
};
