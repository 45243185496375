import { useMutation } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import useUserStore from 'api/store/userStore';
import * as localStorage from 'local-storage';
import { useNavigate } from 'react-router-dom';

const mutationFn = async (): Promise<void> => {
  await axiosClient.get(`${API_ENDPOINTS.AUTH}/logout`);
};

export const useLogout = () => {
  const navigate = useNavigate();
  const clearUser = useUserStore.getState().clearUser;

  return useMutation({
    mutationFn,
    onSuccess: () => {
      clearUser();

      localStorage.remove('selectedClinicId');
      localStorage.remove('auth_token');
      localStorage.remove('token');

      navigate('/', { replace: true });
    },
    onError: (error) => {
      console.error('Logout failed:', error);
    }
  });
};
