import './scss/ViewEditEquipment.scss';

import { Form, Spin } from 'antd';
import { useDeleteEquipment } from 'api/hooks/equipment/useDeleteEquipment';
import { useEditEquipment } from 'api/hooks/equipment/useEditEquipment';
import { useEquipmentDetails } from 'api/hooks/equipment/useEquipmentDetails';
import { IEquipmentPayload } from 'api/interfaces/Equipment';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { deviceStatuses } from 'constants/dictionary/default/inventoryOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useEquipmentId } from 'hooks/useId';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EquipmentItemForm } from './components/EquipmentItemForm';
import { deviceStatus } from './helpers/TagSelector';

dayjs.extend(customParseFormat);

export const EquipmentDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const equipmentId = useEquipmentId();
  const navigate = useNavigate();
  const userAccess = useAccess();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const editEquipment = useEditEquipment(clinicId, equipmentId, () => setEditMode(false));
  const deleteEquipment = useDeleteEquipment(clinicId, () =>
    navigate(`/clinic/${clinicId}/inventory/equipment`)
  );
  const { data, isPending } = useEquipmentDetails(clinicId, equipmentId);

  const onSubmit = (data: IEquipmentPayload): void => {
    if (form.isFieldsTouched()) editEquipment.mutate(data);
  };

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setEditMode(false);
    setShowCancelModal(false);
  };

  const handleDate = (date: string | dayjs.Dayjs): dayjs.Dayjs =>
    date ? dayjs(date, 'YYYY-MM-DD') : null;

  function mapValues() {
    const device = {
      ...data,
      warranty_expiration_date: handleDate(data.warranty_expiration_date),
      next_maintenance_date: handleDate(data.next_maintenance_date),
      purchase_date: handleDate(data.purchase_date)
    };
    if (!data.warranty_expiration_date) delete device.warranty_expiration_date;
    if (!data.next_maintenance_date) delete device.next_maintenance_date;
    if (!data.purchase_date) delete device.purchase_date;
    delete device.user_id;
    return device;
  }

  const onFieldsChange = (e): void => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const localizedMaintenanceStatuses = useLocalizedList(deviceStatuses);

  const getDeviceStatus = (e: string): JSX.Element | '' => {
    const res = localizedMaintenanceStatuses.find((el) => el.value === e);
    const tag = deviceStatus(res);
    return res ? tag : '';
  };

  return (
    <Spin spinning={isPending} className='stock-details-page-spin-container'>
      {!!data && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='device-name'>
              {data.name}
            </h4>
            {getDeviceStatus(data?.device_status)}
          </div>
          <EquipmentItemForm
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            form={form}
            initialValues={mapValues()}
            initialResponsible={{
              value: data.user_id,
              label: data.responsible_person
            }}
            initialRoom={{
              value: data.location.id,
              label: data.location.details
            }}
            initialLocationType={data.location.type}
            initialLocationDetails={data.location.details}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.equipment}
              editMode={editMode}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowDeleteModal(false);
                  setShowCancelModal(true);
                }
                setEditMode(false);
              }}
              onEditClick={() => setEditMode(true)}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </EquipmentItemForm>
          <ConfirmDeleteModal
            type={'device'}
            open={showDeleteModal}
            onOk={() => deleteEquipment.mutate(equipmentId)}
            onCancel={() => setShowDeleteModal(false)}
          />
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </div>
      )}
    </Spin>
  );
};
