import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IReassignPricesPayload } from 'api/interfaces/Stock';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useReassignPrices = (clinicId: number, stockId: number, callbackFn?: () => void) => {
  const mutationFn = (payload: IReassignPricesPayload) =>
    axiosClient.post(`${API_ENDPOINTS.STOCK(clinicId)}/${stockId}/prices`, payload);

  return useCustomMutation(mutationFn, ['stockDetails', clinicId, stockId], callbackFn);
};
