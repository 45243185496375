import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { useCustomMutation } from 'api/hooks/useCustomMutation';

import axiosClient from '../../axiosClient';
import { IEmployeeDetails, IEmployeeUpdatePayload } from '../../interfaces/Employee';

export const useEditEmployee = (clinicId: number, employeeId: number, callbackFn?: () => void) => {
  const endpoint = `${API_ENDPOINTS.EMPLOYEES(clinicId)}/${employeeId}`;

  const queryFn = (payload: IEmployeeUpdatePayload): Promise<IEmployeeDetails> =>
    axiosClient.put(endpoint, payload);

  return useCustomMutation<IEmployeeUpdatePayload, IEmployeeDetails>(
    queryFn,
    [`employeeDetails-${clinicId}-${employeeId}`, `employeesList-${clinicId}`],
    callbackFn
  );
};
