import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, notification, Select, Typography } from 'antd';
import { useCreateInvitation } from 'api/hooks/invintations/useCreateInvitation';
import { useRoles } from 'api/hooks/roles/useRoles';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { Option } = Select;
const { Title } = Typography;

type Props = React.PropsWithChildren<{
  form: FormInstance;
}>;

export const AddInvitationForm = ({ children, form }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.general');

  const onSuccess = () => {
    form.resetFields();
    notification.success({ message: locale.messages.dataSavedSuccessfully });
  };

  const { data: roles } = useRoles(clinicId);
  const sendInvite = useCreateInvitation(clinicId, onSuccess);

  const rolesList = useMemo(
    () =>
      roles?.map(
        (item): JSX.Element => (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        )
      ),
    [roles]
  );

  return (
    <Form
      initialValues={{ list: [undefined] }}
      onFinish={sendInvite.mutate}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Title level={5}>{locale.labels.users}:</Title>
      <Form.Item name='clinic_id' initialValue={clinicId} hidden>
        <Input />
      </Form.Item>
      <Form.List name='list'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className='row invitation-creation-form-row'>
                <Form.Item
                  {...restField}
                  name={[name, 'email']}
                  rules={[
                    requiredRule(locale.messages.emailEmpty),
                    patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
                  ]}
                  className='col-md-4 col-sm-12'
                >
                  <Input placeholder='E-mail' />
                </Form.Item>
                <PhoneFieldFormItem
                  formItemPhonePrefix={[name, 'country_code']}
                  formItemPhoneNumber={[name, 'phone_number']}
                  hideLabel
                />
                <Form.Item
                  {...restField}
                  name={[name, 'role_id']}
                  rules={[requiredRule(locale.messages.roleEmpty)]}
                  className='col-md-3 col-sm-12'
                >
                  <Select placeholder='Select a role'>{rolesList}</Select>
                </Form.Item>
                {key > 0 && (
                  <MinusCircleOutlined
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className='col-md-1 col-sm-12'
                    onClick={() => remove(name)}
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                {locale.buttons.addUser}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};
