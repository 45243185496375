import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillTransactionsList } from 'api/interfaces/Bills';

import { useTableData } from '../useTableData';

export const useBillTransactions = (
  clinicId: number,
  billId: number,
  defaultParams: Record<string, string | number> = { page: 0 },
  searchDisabled: boolean = true
) => {
  const endpoint = `${API_ENDPOINTS.BILLS(clinicId)}/${billId}/payments`;

  const result = useTableData<IBillTransactionsList>(
    endpoint,
    defaultParams,
    ['billTransactionsList', clinicId, billId],
    searchDisabled
  );

  return {
    ...result,
    billTransactions: result.data.data || []
  };
};
