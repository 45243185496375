import './scss/ViewEditTool.scss';

import { Form, Spin } from 'antd';
import { useDeleteTool } from 'api/hooks/tools/useDeleteTool';
import { useEditTool } from 'api/hooks/tools/useEditTool';
import { useToolDetails } from 'api/hooks/tools/useToolDetails';
import { IToolPayload } from 'api/interfaces/Tool';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { toolStatuses } from 'constants/dictionary/default/inventoryOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import useToolId from 'hooks/useToolId';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateToolForm } from './components/CreateToolForm';
import { conditionStatus } from './helpers/TagSelector';

dayjs.extend(customParseFormat);

export const ToolDetails = () => {
  const clinicId = useClinicId();
  const toolId = useToolId();
  const navigate = useNavigate();
  const userAccess = useAccess();
  const [form] = Form.useForm();

  const editTool = useEditTool(clinicId, toolId, () => setEditMode(false));
  const deleteTool = useDeleteTool(clinicId, () => navigate(`/clinic/${clinicId}/inventory/tools`));
  const { data, isPending } = useToolDetails(clinicId, toolId);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSubmit = (data: IToolPayload) => {
    if (form.isFieldsTouched()) editTool.mutate(data);
  };

  function onConfirmCancelClick() {
    form.resetFields();
    setEditMode(false);
    setShowCancelModal(false);
  }

  function onConfirmDeleteClick() {
    deleteTool.mutate(toolId);
  }

  function handleDate(date) {
    return date ? dayjs(date, 'YYYY-MM-DD') : null;
  }

  function mapValues() {
    const tool = {
      ...data,
      warranty_expiration_date: handleDate(data.warranty_expiration_date),
      next_inspection_date: handleDate(data.next_inspection_date),
      last_inspection_date: handleDate(data.last_inspection_date),
      purchase_date: handleDate(data.purchase_date)
    };
    if (!data.warranty_expiration_date) delete tool.warranty_expiration_date;
    delete tool.inspected_by;
    delete tool.location;
    delete tool.user_id;
    return tool;
  }

  const onFieldsChange = (e): void => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const localizedMaintenanceStatuses = useLocalizedList(toolStatuses);

  const getToolStatus = (e: string): JSX.Element | '' => {
    const res = localizedMaintenanceStatuses.find((el) => el.value === e);
    const tag = conditionStatus(res);
    return res ? tag : '';
  };

  return (
    <Spin spinning={isPending} className='stock-details-page-spin-container'>
      {!!data && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='tool-name'>
              {data.name}
            </h4>
            {getToolStatus(data?.condition)}
          </div>
          <CreateToolForm
            onFieldsChange={onFieldsChange}
            editMode={editMode}
            onFinish={onSubmit}
            form={form}
            initialValues={mapValues()}
            initialRoom={{
              value: data.location?.id,
              label: data.location?.details
            }}
            initialLocationType={data.location.type}
            initialLocationDetails={data.location.details}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.tools}
              editMode={editMode}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowDeleteModal(false);
                  setShowCancelModal(true);
                }
                setEditMode(false);
              }}
              onEditClick={() => setEditMode(true)}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </CreateToolForm>
          <ConfirmDeleteModal
            type={'device'}
            open={showDeleteModal}
            onOk={onConfirmDeleteClick}
            onCancel={() => setShowDeleteModal(false)}
          />
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </div>
      )}
    </Spin>
  );
};
