import { usePrintConfiguration } from 'api/hooks/print/usePrintConfiguration';
import { IBillDetails } from 'api/interfaces/Bills';
import { MODAL, useModal } from 'api/store/modalStore';
import useClinicId from 'hooks/useClinicId';
import {
  BeforePrintConfigurationModal,
} from 'layout/modals/printConfiguration/beforePrintConfig/BeforePrintConfigurationModal';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { BillContentToPrint } from './BillContentToPrint';

interface Props {
  bill: IBillDetails;
  buttonSize?: number;
  iconSize?: number; 
  isDataLoading?: boolean;
  setPrintingStarted: (value: boolean) => void;
}

export const BillContentToPrintWrapper = ({ bill, setPrintingStarted }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { open, isOpened } = useModal();

  const contentToPrint = useRef(null);

  const { data: printData } = usePrintConfiguration(clinicId);

  const [localPrintConfig, setLocalPrintConfig] = useState(null);

  useEffect(() => {
    let timer = null;
    if (printData) {
      setLocalPrintConfig(printData);
      timer = setTimeout(initiatePrint, 100); // TODO review how to remove timeout
    }
    return () => {
      clearTimeout(timer);
    };
  }, [printData]);

  const handlePrint = useReactToPrint({
    documentTitle: '',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => setPrintingStarted(false),
    removeAfterPrint: true
  });

  const getContentToPrint = (): JSX.Element => (
    <div className='bill-print-wrapper'>
      <div className='bill-print-content' ref={contentToPrint}>
        <BillContentToPrint bill={bill} currentPrintConfig={localPrintConfig} />
      </div>
    </div>
  );

  const initiatePrint = () => {
    if (bill?.appointment) {
      open(MODAL.beforePrintConfigurationModal);
    } else {
      handlePrint(null, () => contentToPrint.current);
    }
  };

  return (
    <>
      {getContentToPrint()}
      {isOpened(MODAL.beforePrintConfigurationModal) && bill?.appointment && (
        <BeforePrintConfigurationModal
          onPrintClick={() => handlePrint(null, () => contentToPrint.current)}
          isSaleBill={!!bill?.sale}
          localPrintConfig={localPrintConfig}
          setLocalPrintConfig={setLocalPrintConfig}
          setPrintingStarted={setPrintingStarted}
        >
          <div className='bill-print-content preview-document-container'>
            <BillContentToPrint
              bill={bill}
              currentPrintConfig={localPrintConfig}
            />
          </div>
        </BeforePrintConfigurationModal>
      )}
    </>
  );
};
