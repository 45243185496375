import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IProvider } from 'api/interfaces/Provider';

import { useCustomMutation } from '../useCustomMutation';

export const useCreateProvider = (clinicId: number, callbackFn?: (data) => void) => {
  const mutationFn = (payload: IProvider) =>
    axiosClient.post(`${API_ENDPOINTS.PROVIDERS(clinicId)}`, payload);

  return useCustomMutation(mutationFn, ['providersList', clinicId], callbackFn);
};
