import './scss/VLXButton.scss';

import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import React from 'react';
import classNames from 'utils/class-names';

type Props = {
  onButtonClick: () => void;
  hideButton?: boolean;
  buttonText?: string;
  buttonClass?: string;
  buttonType?: ButtonType;
  buttonHtmlType?: 'submit' | 'reset' | 'button';
  buttonDisabled?: boolean;
};

export const VLXButton = ({
  onButtonClick, hideButton, buttonText, buttonType, buttonClass, buttonHtmlType, buttonDisabled
}: Props) => {

  var buttonClasses = classNames({
    'vlx-button': true,
    [buttonClass]: !!buttonClass
  });

  return (
    <Button
      onClick={onButtonClick}
      type={buttonType || 'primary'}
      className={buttonClasses}
      htmlType={buttonHtmlType || 'button'}
      style={{ display: hideButton ? 'none' : null }}
      disabled={buttonDisabled}
    >
      {buttonText}
    </Button>
  );
};
