import { Avatar, Col, Form, Row } from 'antd';
import { useClinicInfo } from 'api/hooks/clinic/useClinicInfo';
import { IPatient } from 'api/interfaces/Appointment';
import { IBillDetails } from 'api/interfaces/Bills';
import { IPrintConfigurationParams } from 'api/interfaces/Print';
import LogoSvg from 'assets/img/logoBlack.svg';
import { TextEditorView } from 'components/TextEditorView';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { companyTypes } from 'i18n/companyTypesSelect';
import { BillInvoicePurchasesTable } from 'pages/inventory/invoices/BillInvoicePurchasesTable';
import React, { ReactNode } from 'react';

import BillsDetailsSalesSection from './BillsDetailsSalesSection';
import { InvoicePrintInfo } from './InvoicePrintInfo';
import { PatientPrintInfo } from './PatientPrintInfo';

const EMPTY_CONTENT = '<p><br></p>';

interface Props {
  bill: IBillDetails;
  currentPrintConfig?: IPrintConfigurationParams;
}

export const BillContentToPrint = ({ bill, currentPrintConfig }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const [editBillForm] = Form.useForm();
  const companyTypesOptions = useI18n(companyTypes);
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;

  const { data: clinic } = useClinicInfo(clinicId);

  const isAppointmentBill = bill?.appointment;

  const headerContentAvaliable =
    currentPrintConfig?.header && currentPrintConfig?.header !== EMPTY_CONTENT;
  const footerContentAvaliable =
    currentPrintConfig?.footer && currentPrintConfig?.footer !== EMPTY_CONTENT;

  const renderBillDetailsByBillType = ({ sale, invoice, client }: IBillDetails): ReactNode => {
    if (isAppointmentBill) {
      return (
        <>
          {bill.appointment.patients.map((item: IPatient) => (
            <PatientPrintInfo
              patient={item}
              key={item.id}
              printAnamnesis={currentPrintConfig?.print_anamnesis}
            />
          ))}
          <Row style={{ marginTop: 30, marginBottom: 10 }} justify={'space-between'}>
            <Col></Col>
            <Col>{bill.appointment?.doctor?.name} ___________________</Col>
          </Row>
          <InvoicePrintInfo
            bill={bill}
            showFreeItems={currentPrintConfig?.print_free_items}
            hideStockDetails={isAppointmentBill ? currentPrintConfig?.not_print_stock_details : false}
          />
        </>
      );
    }
    if (sale) {
      return (
        <BillsDetailsSalesSection
          renderTableOnly
          form={editBillForm}
          initialValues={bill}
          client={client}
        />
      );
    }
    if (invoice) {
      return <BillInvoicePurchasesTable data={bill.invoice.stock} />;
    }
  };

  const getDocumentName = () => {
    if (isAppointmentBill) {
      return locale.labels.specialistConsultationReport;
    } else if (!bill?.sale && !bill?.invoice) {
      return `${bill?.name} №${bill?.id}`;
    }
    return `${locale.labels.invoice} №${bill?.id}`;
  };

  const renderClinicInfo = () => {
    if ((isAppointmentBill || bill?.sale) && headerContentAvaliable) return;

    return (
      <>
        <h4 style={{ textAlign: 'center', marginBottom: 15 }}>
          {companyTypesOptions[clinic?.company_type] || ''} "{clinic?.name}"
        </h4>
        <p style={{ textAlign: 'center', marginBottom: 5 }}>{clinic?.location}</p>
        {clinic?.phone_number && (
          <p style={{ textAlign: 'center' }}>
            +{clinic?.country_code}
            {clinic?.phone_number}
          </p>
        )}
      </>
    );
  };

  return (
    <>
      <div className='print-content-wrapper print-content-wrapper__header'>
        {currentPrintConfig?.logo && <Avatar src={currentPrintConfig?.logo} />}
        {headerContentAvaliable && (
          <TextEditorView borderless value={currentPrintConfig?.header} showAll />
        )}
      </div>
      {renderClinicInfo()}
      <h5 style={{ textAlign: 'center', fontSize: '1.2rem' }}>{getDocumentName()}</h5>

      <Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
          <span id='date'>{bill?.date ? dayjs(bill?.date).format('DD.MM.YYYY HH:mm') : '-'}</span>
        </Col>
        <Col>
          {!!bill?.client?.name && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
              <span id='client'>
                {bill?.client?.name}{' '}
                {bill?.client?.phone_number ? `(+${bill?.client?.phone_number})` : ''}
              </span>
            </>
          )}
        </Col>
        {!!bill && renderBillDetailsByBillType(bill)}
      </Col>

      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          {bill?.amount !== null && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.subtotal}: </span>
              <span id='subtotal'>
                {money(bill?.amount || 0)} {currencies.uah}
              </span>
            </>
          )}
        </Col>
        <Col>
          <>
            <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
            <span id='discount-amount'>
              {money(bill?.services_discount?.amount + bill?.stock_discount?.amount || 0)}{' '}
              {currencies.uah}
            </span>
          </>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.total}: </span>
          <span id='total-amount'>
            {money(bill?.final_amount || 0)} {currencies.uah}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.payed}: </span>
          <span id='total_paid'>
            {money(bill?.final_amount - bill?.remaining_amount || 0)} {currencies.uah}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.billDebt}: </span>
          <span id='remaining-amount'>
            {money(bill?.remaining_amount || 0)} {currencies.uah}
          </span>
        </Col>
      </Col>
      {footerContentAvaliable && (
        <div className='print-content-wrapper'>
          <TextEditorView showAll borderless value={currentPrintConfig?.footer} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          top: 5,
          width: '100%'
        }}
      >
        <span style={{ paddingRight: 10 }}>{locale.labels.generatedBy}</span>
        <img src={LogoSvg} style={{ height: 20 }} />
      </div>
    </>
  );
};
