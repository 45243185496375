export const createSearchParams = (params: Record<string, string | number | string[]>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    const appendValue = Array.isArray(value) ? value.join(',') : String(value);
    searchParams.append(key, appendValue);
  });

  return searchParams.toString();
};
