import './PatientDatails.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import { usePatientVaccinationsList } from 'api/hooks/vaccinations/usePatientVaccinations';
import { IVaccination } from 'api/interfaces/Vaccination';
import { MODAL, useModal } from 'api/store/modalStore';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import { VaccinationItemModal } from 'layout/modals/vaccination/VaccinationItemModal';
import React, { useMemo, useState } from 'react';
import { getDate } from 'utils/get-time';

type Props = {
  locale: ILocale;
  patientId: number;
  clientId?: number;
};

export const PatientVaccinationDetails = ({ locale, patientId, clientId }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { open, isOpened } = useModal();
  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);
  const [selectedItem, setSelectedItem] = useState(null);

  const { data, vaccinations, params, updateParams } = usePatientVaccinationsList(
    clinicId,
    patientId,
    { page: 1 }
  );

  const getCoverageCellData = (data: string[]): string | JSX.Element[] => {
    if (!data?.length) return '-';

    return data.map((el) => (
      <div key={el}>
        {ListFieldWithTooltip(localizedVaccineAntigens.find((item) => item.value === el).label)}
      </div>
    ));
  };

  const openEditItemForm = (record: IVaccination): { onClick: () => void } => ({
    onClick: () => openVaccinationModal(record || {})
  });

  const openVaccinationModal = (record: IVaccination | {}): void => {
    setSelectedItem(record);
    open(MODAL.vaccinationItemModal);
  };

  const columns = useMemo(
    (): TableColumnProps<IVaccination>[] => [
      {
        key: 'name',
        onCell: openEditItemForm,
        title: locale.labels.vacinationName,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.name)
      },
      {
        key: 'coverage',
        onCell: openEditItemForm,
        title: locale.labels.coverage,
        sorter: (a, b) => (a.coverage > b.coverage ? 1 : -1),
        render: (e) => getCoverageCellData(e.coverage)
      },
      {
        key: 'manufacturer',
        onCell: openEditItemForm,
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.manufacturer)
      },
      {
        key: 'serial_number',
        onCell: openEditItemForm,
        title: locale.labels.serialNumber,
        sorter: (a, b) => (a.serial_number > b.serial_number ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.serial_number, 10)
      },
      {
        key: 'date_of_vaccination',
        onCell: openEditItemForm,
        title: locale.labels.dateVaccination,
        sorter: (a, b) => (a.date_of_vaccination > b.date_of_vaccination ? 1 : -1),
        render: (e) => getDate(e.date_of_vaccination) || '-'
      },
      {
        key: 'date_of_next_vaccination',
        onCell: openEditItemForm,
        title: locale.labels.dateNextVaccination,
        sorter: (a, b) => (a.date_of_next_vaccination > b.date_of_next_vaccination ? 1 : -1),
        render: (e) => getDate(e.date_of_next_vaccination) || '-'
      }
    ],
    [data]
  );

  return (
    <Spin spinning={!vaccinations}>
      <Row className='patient-details__vacination-tab' style={{ marginBottom: 20 }}>
        <div style={{ width: '100%' }}>
          <Table
            pagination={false}
            className='accent-header patients-vaccination-table'
            rowKey='id'
            dataSource={vaccinations}
            columns={columns}
          />
        </div>
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Pagination
            current={+params.page}
            total={data.metadata.total}
            showSizeChanger={false}
            pageSize={20}
            onChange={(page) => {
              updateParams({ page });
              scrollToTop();
            }}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => openVaccinationModal({})}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addVaccination}
          />
        </Row>
      </Row>
      {isOpened(MODAL.vaccinationItemModal) && (
        <VaccinationItemModal
          initialValues={selectedItem}
          clientId={clientId}
          patientId={patientId}
        />
      )}
    </Spin>
  );
};
