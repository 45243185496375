import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { AddPaymentForm } from './AddPaymentForm';

type Props = {
  initialValues?: { account_id: number };
};

export const AddPaymentModal = ({ initialValues }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const locale = useLocale('private.finances.payments');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.addPayment}
        centered
        open
        form={form}
        onModalClose={closeModal}
        width={800}
      >
        {/* todo API fix type */}
        <AddPaymentForm locale={locale} form={form} initialValues={initialValues as any}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.financialAccounts}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </AddPaymentForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
