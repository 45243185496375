import { CheckCircleTwoTone, CloseCircleTwoTone, MailOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { LC, useLocaleCode } from 'api/store/localeContext';
import useUserStore from 'api/store/userStore';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import * as ls from 'local-storage';
import React, { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmEmail } from 'services/authService';

const EmailConfirmation = (): JSX.Element => {
  const userLocale = useLinkParams('locale') as LC;
  const navigate = useNavigate();
  const confirmed = useLinkParams('confirmed');
  const operationStatus = useLinkParams('status');

  const userId = useUserStore(({ user }) => user.id);
  const { localeCode, setLocale } = useLocaleCode();

  const finalLocale = localeCode || userLocale || 'enGB';
  const locale = useLocale('public.email-confirmation-page', finalLocale);

  const confirmationResults = {
    0: {
      icon: <Spin size='large' />,
      text: 'E-mail verification'
    },
    200: {
      icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
      text: locale.messages.emailConfirmed
    },
    208: {
      icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
      text: locale.messages.emailNotConfirmedYet
    },
    401: {
      icon: <CloseCircleTwoTone twoToneColor='red' />,
      text: `${locale.messages.verificationFailed}, ${locale.messages.tokenUsed}`
    },
    403: {
      icon: <CloseCircleTwoTone twoToneColor='red' />,
      text: locale.messages.verificationFailed,
      button: (
        <>
          <p>{locale.messages.tokenExpired}</p>
          <Button
            type='primary'
            onClick={() => sendToken(true)}
            style={{ width: 'auto' }}
            icon={<MailOutlined style={{ fontSize: '1.3rem' }} />}
          >
            {locale.labels.resend}
          </Button>
        </>
      )
    }
  };

  const { token } = useParams() as any;
  const status = Number(operationStatus) || 0;

  function pushHistoryState(confirmed, status = null) {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?confirmed=${confirmed}${
        status ? `&status=${status}` : ''
      }`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }

  function navigateToStatus(status, resend = false) {
    if (!resend) {
      navigate(`/confirm-email/${token}?locale=${userLocale}&confirmed=true&status=${status}`);
      navigate(0);
    } else {
      navigate(`/confirm-email/${token}?locale=${userLocale}&confirmed=true`);
    }
  }

  const sendToken = (resend?: boolean) => {
    console.log('sendToken');
    pushHistoryState(true);
    confirmEmail(token, resend, locale)
      .then((res) => {
        if (res.data?.user_email) {
          console.log('confirmEmail');
          confirmEmail(res.data?.token, true, locale)
            .then((newRes) => {
              navigateToStatus(res.status, resend);
            })
            .catch((newRes) => {
              navigateToStatus(newRes, resend);
            });
        } else {
          navigateToStatus(res.status, resend);
        }
      })
      .catch((res) => {
        navigateToStatus(res, resend);
      })
      .finally(() => {});
  };

  useEffect(() => {
    // todo API check this code if needed.
    if (!ls.get('locale')) {
      setLocale(userLocale);
    } else {
      setLocale(ls.get('locale'));
    }
    if (userId) {
      navigate('/');
      return;
    }
    if (!confirmed && !userId) {
      console.log('useEffect');
      sendToken(false);
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1 style={{ fontSize: '6rem' }} className='text-color-300 mb-2'>
        {confirmationResults[status].icon}
      </h1>
      <h6 className='text-md'>{confirmationResults[status].text}</h6>
      {confirmationResults[status].button}
    </div>
  );
};

export default memo(EmailConfirmation);
