import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IPaymentsList } from 'api/interfaces/Payment';

import { useTableData } from '../useTableData';

export const usePaymentsList = (
  clinicId: number,
  defaultParams: Record<string, string | number>
) => {
  const endpoint = API_ENDPOINTS.PAYMENTS(clinicId);
  const result = useTableData<IPaymentsList>(endpoint, defaultParams, ['paymentsList', clinicId]);

  return {
    ...result,
    payments: result.data.data
  };
};
