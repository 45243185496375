import { IAppointment } from 'api/interfaces/Appointment';
import dayjs from 'dayjs';
import { ILocale } from 'interfaces/locale';

export const getAppointmentTooltip = (appointment: IAppointment, locale: ILocale) => {
  const timeNow = dayjs();
  if (appointment.status !== 'future') return locale.messages.appointmentCanNotBeStartedList;
  if (dayjs(appointment?.start_time).diff(timeNow, 'hours') >= 3) return locale.messages.appointmentCanNotBeStarted;
  return null;
};
