import { create } from 'zustand';

export enum MODAL {
  addAppointmentPatient = 'addAppointmentPatient',
  clientItemModal = 'addClientItemModal',
  editAppointment = 'editAppointment',
  cancellationForm = 'cancellationForm',
  addAppointment = 'addAppointment',
  addPatientModal = 'addPatientModal',
  editPatientModal = 'editPatientModal',
  generateVaccinationReport = 'generateVaccinationReport',
  vaccinationItemModal = 'vaccinationItemModal',
  deletePatientModal = 'deletePatientModal',
  addInvitationModal = 'addInvitationModal',
  addRoleModal = 'addRoleModal',
  addServiceModal = 'addServiceModal',
  appointmentBillModal = 'appointmentBillModal',
  addBillPayment = 'addBillPayment',
  beforePrintConfigurationModal = 'beforePrintConfigurationModal',
  allDayEventsModal = 'allDayEventsModal',
  addDictionary = 'addDictionary',
  editEventModal = 'editEventModal',
  addPaymentModal = 'addPaymentModal',
  editPaymentModal = 'editPaymentModal',
  addFinancialAccountModal = 'addFinancialAccountModal',
  editFinancialAccountModal = 'editFinancialAccountModal',
  transferAccountsModal = 'TransferAccountsModal',
  addBillModal = 'addBillModal',
  editBillModal = 'editBillModal',
  replenishmentPopup = 'replenishmentPopup',
  saleItemModal = 'saleItemModal',
  createStockItemModal = 'createStockItemModal',
  editStockItemModal = 'editStockItemModal',
  reassignPriceModal = 'reassignPriceModal',
  reassignLocationModal = 'reassignLocationModal',
  addItemsToStockModal = 'addItemsToStockModal',
  printMultiplePricesModal = 'printMultiplePricesModal',
  printPricesModal = 'printPricesModal',
  confirmDeleteModal = 'confirmDeleteModal',
  addInvoiceModal = 'addInvoiceModal',
  editInvoiceModal = 'editInvoiceModal',
  addProviderModal = 'addProviderModal',
  editProviderModal = 'editProviderModal',
  confirmDeletePurchaseModal = 'confirmDeletePurchaseModal',
  addToolModal = 'addToolModal',
  editToolModal = 'editToolModal',
  addEquipmentModal = 'addEquipmentModal',
  editEquipmentModal = 'editEquipmentModal'
  // Add other modal types here as needed
}

interface ModalState {
  modal: MODAL[]; // Currently active modal
  isOpened: (key: MODAL) => boolean; // Check if a modal is open
  open: (key: MODAL) => void; // Open a specific modal
  closeModal: () => void; // Close the currently open modal
}

export const useModal = create<ModalState>((set, get) => ({
  modal: [],
  isOpened: (key: MODAL) => get().modal.includes(key),
  open: (key: MODAL) => set((state) => ({ modal: [...state.modal, key] })),
  closeModal: () =>
    set((state) => ({
      modal: state.modal.length > 1 ? state.modal.slice(0, -1) : []
    })),
  closeAllModals: () => set({ modal: [] })
}));
