import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IEquipmentPayload } from 'api/interfaces/Equipment';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditEquipment = (clinicId: number, equipmentId: number, callbackFn?: (data) => void) => {
  const mutationFn = (payload: IEquipmentPayload) =>
    axiosClient.put(`${API_ENDPOINTS.EQUIPMENT(clinicId)}/${equipmentId}`, payload);

  const queryKeys = ['equipmentList', ['equipmentDetails', clinicId, equipmentId]];

  return useCustomMutation(mutationFn, queryKeys, callbackFn);
};