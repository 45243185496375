import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IBillDetails } from 'api/interfaces/Bills';

export const useBillDetails = (clinicId: number, billId: number) => {
  return useQuery<IBillDetails>({
    queryKey: ['billDetails', clinicId, billId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.BILLS(clinicId)}/${billId}`);
      return data;
    },
    enabled: !!billId
  });
};
