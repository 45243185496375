import {
  getClinicStock,
  getClinicStockById,
  getClinicStockItemsById
} from 'services/clinic/inventory/stockService';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { StockIndexResponse } from 'services/interfaces/clinics/inventory/stock/StockIndexResponse';
import { StockItemsShowResponse } from 'services/interfaces/clinics/inventory/stock/StockItemsShowResponse';
import { ISortObject } from 'services/interfaces/ISortObject';

import {
  RESET,
  SET_BE_ERROR,
  SET_SELECTED_STOCK,
  SET_SELECTED_STOCK_ITEMS,
  SET_STOCK,
  SET_STOCK_ITEMS_LOADING,
  SET_STOCK_LOADING,
  SET_STOCK_STATS,
  SetBeError,
  SetLoadingAction,
  SetSelectedStockAction,
  SetSelectedStockItemsAction,
  SetStockAction,
  SetStockItemsLoadingAction,
  SetStockStatsAction,
  SHOW_CREATE_MODAL,
  ShowCreateModal,
  StockResetAction
} from './types';

export const setStock = (data: StockIndexResponse): SetStockAction => ({
  type: SET_STOCK,
  payload: data
});

export const setStockStats = (data: StockIndexResponse): SetStockStatsAction => ({
  type: SET_STOCK_STATS,
  payload: data
});

export const resetStock = (): StockResetAction => ({
  type: RESET
});

export const setSelectedStock = (data: ISelectedStock): SetSelectedStockAction => ({
  type: SET_SELECTED_STOCK,
  payload: data
});

export const setSelectedStockItems = (
  data: StockItemsShowResponse
): SetSelectedStockItemsAction => ({
  type: SET_SELECTED_STOCK_ITEMS,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_STOCK_LOADING,
  payload: value
});

export const setStockItemsLoading = (value: boolean): SetStockItemsLoadingAction => ({
  type: SET_STOCK_ITEMS_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBeError => ({
  type: SET_BE_ERROR,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModal => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const getStock = (
  clinicId: number,
  pageSize: any = 20,
  page: number,
  searchArray?: any[],
  sortObject?: ISortObject,
  callBackFn?: () => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicStock(clinicId, page, searchArray, pageSize, sortObject)
      .then((data) => {
        dispatch(setStock(data));
        if (callBackFn) callBackFn();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

// export const getStockStats = (clinicId: number): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     getClinicStockStats(clinicId)
//       .then((data) => {
//         dispatch(setStockStats(data));
//       })
//       .catch((err) => {
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

export const getStockById = (clinicId: number, deviceId: number, callBackFunc?: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicStockById(clinicId, deviceId)
      .then((data: ISelectedStock) => {
        if (callBackFunc) {
          callBackFunc(data);
        }
        dispatch(setSelectedStock(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getStockDetailsByStockId = async (
  clinicId: number,
  deviceId: number
): Promise<any> => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    return getClinicStockById(clinicId, deviceId)
      .then((data) => data)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getStockItemsById = (clinicId: number, deviceId: number, page: number): any => {
  return (dispatch) => {
    dispatch(setStockItemsLoading(true));
    getClinicStockItemsById(clinicId, deviceId, page)
      .then((data) => {
        dispatch(setSelectedStockItems(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setStockItemsLoading(false)));
  };
};

// export const createStock = (
//   clinicId: number,
//   data: IStockItemCreateEditRequest,
//   callbackFn
// ): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     createClinicStock(clinicId, data)
//       .then((data) => {
//         if (callbackFn) {
//           callbackFn(data);
//         } else {
//           dispatch(getStock(clinicId, 20, 1));
//         }
//         dispatch(showCreateModal(false));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const updateStock = (
//   clinicId: number,
//   deviceId: number,
//   data: IStockItemCreateEditRequest
// ): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     updateClinicStock(clinicId, deviceId, data)
//       .then(() => {
//         dispatch(getStockById(clinicId, deviceId));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const removeStock = (clinicId: number, stockId: number, callbackFn?: () => void): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     removeClinicStock(clinicId, stockId)
//       .then((data) => {
//         dispatch(getStockById(clinicId, stockId));
//         callbackFn?.();
//       })
//       .catch((err) => {
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const removeStockItem = (clinicId: number, stockId: number, itemId: number, locale): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     removeClinicStockItem(clinicId, stockId, itemId)
//       .then(() => {
//         notification.success({ message: locale.messages.stockPurchaseRemovedSuccessfully });
//         dispatch(setSelectedStockItems(null));
//         dispatch(getStockItemsById(clinicId, stockId, 0));
//         dispatch(getStockById(clinicId, stockId));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const updateStockItem = (
//   clinicId: number,
//   stockId: number,
//   data: IStockItemCreateEditRequest,
//   itemId: number
// ): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     updateClinicStockItem(clinicId, stockId, data, itemId)
//       .then(() => {
//         dispatch(getStockById(clinicId, stockId));
//         dispatch(getStockItemsById(clinicId, stockId, 0));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const createStockItem = (
//   clinicId: number,
//   data: IStockItemCreateEditRequest,
//   stockId: number
// ): any => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     createClinicStockItem(clinicId, data, stockId)
//       .then((data) => {
//         dispatch(getStockItemsById(clinicId, stockId, 0));
//         dispatch(showCreateModal(false));
//         dispatch(getStockById(clinicId, stockId));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       })
//       .finally(() => dispatch(setLoading(false)));
//   };
// };

// export const reassignLocations = (
//   clinicId: number,
//   stockId: number,
//   payload: IReassignLocationsRequest,
//   callbackFn: () => void
// ): any => {
//   return (dispatch) => {
//     reassignLocationsRequest(clinicId, stockId, payload)
//       .then(() => {
//         callbackFn();
//         dispatch(getStockById(clinicId, stockId));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       });
//   };
// };

// export const reassignPrices = (
//   clinicId: number,
//   stockId: number,
//   payload: IReassignPricesRequest,
//   callbackFn: () => void
// ): any => {
//   return (dispatch) => {
//     reassignPricesRequest(clinicId, stockId, payload)
//       .then(() => {
//         callbackFn();
//         dispatch(getStockById(clinicId, stockId));
//       })
//       .catch((err) => {
//         dispatch(setBackendError(true));
//         console.log(err);
//       });
//   };
// };
