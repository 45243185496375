import './scss/Provider.scss';

import { Button, Card, Col, Menu, MenuProps, Row, Spin } from 'antd';
import { useProviderDetails } from 'api/hooks/providers/useProviderDetails';
import { MODAL, useModal } from 'api/store/modalStore';
import { DebtStatus } from 'components/DebtStatus';
import { TextEditorView } from 'components/TextEditorView';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useProviderId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { EditProviderModal } from './modal/EditProviderModal';
import { ProviderHistoryTab } from './ProviderHistoryTab';

export const ProviderDetails = (): JSX.Element => {
  const clinicId = useClinicId();
  const providerId = useProviderId();

  const userAccess = useAccess();
  const locale = useLocale('private.inventory.providers');
  const { open, isOpened } = useModal();
  const [currentTab, setCurrentTab] = useState('history');

  const { data: providerDetails, isPending } = useProviderDetails(clinicId, providerId);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = (e) => {
    setCurrentTab(e.key);
  };

  const getParsedContacts = (list: string, key: string, linkTo: string): JSX.Element | string => {
    const data = JSON.parse(list)?.filter((item) => !!item?.[key]);
    if (!data.length) return '-';

    return data.map((item, index) => (
      <Row gutter={24} key={index} align='middle'>
        <Col flex='0 1 100px'>
          <Link
            className='additional_contacts_number'
            to={`${linkTo}:${item?.country_code ? `+${item?.country_code}` : ''}${item[key]}`}
            style={{ fontSize: 15, marginRight: 20 }}
          >
            {item?.country_code ? `+${item?.country_code}` : ''}
            {item[key]}
          </Link>
        </Col>
        <Col flex='0 1 370px'>
          <span style={{ fontSize: 15 }}>{item?.description}</span>
        </Col>
      </Row>
    ));
  };

  const ProviderDetail = ({
    icon,
    children,
    id
  }: React.PropsWithChildren<{ icon: string; id?: string }>): JSX.Element => (
    <Row align='middle' className='provider-info-container__row'>
      <Col className='provider-info-container__col-left provider-icon'>
        <span className={`icofont-${icon} info-icon`} />
      </Col>
      <Col className='provider-info-container__col-right' id={id || ''}>
        {children}
      </Col>
    </Row>
  );

  return (
    <Spin spinning={isPending}>
      {!!providerDetails && (
        <>
          <Col span={24} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container provider-info-container'>
              <Row>
                <Col xl={12} span={24}>
                  <ProviderDetail icon='briefcase-1' id={'provider-name'}>
                    <span style={{ marginRight: 15, fontSize: 18 }}>{providerDetails.name}</span>
                    <DebtStatus debt={providerDetails?.debt} />
                  </ProviderDetail>
                  <ProviderDetail icon='ui-touch-phone' id={'provider-phones'}>
                    {getParsedContacts(
                      providerDetails.phone_numbers as string,
                      'phone_number',
                      'tel'
                    )}
                  </ProviderDetail>
                  <ProviderDetail icon='ui-message' id={'provider-emails'}>
                    {getParsedContacts(providerDetails.emails as string, 'email', 'mailto')}
                  </ProviderDetail>
                </Col>
                <Col xl={12} span={24}>
                  {providerDetails.address && (
                    <ProviderDetail icon='location-pin' id={'provider-location'}>
                      {providerDetails.address}
                    </ProviderDetail>
                  )}
                  {providerDetails.comments && (
                    <ProviderDetail icon='comment'>
                      <div className='comments-container' id={'provider-comments'}>
                        <TextEditorView value={providerDetails.comments} />
                      </div>
                    </ProviderDetail>
                  )}
                </Col>
              </Row>
              {userAccess.accesses.providers.edit && (
                <Row className='provider-info-container__edit-button-container'>
                  <Button
                    type='primary'
                    style={{ borderRadius: 30 }}
                    onClick={() => open(MODAL.editProviderModal)}
                  >
                    <span
                      className='icofont icofont-edit-alt mr-2 sticky-btn-icon'
                      style={{ fontSize: '1.1em' }}
                    />
                    <span>{locale.buttons.edit}</span>
                  </Button>
                </Row>
              )}
            </Card>
          </Col>
          <Col span={24}>
            <Card className='ant-card-bordered personal-info-card with-shadow'>
              <Menu
                onClick={changeTab}
                selectedKeys={[currentTab]}
                mode='horizontal'
                items={items}
                style={{ marginBottom: 20 }}
              />
              {currentTab === 'history' && (
                <ProviderHistoryTab providerId={providerId} clinicId={clinicId} />
              )}
            </Card>
          </Col>
        </>
      )}
      {isOpened(MODAL.editProviderModal) && <EditProviderModal initialValues={providerDetails} />}
    </Spin>
  );
};
