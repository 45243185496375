export const groupedFilters = (
  selectOptions: Array<{ value: string; label: string; filter?: string }>,
  selectValue: string[]
) => {
  return selectOptions?.reduce((acc, option) => {
    if (selectValue.includes(option.value)) {
      const filterType = option.filter || 'statuses'; // Default to 'statuses' if no `filter` property
      if (!acc[filterType]) {
        acc[filterType] = [];
      }
      acc[filterType].push(option.value);
    }
    return acc;
  }, {} as Record<string, string[]>);
};
