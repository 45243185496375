import { Form } from 'antd';
import { IVaccination } from 'api/interfaces/Vaccination';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { VaccinationItemForm } from './VaccinationItemForm';

type Props = {
  initialValues: IVaccination;
  clientId?: number;
  patientId?: number;
};

export const VaccinationItemModal = ({
  initialValues,
  clientId,
  patientId
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const { closeModal } = useModal();
  const locale = useLocale('private.patients.patient-page');

  const [formEditMode, setFormEditMode] = useState<boolean>(!!initialValues?.id);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setFormEditMode(false);
    setShowCancelModal(false);
    closeModal();
  };

  const onFieldsChange = (e): void => {
    if (e.length < 2 && !formEditMode) {
      setFormEditMode(true);
    }
  };

  const mapValues = (data): IVaccination & { owner: number; patientId: number } => {
    if (data && Object.keys(data).length) {
      const res = {
        ...data,
        owner: data?.client_id ?? clientId,
        patient_id: data?.patient_id ?? patientId
      };
      if (data.expiration_date) {
        res.expiration_date = dayjs(data.expiration_date);
      }
      if (data.date_of_vaccination) {
        res.date_of_vaccination = dayjs(data.date_of_vaccination);
      }
      if (data.date_of_next_vaccination) {
        res.date_of_next_vaccination = dayjs(data.date_of_next_vaccination);
      }

      return res;
    } else {
      return { ...data, patient_id: patientId, owner: clientId };
    }
  };

  return (
    <>
      <VLXModal
        title={initialValues?.id ? locale.labels.editVaccination : locale.labels.createVaccination}
        centered
        open
        form={form}
        onModalClose={() => {
          closeModal();
          setFormEditMode(false);
        }}
        width={800}
      >
        <VaccinationItemForm
          form={form}
          onFieldsChange={onFieldsChange}
          setFormEditMode={setFormEditMode}
          closeModal={closeModal}
          initialValues={mapValues(initialValues)}
          editMode={!!initialValues?.id}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.patients}
            editMode={initialValues ? formEditMode : true}
            onEditClick={() => setFormEditMode(true)}
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                setFormEditMode(false);
                closeModal();
              }
            }}
          />
        </VaccinationItemForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
