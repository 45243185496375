import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import { useUserProfile } from '../user/useUserProfile';

const headers = { 'Content-Type': 'multipart/form-data' };

export const useUploadUserAvatar = () =>
  useUploadAvatar(`${API_ENDPOINTS.USER}/profile`, ['userProfile']);

export const useUploadEmployeeAvatar = (clinicId: number, employeeId: number) =>
  useUploadAvatar(`${API_ENDPOINTS.EMPLOYEES(clinicId)}/${employeeId}`, [
    'employeeDetails',
    clinicId,
    employeeId
  ]);

export const useUploadClientAvatar = (clinicId: number, clientId: number) =>
  useUploadAvatar(`${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}`, [
    'clientDetails',
    clinicId,
    clientId
  ]);

export const useUploadPatientAvatar = (clinicId: number, patientId: number) =>
  useUploadAvatar(`${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}`, [
    'patientDetails',
    clinicId,
    patientId
  ]);

export const useUploadClinicAvatar = (clinicId: number) =>
  useUploadAvatar(`${API_ENDPOINTS.CLINICS(clinicId)}`, ['clinicInfo', clinicId]);

export const useUploadAvatar = (endpoint: string, queryKey: (string | number)[]) => {
  const queryClient = useQueryClient();
  const getUser = useUserProfile();

  return useMutation({
    mutationFn: async (payload: FormData) => {
      const { data } = await axiosClient.post(`${endpoint}/avatar`, payload, { headers });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      notification.success({ message: 'Avatar uploaded successfully' });
      getUser.mutate();
    },
    onError: (error) => {
      notification.error({
        message: 'Failed to upload avatar',
        description: error.message
      });
    }
  });
};
