import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IProvider } from 'api/interfaces/Provider';

export const useProviderDetails = (clinicId: number, providerId: number) => {
  return useQuery<IProvider>({
    queryKey: ['providerDetails', clinicId, providerId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.PROVIDERS(clinicId)}/${providerId}`);
      return data;
    },
    enabled: !!providerId
  });
};
