import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockStats } from 'api/interfaces/Stock';

export const useStockStats = (clinicId: number) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(API_ENDPOINTS.STOCK_STATS(clinicId));
    return data;
  };

  return useQuery<IStockStats>({
    queryFn,
    queryKey: ['stockStats', clinicId]
  });
};
