import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteService = (clinicId: number, callbackFn?: () => void) => {
  const mutationFn = (serviceId: number) =>
    axiosClient.delete(`${API_ENDPOINTS.SERVICES(clinicId)}/${serviceId}`);

  return useCustomMutation(mutationFn, null, callbackFn);
};
