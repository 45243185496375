import { Form, Input } from 'antd';
import { useCancelAppointment } from 'api/hooks/appointments/useCancelAppointment';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { requiredRule } from 'utils/form-rules';

type Props = {
  clinicId: number;
  appointmentId: number;
};

export const CancellationForm = ({ appointmentId, clinicId }: Props): JSX.Element => {
  const { closeModal } = useModal();
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const locale = useLocale('private.appointments.cancellation-form');
  const [showCancelModal, setShowCancelModal] = useState(false);

  const onSuccess = (): void => closeModal();

  const cancelAppointment = useCancelAppointment(clinicId, onSuccess);

  const onSubmit = (): void => {
    const { reason } = form.getFieldsValue();
    const payload = { cancellation_reason: reason };

    cancelAppointment.mutate({ appointmentId, payload });
  };

  return (
    <VLXModal
      title={locale.labels.appointmentCancellation}
      centered
      open
      form={form}
      onModalClose={closeModal}
      width={800}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name='reason'
          label={locale.labels.reason}
          rules={[requiredRule(locale.errors.reasonCanNotBeEmpty)]}
        >
          <Input />
        </Form.Item>
        <ViewDetailsButtonsGroup
          fixed
          showCreateBtn
          accessGroup={userAccess.accesses.appointments}
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
        />
      </Form>
      <ConfirmCancelModal
        centered
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />
    </VLXModal>
  );
};
