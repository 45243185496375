import './scss/ViewEditInvoice.scss';

import { Button, Col, notification, Pagination, Row, Table, TableColumnProps } from 'antd';
import { useDeleteInvoicePurchase } from 'api/hooks/invoices/useDeleteInvoicePurchase';
import { useInvoiceStockPurchases } from 'api/hooks/invoices/useInvoiceStockPurchases';
import { IInvoice, IInvoicePurchaseItem } from 'api/interfaces/Invoice';
import { MODAL, useModal } from 'api/store/modalStore';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { PrintPricesModal } from 'layout/modals/printPrices/PrintPricesModal';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { usePlural } from '../stock/utils/usePlural';

interface Props {
  editingDisabled: boolean;
  invoice: IInvoice;
}

export const InvoicePurchasesTable = ({ editingDisabled, invoice }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const { open, isOpened, closeModal } = useModal();
  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;
  const { unitFormat, packageFormat } = usePlural();

  const onDelete = () => {
    notification.success({ message: locale.messages.recordSuccessfullyDeleted });
    closeModal();
  };

  const { invoicePurchases, updateParams, isLoading, params, data } = useInvoiceStockPurchases(
    clinicId,
    invoice.id,
    null,
    false
  );

   useEffect(() => updateParams(), []);

  const [itemToDelete, setItemToDelete] = useState<IInvoicePurchaseItem>(null);
  const deletePurchase = useDeleteInvoicePurchase(clinicId, invoice, itemToDelete, onDelete);

  const [selectedStock, setSelectedStock] = useState(null);
  const [allInvoiceItems, setAllInvoiceItems] = useState([]);

  const itemFormat = (item: IInvoicePurchaseItem): string => {
    const packagingFormat = packageFormat(1, item?.purchase_format);
    const unitOfMeasurement = unitFormat(1, item?.purchase_format);
    return packagingFormat || unitOfMeasurement;
  };

  const getPrice = (
    item: IInvoicePurchaseItem,
    price: number,
    unitSellingPrice?: number
  ): JSX.Element => (
    <Col>
      {item?.stock?.packaging_format !== 'none' && (
        <Row className='time-cell-row' style={{ width: 'max-content' }}>
          {`${packageFormat(1, item?.stock?.packaging_format)}: ${price.toFixed(2)} ${
            currencies.uah
          }`}
        </Row>
      )}
      <Row className='time-cell-row' style={{ width: 'max-content' }}>
        {item?.stock?.unit_of_measurement !== 'pcs' || item?.stock?.packaging_format !== 'none'
          ? unitFormat(1, item?.stock?.unit_of_measurement) + ': '
          : ''}
        {unitSellingPrice.toFixed(2) ?? (price / item?.stock?.quantity_per_package).toFixed(2)}{' '}
        {currencies.uah}
      </Row>
    </Col>
  );

  const columns = useMemo(
    (): TableColumnProps<IInvoicePurchaseItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        render: (e) =>
          ListFieldWithTooltip(
            <Link
              className={'custom-links'}
              to={`/clinic/${clinicId}/inventory/stock/${e.stock.id}`}
            >
              {e.stock.name}
            </Link>
          )
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series ?? '-'
      },
      {
        key: 'format',
        title: locale.labels.purchaseFormat,
        render: (e) => itemFormat(e) ?? '-'
      },
      {
        key: 'price_per_unit',
        title: locale.labels.pricePerItem,
        render: (e) => (e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-')
      },
      {
        key: 'initial_quantity',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.initial_quantity ?? '-'
      },
      {
        key: 'total_price',
        title: locale.labels.totalPrice,
        render: (e) => `${e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.sellingPrice,
        render: (e) => getPrice(e, e?.selling_price, e?.unit_selling_price)
      },
      {
        key: 'action',
        title: locale.labels.actions,
        render: (item: IInvoicePurchaseItem) => (
          <Row>
            {!item.deleted && (
              <Button
                type='primary'
                onClick={() => {
                  open(MODAL.printPricesModal);
                  setSelectedStock(item);
                }}
                shape={'circle'}
              >
                <span className='icofont icofont-print' />
              </Button>
            )}
            {!item.deleted && !editingDisabled && (
              <Button
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  setItemToDelete(item);
                  open(MODAL.confirmDeletePurchaseModal);
                }}
                danger
                type='primary'
                shape={'circle'}
              >
                <span className='icofont icofont-trash' />
              </Button>
            )}
          </Row>
        )
      }
    ],
    [invoicePurchases, editingDisabled]
  );

  const printPricesClick = (): void => {
    open(MODAL.printMultiplePricesModal);

    const filteredInvoiceItemsData = invoicePurchases.filter((el) => !el.deleted);
    setAllInvoiceItems(filteredInvoiceItemsData.map((el) => el.stock));
  };

  return (
    <>
      <Table
        pagination={false}
        className='accent-header stock-list'
        rowKey='id'
        loading={isLoading}
        dataSource={invoicePurchases}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <Pagination
          current={+params.page}
          total={data.metadata.total}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
        <Row>
          <Button type='primary' onClick={printPricesClick} style={{ borderRadius: 30 }}>
            <span className='icofont icofont-print' />
            {locale.buttons.printPrices}
          </Button>
          {!editingDisabled && (
            <div
              className='invoice-row-container__add-item-container'
              style={{ width: 'fit-content' }}
            >
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{ borderRadius: 30, float: 'right', marginLeft: 8 }}
                onClick={() => open(MODAL.addItemsToStockModal)}
              >
                {locale.buttons.addInvoiceStockItems}
              </Button>
            </div>
          )}
        </Row>
        {isOpened(MODAL.confirmDeletePurchaseModal) && (
          <ConfirmDeleteModal open onCancel={closeModal} onOk={deletePurchase.mutate} />
        )}
        {isOpened(MODAL.printPricesModal) && (
          <PrintPricesModal prices={selectedStock?.stock?.prices} stock={selectedStock?.stock} />
        )}
      </Row>
      {isOpened(MODAL.printMultiplePricesModal) && (
        <PrintPricesModal stock={allInvoiceItems} multipleStocksMode />
      )}
    </>
  );
};
