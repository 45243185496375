import { API_ENDPOINTS } from 'api/constants/api_endpoints';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useDeleteBill = (clinicId: number, billId: number, callbackFn?: () => void) => {
  const mutationFn = () => axiosClient.delete(`${API_ENDPOINTS.BILLS(clinicId)}/${billId}`);

  return useCustomMutation(
    mutationFn,
    [
      ['billDetails', clinicId, billId],
      ['billTransactionsList', clinicId, billId]
    ],
    callbackFn
  );
};
