import './ClinicRegistration.scss';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Row, Select, Spin, Typography } from 'antd';
import { useClinicRegistration } from 'api/hooks/clinic/useClinicRegistration';
import { IClinicForm } from 'api/interfaces/Clinic';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import { useAccess } from 'hooks/useAccess';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { companyTypes } from 'i18n/companyTypesSelect';
import { AddButton } from 'pages/clinic/components/AddButton';
import { RemoveButton } from 'pages/clinic/components/RemoveButton';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { trimSocialLinks } from 'utils/trimSocialLinks';

// ToDo unused page
// import PricingPage from './PricingPage';

const { Title } = Typography;
const { Option } = Select;

const ClinicRegistration = (): JSX.Element => {
  const navigate = useNavigate();
  const userAccess = useAccess();
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.general');
  const companyTypesOptions = useI18n(companyTypes);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSuccess = (clinicId: number): void => {
    Modal.confirm({
      title: locale.labels.clinicSuccessfullyCreated,
      icon: <CheckCircleOutlined />,
      okText: locale.buttons.clinicInfo,
      cancelText: locale.buttons.homePage,
      onOk: () => {
        // TODO API redirect is not working properly
        navigate(`/clinic/${clinicId}/administration/info`);
      },
      onCancel: () => {
        navigate('/');
      }
    });
  };
  const createClinic = useClinicRegistration(onSuccess);

  const onSubmit = (values: IClinicForm): void => {
    const payload = {
      ...values,
      social_media_links: trimSocialLinks(values.social_media_links),
      additional_contacts: values.additional_contacts
        ? JSON.stringify(values.additional_contacts)
        : null
    };

    createClinic.mutate(payload);
  };

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setShowCancelModal(false);
  };

  const socialMediaList = (): JSX.Element[] =>
    socialMediaLinks.map((link, index) => (
      <Option key={index} value={link.value}>
        {link.value}
        {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
      </Option>
    ));

  return (
    <>
      <Title level={4}>{locale.labels.clinicRegistration}</Title>
      <Spin spinning={createClinic.isPending}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
          {/* {!subscriptionId ? (
            <PricingPage setSubscriptionId={setSubscriptionId} />
          ) : ( */}
          <div style={{ maxWidth: 690, margin: '0 auto' }}>
            <Row gutter={[20, 0]}>
              <Col span={24} md={12}>
                <Form.Item name='subscription_id' initialValue={1} hidden>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.clinicName}
                  name='name'
                  rules={[requiredRule(locale.messages.nameEmpty)]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={locale.labels.type} name='company_type'>
                  <Select
                    options={Object.keys(companyTypesOptions).map((value) => ({
                      value,
                      label: companyTypesOptions[value]
                    }))}
                  />
                </Form.Item>
                <PhoneFieldFormItem
                  formItemPhonePrefix={['country_code']}
                  formItemPhoneNumber={['phone_number']}
                />
                <Form.Item
                  label={locale.labels.eMail}
                  name='email'
                  rules={[
                    requiredRule(locale.messages.emailEmpty),
                    patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.address}
                  name='location'
                  rules={[requiredRule(locale.messages.addressEmpty)]}
                >
                  <Input />
                </Form.Item>
                {/* We should change working hours to 2 time selectors. React Calendar should use min/max values based on these selectors. */}
                <TextEditor label={locale.labels.workingHours} name={['working_hours']} />
                <TextEditor label={locale.labels.description} name={['description']} />
              </Col>
              <Row style={{ paddingLeft: '10px' }}>
                <Typography.Title level={5}>{locale.labels.socialMedia}</Typography.Title>
              </Row>
              <Row style={{ width: '100%', paddingLeft: '10px' }}>
                <Form.List name='social_media_links'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }) => (
                        <Row
                          key={key}
                          gutter={[20, 10]}
                          style={{ width: '100%' }}
                          className={'additional-links'}
                        >
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              rules={[requiredRule(locale.messages.selectSocialNetwork)]}
                            >
                              <Select
                                placeholder={locale.placeholders.selectSocialMedia}
                                filterOption={(input, option) =>
                                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                              >
                                {socialMediaList()}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'description']}
                              rules={[
                                requiredRule(locale.messages.specifyLink),
                                patternRule(PATTERNS.SOCIAL_LINK, locale.errors.invalidSocial)
                              ]}
                            >
                              <Input placeholder={locale.placeholders.linkToProfile} />
                            </Form.Item>
                          </Col>
                          <RemoveButton remove={() => remove(name)} />
                        </Row>
                      ))}
                      <AddButton add={add} fields={fields} label={locale.buttons.addSocialLinks} />
                    </>
                  )}
                </Form.List>
              </Row>
              <Row style={{ paddingLeft: '10px' }}>
                <Typography.Title level={5}>{locale.labels.additionalContacts}</Typography.Title>
              </Row>
              <Row style={{ width: '100%', paddingLeft: '10px' }}>
                <Form.List name='additional_contacts'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }) => (
                        <Row
                          key={key}
                          gutter={[20, 10]}
                          style={{ width: '100%' }}
                          className={'additional-links'}
                        >
                          <Col span={24} xs={24} md={11}>
                            <PhoneFieldFormItem
                              formItemPhonePrefix={[name, 'country_code']}
                              formItemPhoneNumber={[name, 'phone_number']}
                              hideLabel
                            />
                          </Col>
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'description']}
                              rules={[requiredRule(locale.messages.specifyDescription)]}
                            >
                              <Input placeholder={locale.placeholders.description} />
                            </Form.Item>
                          </Col>
                          <RemoveButton remove={() => remove(name)} />
                        </Row>
                      ))}
                      <AddButton
                        add={add}
                        fields={fields}
                        label={locale.buttons.addAdditionalContacts}
                      />
                    </>
                  )}
                </Form.List>
              </Row>
            </Row>
            <ViewDetailsButtonsGroup
              fixed
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                }
              }}
              accessGroup={userAccess.accesses.clinics}
              editMode
              showCreateBtn
              hideDeleteBtn
            />
          </div>
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </Form>
      </Spin>
    </>
  );
};

export default ClinicRegistration;
