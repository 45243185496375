import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import React, { useState } from 'react';

import { AddInvitationForm } from './AddInvitationForm';

export const AddInvitationModal = (): JSX.Element => {
  const userAccess = useAccess();
  const [form] = Form.useForm();
  const { closeModal } = useModal();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      title={'Invite employees'}
      centered={true}
      form={form}
      open
      onModalClose={closeModal}
      width={800}
    >
      <AddInvitationForm form={form}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.invitations}
          editMode={true}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </AddInvitationForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
