import { Col, Row } from 'antd';
import { IBillDetails } from 'api/interfaces/Bills';
import { TextEditorView } from 'components/TextEditorView';
import { paymentStatuses, yesNoBooleanOptions } from 'constants/dictionary/default/selectOptions';
import { localDateTime } from 'helpers/DateTimeUtils';
import { money } from 'helpers/list-helpers';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import valueFromList from 'utils/value-from-list';

type Props = {
  bill: IBillDetails;
  locale: ILocale;
  currency: string;
};

export const BillHeaderContent = ({ bill, locale, currency }: Props): JSX.Element => {
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const localizedYesNoBooleanOptions = useLocalizedList(yesNoBooleanOptions);

  return (
    <Row>
      <Col span={24} md={12} className='appointment-details-top-container__left-column'>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
          <span id='date'>{localDateTime(bill?.date)}</span>
        </Col>
        <Col>
          {!!bill?.final_amount && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.amount}: </span>
              <span id='price'>
                {money(bill.final_amount)} {currency}
              </span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.total_paid_amount && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.totalPaidAmount}: </span>
              <span id='total_paid'>
                {money(bill.total_paid_amount)} {currency}
              </span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.services_discount?.percentage && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.servicesDiscount}: </span>
              <span id='services_discount_percentage'>{bill.services_discount.percentage}% </span>
              <span id='services_discount_amount'>
                ({bill.services_discount.amount}
                {currency})
              </span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.stock_discount?.percentage && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.stockDiscount}: </span>
              <span id='stock_discount_percentage'>{bill.stock_discount.percentage}% </span>
              <span id='stock_discount_amount'>
                ({bill.stock_discount.amount}
                {currency})
              </span>
            </>
          )}
        </Col>
        <Col>
          <>
            <span style={{ fontWeight: 600 }}>{locale.labels.remainingAmount}: </span>
            <span id='remaining_amount'>
              {money(bill?.remaining_amount)} {currency}
            </span>
          </>
        </Col>
      </Col>
      <Col span={24} md={12} className='appointment-details-top-container__right-column'>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.category}: </span>
          <span id='category'>{bill?.category || '-'}</span>
        </Col>
        <Col>
          {!!bill?.vat_included && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.vatIncluded}: </span>
              <span id='vat_included'>
                {valueFromList(localizedYesNoBooleanOptions, bill.vat_included)}
              </span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.invoice && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.invoice}: </span>
              <span id='invoice_number'>{bill.invoice.invoice_number}</span>
            </>
          )}
        </Col>
        <Col>
          {!!bill?.payment_status && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.paymentStatus}: </span>
              <span id='status'>
                {valueFromList(localizedPaymentStatuses, bill.payment_status)}
              </span>
            </>
          )}
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.description}: </span>
          <TextEditorView value={bill?.description} id='description' borderless />
        </Col>
      </Col>
    </Row>
  );
};
