import { FormInstance } from 'antd';
import { IBillDetails } from 'api/interfaces/Bills';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { BillItemForm } from './BillItemForm';
import { EditAppointmentBillForm } from './EditAppointmentBillForm';

type Props = {
  initialValues: IBillDetails;
  form: FormInstance;
  locale: ILocale;
};

export const EditBillModal = ({ initialValues, form, locale }: Props): JSX.Element => {
  const { closeModal } = useModal();

  const userAccess = useAccess();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [formIsTouched, setFormIsTouched] = useState(false);

  const buttonsGroup = (): JSX.Element => (
    <ViewDetailsButtonsGroup
      fixed
      accessGroup={userAccess.accesses.services}
      onSaveModalClick={() => setFormIsTouched(false)}
      editMode
      hideDeleteBtn
      onCancelClick={() => {
        if (form.isFieldsTouched() || formIsTouched) {
          setShowCancelModal(true);
        } else {
          closeModal();
        }
      }}
    />
  );

  return (
    <>
      {initialValues && (
        <VLXModal
          title={locale.labels.editBill}
          formIsTouched={formIsTouched}
          centered
          open
          form={form}
          onModalClose={closeModal}
          width={800}
        >
          {initialValues?.appointment ? (
            <EditAppointmentBillForm
              locale={locale}
              form={form}
              setFormIsTouched={setFormIsTouched}
              initialValues={initialValues}
            >
              {buttonsGroup()}
            </EditAppointmentBillForm>
          ) : (
            <BillItemForm
              locale={locale}
              form={form}
              setFormIsTouched={setFormIsTouched}
              initialValues={initialValues}
              formEditMode
            >
              {buttonsGroup()}
            </BillItemForm>
          )}
        </VLXModal>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
