import './ClientsList.scss';

import { Button, Pagination, Row, Table, TableColumnProps, Tooltip, Typography } from 'antd';
import { useClientsList } from 'api/hooks/clients/useClientsList';
import { useClientPatientsList } from 'api/hooks/patients/useClientPatientsList';
import { IClient, IClientDetails } from 'api/interfaces/Client';
import { IPatient, IPatientDetails } from 'api/interfaces/Patient';
import { useLocaleCode } from 'api/store/localeContext';
import { MODAL, useModal } from 'api/store/modalStore';
import AppointmentListIcon from 'assets/img/new-appointment.svg';
import tomb from 'assets/img/tomb.svg';
import { DebtStatus } from 'components/DebtStatus';
import StickyButton from 'components/StickyButton';
import { VLXSearchNEW } from 'components/VLXSearchNEW';
import { animalIdUrl } from 'constants/common';
import dayjs from 'dayjs';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { AddAppointment } from 'layout/modals/addAppointment/AddAppointment';
import { ClientItemModal } from 'layout/modals/addClient/ClientItemModal';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge } from '../patients/utils/PatientHelper';

const defaultParams = {
  sort_key: 'last_visit_date',
  sort_order: 'desc',
  page: 1
};

const ClientsList = (): JSX.Element => {
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { isOpened, open } = useModal();
  const locale = useLocale('private.clients.client');
  const patientLocale = useLocale('private.patients.patient-page');
  const appModules = useAccess().availableModules;

  const [initialAppointmentData, setInitialAppointmentData] = useState(null);
  const [expandedClientId, setExpandedClientId] = useState<number | null>();

  const { localeCode } = useLocaleCode();

  const { data, clients, isLoading, params, updateParams, updateSorting } = useClientsList(
    clinicId,
    defaultParams
  );

  const { data: patients, isPending: isPatientsLoading } = useClientPatientsList(
    clinicId,
    expandedClientId
  );

  const redirectToPatientDetails = ({ id }: IPatient) => redirect(`patients/${id}`);
  const redirectToClientDetails = ({ id }: IClient) => redirect(`clients/${id}`);

  const redirect = (endpoint: string): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/${endpoint}`);
    }
  });

  const getClientAddress = (client: IClient): string =>
    ['country', 'region', 'city', 'address']
      .map((k) => client[k])
      .filter((value) => value !== null && value !== '')
      .join(', ') || '-';

  const phoneNumber = (c: IClient): JSX.Element | string => {
    if (!c.phone_number) return '-';

    return (
      <Link
        to={`tel:${c.country_code}${c.phone_number}`}
        className='custom-links d-flex align-baseline nowrap'
      >
        <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
        <span id='phone-number'>{`+${c.country_code}${c.phone_number}`}</span>
      </Link>
    );
  };

  const columns: TableColumnProps<IClient>[] = [
    {
      key: 'first_name',
      dataIndex: 'first_name',
      title: locale.labels.name,
      sorter: () => null,
      render: (_name, e) => (
        <>
          <span>
            {e.first_name} {e.last_name || ''}
          </span>
          {blacklistedStatus(e as IClientDetails)}
        </>
      ),
      onCell: redirectToClientDetails
    },
    {
      key: 'last_visit_date',
      title: locale.labels.lastVisitDate,
      sorter: () => null,
      render: (e) =>
        e?.last_visit_date ? dayjs(e.last_visit_date).format('DD.MM.YYYY HH:mm') : '-'
    },
    {
      key: 'phone_number',
      title: locale.labels.phone,
      sorter: () => null,
      render: phoneNumber
    },
    {
      key: 'email',
      title: locale.labels.email,
      sorter: () => null,
      render: (e) => `${e.email ? e.email : '-'}`,
      onCell: redirectToClientDetails
    },
    {
      key: 'debt',
      title: locale.labels.paymentsStatus,
      hidden: !appModules.finances,
      sorter: () => null,
      render: (e) => <DebtStatus debt={e.diff} />,
      onCell: redirectToClientDetails
    },
    {
      key: 'number_of_pets',
      title: locale.labels.numberOfPets,
      sorter: () => null,
      render: (e) => e.number_of_pets,
      onCell: redirectToClientDetails
    },
    {
      key: 'address',
      title: locale.labels.address,
      sorter: () => null,
      render: (e) => getClientAddress(e),
      onCell: redirectToClientDetails
    },
    {
      key: 'id',
      title: locale.labels.actions,
      render: (e) => (
        <div className='buttons-list nowrap'>
          <Button
            onClick={() => {
              setInitialAppointmentData({
                client_id: e.id,
                phone_number: e.phone_number
              });
              open(MODAL.addAppointment);
            }}
            shape='circle'
            type='primary'
          >
            <img
              src={AppointmentListIcon}
              style={{
                width: 18,
                color: 'white',
                marginLeft: 1
              }}
            />
          </Button>
        </div>
      )
    }
  ];

  const getAnimalData = (data: IPatientDetails): string => {
    const { species, breed } = localizedPetData(data, localeCode);
    return species ? (breed ? `${species} (${breed})` : species) : '-';
  };

  const getAge = (e: IPatient): JSX.Element =>
    e?.date_of_death ? (
      <Typography id='patient-death-icon'>
        <Tooltip
          trigger={'hover'}
          placement='topLeft'
          title={getAnimalAge(patientLocale, e?.birth_date, e?.date_of_death)}
        >
          {'\u00A0'}
          <img
            src={tomb}
            style={{
              width: 22,
              height: 22
            }}
          />
        </Tooltip>
      </Typography>
    ) : (
      <Typography id='list-pet-age'>
        {e?.birth_date ? getAnimalAge(locale, e?.birth_date) : '-'}
      </Typography>
    );

  const expandedColumns = useMemo(
    (): TableColumnProps<IPatient>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        onCell: redirectToPatientDetails,
        render: (name) => name
      },
      {
        key: 'age',
        title: locale.labels.age,
        onCell: redirectToPatientDetails,
        render: getAge
      },
      {
        key: 'species',
        title: locale.labels.speciesBreed,
        onCell: redirectToPatientDetails,
        render: getAnimalData
      },
      {
        key: 'chip_number',
        title: locale.labels.chip,
        render: (e) =>
          e.chip_number ? (
            <a className={'custom-links'} href={animalIdUrl + e.chip_number}>
              {e.chip_number}
            </a>
          ) : (
            '-'
          )
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <div className='buttons-list nowrap'>
            <Button
              onClick={() => {
                setInitialAppointmentData({
                  patients_ids: [e.id],
                  client_id: expandedClientId,
                  phone_number: clients.find((el) => el.id === expandedClientId).phone_number
                });
                open(MODAL.addAppointment);
              }}
              shape='circle'
              type='primary'
            >
              <img
                src={AppointmentListIcon}
                style={{
                  width: 18,
                  color: 'white',
                  marginLeft: 3
                }}
              />
            </Button>
          </div>
        )
      }
    ],
    [patients?.data, expandedClientId]
  );

  const expandedRowRender = (): JSX.Element =>
    patients?.data && (
      <Table
        rowKey='id'
        loading={isPatientsLoading}
        columns={expandedColumns}
        dataSource={patients?.data}
        pagination={false}
        size='small'
        className='client-table'
        bordered
      />
    );

  return (
    <>
      <VLXSearchNEW onFilterChange={updateParams} />
      <Table
        pagination={false}
        className='accent-header clients-list-table'
        rowKey='id'
        loading={isLoading}
        dataSource={clients}
        columns={columns}
        onChange={updateSorting}
        expandable={{
          onExpand: (expanded: boolean, record: IClient) => {
            setExpandedClientId(expanded ? record.id : null);
          },
          expandedRowKeys: [expandedClientId],
          expandedRowRender
        }}
      />
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}
      >
        <Pagination
          current={+params.page}
          total={data.metadata.total || 0}
          showSizeChanger={false}
          pageSize={20}
          onChange={(page) => updateParams({ page })}
        />
      </Row>
      {isOpened(MODAL.clientItemModal) && <ClientItemModal />}
      <StickyButton
        offsetBottom={10}
        onClick={() => open(MODAL.clientItemModal)}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addClient}
      />
      {isOpened(MODAL.addAppointment) && (
        <AddAppointment initialAppointmentData={initialAppointmentData} />
      )}
    </>
  );
};

export default ClientsList;
