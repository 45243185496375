import { Form } from 'antd';
import { IBankAccount } from 'api/interfaces/Finances';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { FinancialAccountForm } from './FinancialAccountForm';

type Props = {
  initialValues: IBankAccount;
  locale: ILocale;
};

export const EditFinancialAccountModal = ({ initialValues, locale }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const { closeModal } = useModal();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <>
      <VLXModal
        title={locale.labels.editAccount}
        centered
        open
        form={form}
        onModalClose={closeModal}
        width={800}
      >
        <FinancialAccountForm locale={locale} form={form} initialValues={initialValues}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.services}
            editMode
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                closeModal();
              }
            }}
            onDeleteClick={() => setShowCancelModal(false)}
          />
        </FinancialAccountForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
