import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStock } from 'api/interfaces/Stock';

export const useStockDetails = (clinicId: number, stockId: number) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(`${API_ENDPOINTS.STOCK(clinicId)}/${stockId}`);
    return data;
  };

  return useQuery<IStock>({
    queryFn,
    queryKey: ['stockDetails', clinicId, stockId],
    enabled: !!stockId
  });
};
