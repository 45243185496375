import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockPurchaseList } from 'api/interfaces/Stock';

import { useTableData } from '../useTableData';

export const useStockPurchases = (
  clinicId: number,
  stockId: number,
  defaultParams: Record<string, string | number> = { page: 0 }
) => {
  const endpoint = stockId ? `${API_ENDPOINTS.STOCK(clinicId)}/${stockId}/items` : null;
  const queryKeys = ['stockPurchases', clinicId, stockId];

  const result = useTableData<IStockPurchaseList>(endpoint, defaultParams, queryKeys, !!stockId);

  return {
    ...result,
    stockItems: result.data.data || []
  };
};
