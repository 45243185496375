import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { DICTIONARY } from 'api/constants/dictionary';
import { IDictionariesList } from 'api/interfaces/Dictionary';

import { useTable } from '../useTable';

export const useDictionary = (
  clinicId: number,
  key: DICTIONARY,
  params: Record<string, number | string | string[]> = { page: 0 }
) => {
  const endpoint = `${API_ENDPOINTS.DICTIONARIES(clinicId)}/${key}`;
  const result = useTable<IDictionariesList>(endpoint, params, [key, clinicId], 10);

  return {
    ...result,
    dictionary: result.data.data || []
  };
};
