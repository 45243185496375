import { Select } from 'antd';
import { useRoomsList } from 'api/hooks/rooms/useRoomsList';
import { IRoom } from 'api/interfaces/Room';
import { roomStatuses } from 'constants/dictionary/default/selectOptions';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import React from 'react';

interface Props {
  appointment?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const { Option } = Select;

export const RoomsSelect = ({ disabled = false, appointment, onChange }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const localizedRoomStatuses = useLocalizedList(roomStatuses);

  const { rooms } = useRoomsList(clinicId, { page: 0, 'q[deleted]': 'false' });

  const getRoomStatus = (stat: string): string => {
    const res = localizedRoomStatuses.find((el) => el?.value === stat);
    return res?.label;
  };

  const roomsList = (): JSX.Element[] => {
    let roomsList: IRoom[] = rooms;
    if (appointment) {
      roomsList = rooms?.filter((room: IRoom) => !room.not_for_appointment);
    }
    return roomsList?.map((item: IRoom) => (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
          <span style={{ color: 'grey', paddingLeft: 10 }}>{getRoomStatus(item?.status)}</span>
        </div>
      </Option>
    ));
  };

  return (
    <Select
      id='location-room'
      onChange={onChange}
      disabled={disabled}
      filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      showSearch
    >
      {roomsList()}
    </Select>
  );
};
