import { Form } from 'antd';
import { useModal } from 'api/store/modalStore';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useAccess } from 'hooks/useAccess';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';

import { BillItemForm } from './BillItemForm';

export const AddBillModal = (): JSX.Element => {
  const [form] = Form.useForm();
  const userAccess = useAccess();
  const locale = useLocale('private.finances.bills');
  const { closeModal } = useModal();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <VLXModal
      title={locale.labels.createBill}
      form={form}
      centered
      open
      onModalClose={closeModal}
      width={800}
    >
      <BillItemForm locale={locale} form={form}>
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.bills}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              closeModal();
            }
          }}
          onDeleteClick={() => setShowCancelModal(false)}
        />
      </BillItemForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={closeModal}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
