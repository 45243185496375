import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IToolDetails } from 'api/interfaces/Tool';

export const useToolDetails = (clinicId: number, toolId: number) => {
  return useQuery<IToolDetails>({
    queryKey: ['toolDetails', clinicId, toolId],
    queryFn: async () => {
      const { data } = await axiosClient.get(`${API_ENDPOINTS.TOOLS(clinicId)}/${toolId}`);
      return data;
    },
    enabled: !!toolId
  });
};
