import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IRoomPayload } from 'api/interfaces/Room';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useCreateRoom = (clinicId: number, callbackFn?: () => void) => {
  return useCustomMutation<IRoomPayload, void>(
    (payload: IRoomPayload) => axiosClient.post(`${API_ENDPOINTS.ROOMS(clinicId)}`, payload),
    ['roomsList'],
    callbackFn
  );
};
