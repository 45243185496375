import { Column } from '@ant-design/charts';
import { IDebt } from 'api/interfaces/Stats';
import { ILocale } from 'interfaces/locale';
import React from 'react';

type Props = {
  total_debts?: IDebt[];
  locale: ILocale;
};

export const TotalDebtsColumnChart = ({ total_debts, locale }: Props): JSX.Element => {
  return (
    <Column
      title={locale.labels.totalDebts}
      data={total_debts || []}
      isStack={false}
      xField='type'
      yField='amount'
      colorField='type'
      style={{
        maxWidth: 50,
        minWidth: 30
      }}
      label={{
        text: 'label',
        textBaseline: 'bottom',
        style: {
          fill: '#000',
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center'
        },
        offset: 10
      }}
    />
  );
};
