import 'assets/sass/components/_table.scss';

import { Col, Form, Row, Spin, Typography } from 'antd';
import { useClinicInfo } from 'api/hooks/clinic/useClinicInfo';
import { useEditClinic } from 'api/hooks/clinic/useEditClinic';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXInputNumber from 'components/VLXInputNumber';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { requiredRule } from 'utils/form-rules';

export const GeneralSettings = (): JSX.Element => {
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.general-settings');
  const userAccess = useAccess();

  const { data: clinic, isPending } = useClinicInfo(clinicId);
  const editClinic = useEditClinic(clinicId);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [clinic]);

  const onSubmit = (values: { markup_package: number; markup_unit: number }): void => {
    if (form.isFieldsTouched()) {
      const apiData = {
        ...clinic,
        markup_package: Number(values.markup_package),
        markup_unit: Number(values.markup_unit)
      };
      editClinic.mutate(apiData);
    }
    setEditMode(false);
  };

  return (
    <Spin spinning={isPending || editClinic.isPending}>
      <Form
        layout='vertical'
        onFinish={onSubmit}
        form={form}
        initialValues={{
          markup_package: clinic?.markup_package,
          markup_unit: clinic?.markup_unit
        }}
        onFieldsChange={() => setEditMode(true)}
      >
        <Typography.Title level={4}>{locale.labels.defaultMarkups}</Typography.Title>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <Form.Item
              label={locale.labels.perPackage}
              name='markup_package'
              rules={[requiredRule(locale.errors.canNotBeEmpty)]}
            >
              <VLXInputNumber precision={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <Form.Item
              label={locale.labels.perUnit}
              name='markup_unit'
              rules={[requiredRule(locale.errors.canNotBeEmpty)]}
            >
              <VLXInputNumber precision={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.clinics}
              editMode={editMode}
              hideDeleteBtn
              onEditClick={() => setEditMode(true)}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  form.resetFields();
                }
                setEditMode(false);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
