import { useQuery } from '@tanstack/react-query';
import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';

type DocumentProps = {
  endpoint: string;
  queryKey: (string | number)[];
  enabled?: boolean;
};

export const useInvoiceDocuments = (clinicId: number, invoiceId: number) =>
  useDocumentsList({
    endpoint: `${API_ENDPOINTS.INVOICES(clinicId)}/${invoiceId}/documents`,
    queryKey: ['invoiceDocuments', clinicId, invoiceId],
    enabled: !!invoiceId
  });

export const useClientDocuments = (clinicId: number, clientId: number) =>
  useDocumentsList({
    endpoint: `${API_ENDPOINTS.CLIENTS(clinicId)}/${clientId}/documents`,
    queryKey: ['clientDocuments', clinicId, clientId],
    enabled: !!clientId
  });

export const usePatientDocuments = (clinicId: number, patientId: number) =>
  useDocumentsList({
    endpoint: `${API_ENDPOINTS.PATIENTS(clinicId)}/${patientId}/documents`,
    queryKey: ['patientDocuments', clinicId, patientId],
    enabled: !!patientId
  });

export const useClinicDocuments = (clinicId: number) =>
  useDocumentsList({
    endpoint: `${API_ENDPOINTS.CLINICS(clinicId)}/documents`,
    queryKey: ['clinicDocuments', clinicId],
    enabled: !!clinicId
  });

export const useDocumentsList = ({ endpoint, queryKey, enabled }: DocumentProps) => {
  const queryFn = async () => {
    const { data } = await axiosClient.get(endpoint);
    return data;
  };
  return useQuery({ queryFn, queryKey, enabled });
};
