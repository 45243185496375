import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IProvider } from 'api/interfaces/Provider';

import axiosClient from '../../axiosClient';
import { useCustomMutation } from '../useCustomMutation';

export const useEditProvider = (
  clinicId: number,
  providerId: number,
  callbackFn?: (data) => void
) => {
  const mutationFn = (payload: IProvider) =>
    axiosClient.put(`${API_ENDPOINTS.PROVIDERS(clinicId)}/${providerId}`, payload);

  return useCustomMutation(
    mutationFn,
    [
      ['providersList', clinicId],
      ['providerDetails', clinicId, providerId]
    ],
    callbackFn
  );
};
