import axiosClient from 'api/axiosClient';
import { API_ENDPOINTS } from 'api/constants/api_endpoints';
import { IStockPayload } from 'api/interfaces/Stock';

import { useCustomMutation } from '../useCustomMutation';

export const useCreateStock = (clinicId: number, callbackFn?: (data) => void) => {
  const mutationFn = (payload: IStockPayload) =>
    axiosClient.post(`${API_ENDPOINTS.STOCK(clinicId)}`, payload);

  return useCustomMutation(mutationFn, ['stockList', clinicId], callbackFn);
};
