import './AppointmentDetails.scss';

import { Col, Collapse, Menu, MenuProps, Row, Spin, Tag, Tooltip, Typography } from 'antd';
import { useAppointmentBill } from 'api/hooks/appointments/useAppointmentBill';
import { useAppointmentDetails } from 'api/hooks/appointments/useAppointmentDetails';
import { useAppointmentPatients } from 'api/hooks/appointments/useAppointmentPatients';
import { useClientDetails } from 'api/hooks/clients/useClientDetails';
import { usePatientDocuments } from 'api/hooks/documents/useDocuments';
import { useUploadPatientDocument } from 'api/hooks/documents/useUploadDocument';
import { IHealthRecord, IHealthRecordService, IPatientDetails } from 'api/interfaces/Patient';
import { MODAL, useModal } from 'api/store/modalStore';
import checkedIcon from 'assets/img/checked.svg';
import warningIcon from 'assets/img/warning.svg';
import DocumentsTab from 'components/DocumentsTab';
import { HealthRecordsHistory } from 'components/HealthRecordsHistory';
import { PremiumSubscriptionTooltip } from 'components/PremiumSubscriptionTooltip';
import { useAccess } from 'hooks/useAccess';
import useClinicId from 'hooks/useClinicId';
import { useAppointmentId } from 'hooks/useId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import { AddAppointmentPatient } from 'layout/modals/addAppointmentPatient/AddAppointmentPatient';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ClientDetailsPetsTab } from '../clients/ClientDetailsPetsTab';
import { ClientPatientsList } from '../clients/ClientPatientsList';
import { PatientVaccinationDetails } from '../patients/PatientVaccinationDetails';
import { AppointmentDetailsPatientTab } from './AppointmentDetailsPatientTab';
import AppointmentDetailsTopContainer from './AppointmentDetailsTopContainer';
import { AppointmentServicesContainer } from './AppointmentServices/AppointmentServicesContainer';

const AppointmentDetails = (): JSX.Element => {
  const { isOpened, open } = useModal();
  const clinicId = useClinicId();
  const appointmentId = useAppointmentId();
  const locale = useLocale('private.appointments.event');
  const currencies = useLocale('private.currencies').labels;
  const patientsLocale = useLocale('private.patients.patient-page');
  const { availableModules, accesses } = useAccess();
  const patientIdSearchParam = useLinkParams('patientId');
  const activeTabSearchParam = useLinkParams('tab');

  const [selectedPatientServices, setSelectedPatientServices] =
    useState<IHealthRecordService[]>(null);
  const [servicesStockStatus, setServicesStockStatus] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState<IPatientDetails>(null);
  const [currentTab, setCurrentTab] = useState('card');
  const [currentPatient, setCurrentPatient] = useState(null);
  const [patientHealthRecord, setPatientHealthRecord] = useState<IHealthRecord>(null);

  const { data: appointment, isPending } = useAppointmentDetails({
    clinic_id: clinicId,
    appointment_id: appointmentId
  });
  const { data: appointmentBill } = useAppointmentBill(clinicId, appointment?.id);
  const { data: appointmentClient } = useClientDetails(clinicId, appointment?.client?.id);
  const { data: appointmentPatients } = useAppointmentPatients(clinicId, appointmentId);
  const { data: patientDocuments } = usePatientDocuments(clinicId, selectedPatient?.id);
  const uploadDocument = useUploadPatientDocument(clinicId, selectedPatient?.id);

  useEffect((): void => {
    const statuses = selectedPatientServices?.map((el): { id: number; status: boolean } => ({
      id: el?.id,
      status: el?.stock_saved
    }));

    setServicesStockStatus(statuses);
  }, [selectedPatientServices]);

  useEffect(() => {
    changeSelectedPatient(+currentPatient);
  }, [patientIdSearchParam, appointmentPatients]);

  useEffect(() => {
    if (activeTabSearchParam) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  const getDetailsTabIcon = (): JSX.Element => {
    const healthRecord = appointmentPatients?.find(
      (el) => el.id === selectedPatient?.id
    )?.health_record;

    return getTabIcon(healthRecord?.completed, locale.labels.notFilled);
  };

  const getStockTabIcon = (): JSX.Element => {
    let checked = true;

    if (!servicesStockStatus?.length || servicesStockStatus?.some((service) => !service.status)) {
      checked = false;
    }

    return getTabIcon(checked, locale.labels.mandatoryToEnter);
  };

  const getTabIcon = (checked: boolean, tooltipText: string): JSX.Element => (
    <Tooltip trigger={'hover'} placement='topLeft' title={!checked && tooltipText}>
      <img
        style={{ height: 20, width: 20, verticalAlign: -4 }}
        src={checked ? checkedIcon : warningIcon}
      />
    </Tooltip>
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: locale.tabs.card,
        key: 'card'
      },
      {
        label: locale.tabs.details,
        key: 'details',
        icon: getDetailsTabIcon()
      },
      {
        label: locale.tabs.servicesAndStock,
        key: 'stock',
        icon: getStockTabIcon()
      },
      {
        label: locale.tabs.vaccinations,
        key: 'vaccinations'
      },
      {
        label: (
          <PremiumSubscriptionTooltip display={!availableModules.documents}>
            {locale.tabs.documents}
          </PremiumSubscriptionTooltip>
        ),
        key: 'documents',
        disabled: !availableModules.documents
      },
      {
        label: locale.tabs.history,
        key: 'history'
      }
    ],
    [servicesStockStatus, appointmentPatients, selectedPatient, appointment, availableModules]
  );

  const disableFields = useMemo((): boolean => {
    if (!appointmentBill) return false;
    return (
      ['partially_paid', 'paid'].includes(appointmentBill.payment_status) ||
      ['finished', 'cancelled', 'future'].includes(appointment?.status)
    );
  }, [appointment?.status, appointmentBill?.payment_status]);

  const disableVisitDetails = (): boolean => {
    return ['cancelled', 'future'].includes(appointment?.status);
  };

  const disablePatientsListModifications = (): boolean => {
    if (!appointmentBill) return false;
    return ['cancelled', 'finished'].includes(appointment?.status);
  };

  const changeSelectedPatient = (patientId: number): void => {
    if (!appointmentPatients?.length) return;

    const patientLookup = (id: number) => {
      const patient = appointmentPatients.find((el) => el?.id === id);

      if (patient) {
        onSelectedPatientChange(patient);
      }
    };

    if (patientId) {
      patientLookup(patientId);
      return;
    } else if (patientIdSearchParam) {
      patientLookup(+patientIdSearchParam);
      setCurrentPatient(patientIdSearchParam);
      return;
    }
    const patient = appointmentPatients[0];
    onSelectPatient(patient.id);
    onSelectedPatientChange(patient);
  };

  const onSelectedPatientChange = (patient: IPatientDetails): void => {
    setSelectedPatient(patient);
    setPatientHealthRecord(patient?.health_record);
    setSelectedPatientServices(patient?.health_record?.services);
  };

  const onSelectPatient = (id: number): void => {
    setCurrentPatient(id);
    changeSelectedPatient(id);
    pushHistoryState(id, 'card');
  };

  const callbackAfterPatientRemove = (patientId: number) => {
    const res = appointmentPatients.find((el) => el.id !== patientId);
    if (res) {
      onSelectPatient(res.id);
    }
  };

  const changeTab: MenuProps['onClick'] = (e): void => {
    pushHistoryState(currentPatient, e.key);
  };

  const pushHistoryState = (patientId: number, tab: string): void => {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?patientId=${patientId}&tab=${tab}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      setCurrentTab(tab);
    }
  };

  function saveStatusTag(serviceId: number): JSX.Element {
    const isServicesStockStatusSaved = servicesStockStatus?.find(
      (el) => el.id === serviceId
    )?.status;

    return (
      <Tag
        id='bill-status-type'
        color={isServicesStockStatusSaved ? 'green' : 'red'}
        style={{
          fontWeight: 'bold'
        }}
      >
        {isServicesStockStatusSaved
          ? locale.labels.savedStockChanges
          : locale.labels.unsavedStockChanges}
      </Tag>
    );
  }

  const collapseHeader = useCallback(
    (service: IHealthRecordService): JSX.Element => (
      <Row gutter={[24, 12]} justify={'start'} className={'collapse-header-row'}>
        <Col xs={24} sm={24} md={8} lg={7} xl={9}>
          <Typography.Title level={5}>{service?.name}</Typography.Title>
        </Col>
        <Col xs={24} sm={24} md={7} lg={8} xl={6}>
          <div>{saveStatusTag(service?.id)}</div>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={7} style={{ justifyContent: 'end' }}>
          <Typography.Title level={5}>{calculateFullPrice(service)}</Typography.Title>
        </Col>
      </Row>
    ),
    [servicesStockStatus]
  );

  const totalSum = (selectedPatientServices) => {
    return selectedPatientServices?.reduce((serviceAcc, service) => {
      // Start with the service's total_price.
      let serviceSum = service.total_price;

      // If the service has a stock array, process each stock item.
      if (Array.isArray(service.stock)) {
        serviceSum += service.stock.reduce((stockAcc, stockItem) => {
          // Only add the stock's price if it is not included in the service price.
          if (!stockItem.included_in_service_price) {
            // Uncomment the next line if you need to consider quantity:
            // return stockAcc + (stockItem.price * stockItem.quantity);
            return stockAcc + stockItem.price;
          }
          return stockAcc;
        }, 0);
      }

      // Accumulate the service sum.
      return serviceAcc + serviceSum;
    }, 0);
  };

  const calculateFullPrice = (service: IHealthRecordService): string => {
    const startingPrice = service?.total_price;
    let stockPrice = 0;
    service?.stock.forEach((item) => {
      stockPrice = stockPrice + (+item.price || 0);
    });

    const price = (Number(startingPrice) + Number(stockPrice)).toFixed(2);
    return `${currencies.totalPrice}: ${price} ${currencies.uah}`;
  };

  return (
    <Spin spinning={isPending} className='appointment-details-page-spin-container'>
      {appointment && (
        <>
          <AppointmentDetailsTopContainer
            appointment={appointment}
            appointmentClient={appointmentClient}
            appointmentBill={appointmentBill}
            healthRecordsNotFinalized={appointmentPatients?.some(
              (patient) => !patient.health_record
            )}
            inventoryItemsNotFinalized={
              servicesStockStatus?.length === 0 ||
              servicesStockStatus?.some((service) => !service.status)
            }
          />
          <Col span={24} className='appointment-container'>
            <Col className='ant-card-bordered personal-info-card'>
              {appointmentPatients && (
                <Row>
                  <ClientPatientsList
                    appointmentId={appointment.id}
                    clientPatients={appointmentPatients}
                    selectedPatientId={selectedPatient?.id}
                    lgRowWidth={6}
                    setSelectedPatient={onSelectPatient}
                    addNewPetAction={() => open(MODAL.addAppointmentPatient)}
                    disabled={disablePatientsListModifications()}
                    removeCallbackFn={callbackAfterPatientRemove}
                  />
                  <Col span={24} lg={18} className='client-details__patient-details'>
                    <Menu
                      onClick={changeTab}
                      selectedKeys={[currentTab]}
                      mode='horizontal'
                      items={items}
                      style={{ marginBottom: 20 }}
                    />
                    {currentTab === 'card' && !!selectedPatient && (
                      <ClientDetailsPetsTab
                        key={'clients tab'}
                        accessGroup={accesses.appointments.edit}
                        data={selectedPatient}
                      />
                    )}
                    {currentTab === 'details' && (
                      <AppointmentDetailsPatientTab
                        key={'details tab'}
                        locale={locale}
                        appointment={appointment}
                        patientId={selectedPatient?.id}
                        initialValues={patientHealthRecord}
                        disabled={disableVisitDetails()}
                      />
                    )}
                    {currentTab === 'stock' && (
                      <Spin spinning={selectedPatientServices === null}>
                        <React.Fragment key={'clients tab'}>
                          <AppointmentDetailsPatientTab
                            key={'details tab'}
                            locale={locale}
                            onlyServices
                            appointment={appointment}
                            patientId={selectedPatient?.id}
                            initialValues={patientHealthRecord}
                            disabled={disableFields}
                          />
                          {selectedPatientServices?.length > 0 && (
                            <>
                              <Collapse
                                accordion
                                className='appointment-items-collapse-header'
                                size='large'
                                style={{ marginTop: 20 }}
                                items={selectedPatientServices.map((el) => ({
                                  label: collapseHeader(el),
                                  key: el?.id,
                                  children: (
                                    <AppointmentServicesContainer
                                      key={el?.id}
                                      healthRecordId={patientHealthRecord.id}
                                      disabled={disableFields}
                                      serviceData={el}
                                      servicesStockStatus={servicesStockStatus}
                                      setServicesStockStatus={setServicesStockStatus}
                                    />
                                  )
                                }))}
                              ></Collapse>
                              <Row
                                className='services-total-price-row'
                                style={{ marginTop: '20px' }}
                              >
                                <div style={{ display: 'flex', marginRight: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{`${locale.labels.finalTotalPrice}: `}</span>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginLeft: '10px'
                                    }}
                                  >
                                    <span id='services-total-price'>{`${totalSum(
                                      selectedPatientServices
                                    )?.toFixed(2)} ${currencies.uah}`}</span>
                                  </div>
                                </div>
                              </Row>
                            </>
                          )}
                        </React.Fragment>
                      </Spin>
                    )}
                    {currentTab === 'vaccinations' && selectedPatient?.id && (
                      <PatientVaccinationDetails
                        locale={patientsLocale}
                        patientId={selectedPatient?.id}
                        clientId={appointmentClient?.id}
                      />
                    )}
                    {currentTab === 'documents' && availableModules.documents && (
                      <DocumentsTab uploadDocument={uploadDocument} data={patientDocuments} />
                    )}
                    {currentTab === 'history' && !!selectedPatient && (
                      <HealthRecordsHistory
                        key={'history tab'}
                        clinicId={clinicId}
                        patientId={selectedPatient.id}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
          {isOpened(MODAL.addAppointmentPatient) && (
            <AddAppointmentPatient
              clientId={appointment?.client?.id}
              appointmentId={appointment?.id}
              appointmentPatients={appointmentPatients}
            />
          )}
        </>
      )}
    </Spin>
  );
};
export default AppointmentDetails;
